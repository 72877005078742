import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { Fragment, useEffect, useContext, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"
import slugify from "slug"
import { Menu, Transition } from "@headlessui/react"

import { MainModalContext } from "@/hooks/MainModalHook"

import { copyToClipboard } from "@/components/utils/clipboard"
import { UserDataContext } from "@/hooks/UserDataHook"
import { CategoryContext } from "@/context/CategoryContext"
import Category from "@/components/modals/changeOrder/ChangeOrderCategory"
import useModal from "@/hooks/useModal"
import ConfirmationModal from "../modals/ConfirmationModal"
import { MenuIconVertical } from "@/svg/SvgIcons"

export const PRESENTATION_URL = gql`
  query presentationUrl($id: String!) {
    presentationUrl(id: $id)
  }
`

const REMOVE_PRESENTATION = gql`
  mutation removePresentation($id: String!) {
    removePresentation(id: $id) {
      code
      success
      message
      presentation {
        batchId
      }
    }
  }
`

// TODO: BU: setModalIsOpened, getSlidesAndLock & refetch are a temporary solution and should be removed/refactored
const ContextMenu = ({
  batch,
  type,
  setModalIsOpened,
  refetch,
  getSlidesAndLock,
  isOpened,
  setIsOpened,
  isSearchPage = false
}) => {
  const history = useHistory()

  const { openModal } = useContext(MainModalContext)
  const {
    user,
    user: { isEditModeActive }
  } = useContext(UserDataContext)
  const { categories, moveBatchToCategory, onRemoveBatch } = useContext(CategoryContext)

  const { isOpen, openModal: openConfirmationModal, closeModal: closeConfirmationModal } = useModal()

  const isMember = user.role === "member"
  const arePresentationActionsDisabled =
    ["templates", "shared", "sharedBatches", "favourites"].includes(type) && isMember

  const [fetchPresentationUrl] = useLazyQuery(PRESENTATION_URL, {
    context: { isUsingNewScApi: true },
    variables: { id: batch.batchId },
    fetchPolicy: "network-only"
  })

  const [removePresentation] = useMutation(REMOVE_PRESENTATION, {
    context: { isUsingNewScApi: true },
    update(cache) {
      cache.evict({ id: cache.identify(batch) })
      onRemoveBatch && onRemoveBatch(batch)
    }
  })

  const handleDownloadPresentation = async () => {
    const {
      data: { presentationUrl }
    } = await fetchPresentationUrl()
    if (presentationUrl) window.location.href = presentationUrl
  }

  const handleEditInPowerPoint = () => {
    const base64jwtToken = btoa(localStorage.getItem("jwtToken") as string)
    const base64batchId = btoa(batch.batchId as string)
    const base64slideId = btoa("undefined")
    const random5token = Math.random().toString(36).slice(-5)

    window.location.href = `ms-powerpoint:ofe|u|${
      import.meta.env.VITE_SC_WEBDAV_PATH
    }/${base64jwtToken}/${base64batchId}/${base64slideId}/${random5token}.pptx`

    getSlidesAndLock({ variables: { id: batch.batchId } })
  }

  const handleDelete = async () => {
    await removePresentation({ variables: { id: batch.batchId } })

    const presentationCategory = categories.find((category: typeof Category) => category._id === batch?.category?._id)

    if (presentationCategory) {
      history.push(`/library/${presentationCategory.urlToken}/${slugify(presentationCategory.name)}`)
    }
  }

  const isShowShareOptions = useMemo(() => {
    return !isMember || batch.mode === "slides"
  }, [isMember, batch.mode])

  return (
    <>
      <Menu
        as="div"
        className={`cursor-auto ${isSearchPage ? "" : "absolute"} text-left top-0 right-3 ${
          isSearchPage || (!isSearchPage && isOpened) ? "opacity-100" : "opacity-0 group-hover:opacity-100"
        }`}
      >
        {({ open }) => {
          useEffect(() => {
            setIsOpened(open)
          }, [open])
          return (
            <>
              <Menu.Button
                className={`flex hover:text-sc-blue ${isSearchPage ? "text-sc-font-gray" : "text-gray-300"}`}
                id="contextMenu"
              >
                {isSearchPage ? (
                  <MenuIconVertical />
                ) : (
                  <svg
                    aria-hidden="true"
                    className="w-10 h-10"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                  </svg>
                )}
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className="[&>*]:cursor-pointer absolute right-0 z-20 w-56 origin-top-right rounded-md bg-white shadow-lg ring-gray-300 focus:outline-none py-1"
                  id="contextMenuItems"
                >
                  {/* TODO: BU: refactor this disabled thing */}
                  <Menu.Item disabled={batch.sets[0]?.slides?.length < 1}>
                    {({ active, disabled }) => (
                      <a
                        className={`block px-4 py-2 text-xs ${disabled ? "opacity-50 pointer-events-none" : ""} ${
                          active ? "bg-gray-100 !text-blue-500" : "text-gray-500"
                        }`}
                        onClick={handleDownloadPresentation}
                      >
                        Download
                      </a>
                    )}
                  </Menu.Item>
                  {!arePresentationActionsDisabled && !isMember && (
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={`block px-4 py-2 text-xs ${
                            active ? "bg-gray-100 !text-blue-500" : "text-gray-500"
                          }`}
                          onClick={() => {
                            if (type === "templates") {
                              openModal({
                                content: "editCategory",
                                data: {
                                  id: batch.id,
                                  batchId: batch.batchId,
                                  categoryId: batch.category?._id,
                                  refetch
                                }
                              })
                            } else {
                              // TODO: BU: refactor this
                              setModalIsOpened(true)
                            }
                          }}
                        >
                          Edit
                        </a>
                      )}
                    </Menu.Item>
                  )}
                  {isShowShareOptions && (
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={`block px-4 py-2 text-xs ${
                            active ? "bg-gray-100 !text-blue-500" : "text-gray-500"
                          }`}
                          onClick={() =>
                            openModal({
                              content: "SharePresentationModal",
                              data: {
                                batch,
                                type,
                                refetch
                              }
                            })
                          }
                        >
                          Share
                        </a>
                      )}
                    </Menu.Item>
                  )}
                  {type !== "templates" && isEditModeActive && (
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={`block px-4 py-2 text-xs ${
                            active ? "bg-gray-100 !text-blue-500" : "text-gray-500"
                          }`}
                          onClick={handleEditInPowerPoint}
                        >
                          Edit in PowerPoint
                        </a>
                      )}
                    </Menu.Item>
                  )}
                  {isEditModeActive && (
                    <Menu.Item>
                      {({ active }) => (
                        <Menu
                          as="div"
                          className={`block text-xs ${active ? "bg-gray-100 !text-blue-500" : "text-gray-500"}`}
                        >
                          <Menu.Button className="flex px-4 py-2 justify-between w-full">
                            <span>Move to</span>
                            <span> {">"} </span>
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              className="absolute -mt-[32px] left-56 z-2000 w-56 origin-top-right  bg-white shadow-lg ring-gray-300 focus:outline-none"
                              id="contextMenuMoveToItems"
                            >
                              {categories
                                .filter(
                                  (category: typeof Category) =>
                                    category?._id !== batch?.category?._id &&
                                    !category?.subCategories?.find(
                                      (subCategory: typeof Category) => subCategory._id === batch?.category?._id
                                    )
                                )
                                .map((category: typeof Category) => (
                                  <Menu.Item key={category._id}>
                                    {({ active }) => (
                                      <a
                                        className={`block px-4 py-2 text-xs ${
                                          active ? "bg-gray-100 !text-blue-500" : "text-gray-500"
                                        }`}
                                        onClick={async () => {
                                          await moveBatchToCategory(category, batch)
                                        }}
                                      >
                                        {category.name}
                                      </a>
                                    )}
                                  </Menu.Item>
                                ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      )}
                    </Menu.Item>
                  )}

                  {!arePresentationActionsDisabled && (
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={`block px-4 py-2 text-xs ${
                            active ? "bg-gray-100 !text-blue-500" : "text-gray-500"
                          }`}
                          onClick={() => openConfirmationModal()}
                        >
                          Delete
                        </a>
                      )}
                    </Menu.Item>
                  )}
                  {import.meta.env.DEV && batch._id && (
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={`block px-4 py-2 text-[10px] ${
                            active ? "bg-gray-100 !text-blue-500" : "text-gray-500"
                          }`}
                          onClick={() => {
                            copyToClipboard(batch.id)
                          }}
                        >
                          <small>{batch._id}</small>
                        </a>
                      )}
                    </Menu.Item>
                  )}
                  {isSearchPage && (
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          className={`block px-4 py-2 text-xs ${
                            active ? "bg-gray-100 !text-blue-500" : "text-gray-500"
                          }`}
                          to={
                            batch.isMyPresentation
                              ? `/my-presentations/${batch.urlToken}/${slugify(batch.name)}`
                              : `/library/${batch.urlToken}/${slugify(batch.name)}`
                          }
                        >
                          {" "}
                          Go to {type === "templates" ? "collection" : "presentation"}
                        </Link>
                      )}
                    </Menu.Item>
                  )}
                </Menu.Items>
              </Transition>
            </>
          )
        }}
      </Menu>
      <ConfirmationModal
        close={closeConfirmationModal}
        confirmButtonText="Delete"
        confirmFunction={handleDelete}
        context={
          <>
            Are you sure you want to remove <span className="text-scblue">{batch.name}&nbsp;</span>
            from your&nbsp;SlideCamp?
          </>
        }
        isOpen={isOpen}
        mode="danger"
        title="Delete"
      />
    </>
  )
}

export default ContextMenu
