import Dropzone from "react-dropzone"
import TagsInput from "react-tagsinput"
import { CategoryIcon } from "@/svg/CategoryIcons"

export const InputWrapper = ({ error, children, label }) => (
  <div className="profile-input">
    {error && <span className="sc-form-error">{error}</span>}
    {children}
    <label>{label}</label>
  </div>
)

export const DropzoneInput = ({ name, files, onChange }) => (
  <div>
    {/* <Dropzone
      name={name}
      multiple={false}
      style={{}}
      onDrop={(filesToUpload) => onChange(filesToUpload)}
      className="template-dropzone-wrapper"
    >
      <div className="template-dropzone">{files && Array.isArray(files) && <p>{files[0].name}</p>}</div>
    </Dropzone> */}
    <Dropzone name={name} onDrop={(filesToUpload) => onChange(filesToUpload)}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="template-dropzone-wrapper">
            <div className="template-dropzone">{files && Array.isArray(files) && <p>{files[0].name}</p>}</div>
          </div>
        </div>
      )}
    </Dropzone>
  </div>
)

export const TagWrapper = ({ name, value, onChange, placeholder }) => (
  <TagsInput
    addKeys={[9, 13, 188]}
    inputProps={{ placeholder }}
    maxTags={5}
    onChange={(value) => {
      onChange({ persist: () => {}, target: { name: name, value } })
    }}
    value={value}
  />
)

export const RadioIconInput = ({ icon, value, handleChange, checked }) => {
  return (
    <div aria-checked={checked} className="categories-ico group">
      <label className="categories-ico-container">
        <input checked={checked} name="icon" onChange={handleChange} type="radio" value={value} />
        <CategoryIcon categoryIcon={icon} />
        <div className="icon-border" />
      </label>
    </div>
  )
}
