import React, { useContext } from "react"
import { useQuery } from "@apollo/client"
import { NEW_BATCHES } from "./Batches"
import { LoadingPlaceholder } from "../../utils/placeholders"
import { UserDataContext } from "../../../hooks/UserDataHook"
import Presentation from "../../presentation/Presentation"
import { NavLink, useHistory } from "react-router-dom"
import slugify from "slug"
import { ArrowRightIcon } from "../../../svg/SvgIcons"
import { CategoryContext } from "../../../context/CategoryContext"

const PowerPointDashboard = () => {
  const { user } = useContext(UserDataContext)
  const { cart } = user
  const { data, loading, refetch } = useQuery(NEW_BATCHES, {
    variables: { limit: 4 },
    context: { isUsingNewScApi: true }
  })
  const history = useHistory()
  const { categories } = useContext(CategoryContext)

  const handleViewBatch = (urlToken, name, slides) => {
    history.push({ pathname: `/dashboard/${urlToken}/${slugify(name)}`, state: { slides, currentPathname: name } })
  }

  if (loading) {
    return <LoadingPlaceholder />
  }

  return (
    <div
      className={`h-full w-full flex justify-center mobile-xs:px-[20px] py-[20px] bg-[#F5F5F5] ${
        cart ? "tablet-xl:px-[60px]" : "tablet-sm:px-[60px]"
      }`}
    >
      <div className="w-full desktop-big:w-[1812px]">
        <div>
          <h2 className="text-[9px] text-[#717171] uppercase mb-[15px]">RECENTLY ADDED</h2>
          {data.organizationPresentations.collections.length > 0 && (
            <div
              className={`grid gap-[15px] min-[339px]:grid-cols-2 w-full ${
                cart
                  ? "desktop-sm:grid-cols-2 desktop-xl:grid-cols-3 desktop-3xl:grid-cols-4"
                  : "tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
              }`}
            >
              {data.organizationPresentations.collections.map((collection) => (
                <Presentation
                  active={false}
                  batch={collection}
                  handleViewBatch={handleViewBatch}
                  hideTools
                  id={collection.id}
                  isSearchPage={true}
                  key={collection.id}
                  name={collection.name || "No given name"}
                  noActions="true"
                  refetch={refetch}
                  type="templates"
                />
              ))}
            </div>
          )}

          {data.organizationPresentations.presentations.length > 0 && (
            <div
              className={`mt-[15px] grid gap-x-[15px] gap-y-[20px] min-[339px]:grid-cols-2 w-full ${
                cart
                  ? "desktop-sm:grid-cols-2 desktop-xl:grid-cols-3 desktop-3xl:grid-cols-4"
                  : "tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
              }`}
            >
              {data.organizationPresentations.presentations.map((presentation) => (
                <Presentation
                  active={false}
                  batch={presentation}
                  handleViewBatch={handleViewBatch}
                  hideTools
                  id={presentation.id}
                  isFavourite={presentation.isFavourite}
                  isSearchPage={true}
                  key={presentation.id}
                  name={presentation.name || "No given name"}
                  noActions
                  refetch={refetch}
                />
              ))}
            </div>
          )}
        </div>
        <div className="mt-[51px]">
          <h2 className="text-[9px] text-[#717171] uppercase mb-[1px]">LIBRARY</h2>
          <nav className="overflow-y-auto">
            <ul className="text-[14px] font-semibold">
              {categories.map((category) => {
                return (
                  <li className="border-b border-[#B0B0B047] last:border-0" key={category._id}>
                    <NavLink
                      className="flex items-center justify-between py-[19px] text-sc-text-dark hover:text-[#1683FB]"
                      to={{
                        pathname: `/library/${category.urlToken}/${slugify(category.name)}`,
                        state: { currentPathname: category.name }
                      }}
                    >
                      <span>{category.name}</span>
                      <ArrowRightIcon />
                    </NavLink>
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default PowerPointDashboard
