import { useApolloClient, useMutation, gql, useLazyQuery } from "@apollo/client"
import { useState, useRef, useContext, useEffect, useMemo } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useDrag } from "react-dnd"
import PreviewDropdown from "@/components/presentation/PreviewDropdown"
import { fragments } from "@/graphql/fragments"
import { SlideModalContext } from "@/hooks/SlideModalHook"
import SlideDropdownList from "@/components/slide/SlideDropdownList"
import SlidePlaceholder from "./SlidePlaceholder"
import { UserDataContext } from "@/hooks/UserDataHook"
import { Menu } from "@headlessui/react"
import { MenuIconVertical } from "@/svg/SvgIcons"
import { useIsInsidePowerPoint } from "@/context/IsInsidePowerPointContext"
import { getSelectedSlideID, selectInsertedSlide } from "@/components/utils/powerpoint/add-in"

const SLIDE_DOWNLOAD_URL = gql`
  query slideDownloadUrl($slideId: String!, $presentationName: String!) {
    slideDownloadUrl(slideId: $slideId, presentationName: $presentationName)
  }
`

const GET_THUMB_AND_LOCK = gql`
  query slide($slideId: String!) {
    slide(slideId: $slideId) {
      thumbUrl
      lock
      state
      updatedAt
    }
  }
`

const REMOVE_SLIDE = gql`
  mutation removeSlide($presentationId: String!, $slideId: String!) {
    removeSlide(presentationId: $presentationId, slideId: $slideId) {
      code
      success
      message
      job {
        id
        queueName
      }
    }
  }
`

const SET_SLIDE_FAVOURITE = gql`
  mutation setSlideFavourite($slideId: String!, $state: Boolean!) {
    setSlideFavourite(slideId: $slideId, state: $state) {
      id
      slideId
      isFavourite
    }
  }
`

const Slide = ({
  batch,
  batchType,
  currentSet, // TODO: BU: this is related to the colorVersions and should be removed at some point
  handleMergeJobStatus,
  setPresentationThumbUrl,
  index,
  noActions,
  refetch,
  sets,
  slide,
  isHoverLayerLight,
  isSearchPage
}) => {
  const { id, slideId, blueprintId, thumbUrl, isFavourite } = slide
  const location = useLocation()
  const isInsidePowerPoint = useIsInsidePowerPoint()
  const isFavoritesPath = location.pathname.split("/")[1] === "favorites"
  const { openModal: openSlideModal } = useContext(SlideModalContext)
  const {
    updateSlidesInCartFunction,
    user: { isEditModeActive, cart }
  } = useContext(UserDataContext)

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const [isReplacing, setIsReplacing] = useState(slide.state === "locked")
  const [currentThumbUrl, setCurrentThumbUrl] = useState(thumbUrl)
  const [isPreviouslyLocked, setIsPreviouslyLocked] = useState(false)
  const [previousState, setPreviousState] = useState(slide.state)
  const [isAddSlideLoading, setIsAddSlideLoading] = useState(false)
  const { slideId: slideIdParam } = useParams()
  const slideContainerRef = useRef(null)

  const client = useApolloClient()

  const [setSlideFavourite] = useMutation(SET_SLIDE_FAVOURITE, {
    context: { isUsingNewScApi: true }
  })
  const [removeSlide] = useMutation(REMOVE_SLIDE, {
    context: { isUsingNewScApi: true },
    onCompleted: (data) => {
      const { queueName, id: jobId } = data.removeSlide.job

      handleMergeJobStatus(queueName, jobId)
    }
  })

  const [getSlideDownloadUrl] = useLazyQuery(SLIDE_DOWNLOAD_URL, {
    fetchPolicy: "network-only",
    context: { isUsingNewScApi: true }
  })

  const [getThumbAndLock, { stopPolling }] = useLazyQuery(GET_THUMB_AND_LOCK, {
    notifyOnNetworkStatusChange: true,
    context: { isUsingNewScApi: true },
    pollInterval: 1000,
    fetchPolicy: "network-only",
    onCompleted: ({ slide }) => {
      const isSlideLocked = slide.state === "locked"
      if (
        slide.lock === null &&
        slide.state === "uploaded" &&
        (previousState === "finalizing" || previousState === "locked")
      )
        stopPolling()
      if (
        (isPreviouslyLocked === true || previousState === "finalizing") &&
        slide.lock === false &&
        slide.state === "uploaded"
      )
        stopPolling()

      if (index === 0 && setPresentationThumbUrl && !isSlideLocked) setPresentationThumbUrl(slide.thumbUrl)
      if (!isSlideLocked) setCurrentThumbUrl(slide.thumbUrl)
      setIsPreviouslyLocked(slide.lock)
      setPreviousState(slide.state)

      switch (slide.state) {
        case "locked":
          setIsReplacing(true)
          break
        case "finalizing":
          if (!isSearchPage && !isFavoritesPath) {
            refetch()
          }
          setIsReplacing(false)
          break
        case "uploaded":
          refetch()
          setIsReplacing(false)

          // Update the presentation`s thumbUrl
          batch &&
            client.writeFragment({
              id: batch.id,
              fragment: gql`
                fragment UsersBatchFields on UsersBatch {
                  thumbUrl
                }
              `,
              data: { thumbUrl }
            })

          break
      }
    }
  })

  const imgRef = useRef()
  const imageSource = currentThumbUrl.replace("{width}", 208)
  const imageSourceSet = currentThumbUrl.replace("{width}", 416)

  const [{ isDragging }, dragRef] = useDrag({
    type: "SLIDE",
    item: { index, thumb: imageSource, imageRef: imgRef },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: () => isEditModeActive // isTeamOwner
  })

  useEffect(() => {
    if (slideIdParam === slide.id) {
      const element = slideContainerRef.current
      if (element) {
        const topOffset = element.getBoundingClientRect().top + window.scrollY - 20
        window.scrollTo({ top: topOffset, behavior: "smooth" })
      }
    }
  }, [slideIdParam])

  const handleDropdown = () => setDropdownIsOpen(!dropdownIsOpen)

  const onOpenModal = ({ position, sets, e }) => {
    e.preventDefault()
    openSlideModal({ position, sets, pId: batch && batch.batchId, downloadSlide })
  }

  const downloadSlide = async () => {
    const { data } = await getSlideDownloadUrl({
      variables: {
        slideId: blueprintId,
        presentationName: batch && batch?.name ? batch.name : "Favourite"
      }
    })

    handleDropdown()
    if (data.slideDownloadUrl) window.location.href = data.slideDownloadUrl
  }

  const updateSlideInCart = async () => {
    if (cart?.sets?.length) {
      const items = Array.from(cart.sets[0].slides)
      items.push(slide)
      await updateSlidesInCartFunction(items)
    } else {
      const items = [slide]
      await updateSlidesInCartFunction(items)
    }
  }
  const handleLike = async () => {
    await setSlideFavourite({
      variables: {
        slideId: blueprintId,
        state: !isFavourite
      },
      update: (cache, { data: { setSlideFavourite } }) => {
        if (!setSlideFavourite) {
          return
        }
        const slide = client.readFragment({
          id: blueprintId,
          fragment: fragments.presentationSlide
        })

        if (slide && slide.id) {
          const updatedSlide = {
            ...slide,
            isFavourite: setSlideFavourite.isFavourite
          }
          client.writeFragment({
            id: blueprintId,
            fragment: fragments.presentationSlide,
            data: updatedSlide
          })
          return
        }
        const favouriteSlide = client.readFragment({
          id: blueprintId,
          fragment: fragments.favouriteSlide
        })
        if (favouriteSlide && favouriteSlide.id) {
          const updatedSlide = {
            ...favouriteSlide,
            isFavourite: setSlideFavourite.isFavourite
          }
          client.writeFragment({
            id: blueprintId,
            fragment: fragments.favouriteSlide,
            data: updatedSlide
          })
          return
        }
      },
      optimisticResponse: {
        setSlideFavourite: {
          __typename: "SetSlideFavouriteResponse",
          slideId,
          id,
          isFavourite: !isFavourite
        }
      }
    })
  }

  const deleteSlide = () => {
    removeSlide({
      variables: { presentationId: batch.batchId, slideId: blueprintId },
      update: (proxy) => {
        const deletedSlideIdx = currentSet.slides.findIndex((_slide) => _slide.blueprintId === blueprintId)
        proxy.writeFragment({
          id: batch.id,
          fragment: fragments.batch,
          data: {
            ...batch,
            sets: batch.sets.map((set) => ({
              ...set,
              slides: set.slides.filter((_slide) => _slide.blueprintId !== blueprintId)
            })),
            ...(batch.mode !== "blueprints" && currentSet.slides.length > 1
              ? { thumbUrl: currentSet.slides[deletedSlideIdx === 0 ? 1 : 0].thumbUrl }
              : {
                  thumbUrl: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                })
            // BU: set empty gif if deleting last slide -^
          }
        })
        // refetch new slides after deletion
        refetch()
      }
    })
  }

  const highlightSlide = useMemo(() => {
    return !!(cart?.sets?.length && cart.sets[0].slides.find((slide) => slide.blueprintId === id))
  }, [cart, id])

  useEffect(() => {
    if (slide.state !== "uploaded") getThumbAndLock({ variables: { slideId: slide.blueprintId } })
  }, [slide.state])

  const addToPowerPointPresentation = async () => {
    setIsAddSlideLoading(true)
    const { data } = await getSlideDownloadUrl({
      variables: {
        slideId: blueprintId,
        presentationName: batch && batch?.name ? batch.name : "Favourite"
      }
    })

    try {
      await window.PowerPoint.run(async function (context) {
        // Download and convert to base64
        try {
          const response = await fetch(data.slideDownloadUrl)
          const blob = await response.blob()
          const reader = new FileReader()

          const base64String = await new Promise((resolve, reject) => {
            reader.onloadend = () => {
              resolve(reader.result.split(",")[1]) // Remove the data URL prefix to get only the base64 string
            }
            reader.onerror = (error) => {
              reject(error)
            }
            reader.readAsDataURL(blob)
          })

          context.presentation.slides.load()
          await context.sync()
          let selectedSlideID

          if (context.presentation.slides.items.length) {
            selectedSlideID = await getSelectedSlideID()
          }

          context.presentation.insertSlidesFromBase64(base64String, {
            formatting: "KeepSourceFormatting",
            targetSlideId: selectedSlideID ? selectedSlideID + "#" : null
          })
          await context.sync()
          selectInsertedSlide(selectedSlideID)
          setIsAddSlideLoading(false)
        } catch (error) {
          console.error("Error fetching the file", error)
          setIsAddSlideLoading(false)
          throw error
        }
      })
    } catch (error) {
      console.error("Error: " + error)
      setIsAddSlideLoading(false)
      throw error
    }
  }

  const handleAddSlide = async () => {
    // Add slide to PowerPoint presentation or add slide to cart
    if (isInsidePowerPoint) {
      await addToPowerPointPresentation()
    } else {
      await updateSlideInCart()
    }
  }

  return isReplacing ? (
    <div className="m-[6.25px] aspect-[344/193.5]">
      <SlidePlaceholder />
    </div>
  ) : (
    <div
      className={`h-full relative transition ease-in-out duration-[200ms] ${
        isInsidePowerPoint ? "rounded-[7px]" : ""
      } ${isHoverLayerLight ? "m-0" : ""} ${
        highlightSlide ? `!opacity-[0.6] ${!isSearchPage ? "scale-[.94]" : ""} ` : "shadow-[0_0_12px_1px_#7a7f8733]"
      } ${isDragging ? "on-drag" : ""}`}
      data-testid="slide"
      ref={dragRef}
    >
      <div
        className={`w-full h-full ${
          slide.id === slideIdParam
            ? `border-2 border-sc-blue ${isInsidePowerPoint ? "rounded-[7px]" : "rounded-[3px]"}`
            : ""
        }`}
        ref={slideContainerRef}
      >
        <picture>
          <img
            alt={slide.name}
            className={`w-full h-full object-cover aspect-[427/240.5] ${isInsidePowerPoint ? "rounded-[7px]" : ""}`}
            data-testid="slide-thumbnail"
            ref={imgRef}
            src={imageSource}
            srcSet={imageSourceSet}
          />
        </picture>
      </div>
      <div
        className={`opacity-0 hover:opacity-100 transition ease-in-out absolute top-0 w-full h-full text-center ${
          isHoverLayerLight
            ? "bg-sc-overlay-light duration-700"
            : isInsidePowerPoint
            ? "border-2 border-transparent rounded-[7px] hover:border-sc-blue duration-200"
            : "bg-sc-overlay duration-700"
        } ${dropdownIsOpen ? "links-collapsed opacity-100" : ""} ${highlightSlide || isDragging ? "hidden" : ""}`}
      >
        {isSearchPage ? (
          // Slide UI for the new search page design
          <>
            <div className="h-full flex justify-center items-center">
              <button
                className={`flex justify-center items-center text-[14px] rounded-[40px] transition-colors ease-in-out duration-200 text-[#6B6F7A] bg-sc-add-to-cart-bg shadow-add-to-cart-btn ${
                  isInsidePowerPoint
                    ? "w-[82px] h-[30px] px-[16px] py-[7px] bg-scblue text-white hover:bg-[#136FD5]"
                    : "w-[141px] h-[31px] px-[8px] mobile-sm:w-[170px] mobile-sm:h-[34px] tablet-md:w-[141px] tablet-md:h-[31px] tablet-xl:w-[170px] tablet-xl:h-[34px] hover:bg-white hover:text-sc-blue"
                }`}
                data-testid="btn-add-to-cart"
                disabled={isAddSlideLoading}
                onClick={handleAddSlide}
              >
                {isAddSlideLoading ? (
                  <svg
                    aria-hidden="true"
                    className="inline w-4 h-4 text-white animate-spin"
                    fill="none"
                    role="status"
                    viewBox="0 0 100 101"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                ) : isInsidePowerPoint ? (
                  "Add"
                ) : (
                  "Add to cart"
                )}
              </button>
            </div>
            <div
              className={`absolute top-0 w-full py-[12px] px-[14px] flex justify-end ${isInsidePowerPoint && "hidden"}`}
            >
              <div className="flex items-center justify-center text-sc-font-gray bg-white px-[5px] rounded-[50px]">
                <button
                  className="w-[27px] h-[32px] aspect-square px-[8px] py-[4px] flex justify-center items-center hover:text-sc-blue transition-colors ease-in-out duration-200"
                  data-testid="btn-preview"
                  onClick={(e) => onOpenModal({ position: index, sets, e })}
                  type="button"
                >
                  <svg
                    className="w-full aspect-square"
                    fill="none"
                    viewBox="0 0 13 14"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.46529 6.03473C7.46529 5.74708 7.23209 5.51389 6.94445 5.51389H5.55556V4.125C5.55556 3.83736 5.32237 3.60417 5.03473 3.60417C4.74708 3.60417 4.5139 3.83736 4.5139 4.125V5.51389H3.125C2.83735 5.51389 2.60417 5.74708 2.60417 6.03473C2.60417 6.32236 2.83735 6.55556 3.125 6.55556H4.5139V7.94445C4.5139 8.23209 4.74708 8.46528 5.03473 8.46528C5.32237 8.46528 5.55556 8.23209 5.55556 7.94445V6.55556H6.94445C7.23209 6.55556 7.46529 6.32236 7.46529 6.03473ZM5.03473 1C7.81536 1 10.0695 3.25413 10.0695 6.03473C10.0695 7.23743 9.64772 8.3416 8.94411 9.20751L12.3474 12.6109C12.5508 12.8143 12.5508 13.144 12.3474 13.3474C12.1626 13.5324 11.8732 13.5492 11.6693 13.3979L11.6109 13.3474L8.20751 9.9441C7.34161 10.6477 6.23744 11.0695 5.03473 11.0695C2.25412 11.0695 0 8.81535 0 6.03473C0 3.25413 2.25412 1 5.03473 1ZM5.03473 2.04167C2.82942 2.04167 1.04167 3.82942 1.04167 6.03473C1.04167 8.24 2.82942 10.0278 5.03473 10.0278C7.24001 10.0278 9.02779 8.24 9.02779 6.03473C9.02779 3.82942 7.24001 2.04167 5.03473 2.04167Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
                <button
                  className={
                    "w-[27px] h-[32px] px-[8px] py-[4px] flex justify-center items-center hover:text-sc-blue transition-colors ease-in-out duration-200"
                  }
                  data-testid="btn-heart"
                  onClick={handleLike}
                >
                  <svg
                    className={`${
                      isFavourite ? "fill-sc-blue animate-heart-beat" : "fill-sc-font-gray"
                    } hover:fill-sc-blue`}
                    data-testid={`${isFavourite ? "heart-beat" : "heart-unbeat"}`}
                    viewBox="0 0 13 14"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.03146 2.06531L6.49811 2.63947L5.96306 2.0648C4.59895 0.599519 2.38724 0.599519 1.02311 2.0648C-0.341035 3.53007 -0.341035 5.90575 1.02311 7.37104L6.15413 12.8824C6.34448 13.0869 6.6531 13.0869 6.84345 12.8824L11.9784 7.37C13.3395 5.89988 13.3419 3.53086 11.9775 2.06531C10.6109 0.59737 8.39809 0.59737 7.03146 2.06531ZM11.2872 6.63166L6.49876 11.7719L1.71241 6.63061C0.728951 5.57424 0.728951 3.86156 1.71241 2.8052C2.69586 1.74883 4.29034 1.74883 5.2738 2.8052L6.15595 3.75278C6.34955 3.96071 6.66454 3.95665 6.8534 3.74381L7.72079 2.80572C8.70672 1.74669 10.3022 1.74669 11.2882 2.80572C12.2719 3.86234 12.2702 5.56998 11.2872 6.63166Z" />
                    <path
                      className={isFavourite ? "fill-sc-blue" : "fill-transparent"}
                      d="M7 12.4643L1.5 6.46429L1 5.46429V3.46429L2.5 1.96429L4 1.46429L5.5 1.96429L7 3.46429L7.5 2.46429L9.5 1.46429L11.5 1.96429L13 3.96429L12.5 6.46429L7 12.4643Z"
                    />
                  </svg>
                </button>
                {/* Slide drop down for shared expanded group */}

                <Menu as="div" className={"w-[27px] h-[32px] px-[8px] py-[4px] relative flex justify-center"}>
                  {({ open }) => {
                    useEffect(() => {
                      setDropdownIsOpen(open)
                    }, [open])

                    return (
                      <>
                        <Menu.Button
                          className="flex w-full h-full items-center justify-center hover:text-scblue"
                          data-testid="category-menu-button"
                          id="contextMenu"
                        >
                          <MenuIconVertical />
                        </Menu.Button>
                        <Menu.Items
                          className="absolute top-[32px] right-0 z-20 w-56 text-sm rounded-md bg-white shadow-lg ring-gray-300 focus:outline-none p-1"
                          id="contextMenuItems"
                        >
                          <SlideDropdownList
                            bartek={!noActions && isEditModeActive}
                            batchId={batch?.batchId || ""}
                            deleteAvailable={
                              (!noActions && isEditModeActive) ||
                              (isEditModeActive && batchType === "batches") ||
                              (isEditModeActive && batchType === "sharedBatches")
                            }
                            deleteSlide={deleteSlide}
                            editInPowerPointAvailable={!isFavoritesPath && isEditModeActive}
                            getThumbAndLock={getThumbAndLock}
                            goToSlideAvailable
                            handleDownload={downloadSlide}
                            handleMergeJobStatus={handleMergeJobStatus}
                            isEditModeActive={isEditModeActive}
                            moveToAvailable={batch && batchType === "templates" && !noActions && isEditModeActive}
                            refetch={refetch}
                            slide={slide}
                          />
                        </Menu.Items>
                      </>
                    )
                  }}
                </Menu>
              </div>
            </div>
          </>
        ) : (
          // Regular slide UI
          <>
            <div className="h-full flex justify-center items-center">
              <button
                className={
                  "h-[17.3%] w-[48.6%] text-[14.5px] rounded-[30px] hover:bg-[#ffffff4d] transition-colors ease-in-out duration-200 border border-[#e9eaed] text-white"
                }
                data-testid="btn-add-to-cart"
                onClick={handleAddSlide}
              >
                {isInsidePowerPoint ? "Add" : "Add to cart"}
              </button>
            </div>
            <div className={`${isInsidePowerPoint && "hidden"}`}>
              <button
                className={
                  "w-[8.4%] aspect-square absolute top-[18px] left-[18px] flex justify-center items-center border border-white rounded-[3px] hover:bg-[#ffffff4d] transition-colors ease-in-out duration-200"
                }
                data-testid="btn-heart"
                onClick={handleLike}
              >
                <svg
                  className={`${isFavourite ? "fill-white animate-heart-beat" : "fill-transparent"} stroke-white`}
                  data-testid={`${isFavourite ? "heart-beat" : "heart-unbeat"}`}
                  viewBox="0 0 18 15.4"
                  width="57.8%"
                >
                  <path d="M2,1.7C2.3,1.3,2.8,1,3.3,0.8C5.5-0.1,8,0.9,9,3c1-2.1,3.5-3.1,5.7-2.2c2.3,0.9,3.4,3.5,2.5,5.8c-0.5,1.1-2.4,3.1-4.3,4.8L12.3,12c-1.6,1.5-3.1,2.7-3.3,2.9c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0s0,0,0,0c-0.4-0.3-7.3-6-8.2-8.2C0.2,5.1,0.5,3.5,1.4,2.2L2,1.7z" />
                </svg>
              </button>
              {/* Slide drop down for shared expanded group */}
              <PreviewDropdown
                classFixed={false}
                isOpen={dropdownIsOpen}
                openDropdown={handleDropdown}
                tooltipClassName={"w-full"}
              >
                <SlideDropdownList
                  bartek={!noActions && isEditModeActive}
                  batchId={batch?.batchId || ""}
                  closeDropdown={handleDropdown}
                  deleteAvailable={
                    (!noActions && isEditModeActive) ||
                    (isEditModeActive && batchType === "batches") ||
                    (isEditModeActive && batchType === "sharedBatches")
                  }
                  deleteSlide={deleteSlide}
                  editInPowerPointAvailable={!isFavoritesPath && isEditModeActive}
                  getThumbAndLock={getThumbAndLock}
                  handleDownload={downloadSlide}
                  handleMergeJobStatus={handleMergeJobStatus}
                  isEditModeActive={isEditModeActive}
                  moveToAvailable={batch && batchType === "templates" && !noActions && isEditModeActive}
                  refetch={refetch}
                  slide={slide}
                />
              </PreviewDropdown>
              <button
                className="absolute top-[18px] left-[62px] w-[8.4%] aspect-square flex justify-center items-center text-white border border-white rounded-[3px] hover:bg-[#ffffff4d] transition-colors ease-in-out duration-200"
                data-testid="btn-preview"
                onClick={(e) => onOpenModal({ position: index, sets, e })}
                type="button"
              >
                <svg fill="currentColor" viewBox="0 0 13.8 13.8" width="57.8%">
                  <path d="m5.4 10.8c-3 0-5.4-2.4-5.4-5.4s2.4-5.4 5.4-5.4 5.4 2.4 5.4 5.4c0 2.9-2.5 5.4-5.4 5.4zm0-10.1c-2.6 0-4.7 2.1-4.7 4.7s2.1 4.7 4.7 4.7 4.7-2.1 4.7-4.7-2.1-4.7-4.7-4.7z" />
                  <path d="m5.4 8c-.2 0-.4-.2-.4-.4v-4.5c0-.2.2-.4.4-.4s.4.2.4.4v4.5c-.1.2-.2.4-.4.4z" />
                  <path d="m7.6 5.7h-4.5c-.2 0-.4-.2-.4-.4s.2-.3.4-.3h4.5c.2 0 .4.2.4.4s-.2.3-.4.3z" />
                  <path d="m13.4 13.8c-.1 0-.2 0-.2-.1l-4.5-4.5c-.2-.2-.2-.4 0-.5s.4-.1.5 0l4.5 4.5c.1.1.1.4 0 .5-.1 0-.2.1-.3.1z" />
                </svg>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Slide
