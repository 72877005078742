import { useContext } from "react"
import { gql, useMutation } from "@apollo/client"
import { MainModalContext } from "@/hooks/MainModalHook"
import { UserDataContext } from "@/hooks/UserDataHook"
import UnitsAndTeamMembersInput from "@/components/modals/share/UnitsAndTeamMembersInput"
import useUnitsAndTeamMembersInputLogic from "@/hooks/useUnitsAndTeamMembersInputLogic"
import ShareWithOthers from "@/components/modals/share/ShareWithOthers"
import { Batch } from "@/graphql/types/queries"

interface ShareProps {
  batch: Batch
  type: string
  isPrivate: boolean
}

const UPDATE_BATCH_SHARING_SETTINGS = gql`
  mutation setBatchSharing(
    $batchId: String!
    $addedUnitIds: [String!] = []
    $removedUnitIds: [String!] = []
    $addedUserIds: [String!] = []
    $removedUserIds: [String!] = []
    $accessibleByAll: Boolean!
  ) {
    addedBatchSharing: setBatchSharing(
      unitIds: $addedUnitIds
      memberIds: $addedUserIds
      batchId: $batchId
      state: true
      accessibleByAll: $accessibleByAll
    ) {
      code
      success
      message
    }
    removedBatchSharing: setBatchSharing(
      unitIds: $removedUnitIds
      memberIds: $removedUserIds
      batchId: $batchId
      state: false
      accessibleByAll: $accessibleByAll
    ) {
      code
      success
      message
    }
  }
`

const SharePresentationModal = ({ batch, type: presentaionType, refetch }: ShareProps) => {
  // Update batch sharing data
  const [updateBatchSharingSettings] = useMutation(UPDATE_BATCH_SHARING_SETTINGS)
  const { user } = useContext(UserDataContext)
  const { closeModal } = useContext(MainModalContext)

  const { options, selectedOptions, handleOptionChange, formatOptionLabel, addedAndRemovedIds } =
    useUnitsAndTeamMembersInputLogic(batch)

  const handleSave = async () => {
    const accessibleByAll =
      user.role === "owner" || user.role === "admin"
        ? !!selectedOptions.find((option) => option.value === "all")
        : batch.accessibleByAll
    await updateBatchSharingSettings({
      variables: {
        batchId: batch.id,
        ...addedAndRemovedIds,
        accessibleByAll
      },
      context: { isUsingNewScApi: true },
      onCompleted: () => {
        refetch && refetch()
        closeModal()
      }
    })
  }

  return (
    <>
      <div className="sc-modal-header">
        <h1>Share presentation</h1>
      </div>

      <div className="team-share">
        {batch.category && (user.role === "owner" || user.role === "admin") && (
          <>
            <label htmlFor="teamMembersAndUnits">Share with:</label>
            <UnitsAndTeamMembersInput
              formatOptionLabel={formatOptionLabel}
              handleOptionChange={handleOptionChange}
              options={[...options.units, ...options.members]}
              selectedOptions={selectedOptions}
            />
          </>
        )}

        {presentaionType !== "templates" && <ShareWithOthers batch={batch} />}

        <div className="sc-modal-buttons">
          <button className="btn-gray-blue" onClick={closeModal} type="button">
            Cancel
          </button>
          <button
            className="btn-dark-blue"
            onClick={() => {
              handleSave()
            }}
            type="button"
          >
            Save
          </button>
        </div>
      </div>
    </>
  )
}

export default SharePresentationModal
