import { createContext, useContext, useEffect, useState } from "react"

const IsInsidePowerPointContext = createContext<null | boolean>(null)

export const IsInsidePowerPointProvider = ({ children }) => {
  const [isInsidePowerPoint, setIsInsidePowerPoint] = useState<null | boolean>(null)

  useEffect(() => {
    const isPowerPoint = window.location.host.includes("powerpoint")
    setIsInsidePowerPoint(isPowerPoint)
  }, [])

  return <IsInsidePowerPointContext.Provider value={isInsidePowerPoint}>{children}</IsInsidePowerPointContext.Provider>
}

export const useIsInsidePowerPoint = () => {
  return useContext(IsInsidePowerPointContext)
}
