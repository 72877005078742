import { useQuery } from "@apollo/client"
import React from "react"
import { PricingData } from "../../graphql/queries"
import PricingComponent from "./PricingComponent"
import { ErrorPlaceholder } from "../utils/placeholders"

const PricingContainer = () => {
  const { data, loading, error } = useQuery(PricingData, { fetchPolicy: "cache-and-network" })

  if (loading) return <div className="profile-loading" />
  if (error) return <ErrorPlaceholder />

  const { group } = data.session.user

  const plans = group.plans || []
  const groupId = group.groupId

  const renderPlans = () => plans.map((plan) => <PricingComponent data={plan} key={plan.planId} />)

  return (
    <div className="pricing-top">
      {groupId !== "sc2" ? (
        <React.Fragment>
          <div className="plans-container">
            {renderPlans()}
            <div className="plan">
              <h2>Complete</h2>
              <div className="info">
                <ul>
                  <li>Brand guidelines, industry specific, or&nbsp;company wide&nbsp;needs? </li>
                </ul>
                <a
                  className="btn btn-info"
                  href="https://www.slidecamp.io/demo-request"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Schedule a Demo
                </a>
              </div>
            </div>
          </div>
          <p>You can unsubscribe at any time.</p>
        </React.Fragment>
      ) : (
        <div className="plans-container new-plans">
          {renderPlans()}
          <div className="plan">
            <h2>Team License</h2>
            <div className="info">
              <h3>Custom Quote</h3>
              <ul>
                <li>Branded slides</li>
                <li>Team management</li>
                <li>Presentation storage</li>
                <li>Shared presentations</li>
                <li>Requests additional slides</li>
                <li>30 day free trial</li>
              </ul>
              <a
                className="btn btn-info"
                href="https://www.slidecamp.io/demo-request"
                rel="noopener noreferrer"
                target="_blank"
              >
                Schedule a Demo
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PricingContainer
