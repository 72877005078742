export const CategoryIcon = ({ categoryIcon }) => {
  switch (categoryIcon) {
    case "add-in sample":
      return <AddInSampleCollection />
    case "Calendars":
      return <Calendars />
    case "Basic Slides":
    case "Basic":
    case "Basics":
      return <Basics />
    case "Backgrounds":
      return <Backgrounds />
    case "Data & Charts":
    case "Charts":
      return <Charts />
    case "Agenda":
      return <Agenda />
    case "Data":
      return <Data />
    case "Icons":
      return <Icons />
    case "Images & Videos":
    case "Images":
    case "Image Slides":
      return <ImagesAndVideos />
    case "Maps":
      return <Maps />
    case "Notebook":
      return <Notebook />
    case "Processes":
      return <Processes />
    case "Sections":
    case "Section Slides":
      return <Sections />
    case "Smartphone":
      return <Smartphone />
    case "Maps Europe":
      return <MapsEurope />
    case "Maps USA":
      return <MapsUSA />
    case "Maps Canada":
      return <MapsCanada />
    case "Tablet":
      return <Tablet />
    case "Team":
    case "Team Slides":
      return <Team />
    case "Timelines & Processes":
    case "Timelines":
      return <Timelines />
    case "Web":
    case "Mobile & Web":
      return <Web />
    case "Infographics":
      return <Infographics />
    case "Free Slides":
      return <FreeSlides />
    case "Social Media":
    case "Social Media Slides":
      return <SocialMedia />
    case "Maps South America":
      return <MapsSouthAmerica />
    case "Maps Asia":
      return <MapsAsia />
    case "Maps Central America":
      return <MapsCentralAmerica />
    case "Quotes and testimonials":
      return <QuotesAndTestimonials />
    case "Presentation":
      return <Presentation />
    case "Photo":
      return <Photo />
    case "Camera":
      return <Camera />
    case "IMac":
      return <IMac />
    case "Maps North America":
      return <MapsNorthAmerica />
    case "Maps Middle East":
      return <MapsMiddleEast />
    case "Australia":
      return <Australia />
    case "Africa":
      return <Africa />
    case "Other":
      return <Other />
    case "Intro":
      return <Intro />
    case "People":
      return <People />
    case "Food":
      return <Food />
    default:
      return <Basics />
  }
}

export const AddInSampleCollection = () => {
  return (
    <svg fill="none" height="20.5" viewBox="0 0 32 32" width="20.5" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M6 8C6.55228 8 7 8.44772 7 9V23C7 23.5523 6.55228 24 6 24C5.44772 24 5 23.5523 5 23V9C5 8.44772 5.44772 8 6 8Z"
        fill="#1D1D1F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M26 8C26.5523 8 27 8.44772 27 9V23C27 23.5523 26.5523 24 26 24C25.4477 24 25 23.5523 25 23V9C25 8.44772 25.4477 8 26 8Z"
        fill="#1D1D1F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 15C12.5523 15 13 15.4477 13 16V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V16C11 15.4477 11.4477 15 12 15Z"
        fill="#1D1D1F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16 14C16.5523 14 17 14.4477 17 15V18C17 18.5523 16.5523 19 16 19C15.4477 19 15 18.5523 15 18V15C15 14.4477 15.4477 14 16 14Z"
        fill="#1D1D1F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M20 13C20.5523 13 21 13.4477 21 14V18C21 18.5523 20.5523 19 20 19C19.4477 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13Z"
        fill="#1D1D1F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M3 6C3 4.89543 3.89543 4 5 4H27C28.1046 4 29 4.89543 29 6V8C29 9.10457 28.1046 10 27 10H5C3.89543 10 3 9.10457 3 8V6ZM27 6H5V8H27V6Z"
        fill="#1D1D1F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16 22C16.5523 22 17 22.4477 17 23V27C17 27.5523 16.5523 28 16 28C15.4477 28 15 27.5523 15 27V23C15 22.4477 15.4477 22 16 22Z"
        fill="#1D1D1F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16 28C15.4477 28 15 28.4477 15 29C15 29.5523 15.4477 30 16 30C16.5523 30 17 29.5523 17 29C17 28.4477 16.5523 28 16 28ZM13 29C13 27.3431 14.3431 26 16 26C17.6569 26 19 27.3431 19 29C19 30.6569 17.6569 32 16 32C14.3431 32 13 30.6569 13 29Z"
        fill="#1D1D1F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M3 23C3 22.4477 3.44772 22 4 22H28C28.5523 22 29 22.4477 29 23C29 23.5523 28.5523 24 28 24H4C3.44772 24 3 23.5523 3 23Z"
        fill="#1D1D1F"
        fillRule="evenodd"
      />
    </svg>
  )
}

export const Calendars = () => {
  return (
    <svg className="w-full h-full category__svg calendars" version="1.1" viewBox="0 0 34 37.2" x="0px" y="0px">
      <g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M32.1,37.2H2c-1.1,0-2-0.9-2-2V5.1c0-1.1,0.9-2,2-2h30.1c1.1,0,2,0.9,2,2v30.1
          C34,36.3,33.2,37.2,32.1,37.2z M2,4.1c-0.5,0-1,0.4-1,1v30.1c0,0.5,0.4,1,1,1h30.1c0.5,0,1-0.4,1-1V5.1c0-0.5-0.4-1-1-1H2z"
        />
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M33.5,11.7h-33V5.1c0-0.8,0.7-1.5,1.5-1.5h30.1c0.8,0,1.5,0.7,1.5,1.5V11.7z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M33.5,12.2h-33C0.2,12.2,0,12,0,11.7V5.1c0-1.1,0.9-2,2-2h30.1c1.1,0,2,0.9,2,2v6.6
            C34,12,33.8,12.2,33.5,12.2z M1,11.2h32V5.1c0-0.5-0.4-1-1-1H2c-0.5,0-1,0.4-1,1V11.2z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M6.1,7.2C5.8,7.2,5.6,7,5.6,6.7V0.5C5.6,0.2,5.8,0,6.1,0s0.5,0.2,0.5,0.5v6.2
            C6.6,7,6.4,7.2,6.1,7.2z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M13.4,7.2c-0.3,0-0.5-0.2-0.5-0.5V0.5c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v6.2
            C13.9,7,13.7,7.2,13.4,7.2z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M20.7,7.2c-0.3,0-0.5-0.2-0.5-0.5V0.5c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v6.2
            C21.2,7,20.9,7.2,20.7,7.2z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M27.9,7.2c-0.3,0-0.5-0.2-0.5-0.5V0.5c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v6.2
            C28.4,7,28.2,7.2,27.9,7.2z"
          />
        </g>
        <g>
          <g>
            <rect
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              height="6.6"
              width="6.6"
              x="4.5"
              y="16.3"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M11.6,23.5H4v-7.6h7.6V23.5z M5,22.5h5.6v-5.6H5V22.5z"
            />
          </g>
          <g>
            <rect
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              height="6.6"
              width="6.6"
              x="13.7"
              y="16.3"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M20.8,23.5h-7.6v-7.6h7.6V23.5z M14.2,22.5h5.6v-5.6h-5.6V22.5z"
            />
          </g>
          <g>
            <rect
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              height="6.6"
              width="6.6"
              x="23"
              y="16.3"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M30.1,23.5h-7.6v-7.6h7.6V23.5z M23.5,22.5h5.6v-5.6h-5.6V22.5z"
            />
          </g>
          <g>
            <rect
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              height="6.6"
              width="6.6"
              x="4.5"
              y="25.4"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M11.6,32.5H4v-7.6h7.6V32.5z M5,31.5h5.6v-5.6H5V31.5z"
            />
          </g>
          <g>
            <rect
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              height="6.6"
              width="6.6"
              x="13.7"
              y="25.4"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M20.8,32.5h-7.6v-7.6h7.6V32.5z M14.2,31.5h5.6v-5.6h-5.6V31.5z"
            />
          </g>
          <g>
            <rect
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              height="6.6"
              width="6.6"
              x="23"
              y="25.4"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M30.1,32.5h-7.6v-7.6h7.6V32.5z M23.5,31.5h5.6v-5.6h-5.6V31.5z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const Basics = () => {
  return (
    <svg className="w-full h-full category__svg basics" version="1.1" viewBox="0 0 54.7 41.4" x="0px" y="0px">
      <g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M51.9,41.4H2.7c-1.5,0-2.8-1.2-2.8-2.8V2.8C0,1.2,1.2,0,2.7,0h49.2c1.5,0,2.8,1.2,2.8,2.8v35.9
          C54.7,40.2,53.4,41.4,51.9,41.4z M2.7,1.5c-0.7,0-1.3,0.6-1.3,1.3v35.9c0,0.7,0.6,1.3,1.3,1.3h49.2c0.7,0,1.3-0.6,1.3-1.3V2.8
          c0-0.7-0.6-1.3-1.3-1.3H2.7z"
        />
        <g>
          <g>
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M24.3,21.3H6.8c-0.5,0-1-0.4-1-1v0c0-0.6,0.4-1,1-1h17.5c0.5,0,1,0.4,1,1v0C25.3,20.8,24.8,21.3,24.3,21.3z"
            />
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M24.3,25.4H6.8c-0.5,0-1-0.5-1-1v0c0-0.5,0.4-1,1-1h17.5c0.5,0,1,0.4,1,1v0C25.3,24.9,24.8,25.4,24.3,25.4z"
            />
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M24.3,29.5H6.8c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h17.5c0.5,0,1,0.5,1,1v0C25.3,29,24.8,29.5,24.3,29.5z"
            />
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M47.9,21.3H30.4c-0.5,0-1-0.4-1-1v0c0-0.6,0.4-1,1-1h17.5c0.5,0,1,0.4,1,1v0C48.9,20.8,48.5,21.3,47.9,21.3z
              "
            />
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M47.9,25.4H30.4c-0.5,0-1-0.5-1-1v0c0-0.5,0.4-1,1-1h17.5c0.5,0,1,0.4,1,1v0C48.9,24.9,48.5,25.4,47.9,25.4z
              "
            />
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M47.9,29.5H30.4c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h17.5c0.5,0,1,0.5,1,1v0C48.9,29,48.5,29.5,47.9,29.5z"
            />
          </g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M35.3,11.2H19.4c-0.9,0-1.6-0.7-1.6-1.6v0c0-0.9,0.7-1.6,1.6-1.6h15.9c0.9,0,1.6,0.7,1.6,1.6v0
            C36.9,10.4,36.2,11.2,35.3,11.2z"
          />
        </g>
      </g>
    </svg>
  )
}

export const Backgrounds = () => {
  return (
    <svg className="w-full h-full category__svg backgrounds" version="1.1" viewBox="0 0 52.6 39.4" x="0px" y="0px">
      <path
        className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
        d="M43.7,34.2H2.4c-0.9,0-1.7-0.8-1.7-1.7V2.4c0-0.9,0.8-1.7,1.7-1.7h41.3c0.9,0,1.7,0.8,1.7,1.7v30.1
        C45.4,33.5,44.6,34.2,43.7,34.2z"
      />
      <path
        className="fill-[#c8d7e3] group-hover:fill-[#b5daff]"
        d="M9.2,33.6c0.3,0.3,0.3,0.8,0,1.1c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2L0.5,27
        c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0L9.2,33.6z M1.5,19c-0.3-0.3-0.8-0.3-1.1,0s-0.3,0.8,0,1.1L15,34.5c0.1,0.1,0.3,0.2,0.5,0.2
        s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1L1.5,19z M1.5,12c-0.3-0.3-0.8-0.3-1.1,0s-0.3,0.8,0,1.1l21.3,21.3c0.1,0.1,0.3,0.2,0.5,0.2
        s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1L1.5,12z M1.4,4.9c-0.3-0.3-0.8-0.3-1.1,0S0.1,5.7,0.4,6l27.9,27.9c0.1,0.1,0.3,0.2,0.5,0.2
        s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1L1.4,4.9z M3.7,0.3C3.5,0,3,0,2.7,0.3s-0.3,0.8,0,1.1L33.3,32c0.1,0.1,0.3,0.2,0.5,0.2
        s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1L3.7,0.3z M10.6,0.2c-0.3-0.3-0.8-0.3-1.1,0S9.3,1,9.6,1.3l27.2,27.2
        c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1L10.6,0.2z M17.6,0.2c-0.3-0.3-0.8-0.3-1.1,0s-0.3,0.8,0,1.1
        L40.3,25c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1L17.6,0.2z M24.6,0.3c-0.3-0.3-0.8-0.3-1.1,0
        s-0.3,0.8,0,1.1l20.2,20.2c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1L24.6,0.3z M46,14.8L31.6,0.3
        c-0.3-0.3-0.8-0.3-1.1,0s-0.3,0.8,0,1.1L45,15.8c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2C46.3,15.5,46.3,15,46,14.8z M44.8,8.6
        c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1l-7.3-7.3c-0.3-0.3-0.8-0.3-1.1,0s-0.3,0.8,0,1.1L44.8,8.6z"
      />
      <path
        className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
        d="M43.7,35H2.4C1.1,35,0,33.9,0,32.5V2.4C0,1.1,1.1,0,2.4,0h41.3c1.3,0,2.4,1.1,2.4,2.4v30.1
        C46.1,33.9,45,35,43.7,35z M2.4,1.5c-0.5,0-0.9,0.4-0.9,0.9v30.1c0,0.5,0.4,0.9,0.9,0.9h41.3c0.5,0,0.9-0.4,0.9-0.9V2.4
        c0-0.5-0.4-0.9-0.9-0.9H2.4z"
      />
      <path
        className="fill-white"
        d="M50.6,38.6H19c-0.7,0-1.3-0.6-1.3-1.3V14.2c0-0.7,0.6-1.3,1.3-1.3h31.6c0.7,0,1.3,0.6,1.3,1.3
        v23.1C51.9,38,51.3,38.6,50.6,38.6z"
      />
      <path
        className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
        d="M25.8,38.6c0,0.4-0.3,0.8-0.8,0.8h-2c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h2
        C25.5,37.9,25.8,38.2,25.8,38.6z M24.3,13c0-0.4-0.3-0.8-0.8-0.8h-2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2
        C24,13.7,24.3,13.4,24.3,13z M34.5,13c0-0.4-0.3-0.8-0.8-0.8h-2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2
        C34.2,13.7,34.5,13.4,34.5,13z M29.4,13c0-0.4-0.3-0.8-0.8-0.8h-2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2
        C29.1,13.7,29.4,13.4,29.4,13z M20,37.9h-1c-0.2,0-0.3-0.1-0.4-0.2c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1
        c0.4,0.4,0.9,0.7,1.5,0.7h1c0.4,0,0.8-0.3,0.8-0.8S20.4,37.9,20,37.9z M18.4,35.2v-2c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v2
        c0,0.4,0.3,0.8,0.8,0.8S18.4,35.6,18.4,35.2z M35.3,37.9h-2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2c0.4,0,0.8-0.3,0.8-0.8
        S35.7,37.9,35.3,37.9z M17.7,15.5c0.4,0,0.8-0.3,0.8-0.8v-0.5c0-0.2,0.1-0.4,0.3-0.5c0.4-0.2,0.6-0.6,0.4-1c-0.2-0.4-0.6-0.6-1-0.4
        c-0.8,0.3-1.2,1.1-1.2,1.9v0.5C16.9,15.2,17.3,15.5,17.7,15.5z M17.7,20.6c0.4,0,0.8-0.3,0.8-0.8v-2c0-0.4-0.3-0.8-0.8-0.8
        s-0.8,0.3-0.8,0.8v2C16.9,20.3,17.3,20.6,17.7,20.6z M17.7,30.9c0.4,0,0.8-0.3,0.8-0.8v-2c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v2
        C16.9,30.5,17.3,30.9,17.7,30.9z M17.7,25.8c0.4,0,0.8-0.3,0.8-0.8v-2c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v2
        C16.9,25.4,17.3,25.8,17.7,25.8z M30.2,37.9h-2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2c0.4,0,0.8-0.3,0.8-0.8
        S30.6,37.9,30.2,37.9z M51.9,28.4c-0.4,0-0.8,0.3-0.8,0.8v2c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-2
        C52.6,28.7,52.3,28.4,51.9,28.4z M51.9,23.3c-0.4,0-0.8,0.3-0.8,0.8v2c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-2
        C52.6,23.6,52.3,23.3,51.9,23.3z M51.9,18.2c-0.4,0-0.8,0.3-0.8,0.8v2c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-2
        C52.6,18.5,52.3,18.2,51.9,18.2z M51.9,33.5c-0.4,0-0.8,0.3-0.8,0.8v2c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-2
        C52.6,33.8,52.3,33.5,51.9,33.5z M52.5,13.5c-0.1-0.4-0.6-0.6-1-0.4c-0.4,0.1-0.6,0.6-0.4,1c0,0.1,0,0.1,0,0.2v1.6
        c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-1.6C52.6,14,52.6,13.8,52.5,13.5z M49.8,13c0-0.4-0.3-0.8-0.8-0.8h-2
        c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2C49.5,13.7,49.8,13.4,49.8,13z M40.4,37.9h-2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2
        c0.4,0,0.8-0.3,0.8-0.8S40.8,37.9,40.4,37.9z M44.7,13c0-0.4-0.3-0.8-0.8-0.8h-2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2
        C44.4,13.7,44.7,13.4,44.7,13z M50.6,37.9h-2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2.1c0.4,0,0.7-0.3,0.7-0.8S51,37.9,50.6,37.9z
        M45.5,37.9h-2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2c0.4,0,0.8-0.3,0.8-0.8S45.9,37.9,45.5,37.9z M39.6,13
        c0-0.4-0.3-0.8-0.8-0.8h-2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2C39.3,13.7,39.6,13.4,39.6,13z"
      />
    </svg>
  )
}

export const Charts = () => {
  return (
    <svg className="w-full h-full category__svg charts" version="1.1" viewBox="0 0 39.3 45.1" x="0px" y="0px">
      <g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M35.3,6.3C31.9,2.9,27.2,0.7,22,0.7v18.9L35.3,6.3z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M22,20.4c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.5-0.4-0.5-0.7V0.7C21.2,0.3,21.6,0,22,0
            c5.3,0,10.2,2,13.9,5.8c0.3,0.3,0.3,0.8,0,1.1L22.5,20.2C22.4,20.3,22.2,20.4,22,20.4z M22.7,1.5v16.3L34.3,6.3
            C31.1,3.4,27,1.7,22.7,1.5z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M19.7,45.1C8.8,45.1,0,36.3,0,25.5C0,14.6,8.8,5.8,19.7,5.8c0.4,0,0.7,0.3,0.7,0.8v17.1
            l12.1-12.1c0.3-0.3,0.8-0.3,1.1,0c3.7,3.7,5.8,8.7,5.8,13.9C39.3,36.3,30.5,45.1,19.7,45.1z M18.9,7.3C9.2,7.7,1.5,15.7,1.5,25.5
            c0,10,8.2,18.2,18.2,18.2s18.2-8.2,18.2-18.2c0-4.6-1.7-8.9-4.8-12.3L20.2,26c-0.2,0.2-0.5,0.3-0.8,0.2c-0.3-0.1-0.5-0.4-0.5-0.7
            V7.3z"
          />
        </g>
      </g>
    </svg>
  )
}

export const Agenda = () => {
  return (
    <svg
      className="w-full h-full fill-[#e4ebf1] category__svg agenda"
      version="1.1"
      viewBox="0 0 54.2 36.5"
      x="0px"
      y="0px"
    >
      <g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M5.1,10.1C2.3,10.1,0,7.9,0,5.1C0,2.3,2.3,0,5.1,0s5.1,2.3,5.1,5.1
            C10.1,7.9,7.9,10.1,5.1,10.1z M5.1,1.5c-2,0-3.6,1.6-3.6,3.6c0,2,1.6,3.6,3.6,3.6S8.6,7,8.6,5.1C8.6,3.1,7,1.5,5.1,1.5z"
          />
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M49.2,10.1H19.5c-2.8,0-5.1-2.3-5.1-5.1c0-2.8,2.3-5.1,5.1-5.1h29.6c2.8,0,5.1,2.3,5.1,5.1
            C54.2,7.9,51.9,10.1,49.2,10.1z M19.5,1.5c-2,0-3.6,1.6-3.6,3.6c0,2,1.6,3.6,3.6,3.6h29.6c2,0,3.6-1.6,3.6-3.6
            c0-2-1.6-3.6-3.6-3.6H19.5z"
          />
        </g>
        <g>
          <g>
            <circle
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              cx="5.1"
              cy="18.2"
              r="4.3"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M5.1,23.3C2.3,23.3,0,21,0,18.2s2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1S7.9,23.3,5.1,23.3z
              M5.1,14.7c-2,0-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6S7,14.7,5.1,14.7z"
            />
          </g>
          <g>
            <path
              d="M49.2,22.5H19.5c-2.4,0-4.3-1.9-4.3-4.3v0c0-2.4,1.9-4.3,4.3-4.3h29.6c2.4,0,4.3,1.9,4.3,4.3
              v0C53.5,20.6,51.5,22.5,49.2,22.5z"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M49.2,23.3H19.5c-2.8,0-5.1-2.3-5.1-5.1s2.3-5.1,5.1-5.1h29.6c2.8,0,5.1,2.3,5.1,5.1
              S51.9,23.3,49.2,23.3z M19.5,14.7c-2,0-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6h29.6c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6H19.5z"
            />
          </g>
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M5.1,36.5c-2.8,0-5.1-2.3-5.1-5.1s2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1S7.9,36.5,5.1,36.5z
            M5.1,27.8c-2,0-3.6,1.6-3.6,3.6S3.1,35,5.1,35s3.6-1.6,3.6-3.6S7,27.8,5.1,27.8z"
          />
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M49.2,36.5H19.5c-2.8,0-5.1-2.3-5.1-5.1s2.3-5.1,5.1-5.1h29.6c2.8,0,5.1,2.3,5.1,5.1
            S51.9,36.5,49.2,36.5z M19.5,27.8c-2,0-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6h29.6c2,0,3.6-1.6,3.6-3.6s-1.6-3.6-3.6-3.6H19.5z"
          />
        </g>
      </g>
    </svg>
  )
}

export const Data = () => {
  return (
    <svg className="w-full h-full category__svg data" version="1.1" viewBox="0 0 54.7 41.8" x="0px" y="0px">
      <g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M53.9,20.9H0.8c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h53.2c0.4,0,0.8,0.3,0.8,0.8
            S54.3,20.9,53.9,20.9z"
          />
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M53.9,31.4H0.8C0.3,31.4,0,31,0,30.6s0.3-0.8,0.8-0.8h53.2c0.4,0,0.8,0.3,0.8,0.8
            S54.3,31.4,53.9,31.4z"
          />
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M14,41.8c-0.4,0-0.8-0.3-0.8-0.8V9.8C13.3,9.3,13.6,9,14,9s0.8,0.3,0.8,0.8v31.3
            C14.8,41.5,14.5,41.8,14,41.8z"
          />
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M27.3,41.8c-0.4,0-0.8-0.3-0.8-0.8V9.8c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v31.3
            C28.1,41.5,27.8,41.8,27.3,41.8z"
          />
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M40.6,41.8c-0.4,0-0.8-0.3-0.8-0.8V9.8c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v31.3
            C41.4,41.5,41.1,41.8,40.6,41.8z"
          />
        </g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M51.9,41.8H2.8c-1.5,0-2.8-1.2-2.8-2.8V3.2c0-1.5,1.2-2.8,2.8-2.8h49.2c1.5,0,2.8,1.2,2.8,2.8
          v35.9C54.7,40.6,53.4,41.8,51.9,41.8z M2.8,1.9c-0.7,0-1.3,0.6-1.3,1.3v35.9c0,0.7,0.6,1.3,1.3,1.3h49.2c0.7,0,1.3-0.6,1.3-1.3V3.2
          c0-0.7-0.6-1.3-1.3-1.3H2.8z"
        />
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M53.9,9.7H0.8v-7c0-1.1,0.9-2,2-2h49.2c1.1,0,2,0.9,2,2V9.7z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M53.9,10.5H0.8C0.3,10.5,0,10.2,0,9.8v-7C0,1.2,1.2,0,2.8,0h49.2c1.5,0,2.8,1.2,2.8,2.8v7
            C54.7,10.2,54.3,10.5,53.9,10.5z M1.5,9h51.7V2.8c0-0.7-0.6-1.3-1.3-1.3H2.8c-0.7,0-1.3,0.6-1.3,1.3V9z"
          />
        </g>
        <g>
          <line
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            x1="14"
            x2="14"
            y1="0.7"
            y2="9.7"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M14,10.5c-0.4,0-0.8-0.3-0.8-0.8v-9C13.3,0.3,13.6,0,14,0s0.8,0.3,0.8,0.8v9
            C14.8,10.2,14.5,10.5,14,10.5z"
          />
        </g>
        <g>
          <line
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            x1="27.3"
            x2="27.3"
            y1="0.7"
            y2="9.7"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M27.3,10.5c-0.4,0-0.8-0.3-0.8-0.8v-9c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v9
            C28.1,10.2,27.8,10.5,27.3,10.5z"
          />
        </g>
        <g>
          <line
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            x1="40.6"
            x2="40.6"
            y1="0.7"
            y2="9.7"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M40.6,10.5c-0.4,0-0.8-0.3-0.8-0.8v-9c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v9
            C41.4,10.2,41.1,10.5,40.6,10.5z"
          />
        </g>
      </g>
    </svg>
  )
}

export const Icons = () => {
  return (
    <svg
      className="w-full h-full category__svg icons"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 42.6 42.6"
      x="0px"
      y="0px"
    >
      <g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="27.1,23.1 24.9,24.6 23.9,36.9 18.7,36.9 17.7,24.6 15.5,23.1 15.5,13.4 19.1,12.9
            21.3,17.3 23.6,12.9 27.1,13.4 27.1,23.1 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M23.9,37.6h-5.3c-0.4,0-0.7-0.3-0.7-0.7L17,25l-2-1.4c-0.2-0.1-0.3-0.4-0.3-0.6v-9.6
            c0-0.4,0.3-0.7,0.6-0.7l3.6-0.5c0.3,0,0.6,0.1,0.8,0.4l1.6,3.1l1.6-3.1c0.1-0.3,0.5-0.5,0.8-0.4l3.6,0.5c0.4,0.1,0.6,0.4,0.6,0.7
            v9.6c0,0.2-0.1,0.5-0.3,0.6l-2,1.4l-0.9,11.9C24.7,37.3,24.3,37.6,23.9,37.6z M19.4,36.1h3.9l0.9-11.6c0-0.2,0.1-0.4,0.3-0.6
            l1.9-1.4v-8.6L24,13.7l-2,4c-0.3,0.5-1.1,0.5-1.3,0l-2-4l-2.4,0.4v8.6l1.9,1.4c0.2,0.1,0.3,0.3,0.3,0.6L19.4,36.1z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M21.3,11.6c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9
            C24.2,10.3,22.9,11.6,21.3,11.6L21.3,11.6z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M21.3,12.3c-2,0-3.7-1.6-3.7-3.7c0-2,1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7
            C25,10.7,23.3,12.3,21.3,12.3z M21.3,6.5c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2S22.5,6.5,21.3,6.5z"
          />
        </g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M21.3,42.6C9.6,42.6,0,33.1,0,21.3C0,9.6,9.6,0,21.3,0s21.3,9.6,21.3,21.3
          C42.6,33.1,33.1,42.6,21.3,42.6z M21.3,1.5c-10.9,0-19.8,8.9-19.8,19.8c0,10.9,8.9,19.8,19.8,19.8s19.8-8.9,19.8-19.8
          C41.1,10.4,32.2,1.5,21.3,1.5z"
        />
      </g>
    </svg>
  )
}

export const ImagesAndVideos = () => {
  return (
    <svg className="w-full h-full category__svg imagesandvideos" version="1.1" viewBox="0 0 52 39.4" x="0px" y="0px">
      <g>
        <polygon
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          points="5.8,38.1 6.5,38.6 8.7,38.6 26.3,21 25.3,20 23.9,20 	"
        />
        <polygon
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          points="29.9,24.6 30.6,25.1 31.7,26.2 44.4,13.5 43.4,12.5 42,12.5 	"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M49.3,39.4H2.6c-1.5,0-2.6-1.2-2.6-2.6V2.6C0,1.2,1.2,0,2.6,0h46.7C50.8,0,52,1.2,52,2.6v34.1
          C52,38.2,50.8,39.4,49.3,39.4z M2.6,1.5C2,1.5,1.5,2,1.5,2.6v34.1c0,0.6,0.5,1.1,1.1,1.1h46.7c0.6,0,1.1-0.5,1.1-1.1V2.6
          c0-0.6-0.5-1.1-1.1-1.1H2.6z"
        />
        <g>
          <circle
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            cx="12.9"
            cy="12.5"
            r="5.4"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M12.9,18.6c-3.4,0-6.1-2.7-6.1-6.1c0-3.4,2.7-6.1,6.1-6.1s6.1,2.7,6.1,6.1
            C19.1,15.9,16.3,18.6,12.9,18.6z M12.9,7.9c-2.5,0-4.6,2.1-4.6,4.6c0,2.5,2.1,4.6,4.6,4.6s4.6-2.1,4.6-4.6
            C17.6,9.9,15.5,7.9,12.9,7.9z"
          />
        </g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M5.3,39.4c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l18.6-18.6c0.7-0.7,1.8-0.7,2.5,0
          l10.2,10.2c0.3,0.3,0.3,0.8,0,1.1s-0.8,0.3-1.1,0L24.8,20.6c-0.1-0.1-0.3-0.1-0.4,0L5.9,39.1C5.7,39.3,5.5,39.4,5.3,39.4z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M29.8,25.3l0-0.8l0.5-0.5l-0.5,0.5L29.4,24c0.4-0.3,5.1-5,11.9-11.9c0.3-0.3,0.8-0.5,1.2-0.5
          c0,0,0,0,0,0c0.5,0,0.9,0.2,1.2,0.5l7.9,7.9c0.3,0.3,0.3,0.8,0,1.1s-0.8,0.3-1.1,0l-7.9-7.9c-0.1-0.1-0.1-0.1-0.2-0.1l0,0
          c0,0-0.1,0-0.2,0.1C30.3,25.3,30.3,25.3,29.8,25.3z"
        />
      </g>
    </svg>
  )
}

export const Maps = () => {
  return (
    <svg className="w-full h-full category__svg maps" version="1.1" viewBox="0 0 56.4 41.2" x="0px" y="0px">
      <g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M55.6,41.2H0.7c-0.3,0-0.5-0.2-0.7-0.4C-0.1,40.5,0,40.2,0.2,40l9.2-11.6
          c0.1-0.2,0.4-0.3,0.6-0.3h36.5c0.2,0,0.4,0.1,0.6,0.3L56.2,40c0.2,0.2,0.2,0.5,0.1,0.8S55.9,41.2,55.6,41.2z M2.3,39.7h51.8
          l-8-10.1H10.3L2.3,39.7z"
        />
        <g>
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M14,41.2c-0.1,0-0.2,0-0.4-0.1c-0.4-0.2-0.5-0.7-0.3-1l6.3-11.6c0.2-0.4,0.7-0.5,1-0.3
            c0.4,0.2,0.5,0.7,0.3,1l-6.3,11.6C14.5,41,14.2,41.2,14,41.2z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M42.4,41.2c-0.3,0-0.5-0.1-0.7-0.4l-6.3-11.6c-0.2-0.4-0.1-0.8,0.3-1c0.4-0.2,0.8-0.1,1,0.3
            L43,40.1c0.2,0.4,0.1,0.8-0.3,1C42.6,41.2,42.5,41.2,42.4,41.2z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M39.8,12.3c0-6.6-5.5-11.9-12.1-11.6c-5.9,0.2-10.7,5-11.1,10.9c-0.1,2.5,0.5,4.9,1.8,6.9
            L26,32.8c0.9,1.7,3.4,1.8,4.4,0l8-14.9h0C39.3,16.2,39.8,14.3,39.8,12.3z M28.2,17.8c-3,0-5.5-2.4-5.5-5.5s2.4-5.5,5.5-5.5
            s5.5,2.4,5.5,5.5S31.2,17.8,28.2,17.8z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M28.2,34.9C28.2,34.9,28.2,34.9,28.2,34.9c-1.2,0-2.3-0.6-2.9-1.7l-7.6-14.3
            c-1.4-2.1-2-4.7-1.9-7.3C16.2,5.4,21.4,0.3,27.7,0c3.4-0.1,6.6,1.1,9.1,3.4c2.4,2.4,3.8,5.5,3.8,8.9c0,2.1-0.5,4.1-1.5,5.9
            l-8,14.9C30.5,34.2,29.4,34.9,28.2,34.9z M28.2,1.5c-0.2,0-0.3,0-0.5,0c-5.5,0.2-10.1,4.7-10.4,10.2c-0.1,2.3,0.4,4.5,1.7,6.4
            l7.6,14.3c0.3,0.6,0.9,0.9,1.5,0.9c0,0,0,0,0,0c0.6,0,1.2-0.3,1.5-0.9l8-14.9c0.9-1.6,1.3-3.4,1.3-5.2c0-3-1.2-5.8-3.3-7.8
            C33.6,2.6,31,1.5,28.2,1.5z M28.2,18.5c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
            C34.4,15.8,31.6,18.5,28.2,18.5z M28.2,7.6c-2.6,0-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7
            C32.9,9.8,30.8,7.6,28.2,7.6z"
          />
        </g>
      </g>
    </svg>
  )
}

export const Notebook = () => {
  return (
    <svg className="w-full h-full category__svg notebook" version="1.1" viewBox="0 0 65.3 41.2" x="0px" y="0px">
      <g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M61.2,41.2h-57C1.9,41.2,0,39.3,0,37c0-0.4,0.3-0.8,0.8-0.8h63.8c0.4,0,0.8,0.3,0.8,0.8
          C65.3,39.3,63.5,41.2,61.2,41.2z M1.6,37.8c0.3,1.1,1.3,1.9,2.6,1.9h57c1.2,0,2.2-0.8,2.6-1.9H1.6z"
        />
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M57.4,0.8H7.9c-1.4,0-2.4,1.1-2.4,2.4V37h3.3V4.8c0-0.3,0.3-0.6,0.6-0.6h46.5
            c0.3,0,0.6,0.3,0.6,0.6V37h3.4V3.2C59.9,1.8,58.8,0.8,57.4,0.8z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M59.9,37.8h-3.4c-0.4,0-0.8-0.3-0.8-0.8V5H9.5V37c0,0.4-0.3,0.8-0.8,0.8H5.4
            c-0.4,0-0.8-0.3-0.8-0.8V3.2C4.7,1.4,6.1,0,7.9,0h49.5c1.8,0,3.2,1.4,3.2,3.2V37C60.6,37.4,60.3,37.8,59.9,37.8z M57.2,36.3h1.9
            V3.2c0-0.9-0.8-1.7-1.7-1.7H7.9C7,1.5,6.2,2.3,6.2,3.2v33.1H8V4.8c0-0.8,0.6-1.4,1.4-1.4h46.5c0.8,0,1.4,0.6,1.4,1.4V36.3z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M36.7,38.7h-8c-0.7,0-1.3-0.6-1.3-1.3l0,0c0-0.2,0.1-0.3,0.3-0.3h9.9c0.2,0,0.3,0.1,0.3,0.3
            l0,0C37.9,38.1,37.3,38.7,36.7,38.7z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M36.7,39.4h-8c-1.1,0-2-0.9-2-2c0-0.6,0.5-1.1,1.1-1.1h9.9c0.6,0,1.1,0.5,1.1,1.1
            C38.7,38.5,37.8,39.4,36.7,39.4z M28.4,37.8c0.1,0,0.1,0.1,0.2,0.1h8c0.1,0,0.2,0,0.2-0.1H28.4z"
          />
        </g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M34.5,3.3h-3.7C30.4,3.3,30,3,30,2.6s0.3-0.8,0.8-0.8h3.7c0.4,0,0.8,0.3,0.8,0.8
          S34.9,3.3,34.5,3.3z"
        />
      </g>
    </svg>
  )
}

export const Processes = () => {
  return (
    <svg className="w-full h-full category__svg processes" version="1.1" viewBox="0 0 41.9 41.3" x="0px" y="0px">
      <g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M3.7,32c-0.3,0-0.5-0.1-0.7-0.4c-1.7-2.9-2.6-6.2-2.6-9.6C0.5,14,5.6,6.8,13.2,4
          c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1C6.7,8,2,14.7,2,22.1c0,3.1,0.8,6.2,2.4,8.8c0.2,0.4,0.1,0.8-0.3,1
          C4,32,3.8,32,3.7,32z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M19.7,41.3c-5,0-9.8-1.9-13.4-5.5c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0
          c3.3,3.2,7.7,5,12.4,5c6.4,0,12.3-3.5,15.5-9.1c0.2-0.4,0.7-0.5,1-0.3c0.4,0.2,0.5,0.7,0.3,1C33.1,37.5,26.7,41.3,19.7,41.3z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M37.7,26.9c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.7-0.5-0.6-0.9c0.3-1.3,0.4-2.6,0.4-3.9
          c0-9.8-7.9-17.7-17.7-17.7c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8c10.6,0,19.2,8.6,19.2,19.2c0,1.4-0.2,2.8-0.5,4.2
          C38.4,26.7,38.1,26.9,37.7,26.9z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M12.2,9.1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.5-0.6-0.4-1L13,4.8L9.8,3.3
          c-0.4-0.2-0.5-0.6-0.4-1c0.2-0.4,0.6-0.5,1-0.4l3.9,1.8c0.4,0.2,0.5,0.6,0.4,1l-1.8,3.9C12.8,9,12.5,9.1,12.2,9.1z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M37.8,27c-0.2,0-0.4-0.1-0.6-0.3l-2.6-3.4c-0.3-0.3-0.2-0.8,0.1-1.1c0.3-0.3,0.8-0.2,1.1,0.1
          l2.2,2.8l2.8-2.2c0.3-0.3,0.8-0.2,1.1,0.1c0.3,0.3,0.2,0.8-0.1,1.1l-3.4,2.6C38.1,27,37.9,27,37.8,27z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M6.8,40c-0.4,0-0.7-0.3-0.7-0.7L5.8,35c0-0.4,0.3-0.8,0.7-0.8l4.3-0.3c0.4,0,0.8,0.3,0.8,0.7
          c0,0.4-0.3,0.8-0.7,0.8l-3.5,0.2l0.2,3.5C7.6,39.6,7.3,39.9,6.8,40C6.9,40,6.8,40,6.8,40z"
        />
        <g>
          <g>
            <circle
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              cx="18.6"
              cy="3.6"
              r="2.9"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M18.6,7.3c-2,0-3.6-1.6-3.6-3.6S16.6,0,18.6,0s3.6,1.6,3.6,3.6S20.6,7.3,18.6,7.3z
              M18.6,1.5c-1.2,0-2.1,1-2.1,2.1s1,2.1,2.1,2.1s2.1-1,2.1-2.1S19.8,1.5,18.6,1.5z"
            />
          </g>
          <g>
            <circle
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              cx="35.8"
              cy="30.8"
              r="2.9"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M35.8,34.5c-2,0-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6s3.6,1.6,3.6,3.6S37.8,34.5,35.8,34.5z
              M35.8,28.7c-1.2,0-2.1,1-2.1,2.1s1,2.1,2.1,2.1s2.1-1,2.1-2.1S37,28.7,35.8,28.7z"
            />
          </g>
          <g>
            <circle
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              cx="3.6"
              cy="30.8"
              r="2.9"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M3.6,34.5c-2,0-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6s3.6,1.6,3.6,3.6S5.6,34.5,3.6,34.5z
              M3.6,28.7c-1.2,0-2.1,1-2.1,2.1s1,2.1,2.1,2.1s2.1-1,2.1-2.1S4.8,28.7,3.6,28.7z"
            />
          </g>
        </g>
        <circle
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          cx="19.7"
          cy="22.1"
          r="10.2"
        />
      </g>
    </svg>
  )
}

export const Sections = () => {
  return (
    <svg className="w-full h-full category__svg sections" version="1.1" viewBox="0 0 51.7 37.3" x="0px" y="0px">
      <g>
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M49.8,12.4h-41c-0.6,0-1.1-0.5-1.1-1.1V0h43.2v11.3C50.9,11.9,50.4,12.4,49.8,12.4z"
        />
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M8.8,24.9h41c0.6,0,1.1,0.5,1.1,1.1v11.3H7.7V26C7.7,25.4,8.2,24.9,8.8,24.9z"
        />
        <g>
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M50.9,19.1H5.3c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h45.7c0.4,0,0.8,0.3,0.8,0.8
            S51.3,19.1,50.9,19.1z"
          />
          <g>
            <circle
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              cx="3.9"
              cy="18.3"
              r="3.1"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M3.9,22.2c-2.1,0-3.9-1.7-3.9-3.9s1.7-3.9,3.9-3.9s3.9,1.7,3.9,3.9S6,22.2,3.9,22.2z
              M3.9,16c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4S5.2,16,3.9,16z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const Smartphone = () => {
  return (
    <svg className="w-full h-full category__svg smartphone" version="1.1" viewBox="0 0 23.1 41.2" x="0px" y="0px">
      <g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M20.4,0.8H2.8c-1.1,0-2,0.9-2,2v35.7c0,1.1,0.9,2,2,2h17.6c1.1,0,2-0.9,2-2V2.8
            C22.4,1.6,21.5,0.8,20.4,0.8z M20.1,35.1H3V4.5h17.1V35.1z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M20.4,41.2H2.8C1.2,41.2,0,40,0,38.4V2.8C0,1.2,1.2,0,2.8,0h17.6c1.5,0,2.8,1.2,2.8,2.8v35.7
            C23.1,40,21.9,41.2,20.4,41.2z M2.8,1.5c-0.7,0-1.3,0.6-1.3,1.3v35.7c0,0.7,0.6,1.3,1.3,1.3h17.6c0.7,0,1.3-0.6,1.3-1.3V2.8
            c0-0.7-0.6-1.3-1.3-1.3H2.8z M20.1,35.9H3c-0.4,0-0.8-0.3-0.8-0.8V4.5c0-0.4,0.3-0.8,0.8-0.8h17.1c0.4,0,0.8,0.3,0.8,0.8v30.6
            C20.9,35.5,20.5,35.9,20.1,35.9z M3.8,34.4h15.6V5.3H3.8V34.4z"
          />
        </g>
        <circle
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          cx="11.6"
          cy="37.8"
          r="1.1"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M13.4,3.1H9.7c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h3.7c0.3,0,0.5,0.2,0.5,0.5
          S13.7,3.1,13.4,3.1z"
        />
      </g>
    </svg>
  )
}

export const MapsEurope = () => {
  return (
    <svg className="w-full h-full category__svg mapseurope" version="1.1" viewBox="0 0 51.9 64.4" x="0px" y="0px">
      <g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M50.5,45l-3.4-1.1c0,0-0.1,0-0.1-0.1l-1.5-1.4c0,0-0.1-0.1-0.1-0.1l-0.2-1.1
            c-0.1-0.4-0.4-0.7-0.8-0.7H43c-0.1,0-0.2-0.1-0.2-0.2l-0.2-0.9c0-0.1,0-0.2,0.2-0.3l0.3-0.1c0.3-0.1,0.5-0.3,0.6-0.6
            c0.1-0.3,0-0.6-0.3-0.8l-1.2-1c0,0-0.1-0.1-0.1-0.2l-0.1-1.1c0-0.4-0.3-0.7-0.7-0.8l-2.1-0.4c-0.1,0-0.2-0.1-0.2-0.2l-0.2-3.3
            c0-0.1,0-0.2-0.1-0.2l-0.6-1.5c0-0.1,0-0.3,0.1-0.3l0.5-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1-0.1-0.2-0.3-0.2H36
            c-0.3,0-0.6,0.2-0.8,0.5L34.9,29c-0.1,0.2-0.1,0.5,0,0.7l0.6,1.2c0,0.1,0,0.2,0,0.2l-0.6,1c-0.1,0.1-0.2,0.2-0.4,0.1l-0.6-0.5
            c-0.2-0.1-0.3-0.2-0.5-0.2c-0.4,0-0.7,0.3-0.8,0.6L32,35l-0.7,2c0,0.1-0.1,0.2-0.2,0.2h-1.4c-0.1,0-0.2-0.1-0.2-0.2l-0.1-0.4
            c0-0.2-0.2-0.2-0.4-0.2l-2.4,0.7c-0.2,0.1-0.4,0.2-0.5,0.5L25.4,39c-0.1,0.1-0.2,0.1-0.2,0.1c0,0-0.2,0-0.2-0.1l-0.7-1.3
            c-0.1-0.3-0.4-0.5-0.7-0.5c0,0-0.1,0-0.1,0l-2,0.2c-0.1,0-0.2,0-0.2-0.1l-0.8-1.1c0-0.1-0.1-0.2,0-0.2l0.8-1.9
            c0-0.1,0.1-0.2,0.1-0.4l-0.1-1.5c0-0.6-0.6-0.9-1.2-0.7l-0.7,0.3C19.2,31.8,19,32,19,32.3l-0.4,1.8c0,0.2,0,0.3,0,0.5l0.8,2.5
            l0.5,1.1c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.1L18.4,39c0,0-0.1,0-0.1,0l-0.6-0.1c-0.3-0.1-0.7,0.1-0.9,0.4l-2.1,3.3
            c0,0-0.1,0.1-0.1,0.1L13,43.2c-0.1,0.1-0.3,0.1-0.4,0.3l-1.8,2.2c0,0.1-0.1,0.1-0.2,0.1l-0.5-0.1c-0.4-0.1-0.9,0.2-1,0.7
            c0,0.1-0.1,0.2-0.2,0.2l-0.7,0.1c-0.4,0-0.7,0.3-0.7,0.7c-0.1,0.4,0.1,0.7,0.4,0.9l0.6,0.3c0,0,0.1,0.1,0.1,0.1l1.6,3.2
            c0,0.1,0,0.1,0,0.2l-0.9,2.2c0,0.1-0.1,0.1-0.2,0.1H2.8c-0.5,0-0.8,0.4-0.8,0.8V62c0,0.5,0.4,0.8,0.8,0.8h1.3c0.1,0,0.2,0,0.2,0.1
            l0.2,0.3c0.1,0.3,0.4,0.4,0.7,0.4c0.1,0,0.3,0,0.4-0.1l0.7-0.4c0,0,0.1,0,0.1,0l2,0c0.2,0,0.5-0.1,0.6-0.3l1.6-1.9
            c0.1-0.1,0.1-0.2,0.2-0.3l0.8-2.7c0-0.1,0.1-0.1,0.1-0.1l1.9-1.1c0.2-0.1,0.4-0.4,0.4-0.6l0.1-1.2c0-0.1,0.1-0.2,0.2-0.2l2.6-0.5
            c0.2,0,0.4-0.2,0.5-0.3l0.5-0.6c0-0.1,0.1-0.1,0.2-0.1l0.3,0.1c0.2,0.1,0.5,0,0.7-0.1l0.5-0.4c0.1-0.1,0.2-0.1,0.2,0l1.3,0.5
            c0.1,0,0.1,0.1,0.2,0.2l0.3,1.5c0,0.2,0.1,0.4,0.3,0.5l1.5,1.3c0,0,0.1,0.1,0.1,0.1l2.8,1.8c0.1,0,0.1,0.1,0.1,0.2l0.3,1.2v1.3
            c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.3,0,0.3-0.1l0.8-1.2c0.1-0.2,0.2-0.5,0.1-0.7l-0.3-0.9l0.1,0l0.8,0.4c0,0,0.1,0,0.1,0c0,0,0,0,0,0
            c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.2-0.1-0.2l-0.7-1.3c-0.1-0.1-0.2-0.2-0.3-0.3l-1.5-0.8l-1.2-0.9c0,0,0,0-0.1-0.1l-1.8-3.1
            c0-0.1,0-0.2,0-0.3l0.8-1.2c0.1-0.1,0.3-0.1,0.4,0l1.7,2.4l0.5,0.7c0.1,0.2,0.3,0.3,0.6,0.4l1.2,0.2c0,0,0.1,0,0.1,0.1l1.6,1.3
            c0.1,0,0.1,0.1,0.1,0.2v2.1c0,0.2,0,0.3,0.1,0.5l1.4,2.1c0.1,0.1,0.2,0.3,0.4,0.3l0.6,0.2c-0.1,0.1-0.1,0.2-0.2,0.2
            c-0.1,0.2-0.1,0.5,0,0.8l0.1,0.3c0.1,0.3,0.4,0.4,0.7,0.4h0l0.2,0c0.3,0,0.5-0.1,0.7-0.4c0.2-0.2,0.2-0.5,0.1-0.7L34,60.2
            c0,0,0-0.1,0-0.1l0.1-1.8c0-0.1,0.1-0.2,0.1-0.2l1.8-0.8c0.1,0,0.1,0,0.2,0l0.2,0.1c0.4,0.2,1,0,1.1-0.4l0.2-0.5
            c0-0.1,0.1-0.1,0.2-0.1l0.7-0.1c0.3,0,0.5-0.2,0.6-0.4c0.1-0.2,0.1-0.5,0-0.7l0-0.1c0-0.1,0-0.1,0-0.2l2.1-4.5
            c0-0.1,0.1-0.1,0.2-0.1l2-0.4c0,0,0.1,0,0.1,0l0.9,0.4c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2c-0.2,0.2-0.2,0.6-0.1,0.9l0.2,0.5
            c0.2,0.4,0.8,0.6,1.2,0.4l0.9-0.5c0.2-0.1,0.4-0.3,0.4-0.6c0-0.2,0-0.5-0.2-0.7l-0.2-0.2c-0.1-0.1-0.2-0.2-0.2-0.2l4.3-2.9
            c0.2-0.2,0.4-0.4,0.4-0.7v-0.8C51.1,45.4,50.9,45.1,50.5,45z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M5.3,64.4c-0.6,0-1.1-0.3-1.4-0.8l0,0H2.8c-0.9,0-1.6-0.7-1.6-1.6v-6.9
            c0-0.9,0.7-1.6,1.6-1.6h6l0.7-1.7L8.1,49l-0.5-0.3c-0.6-0.3-0.9-1-0.8-1.7c0.1-0.7,0.7-1.2,1.4-1.3l0.3,0c0.3-0.7,1-1,1.7-0.9
            l0.2,0l1.6-2c0.2-0.2,0.4-0.4,0.7-0.5l1.4-0.5l2-3.1c0.4-0.6,1-0.8,1.7-0.7l0.4,0.1l0.7-0.3l-0.3-0.6l-0.8-2.5
            c-0.1-0.3-0.1-0.6,0-0.9l0.4-1.8c0.1-0.5,0.5-0.9,0.9-1.1l0.7-0.3c0.5-0.2,1-0.2,1.4,0.1c0.4,0.3,0.7,0.7,0.8,1.3l0.1,1.5
            c0,0.2,0,0.5-0.1,0.7L21.3,36l0.5,0.7l1.7-0.2c0,0,0.1,0,0.1,0c0.6,0,1.1,0.3,1.4,0.9l0.2,0.3l0.2-0.4c0.2-0.4,0.6-0.8,1-0.9
            l2.4-0.7c0.5-0.1,1.1,0.2,1.3,0.7h0.7l0.6-1.7L32,32c0.2-0.7,0.8-1.2,1.5-1.2c0.4,0,0.7,0.1,1,0.4l0.1,0.1l0.1-0.2l-0.5-1
            c-0.2-0.4-0.2-0.9-0.1-1.3l0.3-0.8c0.2-0.6,0.8-1,1.5-1h2.9c0.5,0,0.9,0.3,1,0.8c0.1,0.5-0.1,1-0.6,1.2l-0.1,0l0.4,1.1
            c0.1,0.1,0.1,0.3,0.1,0.5l0.1,2.9l1.7,0.4c0.7,0.1,1.2,0.7,1.3,1.5l0,0.9l1,0.9c0.4,0.4,0.6,1,0.5,1.5c-0.1,0.5-0.5,0.9-0.9,1.1
            l0,0h1.1c0.8,0,1.4,0.6,1.6,1.3l0.1,0.9l1.3,1.2l3.3,1.1c0,0,0,0,0,0c0.6,0.2,1.1,0.8,1.1,1.5v0.8c0,0.5-0.3,1-0.7,1.3l-3.5,2.4
            c0.2,0.3,0.2,0.7,0.2,1.1c-0.1,0.5-0.4,0.9-0.8,1.1L46.2,53c-0.4,0.2-0.8,0.3-1.3,0.1c-0.4-0.1-0.8-0.4-1-0.8l-0.2-0.5
            c-0.2-0.3-0.2-0.7-0.1-1.1l-0.2-0.1l-1.5,0.3L40,55c0.1,0.5,0.1,0.9-0.1,1.3c-0.2,0.4-0.7,0.7-1.2,0.8l-0.4,0l-0.1,0.3
            c-0.2,0.4-0.5,0.7-0.9,0.9c-0.4,0.1-0.9,0.1-1.2-0.1l-1.3,0.5l-0.1,1.4l0.5,1.6c0.2,0.5,0.1,1-0.2,1.4c-0.3,0.4-0.7,0.7-1.2,0.7
            l-0.2,0c0,0,0,0,0,0c-0.6,0-1.1-0.3-1.4-0.8l-0.1-0.2c-0.2-0.3-0.3-0.7-0.2-1c-0.2-0.1-0.4-0.3-0.6-0.5L29.9,59c0,0,0-0.1-0.1-0.1
            c0,0.5-0.4,1-1,1c0,0.3-0.1,0.6-0.3,0.8l-0.8,1.2c-0.3,0.4-0.7,0.6-1.2,0.4c-0.5-0.1-0.8-0.5-0.8-1v-1.2l-0.2-0.9l-2.6-1.7
            c-0.1,0-0.1-0.1-0.2-0.1l-1.5-1.3c-0.3-0.2-0.5-0.6-0.5-0.9l-0.2-1.2l-0.7-0.3l-0.3,0.2c-0.4,0.3-0.9,0.4-1.3,0.3l0,0L18,54.5
            c-0.2,0.3-0.6,0.5-1,0.6l-2.2,0.4l-0.1,0.8c0,0.5-0.4,1-0.8,1.2l-1.7,1l-0.7,2.5c-0.1,0.2-0.2,0.4-0.3,0.6l-1.6,1.9
            c-0.3,0.4-0.7,0.6-1.2,0.6l-1.9,0L6,64.2C5.8,64.4,5.5,64.4,5.3,64.4z M2.8,55C2.8,55,2.8,55.1,2.8,55l-0.1,7c0,0,0,0.1,0.1,0.1
            h1.3c0.4,0,0.7,0.2,0.9,0.5l0.6,1l-0.4-0.7L6,62.6c0.1-0.1,0.3-0.1,0.4-0.1l2,0l1.6-1.9l0.8-2.7c0.1-0.3,0.2-0.5,0.5-0.6l1.9-1.1
            l0.2-1.2c0-0.4,0.4-0.8,0.8-0.9l2.6-0.4l0.5-0.7c0.1-0.2,0.4-0.4,0.8-0.4c0.1,0,0.1,0,0.2,0l0.3,0.1l0.6-0.4
            c0.3-0.2,0.6-0.3,1-0.1l1.3,0.5c0.3,0.1,0.6,0.4,0.6,0.8l0.3,1.5l1.5,1.4l2.8,1.8c0.2,0.1,0.3,0.2,0.4,0.4
            c0.1-0.2,0.2-0.4,0.4-0.4c0.1,0,0.1,0,0.2-0.1l-0.1-0.2L26.1,57c0,0-0.1,0-0.1-0.1L24.8,56c-0.1-0.1-0.2-0.2-0.3-0.3l-1.8-3.1
            c-0.2-0.3-0.2-0.8,0-1.1l0.8-1.2c0.2-0.3,0.5-0.4,0.8-0.4h0c0.3,0,0.6,0.2,0.8,0.4l2.2,3.2l1.2,0.2c0.2,0,0.4,0.1,0.5,0.2l1.6,1.3
            c0.2,0.2,0.4,0.5,0.4,0.8v2.1l1.4,2.2l0.6,0.3c0.1,0,0.2,0.1,0.3,0.2l-0.1-0.3c0-0.1-0.1-0.3-0.1-0.4l0.1-1.8
            c0-0.4,0.2-0.7,0.6-0.9l1.8-0.7c0.3-0.1,0.6-0.1,0.9,0l0.2,0.1l0.8,0.3l-0.7-0.3l0.2-0.5c0.1-0.3,0.4-0.5,0.8-0.6l0.7-0.1l0.8-0.4
            l-0.7,0.3c-0.1-0.3-0.1-0.6,0-0.9l2.1-4.5c0.1-0.3,0.4-0.5,0.7-0.6l2-0.4c0,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0.1l0.9,0.4
            c0.3,0.1,0.5,0.4,0.6,0.7c0.1,0.3,0,0.6-0.2,0.9l0.2,0.6l0,0l0.1,0l0,0l0.9-0.5c0,0,0-0.1,0-0.1l-0.2-0.2
            c-0.2-0.1-0.4-0.4-0.4-0.6c0-0.3,0.1-0.5,0.3-0.7l4.3-2.9l0-0.9l0.2-0.8l-0.2,0.7L47,44.6c-0.1,0-0.3-0.1-0.4-0.2L45.1,43
            c-0.2-0.2-0.3-0.4-0.3-0.6l-0.2-1.1c0,0,0-0.1-0.1-0.1H43c-0.5,0-0.9-0.3-1-0.8l-0.2-0.9c-0.1-0.5,0.2-1,0.7-1.2l0.3-0.1l0.5-0.7
            l-0.5,0.6l-1.2-1c-0.2-0.2-0.3-0.4-0.3-0.7l-0.1-1.1l-2.2-0.5c-0.4-0.1-0.8-0.5-0.8-0.9l-0.2-3.3l-0.6-1.5c-0.1-0.2-0.1-0.4,0-0.6
            H36l-0.4,0.8l0.6,1.3c0.2,0.3,0.1,0.7,0,1l-0.6,1c-0.2,0.3-0.4,0.4-0.7,0.5c-0.3,0-0.6,0-0.8-0.2l-0.6-0.5c-0.1,0-0.1,0-0.1,0
            l-0.7,2.8l-0.7,2.1c-0.1,0.4-0.5,0.7-0.9,0.7h-1.4c-0.4,0-0.8-0.3-0.9-0.6l-2,0.5l-0.7,1.4c-0.2,0.4-0.5,0.6-0.9,0.6
            c-0.4,0-0.7-0.2-0.9-0.6l-0.7-1.3l-2.1,0.1c-0.4,0-0.7-0.2-0.9-0.4L20.2,37l0.3,0.8c0.1,0.2,0.1,0.5,0,0.8
            c-0.1,0.3-0.3,0.4-0.6,0.5l-1.3,0.5c-0.2,0.1-0.3,0.1-0.5,0.1c0,0-0.1,0-0.1,0l-0.6-0.1l-2.2,3.3c-0.1,0.2-0.3,0.3-0.5,0.4
            l-1.5,0.6l-1.8,2.2c-0.1,0.2-0.4,0.4-0.9,0.4c0,0-0.1,0-0.1,0L10,46.4c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0.4-0.5,0.7-0.9,0.8l-0.7,0.1
            L8,48.1l0.4-0.7L9,47.8c0.2,0.1,0.3,0.2,0.4,0.4l1.6,3.2c0.1,0.3,0.1,0.6,0,0.8l-0.9,2.2C9.9,54.8,9.6,55,9.2,55H2.8z M33.6,61.4
            c0,0.2-0.1,0.4-0.2,0.5l0.1,0.3c0,0,0.1,0,0.1,0l0.2,0l0.8-0.4l-0.7,0.2L33.6,61.4z M29.6,58.2C29.6,58.3,29.6,58.3,29.6,58.2
            C29.7,58.3,29.7,58.2,29.6,58.2l0-1.9l-1.3-1.1l-1-0.1c-0.5-0.1-0.8-0.3-1.1-0.7l-1.8-2.6l-0.2,0.3l1.6,2.8l1,0.8l1.4,0.7
            c0.3,0.1,0.5,0.3,0.6,0.6L29.6,58.2z M20.4,32.1L20.4,32.1l-0.7,0.3l-0.5,1.9l0.5,1.5c0,0,0-0.1,0-0.1l0.8-1.9l-0.1-1.6l0,0
            C20.5,32.2,20.4,32.1,20.4,32.1z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M12.5,40.9l-0.2-0.7c-0.1-0.3-0.4-0.5-0.7-0.3L11.2,40L11,39.5l-0.2-2.2c0-0.1,0-0.2-0.1-0.2
            l-1.6-2.3c0-0.1-0.1-0.1-0.2-0.1C8.4,34.3,8,34.1,7.8,34c0.2-0.3,0.6-0.8,0.8-1.2c0-0.1,0.1-0.1,0.1-0.2l0.1-0.8
            c0-0.2-0.1-0.5-0.4-0.5l-1.3-0.3c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l0.7-1.1c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.2-0.3-0.2H6.4
            c-0.2,0-0.3,0.1-0.4,0.2l-1.3,2.1c-0.1,0.1-0.1,0.3,0,0.5l1.4,2.5l-0.1,1.5c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.2
            c0,0,0.1,0,0.2,0l0.8-0.3l0.3,2.8L6.3,41c-0.1,0.1-0.1,0.3-0.1,0.5c0.1,0.2,0.2,0.3,0.4,0.3L8,42l-0.7,0.6l-1.7,0.8
            c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.2,0.2,0.3l1.2,0.4c0,0,0.1,0,0.1,0c0.1,0,0.3-0.1,0.4-0.2l0.8-0.8l2.7-0.2
            c0.2,0,0.3-0.1,0.4-0.2l1.2-1.8C12.5,41.1,12.5,41,12.5,40.9z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M7,45.2c-0.1,0-0.3,0-0.3-0.1l-1.3-0.4c-0.4-0.1-0.7-0.5-0.7-0.9c0-0.4,0.2-0.8,0.6-1
            l0.8-0.4c-0.3-0.1-0.5-0.4-0.6-0.7c-0.2-0.4-0.1-0.9,0.2-1.2l1.3-1.6l-0.1-1.5c-0.5,0.1-0.9,0-1.2-0.4c-0.2-0.2-0.4-0.6-0.3-0.9
            l0.1-1.2l-1.3-2.3c-0.2-0.4-0.2-0.9,0-1.2l1.3-2.1c0.2-0.4,0.6-0.6,1.1-0.6h1.1c0.4,0,0.7,0.2,0.9,0.6c0.2,0.3,0.2,0.8-0.1,1.1
            l-0.1,0.2l0.5,0.1c0.6,0.2,1,0.7,0.9,1.4l-0.1,0.8c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.2,0.3-0.3,0.5c0.1,0.1,0.2,0.1,0.4,0.2
            c0.2,0.1,0.3,0.2,0.4,0.4l1.6,2.3c0.1,0.2,0.2,0.4,0.2,0.6l0.2,1.8c0.2,0,0.5,0,0.7,0.1c0.3,0.2,0.6,0.5,0.6,0.8l0.2,0.7
            c0.1,0.3,0,0.7-0.2,0.9l-1.2,1.8c-0.2,0.3-0.5,0.5-0.9,0.5l-2.4,0.2L8,44.8C7.7,45,7.4,45.2,7,45.2z M7.2,41.2l0.9,0.2
            c0.3,0,0.5,0.3,0.6,0.5c0.1,0.3,0,0.6-0.2,0.8l-0.1,0.1l2.3-0.2l1-1.6l-0.1-0.3l-0.1,0.1c-0.4,0.2-0.8,0-1-0.4l-0.2-0.5
            c0-0.1-0.1-0.2-0.1-0.2L10,37.4l-1.5-2.2c-0.5-0.3-0.8-0.4-1-0.5c-0.2-0.1-0.4-0.3-0.4-0.5s0-0.4,0.1-0.6c0.2-0.3,0.5-0.8,0.8-1.1
            l0.1-0.5l-1-0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.3-0.2-0.6,0-0.8l0.3-0.4h0l-1.1,1.8l1.4,2.4C6.9,34.5,7,34.6,7,34.8l-0.1,1.1
            l0.4-0.2c0.2-0.1,0.5,0,0.6,0.1s0.3,0.3,0.3,0.6l0.3,2.8c0,0.2,0,0.4-0.2,0.6L7.2,41.2z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M5.2,37.3L4.8,36c-0.1-0.2-0.3-0.3-0.5-0.3c0,0-0.1,0-0.1,0l-1.9,0.5c-0.1,0-0.2,0.1-0.3,0.2
            l-0.8,1.1c-0.1,0.1-0.1,0.2-0.1,0.3v1.4c0,0.1,0.1,0.3,0.3,0.3l0.1,0c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.2,0.1,0.3,0.3,0.4l-0.8,0.9
            c-0.2,0.2-0.2,0.5,0,0.7l0.4,0.4c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2-0.1l2.7-1.6c0.2-0.1,0.3-0.3,0.2-0.5l-0.2-1.3l0.5-1.2
            C5.3,37.5,5.3,37.4,5.2,37.3z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M1.6,43c-0.3,0-0.6-0.1-0.9-0.4l-0.4-0.4c-0.5-0.5-0.5-1.2-0.1-1.7l0.4-0.5
            c0-0.1,0-0.1-0.1-0.2c-0.2-0.2-0.3-0.4-0.3-0.7v-1.4c0-0.3,0.1-0.5,0.2-0.7l0.8-1.1c0.2-0.2,0.4-0.4,0.7-0.5L4,35
            c0.6-0.1,1.3,0.2,1.5,0.8L5.9,37C6,37.3,6,37.7,5.9,38l-0.5,1L5.6,40c0.1,0.5-0.2,1-0.6,1.2l-2.7,1.6C2,42.9,1.8,43,1.6,43z
            M1.6,41.4L1.6,41.4L4.1,40l-0.1-1.1c0-0.1,0-0.3,0.1-0.4l0.5-1l-0.3-0.9l-1.6,0.4l-0.7,1v1c0.2,0.1,0.3,0.3,0.4,0.4
            c0,0.1,0,0.3,0,0.4c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2,0,0.4-0.1,0.6L1.6,41.4z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M42.4,20.3l-0.8-1l-0.6-3l0.5-1.5c0-0.1,0-0.2,0-0.3l-0.9-2.6l0.5-3c0-0.2,0-0.3-0.1-0.4
            l-1.1-1.1l0.3-3.9l1.3-1.3c0.1-0.1,0.2-0.3,0.1-0.5l-0.2-0.5c-0.1-0.2-0.3-0.3-0.5-0.3c0,0,0,0,0,0l-4.7,0.2C36.1,0.9,36,1,36,1
            l-3.8,2l-3.3,2c0,0-0.1,0.1-0.1,0.1l-3.2,4.6c0,0,0,0,0,0.1l-3.2,6.6l-1,0.5c-0.2,0.1-0.3,0.4-0.2,0.6l0.2,0.6
            c0,0.1,0.2,0.2,0.3,0.3l0.3,0.1l-3,1.2c-0.1,0-0.1,0.1-0.2,0.1l-2.7,3C16,23,15.9,23.2,16,23.3l0.2,0.9c0.1,0.2,0.3,0.4,0.5,0.4
            c0,0,0.1,0,0.2,0l1.3-0.4l-1.4,5.2c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.2H19c0.7,0,1.4-0.4,1.7-1.1l0.5-1.2l3,7.4
            c0.1,0.2,0.3,0.3,0.5,0.3c0.1,0,0.2,0,0.2-0.1l2-1c0.1-0.1,0.2-0.2,0.3-0.4l0.5-5.5l1.5-0.4c0.2-0.1,0.3-0.2,0.4-0.4l0.1-0.8
            c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2l-1.5,0.2l1.1-5.2l3.2-2.5c0.1-0.1,0.2-0.2,0.2-0.3l0.3-2.2l1-2.2l1.8-0.4l1.1,2.4
            l-1.9,2.3l-2.1,2.5c-0.1,0.1-0.1,0.2-0.1,0.4l0.3,3.8c0,0.1,0.1,0.3,0.2,0.3l1.5,1.2c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0.1,0,0.2,0
            l1.7-0.6l2.2-0.1c0.1,0,0.3-0.1,0.4-0.2l3.5-4.6C42.6,20.7,42.6,20.5,42.4,20.3z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M24.6,36c-0.5,0-1-0.3-1.1-0.8l-2.3-5.7c-0.5,0.7-1.2,1.2-2.1,1.2h-1.8c-0.4,0-0.7-0.2-1-0.5
            c-0.2-0.3-0.3-0.7-0.2-1.1l1-3.8c-0.1,0-0.3,0.1-0.4,0.1c-0.6,0-1.1-0.4-1.2-0.9l-0.2-0.9c-0.1-0.4,0-0.8,0.3-1.1l2.7-3
            c0,0,0.1-0.1,0.1-0.1l0.2-0.1c0,0,0.1-0.1,0.2-0.1l2-0.8l-0.2-0.5c-0.2-0.6,0-1.2,0.6-1.5l0.7-0.4l3.1-6.4c0,0,0-0.1,0.1-0.1
            l3.2-4.7c0,0,0.1-0.1,0.1-0.1l0.1-0.1c0,0,0.1-0.1,0.1-0.1l3.3-2l3.9-2.1c0,0,0.1,0,0.2-0.1l0.2,0c0.1,0,0.2,0,0.2,0L40.9,0
            c0,0,0,0,0,0c0.6,0,1,0.3,1.2,0.8l0.2,0.5c0.2,0.4,0,1-0.3,1.3l-1.1,1.1L40.6,7l0.9,0.9c0.3,0.3,0.4,0.7,0.3,1.1l-0.4,2.8l0.9,2.4
            c0.1,0.3,0.1,0.6,0,0.8l-0.5,1.3l0.5,2.6l0.7,0.9c0.4,0.4,0.4,1.1,0,1.5L39.5,26c-0.2,0.3-0.6,0.5-0.9,0.5l-2.1,0.1l-1.6,0.6
            c-0.1,0-0.3,0.1-0.4,0.1c-0.3,0-0.6-0.1-0.8-0.3l-1.5-1.2c-0.3-0.2-0.4-0.5-0.5-0.9l-0.3-3.8c0-0.3,0.1-0.7,0.3-0.9l2.1-2.5
            l1.6-1.9l-0.7-1.4l-0.9,0.2L33,16.3l-0.3,2.1c0,0.3-0.2,0.6-0.5,0.8l-3,2.3l-0.9,3.9l0.5-0.1c0,0,0,0,0,0c0.6,0,0.9,0.2,1.1,0.4
            c0.2,0.3,0.3,0.6,0.3,1l-0.1,0.8c-0.1,0.5-0.4,0.9-0.9,1.1l-1,0.3l-0.4,5c0,0.4-0.3,0.8-0.7,1l-2,1C25,35.9,24.8,36,24.6,36z
            M21.2,26.8c0.3,0,0.6,0.2,0.7,0.5l2.9,7.1l1.6-0.8l0.5-5.3c0-0.3,0.2-0.6,0.6-0.7l1.3-0.4l0-0.3l-1.2,0.2c-0.2,0-0.5-0.1-0.6-0.2
            c-0.2-0.2-0.2-0.4-0.2-0.7l1.1-5.2c0-0.2,0.1-0.3,0.3-0.4l3.2-2.4l0.3-2.1c0-0.1,0-0.1,0.1-0.2l1-2.2c0.1-0.2,0.3-0.4,0.5-0.4
            l1.8-0.4c0.3-0.1,0.7,0.1,0.8,0.4l1.1,2.4c0.1,0.3,0.1,0.6-0.1,0.8L35,18.6l-2.1,2.4l0.3,3.5l1.4,1.1l1.5-0.6c0.1,0,0.1,0,0.2,0
            l2.1-0.1l3.3-4.4L41,19.8c-0.1-0.1-0.1-0.2-0.2-0.3l-0.6-3c0-0.1,0-0.3,0-0.4l0.5-1.5L39.9,12c0-0.1-0.1-0.2,0-0.4l0.4-2.8l-1-1
            c-0.2-0.2-0.2-0.4-0.2-0.6l0.3-3.9c0-0.2,0.1-0.3,0.2-0.5l1.1-1.1l-0.1-0.2l-4.5,0.2l-3.7,2l-3.2,2l-3.1,4.5L23,16.8
            c-0.1,0.1-0.2,0.3-0.3,0.3l-0.8,0.4l0.1,0.3l0.2,0.1c0.3,0.1,0.5,0.4,0.5,0.7s-0.2,0.6-0.5,0.7l-3,1.2l-2.5,2.8l0.1,0.5l1-0.4
            c0.3-0.1,0.6,0,0.8,0.2c0.2,0.2,0.3,0.5,0.2,0.7l-1.3,4.9H19c0.4,0,0.8-0.3,1-0.7l0.5-1.2C20.6,27,20.8,26.8,21.2,26.8
            C21.1,26.8,21.2,26.8,21.2,26.8z"
          />
        </g>
      </g>
    </svg>
  )
}

export const MapsUSA = () => {
  return (
    <svg className="w-full h-full category__svg mapsusa" version="1.1" viewBox="0 0 68.3 44.9" x="0px" y="0px">
      <g>
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M67.2,4.7L67.2,4.7L67.2,4.7c-0.2-0.5-0.5-0.8-0.8-1c-0.3-0.2-0.7-0.3-1.1-0.3
          c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.6,0.5-0.8,0.8L62.6,6c-0.8,1.1-1.8,1.9-3.1,2.4l-0.7,0.3c-0.7,0.3-1.3,0.8-1.6,1.5
          c-0.3,0.7-0.8,1.3-1.5,1.8l-0.3,0.2c-0.4,0.3-0.8,0.6-1.1,1l-1.3,1.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.1
          c-0.2,0-0.4-0.1-0.6-0.3c-0.1-0.1-0.2-0.3-0.2-0.6l0-1.1c0-1.1-0.9-2.1-2-2.3c-0.2,0-0.4-0.1-0.6-0.1c-1.1,0-2.2,0.4-3,1.2
          l-0.4,0.4c-0.2,0.2-0.5,0.3-0.7,0.3c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.2-0.2-0.4-0.2-0.6
          c0-0.3,0.1-0.6,0.3-0.8l0,0v0c0.4-0.4,0.7-0.9,0.7-1.5c0-0.8-0.5-1.6-1.3-1.9l-2.8-1c-2.3-0.8-4.7-1.2-7.1-1.3
          c-1.6,0-3.2-0.3-4.8-0.7l-0.6-0.1c-1.1-0.3-2.2-0.5-3.4-0.6L12.8,2.7c-1.1-0.1-2.3-0.3-3.4-0.6L4.3,0.8c-0.2-0.1-0.4,0-0.6,0.1
          C3.5,1,3.4,1.2,3.3,1.4L2.9,6.7c-0.1,1-0.4,2-0.8,2.9c-0.6,1.1-0.9,2.4-1,3.7l-0.2,3.4c0,0.3,0,0.6,0,0.9c0,2.8,0.8,5.6,2.4,7.9
          c0,0,0,0,0,0c1.3,1.9,3,3.4,5,4.5l9.4,5c0.5,0.3,1.1,0.5,1.7,0.7l4.8,1.8c2.1,0.8,4,2.1,5.4,3.9l1.6,2c0.2,0.3,0.5,0.5,0.8,0.7
          c0.3,0.2,0.7,0.2,1,0.2c0.2,0,0.4,0,0.6-0.1c1-0.3,1.8-1.2,1.8-2.3l0-1.4c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.4,0.8-0.4l3.4-0.2
          c1.3-0.1,2.6-0.4,3.8-1c1.2-0.6,2.5-0.9,3.8-0.9c0.2,0,0.3,0,0.5,0l0.9,0c1.1,0.1,2.1,0.6,2.7,1.5l2.6,3.8c0.2,0.3,0.5,0.5,0.8,0.7
          c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.8-0.1,1.1-0.3c0.3-0.2,0.6-0.6,0.8-1c0,0,0,0,0-0.1c0.3-0.8,0.4-1.6,0.4-2.4
          c0-0.9-0.2-1.7-0.5-2.6l-0.4-1.1c-0.2-0.6-0.4-1.3-0.4-1.9c0-1.1,0.3-2.1,0.9-3l1.4-2.1c0.9-1.3,1.3-2.8,1.3-4.3
          c0-0.3,0-0.6-0.1-0.9c0-0.2,0-0.5,0-0.7c0-0.6,0.1-1.2,0.3-1.7l0.5-1.7c0.5-1.5,1.4-2.8,2.7-3.7c1-0.7,1.6-1.9,1.6-3.1
          c0-0.1,0-0.2,0-0.2l0-0.1v0c0,0,0-0.1,0-0.1c0-0.5,0.2-1,0.5-1.3c0,0,0,0,0-0.1c0.9-1.1,1.4-2.4,1.4-3.8
          C67.6,6.1,67.5,5.4,67.2,4.7z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M32.9,44.9c-0.5,0-0.9-0.1-1.3-0.3c-0.4-0.2-0.8-0.5-1.1-0.9l-1.6-2c-1.3-1.6-3.1-2.9-5-3.6
          l-4.8-1.8c-0.6-0.2-1.2-0.5-1.8-0.8l-9.4-5c-2.1-1.1-4-2.8-5.3-4.8C0.9,23.4,0,20.5,0,17.5c0-0.3,0-0.6,0-0.9l0.2-3.4
          c0.1-1.4,0.5-2.8,1.1-4C1.8,8.4,2,7.5,2.1,6.6l0.5-5.2c0-0.4,0.3-0.8,0.6-1.1C3.6,0,4.1-0.1,4.5,0l5.1,1.3c1.1,0.3,2.2,0.5,3.3,0.6
          l12.2,1.2c1.2,0.1,2.3,0.3,3.5,0.6l0.6,0.1c1.5,0.4,3.1,0.6,4.7,0.6c2.5,0.1,5,0.5,7.4,1.3l2.8,1c1.1,0.4,1.8,1.4,1.8,2.6
          c0,0.8-0.3,1.5-0.9,2c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0,0.3-0.1l0.4-0.4
          c1.1-1.1,2.7-1.7,4.3-1.4c1.5,0.2,2.6,1.5,2.6,3l0,1.1l0.1,0.1l1.4-1.6c0.4-0.4,0.8-0.8,1.3-1.2l0.3-0.2c0.5-0.4,1-0.9,1.2-1.5
          c0.4-0.9,1.1-1.6,2-1.9l0.7-0.3c1.1-0.4,2-1.1,2.7-2.1l0.8-1.4C63,3.7,63.4,3.3,63.8,3c0.9-0.5,2.1-0.5,3,0.1
          c0.5,0.3,0.9,0.8,1.1,1.4l-0.7,0.3l0.7-0.3c0.3,0.7,0.4,1.5,0.4,2.3c0,1.6-0.6,3.1-1.6,4.3c-0.3,0.3-0.4,0.6-0.4,0.9
          c0,0,0,0.1,0,0.1l0,0.1c0,0.1,0,0.2,0,0.3c0,1.5-0.7,2.9-2,3.7c-1.2,0.8-2,2-2.4,3.3l-0.5,1.7c-0.2,0.5-0.2,1-0.2,1.5
          c0,0.2,0,0.4,0,0.6c0,0.3,0.1,0.7,0.1,1c0,1.7-0.5,3.3-1.5,4.7l-1.4,2.1c-0.5,0.8-0.8,1.7-0.8,2.6c0,0.6,0.1,1.1,0.3,1.7l0.4,1.1
          c0.4,0.9,0.5,1.9,0.5,2.8c0,0.9-0.2,1.8-0.5,2.7c-0.2,0.6-0.6,1.1-1.1,1.4c-0.8,0.5-1.9,0.6-2.8,0.2c-0.4-0.2-0.8-0.5-1-0.9
          l-2.6-3.8c-0.5-0.7-1.3-1.1-2.1-1.2l-0.9,0c-0.1,0-0.3,0-0.4,0c-1.2,0-2.4,0.3-3.5,0.8c-1.3,0.6-2.7,1-4.1,1l-3.4,0.2
          c-0.2,0-0.3,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3l0,1.4c0,1.4-1,2.6-2.3,3C33.4,44.9,33.1,44.9,32.9,44.9z M4.1,1.5L3.6,6.8
          C3.5,7.9,3.2,8.9,2.7,9.9c-0.6,1.1-0.9,2.2-0.9,3.4l-0.2,3.4c0,0.3,0,0.6,0,0.8c0,2.7,0.8,5.3,2.3,7.5c1.2,1.8,2.9,3.3,4.8,4.3
          l9.4,5c0.5,0.3,1,0.5,1.6,0.7l4.8,1.8c2.2,0.8,4.2,2.2,5.7,4.1l1.6,2c0.2,0.2,0.3,0.3,0.6,0.5c0.3,0.2,0.7,0.2,1.1,0.1
          c0.7-0.2,1.2-0.8,1.2-1.6l0-1.4c0-0.5,0.2-1,0.5-1.3c0.3-0.4,0.8-0.6,1.3-0.6l3.4-0.2c1.2-0.1,2.4-0.4,3.5-0.9
          c1.5-0.7,3.1-1,4.7-0.9l0.9,0c1.3,0.1,2.5,0.7,3.3,1.8l2.6,3.8c0.1,0.2,0.3,0.3,0.5,0.4c0.4,0.2,0.9,0.2,1.3-0.1
          c0.2-0.1,0.4-0.4,0.5-0.6c0.3-0.8,0.4-1.5,0.4-2.2c0-0.8-0.1-1.6-0.4-2.3L56.6,36c-0.3-0.7-0.4-1.5-0.4-2.2c0-1.2,0.4-2.4,1.1-3.5
          l1.4-2.1c0.8-1.1,1.2-2.5,1.2-3.9c0-0.3,0-0.5,0-0.8c0-0.3,0-0.5,0-0.8c0-0.7,0.1-1.3,0.3-2l0.5-1.7c0.5-1.7,1.6-3.1,3-4.1
          c0.8-0.6,1.3-1.5,1.3-2.5c0-0.1,0-0.1,0-0.2l0-0.3c0-0.7,0.2-1.3,0.7-1.8c0.8-1,1.3-2.1,1.3-3.4c0-0.6-0.1-1.2-0.3-1.8c0,0,0,0,0,0
          l0,0c-0.1-0.2-0.3-0.5-0.5-0.6c-0.4-0.3-1-0.3-1.4,0c-0.2,0.1-0.4,0.3-0.5,0.5l-0.8,1.5c0,0,0,0.1-0.1,0.1c-0.9,1.2-2,2.1-3.4,2.6
          l-0.7,0.3c-0.5,0.2-0.9,0.6-1.2,1.1c-0.4,0.8-1,1.6-1.7,2.1L56,12.7c-0.4,0.3-0.7,0.6-1,0.9l-1.3,1.6c-0.2,0.2-0.3,0.3-0.6,0.4
          c-0.5,0.3-1.3,0.1-1.8-0.3C51.1,15,51,14.6,51,14.2l0-1.1c0-0.8-0.6-1.4-1.3-1.5c-1.1-0.2-2.2,0.2-2.9,1l-0.4,0.4
          c-0.3,0.3-0.8,0.5-1.2,0.5c-0.3,0-0.6-0.1-0.8-0.2c-0.3-0.1-0.5-0.3-0.6-0.6c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.6-1.3
          c0.3-0.3,0.4-0.6,0.4-0.9c0-0.5-0.3-1-0.8-1.2l-2.8-1c-2.2-0.8-4.6-1.2-6.9-1.2c-1.7,0-3.4-0.3-5-0.7l-0.6-0.1
          C27.1,5,26,4.8,24.9,4.7L12.8,3.4c-1.2-0.1-2.4-0.3-3.5-0.6L4.1,1.5z"
        />
      </g>
    </svg>
  )
}

export const MapsCanada = () => {
  return (
    <svg className="w-full h-full category__svg mapscanada" version="1.1" viewBox="0 0 61.4 55.8" x="0px" y="0px">
      <g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M0.8,22.7l1,15.1c0.1,1.4,1.1,2.7,2.4,3.1l24.9,7.7
            c0.1,0,0.3,0,0.4,0l2.9-1.1c0.2-0.1,0.5,0,0.7,0.1l4.7,4.4c0.2,0.2,0.3,0.5,0.1,0.8l-0.8,1.4c-0.3,0.6,0.3,1.2,0.9,0.9l8.8-5
            c0.2-0.1,0.3-0.2,0.3-0.4l0.5-2.1c0.1-0.5,0.7-0.6,1.1-0.3l1.3,1.3c0.2,0.2,0.6,0.2,0.9,0l1.7-1.4l-0.7,2.7l1.1-0.1
            c0.2,0,0.4-0.1,0.5-0.3l3-4.2c0.2-0.2,0.2-0.6,0-0.8l-0.3-0.3c-0.1-0.2-0.3-0.2-0.5-0.2l-0.8,0c-0.1,0-0.1,0-0.2,0l-2.1,0.9
            c-0.4,0.2-0.8-0.1-0.9-0.4l-0.2-0.9c-0.1-0.4-0.6-0.6-1-0.4l-1.3,1c-0.6,0.4-1.3-0.2-0.9-0.8l0.7-1.3c0.1-0.1,0.2-0.2,0.3-0.3
            l3.8-1.4c0.1-0.1,0.3-0.2,0.3-0.3l0.5-1c0.3-0.6,1.2-0.4,1.2,0.3l0.2,2.4c0,0.4,0.4,0.7,0.8,0.6l3.7-0.9c0.8-0.2,1.1-1.3,0.4-1.8
            l-5.7-4.9c-0.1-0.1-0.2-0.1-0.3-0.1l-5.2-1.2c-0.1,0-0.2-0.1-0.3-0.2l-3-3.1c-0.4-0.4-1-0.2-1.1,0.3l-0.2,0.8
            c-0.1,0.6-0.8,0.7-1.2,0.3l-1.2-1.7c-0.1-0.2-0.3-0.3-0.5-0.3l-4.2-0.2c-0.4,0-0.8,0.4-0.6,0.8l1.9,5.9c0.1,0.2,0,0.3,0,0.5
            L37.3,39c-0.1,0.1-0.1,0.2-0.1,0.4l0.3,2.8c0.1,0.6-0.6,0.9-1,0.6l-1.1-0.9c-0.1-0.1-0.2-0.3-0.2-0.4l-0.2-2.1
            c0-0.2-0.2-0.5-0.4-0.5l-6.4-2.2c-0.2-0.1-0.4-0.3-0.4-0.5L27,31.3c0-0.2,0-0.4,0.2-0.5l3.4-3.4c0,0,0,0,0,0l3-3.5
            c0.1-0.2,0.2-0.4,0.1-0.6l-0.6-2.3c-0.1-0.2-0.2-0.4-0.5-0.5L32,20.2c-0.4-0.1-0.7,0.2-0.8,0.5l-0.2,0.9c-0.1,0.6-0.9,0.7-1.2,0.2
            l-1.7-3.5c-0.1-0.2-0.3-0.3-0.5-0.4l-0.4,0c-0.3,0-0.6,0.2-0.7,0.4l-0.2,0.5c-0.1,0.2,0,0.3,0,0.5l0.8,1.9
            c0.2,0.4-0.1,0.8-0.5,0.9l-2.8,0.4c-0.1,0-0.3,0-0.4-0.1l-1.9-1.1c-0.4-0.2-0.8,0-0.9,0.4v0c-0.1,0.5-0.8,0.7-1.1,0.3l-0.7-0.8
            L15,16.5c0,0-0.1-0.1-0.1-0.1l-4.9-3.5c-0.7-0.5-1.6-0.4-2.2,0.2L2.1,19C1.2,20,0.7,21.3,0.8,22.7z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M37.6,55.8c-0.4,0-0.7-0.1-1-0.4c-0.4-0.4-0.5-1.1-0.2-1.7l0.8-1.3l-4.6-4.3l-2.8,1.1
            c-0.3,0.1-0.6,0.1-0.9,0L4,41.6c-1.7-0.5-2.8-2-3-3.8L0,22.7l0,0c-0.1-1.6,0.5-3.1,1.5-4.2l5.6-5.9c0.8-0.9,2.2-1,3.2-0.3l4.9,3.5
            c0.1,0.1,0.2,0.2,0.3,0.3l4.4,5.4c0.1-0.3,0.4-0.6,0.7-0.8c0.4-0.2,0.9-0.2,1.2,0.1l1.9,1.1l2.6-0.4l-0.8-1.8
            c-0.1-0.3-0.2-0.7,0-1l0.2-0.5c0.2-0.6,0.8-1,1.5-0.9l0.4,0c0.5,0,0.9,0.3,1.1,0.8l1.6,3.2l0.1-0.6c0.1-0.4,0.3-0.7,0.6-0.9
            c0.3-0.2,0.7-0.3,1.1-0.2l0.6,0.1c0.5,0.1,0.9,0.5,1.1,1l0.6,2.3c0.1,0.4,0,0.9-0.3,1.2l-3,3.5c0,0-0.1,0.1-0.1,0.1l-3.4,3.4
            l0.7,4.7l6.4,2.1c0.5,0.2,0.9,0.6,0.9,1.2l0.2,2.1l0.9,0.6l-0.3-2.5c0-0.3,0-0.5,0.2-0.8l1.3-2.5l-1.9-5.9
            c-0.1-0.4-0.1-0.9,0.2-1.3c0.3-0.4,0.7-0.6,1.2-0.5l4.2,0.2c0.4,0,0.8,0.2,1.1,0.6l1.1,1.5l0.1-0.6c0.1-0.5,0.5-0.9,1-1.1
            c0.5-0.1,1,0,1.4,0.4l3,3.1l5.2,1.2c0.2,0.1,0.4,0.2,0.6,0.3l5.7,4.9c0.5,0.4,0.7,1.1,0.6,1.8S60.7,42,60,42.2l-3.7,0.9
            c-0.4,0.1-0.8,0-1.2-0.2c-0.3-0.2-0.5-0.6-0.6-1l-0.2-2.1l-0.4,0.7c-0.2,0.3-0.4,0.5-0.8,0.6l-3.8,1.4l-0.5,0.9l1-0.7
            c0.4-0.3,0.9-0.3,1.3-0.2c0.4,0.2,0.8,0.5,0.9,1l0.2,0.7l2-0.8c0.1-0.1,0.3-0.1,0.5-0.1l0.8,0c0.4,0,0.9,0.2,1.1,0.5l0.3,0.3
            c0.4,0.5,0.4,1.2,0.1,1.7l-3,4.2c-0.2,0.3-0.6,0.6-1.1,0.6l-1.1,0.1c-0.2,0-0.5-0.1-0.6-0.3c-0.1-0.2-0.2-0.4-0.1-0.7l0.1-0.5
            c-0.5,0.4-1.3,0.3-1.8-0.1l-1.2-1.2l-0.5,2c-0.1,0.4-0.3,0.7-0.7,0.9l-8.8,5C38.1,55.8,37.9,55.8,37.6,55.8z M32.6,46.6
            c0.3,0,0.7,0.1,1,0.4l4.7,4.4c0.5,0.4,0.6,1.1,0.3,1.7l-0.6,1.1l8.4-4.8l0.5-2.1c0.1-0.5,0.5-0.9,1-1c0.5-0.1,1,0,1.3,0.3l1.3,1.2
            l1.6-1.3c0.3-0.2,0.6-0.2,0.9,0c0.3,0.2,0.4,0.5,0.3,0.8L52.8,49l0.1,0l2.9-4.1l-0.2-0.2l-0.8,0.1l-2,0.9c-0.4,0.2-0.8,0.1-1.2,0
            c-0.4-0.2-0.6-0.5-0.7-0.9l-0.2-0.7l-1.2,0.9c-0.5,0.4-1.2,0.4-1.7-0.1c-0.5-0.4-0.6-1.1-0.3-1.7l0.7-1.4c0.2-0.3,0.4-0.5,0.7-0.6
            l3.8-1.4l0.5-0.9c0.3-0.5,0.9-0.8,1.5-0.7c0.6,0.1,1.1,0.6,1.1,1.3l0.2,2.3l3.6-0.9c0.2,0,0.2-0.2,0.2-0.2c0-0.1,0-0.2-0.1-0.3
            l-5.7-4.9l-5.2-1.2c-0.3-0.1-0.5-0.2-0.7-0.4l-2.8-3l-0.1,0.6c-0.1,0.5-0.5,1-1.1,1.1c-0.5,0.1-1.1-0.1-1.4-0.5l-1.2-1.7l-4-0.1
            l1.8,5.8c0.1,0.4,0.1,0.7-0.1,1.1L38,39.4l0.3,2.7c0.1,0.6-0.2,1.1-0.7,1.4c-0.5,0.3-1.1,0.2-1.5-0.1l-1.1-0.9
            c-0.3-0.2-0.5-0.6-0.5-0.9l-0.2-2l-6.4-2.1c-0.5-0.2-0.9-0.6-0.9-1.1l-0.7-4.8c-0.1-0.4,0.1-0.9,0.4-1.2l3.4-3.4l3-3.5l-0.6-2.2
            L32,21l-0.1,0.8c-0.1,0.6-0.6,1-1.2,1.1c-0.6,0.1-1.2-0.2-1.4-0.8l-1.7-3.5l-0.3,0l-0.1,0.4l0.8,1.8c0.2,0.4,0.1,0.9-0.1,1.2
            c-0.2,0.4-0.6,0.6-1,0.7L24,23.2c-0.3,0-0.6,0-0.9-0.2l-1.8-1c-0.2,0.4-0.5,0.8-1,0.9c-0.5,0.1-1,0-1.4-0.4l-0.7-0.8l-3.9-4.7
            l-4.9-3.4c-0.4-0.3-0.9-0.2-1.2,0.1l-5.6,5.9c-0.8,0.8-1.2,2-1.1,3.1l1,15.1c0.1,1.1,0.8,2.1,1.9,2.4l24.9,7.7l2.8-1.1
            C32.3,46.7,32.5,46.6,32.6,46.6z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M31.8,14.5L30.7,17c-0.1,0.2-0.1,0.5,0.1,0.7L32,19
            c0.1,0.1,0.3,0.2,0.6,0.2l2.9-0.4c0.2,0,0.4,0,0.5,0.2l2.8,2.8c0.2,0.2,0.3,0.6,0.1,0.9l-1.6,2c-0.3,0.4-0.1,0.9,0.4,1L44,27
            c0.4,0.1,0.8-0.2,0.8-0.6l0-5.5c0-0.2-0.1-0.4-0.3-0.5l-9-6c-0.1-0.1-0.2-0.1-0.3-0.1l-2.7-0.1C32.2,14.1,31.9,14.2,31.8,14.5z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M44.1,27.8c-0.1,0-0.2,0-0.3,0l-6.2-1.4c-0.5-0.1-0.8-0.4-1-0.9c-0.2-0.5-0.1-0.9,0.2-1.3
            l1.5-2l-2.8-2.7l-2.9,0.4c-0.4,0.1-0.9-0.1-1.2-0.4l-1.2-1.3c-0.4-0.4-0.5-1-0.3-1.5l1.1-2.5c0,0,0,0,0,0c0.2-0.5,0.8-0.9,1.4-0.8
            l2.7,0.1c0.2,0,0.5,0.1,0.7,0.2l9,6c0.4,0.3,0.6,0.7,0.6,1.2l0,5.5c0,0.4-0.2,0.8-0.5,1.1C44.7,27.7,44.4,27.8,44.1,27.8z
            M38.1,24.9l5.9,1.3l0-5.3L35,15l-2.5-0.1l-1,2.4l1.2,1.2l2.9-0.4c0.4-0.1,0.9,0.1,1.2,0.4l2.8,2.8c0.5,0.5,0.6,1.3,0.1,1.8
            L38.1,24.9z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M24.7,15.5l0.6,1c0.2,0.4,0.8,0.4,1.1,0.1l0.5-0.7
            c0.1-0.1,0.2-0.3,0.1-0.5l-0.2-1c0-0.3-0.3-0.5-0.6-0.5l-0.5-0.1c-0.2,0-0.5,0.1-0.6,0.3l-0.5,0.8C24.6,15,24.6,15.3,24.7,15.5z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M25.8,17.5C25.8,17.5,25.7,17.5,25.8,17.5c-0.6,0-1-0.3-1.2-0.7l-0.6-1v0
            c-0.3-0.4-0.3-1,0-1.4l0.5-0.8c0.3-0.5,0.8-0.7,1.3-0.7l0.5,0.1c0.6,0.1,1.1,0.5,1.2,1.2l0.2,1c0.1,0.4,0,0.8-0.3,1.1L26.9,17
            C26.6,17.3,26.2,17.5,25.8,17.5z M25.4,15.2l0.5,0.8l0.4-0.6l-0.2-0.8l-0.3,0L25.4,15.2z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M23.6,18.3l-0.3-3.9c0-0.4-0.3-0.6-0.7-0.6L20.8,14
            c-0.2,0-0.4-0.1-0.6-0.2l-2.4-3.1c-0.3-0.3-0.8-0.3-1,0l-1.1,1.4c-0.2,0.3-0.2,0.6,0.1,0.8l0.9,0.9c0.1,0.1,0.3,0.2,0.4,0.2l0.9,0
            l-0.2,1.8l-0.7,0.6l1.3,2.2c0.1,0.2,0.3,0.3,0.5,0.3l4,0C23.4,19,23.7,18.7,23.6,18.3z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M23,19.8C23,19.8,23,19.8,23,19.8l-4.1,0c-0.5,0-0.9-0.3-1.2-0.7l-1.3-2.1
            c-0.2-0.3-0.1-0.7,0.2-1l0.5-0.4l0.1-0.7l-0.1,0c-0.4,0-0.7-0.2-1-0.4l-0.9-0.9c-0.5-0.5-0.5-1.3-0.1-1.8l1.1-1.4
            c0.3-0.3,0.7-0.6,1.1-0.6c0,0,0,0,0,0c0.4,0,0.8,0.2,1.1,0.5l2.4,3l1.7-0.2c0.4,0,0.7,0.1,1,0.3c0.3,0.2,0.5,0.6,0.5,0.9l0.3,3.9
            c0,0,0,0,0,0c0,0.4-0.1,0.8-0.4,1.1C23.7,19.6,23.4,19.8,23,19.8z M19,18.2l3.9,0l-0.3-3.6l-1.6,0.2c-0.5,0-0.9-0.2-1.2-0.5
            l-2.3-3l-0.9,1.3l0.9,0.9l0.8,0c0.2,0,0.4,0.1,0.6,0.3c0.1,0.2,0.2,0.4,0.2,0.6L18.6,16c0,0.2-0.1,0.4-0.3,0.5l-0.2,0.2L19,18.2z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M29.9,2.7l3.6-1.8c0.5-0.2,1,0.1,0.9,0.6l-0.9,7.6
            c0,0.3-0.3,0.6-0.6,0.6l-2,0.1c-0.5,0-0.8-0.4-0.6-0.9l0.7-2l-0.9,0.4c-0.3,0.1-0.6,0-0.8-0.2l-0.9-1.5c-0.1-0.2-0.1-0.5,0-0.7
            l1.2-2.1C29.7,2.8,29.8,2.7,29.9,2.7z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M31,10.5c-0.4,0-0.8-0.2-1.1-0.6c-0.3-0.4-0.4-0.9-0.2-1.3l0.2-0.5c-0.4,0-0.9-0.2-1.1-0.7
            L27.8,6c-0.3-0.5-0.3-1,0-1.5L29,2.5c0.1-0.2,0.3-0.4,0.6-0.5c0,0,0,0,0,0l3.6-1.8c0.5-0.2,1-0.2,1.4,0.1C35,0.5,35.3,1,35.2,1.5
            l-0.9,7.6c-0.1,0.7-0.6,1.2-1.3,1.2L31,10.5C31,10.5,31,10.5,31,10.5z M31,6.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.2,0.3,0.5,0.2,0.8
            L31.1,9l1.7-0.1l0.8-7.3l-3.4,1.7l-1.2,2l0.8,1.4l0.8-0.4C30.8,6.2,30.9,6.2,31,6.2z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M21.5,10.1l1.1,0.9c0.3,0.3,0.3,0.8-0.1,1.1l-0.9,0.5h4.2
            c0.4,0,0.7-0.4,0.6-0.8l-0.5-1.9c-0.1-0.4-0.5-0.6-0.9-0.4l-0.8,0.4c-0.2,0.1-0.4,0.1-0.6,0l-0.9-0.4c-0.2-0.1-0.5-0.1-0.7,0.1
            L21.5,10.1z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M25.9,13.3h-4.2c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.3,0-0.7,0.3-0.8l0.7-0.4l-1-0.8
            c-0.2-0.1-0.3-0.4-0.3-0.6s0.1-0.4,0.3-0.6l0.8-0.6c0.4-0.3,1-0.4,1.5-0.2l0.9,0.4l0.7-0.4c0.4-0.2,0.8-0.2,1.2,0
            c0.4,0.2,0.7,0.5,0.8,0.9l0.5,1.9c0.1,0.4,0,0.9-0.3,1.2S26.4,13.3,25.9,13.3z M23.6,11.8h2.2l-0.4-1.7l-0.6,0.3
            c-0.4,0.2-0.8,0.2-1.2,0l-0.8-0.4l0.4,0.3c0.4,0.3,0.5,0.7,0.5,1.2C23.6,11.6,23.6,11.7,23.6,11.8z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M24.6,6.1l0.2,0.4c0.2,0.5,0.9,0.5,1.1,0l0.7-1.2
            c0.2-0.3,0-0.8-0.4-0.9l-0.4-0.1c-0.3-0.1-0.7,0-0.8,0.3l-0.5,1C24.5,5.7,24.5,5.9,24.6,6.1z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M25.4,7.6C25.4,7.6,25.4,7.6,25.4,7.6c-0.5,0-1-0.3-1.2-0.7L24,6.4l0,0
            c-0.2-0.4-0.2-0.8,0-1.2l0.5-1c0.3-0.7,1-1,1.7-0.7l0.4,0.1c0.4,0.1,0.7,0.4,0.8,0.8c0.1,0.4,0.1,0.8-0.1,1.2l-0.7,1.2
            C26.4,7.3,25.9,7.6,25.4,7.6z M25.3,5.8l0.1,0.1l0.5-1l-0.2-0.1L25.3,5.8z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M32.2,13.1l-1.3,0.2c0,0-0.1,0-0.1,0L29,13.1
            c-0.3,0-0.5-0.2-0.5-0.4L27.5,10l2,1.1c0,0,0.1,0,0.1,0.1l0.8,0.7c0.2,0.1,0.4,0.2,0.6,0.1l0.9-0.3c0.4-0.1,0.8,0.2,0.8,0.6l0,0.1
            C32.8,12.8,32.5,13.1,32.2,13.1z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M30.8,14c0,0-0.1,0-0.1,0l-1.8-0.2c-0.5-0.1-1-0.4-1.2-0.9l-0.9-2.6c-0.1-0.3,0-0.6,0.2-0.8
            c0.2-0.2,0.6-0.2,0.8-0.1l2,1.1c0.1,0,0.1,0.1,0.2,0.1l0.8,0.6l0.9-0.3c0.4-0.1,0.9-0.1,1.2,0.2s0.6,0.6,0.6,1.1
            c0,0.8-0.5,1.4-1.2,1.5L31,14C31,14,30.9,14,30.8,14z M29.1,12.3l0.8,0.1l-0.8-0.6l-0.3-0.2L29.1,12.3z"
          />
        </g>
      </g>
    </svg>
  )
}

export const Tablet = () => {
  return (
    <svg className="w-full h-full category__svg tablet" version="1.1" viewBox="0 0 45.9 34.1" x="0px" y="0px">
      <g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M0.7,3.8v26.5c0,1.7,1,3,2.2,3h39.9c1.2,0,2.2-1.3,2.2-3V3.8c0-1.7-1-3-2.2-3H3
            C1.8,0.7,0.7,2.1,0.7,3.8z M39.2,4.1v25.8H4.6V4.1H39.2z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M42.9,34.1H3c-1.6,0-3-1.7-3-3.8V3.8C0,1.7,1.3,0,3,0h39.9c1.6,0,3,1.7,3,3.8v26.5
            C45.9,32.4,44.6,34.1,42.9,34.1z M3,1.5c-0.8,0-1.5,1-1.5,2.3v26.5c0,1.2,0.7,2.3,1.5,2.3h39.9c0.8,0,1.5-1,1.5-2.3V3.8
            c0-1.2-0.7-2.3-1.5-2.3H3z M39.2,30.7H4.6c-0.4,0-0.8-0.3-0.8-0.8V4.1c0-0.4,0.3-0.8,0.8-0.8h34.6c0.4,0,0.8,0.3,0.8,0.8v25.8
            C39.9,30.3,39.6,30.7,39.2,30.7z M5.4,29.2h33.1V4.9H5.4V29.2z"
          />
        </g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M42.2,18.9c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8S43.2,18.9,42.2,18.9z
          M42.2,16.7c-0.2,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3S42.4,16.7,42.2,16.7z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M2.6,19.6c-0.4,0-0.8-0.3-0.8-0.8v-3.7c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v3.7
          C3.3,19.3,3,19.6,2.6,19.6z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M7.4,15.9c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l8.2-8.2c0.3-0.3,0.8-0.3,1.1,0
          s0.3,0.8,0,1.1l-8.2,8.2C7.8,15.8,7.6,15.9,7.4,15.9z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M7.5,12.5c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l4.6-4.6c0.3-0.3,0.8-0.3,1.1,0
          s0.3,0.8,0,1.1l-4.6,4.6C7.9,12.4,7.7,12.5,7.5,12.5z"
        />
      </g>
    </svg>
  )
}

export const Team = () => {
  return (
    <svg className="w-full h-full category__svg team" version="1.1" viewBox="0 0 52.6 33.3" x="0px" y="0px">
      <g>
        <g>
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M43.3,12c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S46,12,43.3,12z M43.3,3.5
            c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S45.2,3.5,43.3,3.5z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M51.8,27.3H34.8c-0.4,0-0.8-0.3-0.8-0.8V15.6c0-1.4,1-2.5,2.4-2.7l3.5-0.5
            c0.3,0,0.6,0.1,0.8,0.4c0.5,1,1.5,1.6,2.6,1.6s2.1-0.6,2.6-1.6c0.1-0.3,0.5-0.5,0.8-0.4l3.5,0.5c1.3,0.2,2.4,1.4,2.4,2.7v10.9
            C52.6,27,52.2,27.3,51.8,27.3z M35.5,25.8h15.6V15.6c0-0.6-0.5-1.1-1.1-1.2L47,14c-0.8,1.2-2.2,2-3.7,2s-2.9-0.7-3.7-2l-3,0.4
            c-0.6,0.1-1.1,0.6-1.1,1.2V25.8z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M9.3,12c-2.8,0-5-2.2-5-5s2.2-5,5-5c2.8,0,5,2.2,5,5S12,12,9.3,12z M9.3,3.5
            C7.3,3.5,5.8,5,5.8,7s1.6,3.5,3.5,3.5c1.9,0,3.5-1.6,3.5-3.5S11.2,3.5,9.3,3.5z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M17.8,27.3H0.8C0.3,27.3,0,27,0,26.5V15.6c0-1.4,1-2.5,2.4-2.7l3.5-0.5
            c0.3,0,0.6,0.1,0.8,0.4c0.5,1,1.5,1.6,2.6,1.6s2.1-0.6,2.6-1.6c0.1-0.3,0.5-0.5,0.8-0.4l3.5,0.5c1.3,0.2,2.4,1.4,2.4,2.7v10.9
            C18.6,27,18.2,27.3,17.8,27.3z M1.5,25.8h15.6V15.6c0-0.6-0.5-1.1-1.1-1.2L13,14c-0.8,1.2-2.2,2-3.7,2s-2.9-0.7-3.7-2l-3,0.4
            C2,14.5,1.5,15,1.5,15.6V25.8z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M26.3,12.1c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.5-5.7,5.7-5.7c3.1,0,5.7,2.5,5.7,5.7
            C32,9.6,29.4,12.1,26.3,12.1L26.3,12.1z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M26.3,12.9c-3.5,0-6.4-2.9-6.4-6.4c0-3.5,2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4
            C32.7,10,29.8,12.9,26.3,12.9z M26.3,1.5c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9C31.2,3.7,29,1.5,26.3,1.5
            z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M37.7,32.5V17.4c0-1-0.7-1.8-1.7-2l-5.3-0.8l-4.4,8.7l-4.4-8.7l-5.3,0.8c-1,0.1-1.7,1-1.7,2
            v15.1H37.7z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M37.7,33.3H14.9c-0.4,0-0.8-0.3-0.8-0.8V17.4c0-1.4,1-2.5,2.4-2.7l5.3-0.8
            c0.3,0,0.6,0.1,0.8,0.4l3.7,7.3l3.7-7.3c0.1-0.3,0.5-0.4,0.8-0.4l5.3,0.8c1.3,0.2,2.4,1.4,2.4,2.7v15.1
            C38.4,33,38.1,33.3,37.7,33.3z M15.7,31.8h21.3V17.4c0-0.6-0.5-1.1-1.1-1.2l-4.7-0.7l-4.1,8.2c-0.3,0.5-1.1,0.5-1.3,0l-4.1-8.2
            l-4.7,0.7c-0.6,0.1-1.1,0.6-1.1,1.2V31.8z"
          />
        </g>
      </g>
    </svg>
  )
}

export const Timelines = () => {
  return (
    <svg className="w-full h-full category__svg timelines" version="1.1" viewBox="0 0 60.6 32.3" x="0px" y="0px">
      <g>
        <rect
          className="transition-colors duration-1000 ease-in-out fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          height="1.5"
          width="55.1"
          x="4.2"
          y="15.4"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M55.9,20.8c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l3.4-3.5l-3.5-3.4
          c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0l4,3.9c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.5l-3.9,4
          C56.3,20.8,56.1,20.8,55.9,20.8z"
        />
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M4.2,16.9c-0.4,0-0.8-0.3-0.8-0.8V0.8C3.5,0.3,3.8,0,4.2,0S5,0.3,5,0.8v15.4
          C5,16.5,4.7,16.9,4.2,16.9z"
        />
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M22.6,32.3c-0.4,0-0.8-0.3-0.8-0.8V16.1c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v15.4
          C23.3,31.9,23,32.3,22.6,32.3z"
        />
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M40.9,16.9c-0.4,0-0.8-0.3-0.8-0.8V0.8c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v15.4
          C41.7,16.5,41.3,16.9,40.9,16.9z"
        />
        <g>
          <circle
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            cx="4.2"
            cy="16.1"
            r="3.5"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M4.3,20.4c-1.7,0-3.4-1.1-4-2.8c-0.8-2.2,0.3-4.6,2.5-5.5l0,0C3.8,11.8,5,11.8,6,12.3
            c1,0.5,1.8,1.3,2.2,2.4c0.8,2.2-0.3,4.6-2.5,5.5C5.2,20.3,4.7,20.4,4.3,20.4z M3.3,13.6c-1.4,0.5-2.2,2.1-1.6,3.5
            c0.5,1.4,2.1,2.2,3.5,1.6c0.7-0.3,1.2-0.8,1.5-1.4c0.3-0.7,0.3-1.4,0.1-2.1c-0.3-0.7-0.8-1.2-1.4-1.5C4.7,13.3,4,13.3,3.3,13.6
            L3.3,13.6z"
          />
        </g>
        <g>
          <circle
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            cx="22.6"
            cy="16.1"
            r="3.5"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M22.6,20.4c-1.7,0-3.4-1.1-4-2.8c-0.4-1.1-0.3-2.2,0.1-3.2c0.5-1,1.3-1.8,2.4-2.2
            c2.2-0.8,4.6,0.3,5.5,2.5c0.8,2.2-0.3,4.6-2.5,5.5C23.6,20.3,23.1,20.4,22.6,20.4z M22.6,13.4c-0.3,0-0.6,0.1-0.9,0.2
            c-0.7,0.3-1.2,0.8-1.5,1.4c-0.3,0.7-0.3,1.4-0.1,2.1c0.3,0.7,0.8,1.2,1.4,1.5c0.7,0.3,1.4,0.3,2.1,0.1c0.7-0.3,1.2-0.8,1.5-1.4
            c0.3-0.7,0.3-1.4,0.1-2.1C24.8,14.1,23.7,13.4,22.6,13.4z"
          />
        </g>
        <g>
          <circle
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            cx="40.9"
            cy="16.1"
            r="3.5"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M40.9,20.4c-0.6,0-1.2-0.1-1.8-0.4c-2.1-1-3.1-3.5-2.1-5.6l0,0c1-2.1,3.5-3.1,5.6-2.1
            c1,0.5,1.8,1.3,2.2,2.4c0.4,1.1,0.3,2.2-0.1,3.3c-0.5,1-1.3,1.8-2.4,2.2C41.9,20.3,41.4,20.4,40.9,20.4z M38.4,15
            c-0.6,1.4,0,3,1.3,3.6c0.7,0.3,1.4,0.3,2.1,0.1s1.2-0.8,1.5-1.4c0.6-1.4,0-3-1.3-3.6C40.7,13,39.1,13.6,38.4,15L38.4,15z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M14,1.5H7.2c-0.4,0-0.8-0.3-0.8-0.8S6.8,0,7.2,0H14c0.4,0,0.8,0.3,0.8,0.8S14.4,1.5,14,1.5z"
          />
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M11.4,3.4H7.2C6.8,3.4,6.5,3,6.5,2.6s0.3-0.8,0.8-0.8h4.1c0.4,0,0.8,0.3,0.8,0.8
            S11.8,3.4,11.4,3.4z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M32.3,30.4h-6.8c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h6.8c0.4,0,0.8,0.3,0.8,0.8
            S32.7,30.4,32.3,30.4z"
          />
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M29.7,32.3h-4.1c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h4.1c0.4,0,0.8,0.3,0.8,0.8
            S30.1,32.3,29.7,32.3z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M50.7,1.5h-6.8c-0.4,0-0.8-0.3-0.8-0.8S43.5,0,43.9,0h6.8c0.4,0,0.8,0.3,0.8,0.8
            S51.1,1.5,50.7,1.5z"
          />
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M48,3.4h-4.1c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8H48c0.4,0,0.8,0.3,0.8,0.8
            S48.5,3.4,48,3.4z"
          />
        </g>
      </g>
    </svg>
  )
}

export const Web = () => {
  return (
    <svg className="w-full h-full category__svg web" version="1.1" viewBox="0 0 52 39.4" x="0px" y="0px">
      <path
        className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
        d="M49.3,39.4H2.6c-1.5,0-2.6-1.2-2.6-2.6V2.6C0,1.2,1.2,0,2.6,0h46.7C50.8,0,52,1.2,52,2.6v34.1
        C52,38.2,50.8,39.4,49.3,39.4z M2.6,1.5C2,1.5,1.5,2,1.5,2.6v34.1c0,0.6,0.5,1.1,1.1,1.1h46.7c0.6,0,1.1-0.5,1.1-1.1V2.6
        c0-0.6-0.5-1.1-1.1-1.1H2.6z"
      />
      <g>
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M51.3,6.1H0.8V2.7c0-1.1,0.9-2,2-2h46.5c1.1,0,2,0.9,2,2V6.1z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M51.3,6.8H0.8C0.4,6.8,0,6.5,0,6.1V2.8C0,1.2,1.3,0,2.8,0h46.5C50.8,0,52,1.2,52,2.8v3.3
          C52,6.5,51.7,6.8,51.3,6.8z M1.5,5.3h49V2.8c0-0.7-0.6-1.3-1.3-1.3H2.8c-0.7,0-1.3,0.6-1.3,1.3V5.3z"
        />
      </g>
      <g>
        <circle
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          cx="3.6"
          cy="3.4"
          r="0.8"
        />
        <circle
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          cx="6"
          cy="3.4"
          r="0.8"
        />
        <circle
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          cx="8.5"
          cy="3.4"
          r="0.8"
        />
      </g>
      <g>
        <rect
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          height="8.5"
          width="42.2"
          x="4.9"
          y="9.8"
        />
        <rect
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          height="3.7"
          width="42.2"
          x="4.9"
          y="30.9"
        />
        <rect
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          height="8.5"
          width="13.2"
          x="4.9"
          y="20.3"
        />
        <rect
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          height="8.5"
          width="13.2"
          x="19.4"
          y="20.3"
        />
        <rect
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          height="8.5"
          width="13.2"
          x="33.9"
          y="20.3"
        />
      </g>
    </svg>
  )
}

export const Infographics = () => {
  return (
    <svg className="w-full h-full category__svg infographics" version="1.1" viewBox="0 0 52.7 44" x="0px" y="0px">
      <circle
        className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
        cx="33"
        cy="31.5"
        r="11.7"
      />
      <circle
        className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
        cx="26.3"
        cy="18.5"
        r="11.7"
      />
      <circle
        className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
        cx="19.7"
        cy="31.5"
        r="11.7"
      />
      <path
        className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
        d="M26.3,31c-1.8,0-3.5-0.4-5.1-1.1c-0.3-0.1-0.5-0.5-0.4-0.8C22,23.2,27.1,19,33,19
        c1.8,0,3.5,0.4,5.1,1.1c0.3,0.1,0.5,0.5,0.4,0.8C37.4,26.8,32.3,31,26.3,31z M22.4,28.7c1.3,0.5,2.6,0.7,3.9,0.7
        c5,0,9.4-3.4,10.6-8.2c-1.3-0.5-2.6-0.7-3.9-0.7C28,20.5,23.7,24,22.4,28.7z"
      />
      <path
        className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
        d="M26.4,41.9c-0.1,0-0.3,0-0.4-0.1c-3.4-2.3-5.4-6.2-5.4-10.3c0-4.1,2-7.9,5.4-10.3
        c0.3-0.2,0.6-0.2,0.9,0c3.4,2.3,5.4,6.2,5.4,10.3c0,4.1-2,7.9-5.4,10.3C26.7,41.9,26.5,41.9,26.4,41.9z M26.4,22.8
        c-2.7,2.1-4.3,5.3-4.3,8.7c0,3.4,1.6,6.6,4.3,8.7c2.7-2.1,4.3-5.3,4.3-8.7C30.7,28.1,29.1,24.9,26.4,22.8z"
      />
      <path
        className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
        d="M26.3,31c-5.9,0-11-4.2-12.2-10c-0.1-0.3,0.1-0.7,0.4-0.8c1.6-0.7,3.4-1.1,5.1-1.1
        c5.9,0,11.1,4.2,12.2,10c0.1,0.3-0.1,0.7-0.4,0.8C29.8,30.6,28.1,31,26.3,31z M15.7,21.3c1.3,4.8,5.6,8.2,10.6,8.2
        c1.4,0,2.7-0.3,4-0.7c-1.3-4.8-5.6-8.2-10.6-8.2C18.3,20.5,17,20.8,15.7,21.3z"
      />
      <g>
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M31.1,28.8c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.4-0.2-0.8-0.4-1.2c0,0,0,0,0,0
          c-0.2-0.4-0.3-0.8-0.5-1.2c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.3-0.4-0.7-0.6-1c0,0-0.1-0.1-0.1-0.1c-0.3-0.4-0.5-0.7-0.8-1c0,0,0,0,0,0
          c-0.3-0.3-0.6-0.6-0.9-0.9c0,0-0.1-0.1-0.1-0.1c-0.3-0.3-0.7-0.6-1-0.8c-0.4,0.3-0.7,0.5-1,0.8c0,0-0.1,0.1-0.1,0.1
          c-0.3,0.3-0.6,0.6-0.9,0.9c0,0,0,0,0,0.1c-0.3,0.3-0.6,0.7-0.8,1c0,0-0.1,0.1-0.1,0.1c-0.2,0.3-0.4,0.6-0.6,1
          c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.4-0.4,0.8-0.5,1.2c0,0,0,0,0,0.1c-0.1,0.4-0.3,0.8-0.4,1.1c0,0.1-0.1,0.2-0.1,0.4
          c0,0.1,0,0.2-0.1,0.4c0.3,0.2,0.7,0.3,1.1,0.4c0,0,0.1,0,0.1,0c0.3,0.1,0.7,0.2,1,0.3c0.1,0,0.2,0,0.2,0.1c0.3,0.1,0.6,0.1,0.9,0.1
          c0.1,0,0.2,0,0.3,0c0.4,0,0.8,0.1,1.2,0.1c0.4,0,0.8,0,1.2-0.1c0.1,0,0.2,0,0.3,0c0.3,0,0.6-0.1,0.9-0.2c0.1,0,0.1,0,0.2,0
          c0.4-0.1,0.7-0.2,1-0.3c0,0,0,0,0.1,0c0.4-0.1,0.7-0.3,1.1-0.4C31.1,29.1,31.1,28.9,31.1,28.8z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M26.3,31c-0.4,0-0.8,0-1.3-0.1l-0.3,0c-0.3,0-0.7-0.1-1-0.2l-0.2,0c-0.4-0.1-0.8-0.2-1.1-0.3
          l-0.1,0c-0.4-0.1-0.8-0.3-1.1-0.4c-0.3-0.1-0.5-0.5-0.4-0.8l0-0.1c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.3,0.1-0.4
          c0.1-0.4,0.2-0.8,0.4-1.2c0.2-0.4,0.4-0.9,0.6-1.3l0.1-0.2c0.2-0.4,0.4-0.7,0.7-1.1l0.1-0.1c0.3-0.4,0.6-0.8,0.9-1.1
          c0.3-0.4,0.7-0.7,1-1l0.1-0.1c0.4-0.3,0.7-0.6,1.1-0.9c0.3-0.2,0.6-0.2,0.9,0c0.4,0.3,0.7,0.5,1.1,0.9c0,0,0.1,0.1,0.1,0.1
          c0.4,0.3,0.7,0.6,1,1c0.3,0.4,0.6,0.8,0.9,1.1c0,0,0.1,0.1,0.1,0.1c0.3,0.4,0.5,0.7,0.7,1.1l0.1,0.2c0.2,0.5,0.4,0.9,0.6,1.3
          c0.2,0.4,0.3,0.8,0.4,1.3l0.1,0.3c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3l0,0.1c0.1,0.3-0.1,0.7-0.4,0.8c-0.4,0.2-0.8,0.3-1.2,0.5
          c-0.4,0.1-0.8,0.2-1.2,0.3l-0.2,0.1c-0.3,0.1-0.7,0.1-1,0.2c-0.2,0-0.2,0-0.3,0C27.2,30.9,26.8,31,26.3,31z M22.4,28.7
          c0.1,0.1,0.3,0.1,0.4,0.2c0.4,0.1,0.7,0.2,1,0.3l0.2,0c0.3,0.1,0.6,0.1,0.9,0.1l0.3,0c0.7,0.1,1.5,0.1,2.2,0l0.3,0
          c0.3,0,0.6-0.1,0.9-0.1l0.2,0c0.3-0.1,0.7-0.2,1-0.3c0.2-0.1,0.4-0.1,0.5-0.2l0,0c-0.1-0.4-0.2-0.7-0.4-1.1
          c-0.1-0.4-0.3-0.7-0.5-1.1l-0.1-0.1c-0.2-0.4-0.4-0.7-0.6-1l0.5-0.5l-0.6,0.4c-0.2-0.3-0.5-0.7-0.8-1c-0.3-0.3-0.6-0.6-0.9-0.9
          l0.4-0.7l-0.5,0.6c-0.2-0.2-0.4-0.3-0.5-0.5c-0.2,0.1-0.4,0.3-0.5,0.5l-0.6-0.5l0.5,0.6c-0.3,0.3-0.6,0.5-0.8,0.8
          c-0.3,0.3-0.6,0.7-0.8,1L24,25.3c-0.2,0.3-0.4,0.6-0.5,0.9l-0.1,0.2c-0.2,0.4-0.4,0.7-0.5,1.1C22.7,27.9,22.5,28.3,22.4,28.7
          C22.4,28.7,22.4,28.7,22.4,28.7z"
        />
      </g>
      <path
        className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
        d="M26.5,10.4c-0.4,0-0.8-0.3-0.8-0.8V0.8c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v8.9
        C27.3,10.1,26.9,10.4,26.5,10.4z"
      />
      <g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M36.3,1.5h-6.8c-0.4,0-0.8-0.3-0.8-0.8S29.1,0,29.5,0h6.8C36.7,0,37,0.3,37,0.8
          S36.7,1.5,36.3,1.5z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M33.7,3.4h-4.1c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h4.1c0.4,0,0.8,0.3,0.8,0.8
          S34.1,3.4,33.7,3.4z"
        />
      </g>
      <g>
        <g>
          <g>
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M51.5,42.1h-6.8c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h6.8c0.4,0,0.8,0.3,0.8,0.8
              S51.9,42.1,51.5,42.1z"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M48.9,44h-4.1c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h4.1c0.4,0,0.8,0.3,0.8,0.8
              S49.3,44,48.9,44z"
            />
          </g>
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M52,42.1h-7.5l-7.1-7.1c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l6.6,6.6H52
            c0.4,0,0.8,0.3,0.8,0.8S52.4,42.1,52,42.1z"
          />
        </g>
        <g>
          <g>
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M8,42.1H1.2c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8H8c0.4,0,0.8,0.3,0.8,0.8
              S8.4,42.1,8,42.1z"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M8,44H3.9c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8H8c0.4,0,0.8,0.3,0.8,0.8S8.4,44,8,44z"
            />
          </g>
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M8.3,42.1H0.8c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h6.9l6.6-6.6c0.3-0.3,0.8-0.3,1.1,0
            s0.3,0.8,0,1.1L8.3,42.1z"
          />
        </g>
      </g>
    </svg>
  )
}

export const FreeSlides = () => {
  return (
    <svg className="w-full h-full category__svg freeslides" version="1.1" viewBox="0 0 34.6 39.1" x="0px" y="0px">
      <g>
        <g>
          <rect
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            height="24.5"
            width="2.4"
            x="28.7"
            y="13.9"
          />
          <rect
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            height="8.5"
            width="2.4"
            x="31.3"
            y="5.4"
          />
        </g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M31.2,39.1H3.4c-0.4,0-0.8-0.3-0.8-0.8V13.9c0-0.4,0.3-0.8,0.8-0.8h27.8c0.4,0,0.8,0.3,0.8,0.8
          v24.5C32,38.8,31.6,39.1,31.2,39.1z M4.1,37.6h26.3v-23H4.1V37.6z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M33.8,14.6H0.8c-0.4,0-0.8-0.3-0.8-0.8V5.4C0,5,0.3,4.6,0.8,4.6h33.1c0.4,0,0.8,0.3,0.8,0.8
          v8.5C34.6,14.3,34.2,14.6,33.8,14.6z M1.5,13.1h31.6v-7H1.5V13.1z"
        />
        <g>
          <rect
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            height="33"
            width="6.2"
            x="14.2"
            y="5.4"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M20.4,39.1h-6.2c-0.4,0-0.8-0.3-0.8-0.8v-33c0-0.4,0.3-0.8,0.8-0.8h6.2c0.4,0,0.8,0.3,0.8,0.8
            v33C21.2,38.8,20.8,39.1,20.4,39.1z M14.9,37.6h4.7V6.1h-4.7V37.6z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M17.4,6.1H6.6c-1.7,0-3.1-1.4-3.1-3.1c0-1,0.5-2,1.4-2.6c0.9-0.6,2-0.7,2.9-0.2l9.9,4.4
            c0.3,0.1,0.5,0.5,0.4,0.8C18.1,5.9,17.8,6.1,17.4,6.1z M6.6,1.5C6.3,1.5,6,1.6,5.7,1.8C5.3,2,5,2.5,5,3.1c0,0.9,0.7,1.6,1.6,1.6
            h7.3l-6.7-3C7,1.5,6.8,1.5,6.6,1.5z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M28,6.1H17.2c-0.4,0-0.7-0.2-0.7-0.6c-0.1-0.3,0.1-0.7,0.4-0.8l9.9-4.4c1-0.4,2-0.3,2.9,0.2
            c0.9,0.6,1.4,1.5,1.4,2.6C31.1,4.8,29.7,6.1,28,6.1z M20.7,4.6H28c0.9,0,1.6-0.7,1.6-1.6c0-0.5-0.3-1-0.7-1.3
            c-0.5-0.3-1-0.3-1.5-0.1L20.7,4.6z"
          />
        </g>
      </g>
    </svg>
  )
}

export const SocialMedia = () => {
  return (
    <svg className="w-full h-full category__svg socialmedia" version="1.1" viewBox="0 0 42.6 42.6" x="0px" y="0px">
      <path
        className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
        d="M21.3,42.6C9.6,42.6,0,33.1,0,21.3C0,9.6,9.6,0,21.3,0s21.3,9.6,21.3,21.3
        C42.6,33.1,33.1,42.6,21.3,42.6z M21.3,1.5c-10.9,0-19.8,8.9-19.8,19.8c0,10.9,8.9,19.8,19.8,19.8s19.8-8.9,19.8-19.8
        C41.1,10.4,32.2,1.5,21.3,1.5z"
      />
      <g>
        <rect
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          height="14.8"
          width="5.8"
          x="9.6"
          y="16.9"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M15.4,32.5H9.6c-0.4,0-0.8-0.3-0.8-0.8V16.9c0-0.4,0.3-0.8,0.8-0.8h5.8c0.4,0,0.8,0.3,0.8,0.8
          v14.8C16.2,32.1,15.9,32.5,15.4,32.5z M10.4,31h4.3V17.7h-4.3V31z"
        />
      </g>
      <circle
        className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
        cx="12.5"
        cy="29.3"
        r="0.8"
      />
      <path
        className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
        d="M27.3,31.6h-6.5c-2.1,0-4.1-0.6-5.8-1.8c-0.2-0.1-0.3-0.4-0.3-0.6v-10c0-0.2,0.1-0.3,0.2-0.5
        l0.9-1.1c0.4-0.5,0.8-1.1,1.1-1.7c0.7-1.6,2.2-4.7,3-6.4c0.6-1.2,2.1-1.7,3.3-1.1c0.9,0.5,1.5,1.5,1.4,2.6l-0.8,5.5h7.4
        c1.3,0,2.4,1,2.5,2.2c0,0,0,0,0,0c0.1,0.7-0.2,1.4-0.6,1.9c-0.2,0.3-0.6,0.5-0.9,0.6c0.2,0.4,0.3,0.9,0.3,1.4
        c-0.1,0.9-0.7,1.7-1.5,2c0.2,0.4,0.3,0.9,0.3,1.4c-0.1,0.9-0.8,1.7-1.7,2.1c0.2,0.4,0.3,0.9,0.2,1.3C29.7,30.6,28.6,31.6,27.3,31.6z
        M16.2,28.8c1.4,0.8,3,1.3,4.6,1.3h6.5c0.5,0,1-0.4,1-0.8c0-0.3-0.1-0.5-0.2-0.7s-0.4-0.3-0.7-0.3c-0.4,0-0.8-0.3-0.8-0.8
        s0.3-0.8,0.8-0.8h1.3c0.5,0,1-0.4,1-0.8c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8H30
        c0.5,0,1-0.4,1-0.8c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h1.2
        c0.3,0,0.5-0.1,0.7-0.3s0.3-0.5,0.2-0.7c0-0.5-0.5-0.8-1-0.8h-7.8c-0.3,0-0.6-0.1-0.9-0.4c-0.2-0.3-0.3-0.6-0.3-0.9l0.8-5.9
        c0.1-0.5-0.1-0.9-0.5-1.1c-0.5-0.2-1.1,0-1.3,0.4c-0.8,1.7-2.3,4.8-3,6.4c-0.3,0.7-0.8,1.4-1.3,2l-0.7,0.9V28.8z"
      />
    </svg>
  )
}

export const MapsSouthAmerica = () => {
  return (
    <svg className="w-full h-full category__svg mapssouthamerica" version="1.1" viewBox="0 0 35 55.9" x="0px" y="0px">
      <g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M34,10.9c-0.1-0.3-0.2-0.5-0.5-0.6l-3-1.2c-0.1,0-0.1,0-0.2-0.1l-6.8-0.8c0,0-0.1,0-0.1,0
            l-0.3-0.1c-0.4-0.1-0.6-0.6-0.4-0.9l0,0c0.2-0.3,0.1-0.6-0.1-0.8l-2.5-2.3c-0.2-0.2-0.4-0.2-0.7-0.2L17.3,4c-0.1,0-0.3,0-0.4-0.1
            l-2.4-1.7c-0.1-0.1-0.2-0.2-0.3-0.4v0c-0.1-0.3-0.4-0.5-0.7-0.5L9.8,1.7c-0.1,0-0.2,0-0.3-0.1L8,0.8c-0.2-0.1-0.4-0.1-0.6,0L3.9,3
            C3.8,3,3.7,3.1,3.7,3.1L2.1,3.3C1.6,3.4,1.5,3.9,1.8,4.3l1,1.1C2.9,5.5,2.9,5.6,3,5.7l0.3,1.6c0,0.2,0,0.3-0.1,0.5L1.5,9.6
            c0,0-0.1,0.1-0.1,0.2l-0.6,1.6c-0.1,0.1-0.1,0.3,0,0.4l0.3,0.8c0.1,0.3,0.1,0.6-0.1,0.8l0,0.1c-0.2,0.3-0.2,0.6-0.1,0.9l0.3,0.6
            c0,0.1,0.1,0.2,0.2,0.2l1.3,1c0.1,0,0.1,0.1,0.1,0.1l3,4.5c0,0.1,0.1,0.1,0.2,0.2l3.9,2.3c0.2,0.1,0.3,0.2,0.3,0.4l1,9.1
            c0,0,0,0.1,0,0.1l0.7,1.9c0,0.1,0,0.2,0,0.3l-0.5,3.6c0,0.2,0,0.3,0.1,0.4l0.5,0.6c0.1,0.1,0.1,0.2,0.1,0.4l0,3
            c0,0.3,0.3,0.6,0.6,0.6l0.1,0c0.3,0,0.5,0.2,0.6,0.5l0.2,0.8c0,0.2,0,0.3-0.1,0.4l-1,1.4c-0.1,0.2,0.5,0.6,0.5,0.6
            c0.1,0,0,0.5,0,0.9c0,0.2,0,0.5,0.1,0.6l2.6,4c0.1,0.1,0.2,0.3,0.4,0.3l3.7,1.6c0.3,0.1,0.6,0.1,0.8-0.1l0,0
            c0.5-0.3,0.5-1.1,0-1.4l-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3l-1.6-2.5c-0.2-0.2-0.2-0.5-0.1-0.8l1-2.1c0.1-0.3,0.1-0.6-0.1-0.9
            l-1.1-1.3c-0.1-0.2-0.2-0.4-0.1-0.6l1.4-2.6c0.1-0.1,0.1-0.2,0.1-0.4l0.1-2.5c0-0.4,0.3-0.7,0.6-0.8l1.1-0.3
            c0.2-0.1,0.4-0.2,0.6-0.4l0.7-1.3c0.1-0.2,0.1-0.5,0-0.7l0-0.1c-0.2-0.6,0.2-1.2,0.8-1.2h0.2c0.3,0,0.6-0.2,0.8-0.5l2.7-5.3
            c0.1-0.2,0.1-0.4,0-0.7l-0.3-0.9c-0.1-0.3,0-0.6,0.2-0.9l2.3-2.4c0.2-0.2,0.4-0.3,0.6-0.3h0.7c0.3,0,0.6-0.2,0.7-0.4l0.8-1.4
            c0.1-0.1,0.1-0.3,0.1-0.4v-4.8c0-0.1,0-0.3,0.1-0.4l1.9-3.2c0.1-0.2,0.1-0.4,0.1-0.6L34,10.9z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M20.2,55.9c-0.2,0-0.4,0-0.6-0.1l-3.7-1.6c-0.3-0.1-0.5-0.3-0.7-0.6l-2.6-4
            c-0.2-0.4-0.3-0.8-0.2-1.2c0-0.1,0-0.2,0.1-0.3c-0.3-0.3-0.5-0.5-0.6-0.8c0-0.2,0-0.5,0.1-0.7l0.9-1.4l-0.2-0.6
            c-0.7,0-1.3-0.6-1.3-1.4l0-3L11,39.7c-0.2-0.3-0.3-0.7-0.3-1l0.5-3.6L10.5,33l-1-9.1l-3.8-2.3c-0.2-0.1-0.3-0.2-0.4-0.4l-3-4.5
            L1,15.8c-0.2-0.1-0.3-0.3-0.4-0.5l-0.3-0.6C0,14.1,0,13.5,0.4,13l0.7-0.4l-0.7,0.3l-0.3-0.8c-0.1-0.3-0.1-0.7,0-1l0.6-1.6
            C0.8,9.4,0.9,9.2,1,9.1l1.5-1.8L2.2,5.9l-1-1.1C0.9,4.4,0.8,3.9,0.9,3.5C1.1,3,1.5,2.7,2,2.6l1.6-0.3L7,0.2c0.4-0.3,0.9-0.3,1.4,0
            L9.8,1l3.7-0.3c0.7-0.1,1.3,0.4,1.5,1.1l2.3,1.6l2.1-0.2c0.5,0,0.9,0.1,1.2,0.4l2.5,2.3c0.4,0.4,0.6,1,0.3,1.6l0.1,0l6.8,0.8
            c0,0,0.1,0,0.1,0l0.2,0.1c0,0,0.1,0,0.1,0l3,1.2c0.5,0.2,0.9,0.7,1,1.2c0,0,0,0,0,0l0.3,1.6c0.1,0.4,0,0.8-0.2,1.1l-1.9,3.2l0,4.9
            c0,0.3-0.1,0.5-0.2,0.8L32,23.8c-0.3,0.5-0.8,0.8-1.4,0.8h-0.7l-2.4,2.5l0.3,1c0.1,0.4,0.1,0.9-0.1,1.2l-2.7,5.3
            c-0.3,0.5-0.8,0.9-1.4,0.9h-0.2c0,0-0.1,0.1-0.1,0.1c0.2,0.5,0.2,1,0,1.4l-0.7,1.3c-0.2,0.4-0.6,0.7-1,0.8l-1.1,0.3
            c0,0-0.1,0-0.1,0.1L20.3,42c0,0.2-0.1,0.5-0.2,0.7l-1.3,2.5l1,1.3c0.4,0.5,0.5,1.1,0.2,1.7l-1,2.1l1.6,2.6l0.3,0.2
            c0.5,0.3,0.8,0.8,0.8,1.3s-0.2,1.1-0.7,1.4C20.9,55.8,20.5,55.9,20.2,55.9z M13.6,47c0.6,0.3,0.4,1.2,0.3,1.7l2.6,4.1l3.7,1.6
            l0.1,0l0-0.2L20,54c-0.2-0.1-0.4-0.3-0.5-0.5L17.9,51c-0.3-0.5-0.3-1-0.1-1.5l1-2.1l0.6-0.6l-0.6,0.5L17.6,46
            c-0.3-0.4-0.4-1-0.2-1.5l1.4-2.6l0.1-2.5c0-0.7,0.5-1.3,1.2-1.5l1.1-0.3l0.7-1.3c0,0,0-0.2,0-0.2c-0.2-0.5-0.2-1.1,0.1-1.5
            c0.3-0.4,0.8-0.7,1.3-0.7h0.2l2.8-5.3l-0.3-1c-0.2-0.6-0.1-1.2,0.3-1.6l2.3-2.4c0.3-0.3,0.7-0.5,1.2-0.5h0.7l0.9-1.5l0-4.9
            c0-0.3,0.1-0.6,0.2-0.8l1.9-3.2l-0.3-1.7c0,0,0-0.1-0.1-0.1l-3-1.2l-6.8-0.8c0,0-0.1,0-0.2,0l-0.3-0.1C22.5,8.7,22.1,8.4,22,8
            c-0.2-0.4-0.1-0.8,0-1.1l-2.4-2.2l-2.2,0.2c-0.3,0-0.6-0.1-0.9-0.2L14,2.9c-0.3-0.2-0.4-0.4-0.5-0.7L9.8,2.5c-0.3,0-0.6,0-0.8-0.2
            L7.7,1.5L4.3,3.6C4.1,3.7,4,3.8,3.8,3.8L2.5,4l0.8,0.9c0.2,0.2,0.3,0.4,0.3,0.7L4,7.2C4,7.6,3.9,8,3.7,8.3l-1.6,1.9l-0.6,1.5
            l0.3,0.7C2,12.8,2,13.4,1.7,13.8l-0.7,0.5L1.6,14l0.3,0.6l1.3,0.9c0.1,0.1,0.2,0.2,0.3,0.3l3,4.5l3.9,2.3c0.4,0.2,0.6,0.6,0.6,1
            l1,9.2l0.7,1.8c0.1,0.2,0.1,0.4,0.1,0.6l-0.5,3.5l0.4,0.5c0.2,0.2,0.3,0.5,0.3,0.8l0,2.9c0.6,0,1.1,0.5,1.3,1.1l0.2,0.8
            c0.1,0.4,0,0.7-0.2,1L13.6,47z"
          />
        </g>
      </g>
    </svg>
  )
}

export const MapsAsia = () => {
  return (
    <svg className="w-full h-full category__svg mapsasia" version="1.1" viewBox="0 0 54.3 58.3" x="0px" y="0px">
      <g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M53.5,13.2c-0.3-0.9-0.5-1.9-0.8-2.8c-0.1-0.3-0.2-0.4-0.4-0.7c-0.1-0.2,0-0.4-0.1-0.6
            c-0.1-0.5-0.5-0.3-0.8-0.6c-0.2-0.2-0.3-0.4-0.4-0.6c-0.3-0.6-0.1-0.9,0-1.5c0.1-0.5,0-0.9-0.1-1.4c-0.1-0.5-0.1-1.1-0.6-1.4
            c-0.4-0.3-1.2-0.3-1.3-0.8c0.3-0.1,0.8-0.2,1-0.5C50,2,49.5,1.7,49.3,1.5c-0.3-0.2-0.6-0.6-0.9-0.7c-0.4-0.2-0.6,0-1,0.2
            c-0.6,0.3-1.4,0.4-2,0.8C44.9,2,44.2,2.8,45,3.2c-0.2,0.1-0.5,0.4-0.7,0.5c-0.2,0.1-0.5-0.1-0.7,0c-0.5,0.1-0.8,0.8-1.2,0.7
            c-0.7,0-0.8-0.6-1.5-0.3c-0.6,0.3-1.3,0.5-1.1,1.2C39.9,5.8,40,5.6,39.7,6c-0.2,0.2-0.3,0.2-0.5,0.2c-0.2-0.1-0.3-0.6-0.6-0.7
            c-0.3-0.2-0.6-0.2-1-0.3c-0.2,0-0.6-0.2-0.8-0.1c-0.3,0.1-0.2,0.4-0.4,0.6c-0.4,0.3-1.1,0.2-1.6,0.2c0.2-0.3,0.5-0.7,0.5-1
            c-0.1-0.3-0.5-0.6-0.8-0.7c-1-0.4-1.5-0.2-2.4,0.2c-0.6,0.3-1.5,0.4-2,0.8c-0.2,0.1-0.2,0.3-0.4,0.3c-0.2,0.1-0.5,0-0.7,0
            c-0.5,0-0.8,0.3-0.5,0.8c-0.2,0-0.7-0.2-0.8-0.3c-0.2-0.3,0.2-0.9-0.5-0.7c-0.6,0.1-1.2,0.7-1.7,1c-0.4,0.3-0.8,0.6-1.2,0.9
            c-0.5,0.4-0.9,0.6-1.6,0.9c-1.1,0.4-1.8,1.1-2.6,2c-0.3,0.4-0.8,1.3-1.3,1.5c-0.3,0.2-0.7-0.1-1,0.2c-0.1,0.2,0,0.7,0,0.9
            c-0.6-0.1-1.1-0.2-1.2,0.5c-0.1,0.8,0.2,0.9,0.8,1.3c-0.7,0.3-0.5,0.7-0.4,1.3c-0.2-0.1-0.5-0.3-0.6-0.4c-0.3-0.3-0.2-0.7-0.6-0.9
            c-0.2-0.1-0.5-0.1-0.8-0.2c-0.3-0.1-0.8-0.5-1.1-0.4c-0.7,0.2-0.7,1.1-0.9,1.6c-0.1,0.2-0.4,0.4-0.5,0.7c-0.2,0.4,0.2,0.6,0.2,0.9
            c0,0.4-0.1,0.3-0.4,0.3c-0.5,0-0.6,0-1,0.4c-0.2,0.2-0.3,0.5-0.4,0.8c-0.1,0.4,0.3,0.5,0.3,0.9c0,0.1-0.2,0.4-0.2,0.5
            c-0.2,0.5,0.1,0,0,0.3c0,0.1-0.1,0.1-0.2,0.1c-0.1,0.2-0.2,0.2-0.3,0.5c0,0.1,0.1,0.3,0.1,0.4c0,0.4-0.3,1.5-0.8,1.1
            c-0.1-0.1-0.5-0.8-0.5-0.9c0-0.2,0.3-0.7,0.5-0.8c0.3-0.2,0.7-0.2,0.7-0.7c0-0.3-0.4-0.7-0.5-1l0,0.1c0.2-0.4-0.4-1.6-0.5-2.1
            c-0.1-0.6-0.5-1-0.7-1.6C8.6,15.5,8.3,15,8,15.5c-0.3,0.4,0.1,1.2-0.1,1.6c-0.2,0.7-0.7,0.1-0.8-0.4c-0.1-0.3,0-0.6,0-0.8
            c-0.1-0.3-0.5-0.6-0.8-0.7c-0.1-0.1-0.3,0-0.5-0.1c-0.1-0.1-0.1-0.3-0.2-0.3c-0.2-0.2-0.3-0.1-0.5-0.2c-0.2-0.1-0.2-0.3-0.5-0.3
            c-0.6,0-1.2,0.9-1.6,1.4c-0.3,0.5-0.4,1.4-0.2,2C2.9,18,3.2,17.9,3.3,18c0.1,0.1,0,0.3,0.1,0.4c0.2,0.4,0.5,0.4,0.8,0.7
            c-0.5,0.2-0.4,0.6-0.6,1c-0.3,0.4-1,0.6-1.3,1c-0.3,0.4-0.2,1-0.4,1.5c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0.3,0,0.4,0,0.7
            c0,0.5-0.2,1.1-0.3,1.7c-0.1,0.6,0,0.9,0,1.5c0,0.4-0.2,0.9-0.3,1.3c-0.1,0.5-0.1,0.9,0,1.4c0.1,0.8,0.5,2,0.4,2.8
            c-0.1,0.5-0.4,0.7-0.4,1.2c0,0.5-0.2,1.4,0.2,1.8c0.6,0.6,1.6,0.7,2.4,0.7c1,0.1,2,0,3-0.1c0.5-0.1,1.3-0.1,1.7-0.3
            c0.4-0.3,0.8-0.8,1.1-1.2c0.6-0.6,0.7-0.9,0.3-1.7C9.3,32.3,9,32.1,9,31.5c1,0.7,1.7,1.5,2.9,1.7c0.3,0.1,0.8,0.1,1.1,0.2
            c0.2,0.1,0.1,0,0.3,0.3c0.3,0.6,0.2,1.3,0.9,1.7c-0.4,0.3,0.2,1.3,0.4,1.5c0.3,0.4,0.6,0.4,1.1,0.4c-0.5,2.3,0.6,4.7,1.2,6.8
            c0.1,0.5,0.4,1.5,0.8,1.9c0.3,0.4,0.5,0.2,0.9,0c0.4-0.2,1.3-0.7,1.5-1.1c0.1-0.3,0-0.6,0.1-0.9c0.1-0.3,0.3-0.5,0.3-0.9
            c0-0.2-0.2-0.8-0.1-1.1c0,0,0.7-0.4,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.4c0.2-0.2,0.5-0.4,0.7-0.5c0.6-0.5,1-0.6,1.8-0.9
            c0.6-0.2,0.6-0.9,1.1-1.1c0.6-0.2,1.5,0.3,2-0.3c0.1,0.4,0.1,1,0.3,1.4c0.3,0.4,0.6,0.5,0.8,1.1c0.1,0.3,0.2,0.7,0.2,1
            c-0.1,0.6-0.2,1,0.6,1.1c0.5,0.1,0.7-0.4,1.1-0.4c0.5,0,0.3,0.4,0.3,0.7c0,0.4,0.4,0.8,0.5,1.2c0.1,0.2,0,0.4,0.1,0.6
            c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.1,0.4c0,0.5-0.1,1,0,1.5c0.1,0.5,0.3,0.6,0.7,1c0.7,0.6,0.5,1.6,0.9,2.3
            c0.2,0.3,0.6,0.5,0.9,0.7c0.4,0.2,0.9,0.7,1.4,0.7c0.7-0.1,0.5-0.6,0.3-1c-0.2-0.5-0.2-0.8-0.3-1.3c-0.1-0.5-0.4-0.9-0.7-1.3
            c-0.3-0.4-0.8-0.5-1.1-0.8c-0.1-0.1,0-0.4-0.1-0.6c-0.1-0.2-0.3-0.4-0.4-0.5c-0.1-0.4,0.2-1,0.3-1.3c0.2,0.4,0.7,0,0.9,0.2
            c0.2,0.2,0.2,0.7,0.5,0.9c0.2,0.1,0.7,0.2,0.8,0.4c0.2,0.4-0.2,0.8,0.5,0.8c0.8,0,0.9-0.7,1.5-1c0.8-0.3,1.5-0.4,1.5-1.3
            c0-0.7-0.1-1.3-0.1-1.9c0-0.3,0-0.7-0.2-1c-0.2-0.3-0.7-0.2-0.9-0.5c-0.4-0.3-0.9-1-0.8-1.5c0.1-0.5,0.8-1.1,1.3-1.2
            c0.1,0.7,0.4,1,1,0.5c0.3-0.2,0.6-0.5,1-0.7c0.6-0.3,1.2-0.6,1.9-0.9c0.4-0.2,0.9-0.3,1.2-0.6c0.3-0.2,0.4-0.7,0.6-1
            c0.3-0.7,0.6-1.3,0.9-2c0.5-1,1-1.7,0.7-2.8c-0.2-0.5-0.4-0.9-0.6-1.4c-0.3-0.4-0.5-1.4-1.2-1.4c0.2-0.3,0.4-0.6,0.6-0.9
            c0.2-0.3,0.6-0.6,0.6-0.9c0.1-0.6-0.6-0.5-1-0.5c-0.5,0-0.6,0.1-0.9,0.4c-0.1-0.3-0.3-0.4-0.5-0.4c0.4-0.1,0.8-0.8,1-1.1
            c0,0.6,0.4,0.6,0.8,0.4c0.4-0.2,0.5-0.3,1-0.3c0,0.2-0.1,0.6-0.1,0.8c0.2,0.6,0.4,0.2,0.8,0.3c0.2,0.1,0.1,0.3,0.2,0.6
            c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.5,0.1,1.3,0.9,1c0.4-0.2,1.1-0.6,1.2-1.1c0.1-0.4,0-1.2-0.1-1.5c-0.2-0.5-0.8-0.7-1.1-1.1
            c-0.3-0.4-0.2-0.2,0-0.6c0.1-0.2,0.3-0.4,0.3-0.6c0.1-0.5,0.2-1,0.4-1.5c0.5,0.3,1.1-0.2,1.3-0.6c0.2-0.3,0.1-0.8,0.1-1.1
            c0-1.1,0.2-2.2,0.1-3.3c-0.1-0.7-0.5-1.6-0.8-2.3c-0.2-0.4-0.4-1-0.8-1.2c-0.5-0.3-1.1-0.2-1.2,0.4c-0.4-0.2-0.4-0.2-0.5-0.6
            c0-0.3,0-0.6,0.1-0.8c0.1-0.7,0.1-1.4,0.6-1.9c0.3-0.3,0.7-0.6,1-0.8c0.2-0.1,0.5-0.1,0.7-0.2c0.5-0.3,0.2-1.4,0.2-1.9
            c0.2,0.1,0.5,0,0.5-0.2c0.2,1.2,0.5,2.3,1.2,3.2c0.4,0.5,0.9,1.1,1.4,1.6c0.2,0.2,0.6,0.4,0.8,0.6C52.9,13.6,53.7,13.9,53.5,13.2z
            "
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M35.2,52.8c-0.6,0-1.1-0.4-1.5-0.6c-0.1-0.1-0.2-0.1-0.2-0.2l-0.2-0.1
            c-0.3-0.2-0.7-0.4-1-0.8c-0.3-0.4-0.3-0.9-0.4-1.3c-0.1-0.4-0.2-0.7-0.4-0.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3-0.2-0.7-0.6-0.8-1.3
            c-0.1-0.6,0-1.2,0-1.7c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2l0-0.2c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.4
            c-0.1-0.2-0.2-0.4-0.3-0.7c-0.2,0.1-0.5,0.2-0.8,0.1c-1.4-0.2-1.3-1.3-1.2-1.8l0-0.2c0-0.2,0-0.4-0.1-0.7
            c-0.1-0.2-0.1-0.2-0.3-0.4c-0.1-0.1-0.3-0.3-0.5-0.5c-0.2-0.2-0.3-0.5-0.3-0.7c-0.2,0-0.5,0-0.6,0c-0.2,0-0.4,0-0.5,0
            c0,0-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.7-0.9,0.9l-0.3,0.1c-0.5,0.2-0.8,0.3-1.3,0.7l-0.7,0.6c-0.3,0.2-0.5,0.3-0.7,0.4
            c-0.1,0-0.1,0-0.2,0.1c0,0-0.2,0.1-0.3,0.2c0,0,0,0.1,0,0.1c0,0.2,0.1,0.4,0.1,0.6c0,0.4-0.1,0.6-0.2,0.8c0,0.1-0.1,0.2-0.1,0.2
            c0,0,0,0.1,0,0.2c0,0.2,0,0.5-0.1,0.8c-0.3,0.6-1,1.1-1.8,1.5l-0.1,0.1c-0.3,0.2-1,0.5-1.7-0.2c-0.2-0.2-0.6-0.7-0.9-2.2
            c-0.1-0.5-0.3-1-0.4-1.5c-0.5-1.6-1-3.2-0.8-4.8c-0.3-0.1-0.6-0.3-0.9-0.6c-0.5-0.7-0.7-1.3-0.7-1.7c-0.3-0.4-0.4-0.8-0.5-1.1
            c0-0.2-0.1-0.4-0.2-0.5c0,0,0,0,0,0c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.3,0-0.4,0c-0.5-0.1-0.9-0.2-1.2-0.4c0,0.6-0.2,1-0.8,1.5
            c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.3-0.5,0.6-0.9,0.9c-0.5,0.3-1.1,0.4-1.6,0.4c-0.1,0-0.3,0-0.4,0c-1,0.2-2,0.2-3.2,0.1
            c-0.9-0.1-2.1-0.1-2.9-0.9c-0.5-0.6-0.5-1.4-0.4-2c0-0.1,0-0.2,0-0.3c0-0.4,0.2-0.8,0.3-1c0.1-0.1,0.1-0.2,0.1-0.3
            c0.1-0.3-0.1-0.9-0.2-1.4c-0.1-0.4-0.2-0.7-0.2-1.1c-0.1-0.6-0.1-1.1,0.1-1.7c0-0.1,0.1-0.3,0.1-0.4c0.1-0.2,0.2-0.5,0.2-0.7
            c0-0.2,0-0.3,0-0.5c0-0.3-0.1-0.7,0-1.1c0-0.2,0.1-0.4,0.1-0.6c0.1-0.3,0.2-0.7,0.2-1c0,0,0,0,0-0.1c-0.1-0.2-0.1-0.5,0.1-1
            c0.1-0.2,0.3-0.4,0.4-0.5c0,0,0.1,0,0.1-0.1c0,0,0-0.2,0.1-0.4c0-0.3,0.1-0.8,0.4-1.2c0.2-0.3,0.6-0.5,0.9-0.7
            c0.2-0.1,0.4-0.2,0.5-0.3c0,0,0-0.1,0.1-0.2c0-0.1,0-0.1,0.1-0.2C3,19.1,2.8,19,2.8,18.8c0-0.1,0-0.1-0.1-0.2
            c-0.2-0.1-0.4-0.3-0.6-0.6c-0.3-0.7-0.3-2,0.2-2.7c0.4-0.7,1.2-1.7,2.2-1.7c0.5,0,0.8,0.2,0.9,0.4c0.2,0,0.4,0.1,0.7,0.3
            c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.5,0.2,0.9,0.6c0.4-0.5,0.8-0.5,1-0.5c0.5,0.1,0.8,0.4,1,1.1
            c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.3,0.4,0.6,0.4,1.1c0,0.1,0.1,0.3,0.2,0.5c0,0.1,0.1,0.3,0.1,0.4c0.1-0.1,0.2-0.3,0.3-0.5
            c0.3-0.5,0.7-0.6,1-0.7c-0.1-0.2-0.1-0.5,0.1-0.8c0.1-0.3,0.3-0.5,0.4-0.6c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.2,0.1-0.4
            c0.2-0.5,0.4-1.4,1.3-1.6c0.5-0.1,1,0.1,1.3,0.3c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0.1c0-0.2,0-0.4,0-0.7
            c0-0.5,0.2-0.8,0.5-1c0.2-0.2,0.4-0.2,0.7-0.3c0-0.2,0.1-0.4,0.2-0.5c0.4-0.6,1-0.5,1.2-0.5c0,0,0.1,0,0.1,0
            c0.1-0.1,0.4-0.6,0.6-0.8c0.1-0.2,0.3-0.4,0.4-0.5c0.6-0.8,1.5-1.8,2.9-2.2c0.6-0.2,0.9-0.4,1.4-0.7c0.4-0.3,0.8-0.7,1.3-1
            c0.1-0.1,0.3-0.2,0.4-0.3C26,4.9,26.5,4.6,27,4.4c0.2,0,0.7-0.2,1.1,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0.2-0.1,0.4-0.2,0.7-0.2
            c0.1,0,0.2,0,0.4,0c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.3-0.2c0.4-0.3,0.9-0.5,1.4-0.7c0.2-0.1,0.5-0.2,0.7-0.3
            c1-0.4,1.7-0.8,3-0.2c0.4,0.2,1.1,0.6,1.2,1.2c0,0,0,0.1,0,0.1c0.1-0.2,0.3-0.3,0.5-0.4c0.4-0.2,0.8,0,1.1,0.1L38,4.4
            c0.3,0.1,0.7,0.1,1,0.4C39.2,4,40,3.7,40.4,3.5l0.2-0.1c0.8-0.4,1.3-0.1,1.6,0.1c0.1,0.1,0.2,0.1,0.2,0.1c0,0,0.1-0.1,0.2-0.1
            c0.2-0.2,0.5-0.5,1-0.6c0.2,0,0.3,0,0.4,0c0,0,0-0.1,0-0.1c0-0.8,0.8-1.5,1.2-1.7c0.4-0.2,0.9-0.4,1.4-0.6
            c0.2-0.1,0.5-0.2,0.7-0.2l0.2-0.1c0.3-0.1,0.8-0.3,1.4-0.1c0.3,0.1,0.6,0.4,0.8,0.6c0.1,0.1,0.2,0.1,0.2,0.2L49.9,1
            c0.3,0.2,1,0.8,0.7,1.6c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.2,0.1,0.2,0.1c0.6,0.4,0.7,1.1,0.8,1.5c0,0.1,0,0.2,0.1,0.3l0,0.1
            c0.1,0.5,0.2,0.9,0.1,1.6c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.4-0.1,0.4,0,0.6l0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0,0.1,0.1
            c0.2,0.1,0.7,0.2,0.9,0.9C53,9.1,53,9.3,53,9.4c0,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.2,0.3,0.3,0.6c0.1,0.4,0.2,0.8,0.4,1.2
            c0.1,0.5,0.3,1,0.5,1.5v0c0.3,0.8-0.2,1.1-0.3,1.2c-0.6,0.4-1.5,0-1.7-0.2L52,13.9c-0.3-0.2-0.5-0.4-0.8-0.6
            c-0.4-0.4-0.7-0.8-1-1.2c-0.1-0.2-0.3-0.3-0.4-0.5c-0.3-0.4-0.5-0.7-0.7-1.1c-0.1,0.1-0.2,0.2-0.3,0.2C48.6,11,48.4,11,48.2,11
            c-0.1,0-0.1,0-0.2,0c-0.2,0.1-0.5,0.4-0.8,0.7c-0.2,0.2-0.2,0.6-0.3,1c0,0.1,0,0.3-0.1,0.4l0,0c0.4-0.1,0.8,0,1.2,0.2
            c0.6,0.3,0.9,0.9,1.1,1.3l0.1,0.2c0.4,0.8,0.8,1.7,0.9,2.5c0.1,0.8,0,1.6,0,2.3c0,0.4-0.1,0.7-0.1,1l0,0.2c0,0.3,0,0.8-0.2,1.3
            c-0.2,0.4-0.7,0.9-1.3,1.1c0,0-0.1,0-0.1,0c0,0.1,0,0.1-0.1,0.2c-0.1,0.2-0.1,0.4-0.2,0.7c-0.1,0.3-0.2,0.5-0.3,0.6
            c0,0,0,0.1-0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.2,0.6,0.5,0.8,0.9c0.3,0.5,0.3,1.5,0.2,2c-0.3,0.9-1.6,1.5-1.6,1.6
            c-0.4,0.2-0.8,0.2-1.1,0c-0.5-0.3-0.6-0.8-0.7-1.1c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0-0.1c-0.1-0.1-0.1-0.2-0.2-0.3
            c-0.1,0.2-0.3,0.4-0.4,0.5c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1-0.1,0.1-0.1,0.2c0.3,0.3,0.5,0.7,0.6,1c0,0.1,0.1,0.2,0.1,0.3
            c0.3,0.5,0.5,0.9,0.7,1.5c0.4,1.2-0.1,2-0.5,2.8c-0.1,0.2-0.2,0.3-0.3,0.5l-0.9,2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.3-0.3,0.8-0.7,1.1
            c-0.3,0.3-0.7,0.4-1,0.5c-0.1,0-0.2,0.1-0.3,0.1l-0.6,0.3c-0.4,0.2-0.8,0.4-1.2,0.6c-0.2,0.1-0.4,0.2-0.6,0.4l-0.2,0.2
            c-0.5,0.3-0.9,0.4-1.3,0.3c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1,0.1-0.1,0.2-0.2,0.2c0,0.1,0.2,0.6,0.5,0.8c0,0,0.1,0,0.2,0.1
            c0.2,0.1,0.6,0.2,0.9,0.5c0.3,0.3,0.3,0.8,0.3,1.1c0,0.1,0,0.1,0,0.2c0.1,0.7,0.2,1.4,0.2,2.1c-0.1,1.2-1,1.6-1.6,1.8
            c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.3c-0.3,0.3-0.7,0.8-1.5,0.8c0,0,0,0,0,0c-0.1,0-0.5,0-0.9-0.3l0,0.1
            c0.3,0.4,0.7,0.9,0.8,1.5c0,0.2,0,0.3,0.1,0.4c0,0.3,0.1,0.5,0.2,0.7c0.2,0.4,0.4,0.9,0.1,1.5c-0.1,0.2-0.4,0.5-1,0.6
            C35.3,52.8,35.2,52.8,35.2,52.8z M32,46c0,0.5,0,0.9,0,1.2c0,0.1,0,0.1,0.2,0.3c0.1,0.1,0.2,0.1,0.2,0.2c0.6,0.5,0.7,1.2,0.8,1.7
            c0.1,0.3,0.1,0.6,0.2,0.8c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.1,0.1,0.2,0.1
            c-0.1-0.3-0.1-0.6-0.2-0.9c0-0.1,0-0.2-0.1-0.4c-0.1-0.3-0.3-0.5-0.5-0.9l-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2
            c-0.2-0.1-0.5-0.3-0.7-0.6c-0.2-0.3-0.2-0.5-0.2-0.7c0,0,0,0,0,0c0,0,0,0,0-0.1c-0.1-0.1-0.3-0.3-0.3-0.6
            c-0.1-0.4,0.1-0.8,0.2-1.2c0-0.1,0.1-0.3,0.1-0.4c0-0.3,0.3-0.6,0.6-0.6c0.2,0,0.5,0,0.6,0.2c0.3,0,0.7,0,1,0.3
            c0.3,0.3,0.3,0.6,0.4,0.7c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0c0.3,0.1,0.6,0.2,0.9,0.5c0.1,0.1,0.1,0.2,0.2,0.4c0,0,0.1-0.1,0.1-0.1
            c0.2-0.2,0.4-0.5,0.8-0.7c0.2-0.1,0.3-0.1,0.4-0.2c0.6-0.2,0.6-0.2,0.6-0.4c0-0.6,0-1.2-0.1-1.8c0-0.1,0-0.2,0-0.3
            c0-0.1,0-0.2,0-0.3c0,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.1-0.7-0.3c-0.5-0.4-1.2-1.4-1.1-2.3c0.2-0.8,1.1-1.7,1.9-1.8
            c0.4-0.1,0.7,0.2,0.8,0.5l0,0c0.3-0.2,0.5-0.4,0.8-0.6c0.4-0.2,0.8-0.4,1.3-0.6l0.6-0.3c0.1-0.1,0.3-0.1,0.4-0.2
            c0.2-0.1,0.5-0.2,0.6-0.3c0.1-0.1,0.2-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.3l0.9-2c0.1-0.2,0.2-0.4,0.3-0.6c0.4-0.7,0.5-1.1,0.4-1.7
            c-0.2-0.5-0.3-0.8-0.6-1.2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.3-0.6-0.4-0.6c-0.3,0-0.5-0.1-0.7-0.3c-0.1-0.2-0.1-0.5,0-0.8
            l0.2-0.3c0.1-0.2,0.3-0.4,0.4-0.7c0.1-0.1,0.1-0.2,0.2-0.2c-0.1,0-0.2,0-0.2,0l0,0c0,0,0,0-0.1,0.1c-0.2,0.2-0.5,0.3-0.8,0.3
            c-0.2-0.1-0.4-0.2-0.5-0.4c-0.3-0.1-0.6-0.3-0.6-0.7c0-0.4,0.2-0.7,0.6-0.8c0,0,0.2-0.3,0.3-0.5c0.1-0.1,0.2-0.2,0.2-0.3
            c0.2-0.3,0.5-0.4,0.8-0.3c0.2,0.1,0.4,0.2,0.5,0.4l0,0c0.3-0.2,0.6-0.3,1.1-0.3c0.4,0,0.8,0.3,0.8,0.7c0,0.1,0,0.3,0,0.4
            c0.1,0,0.2,0,0.3,0.1c0.4,0.2,0.6,0.5,0.6,1c0,0,0,0.1,0,0.1c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4
            c0.3-0.2,0.5-0.3,0.5-0.4c0-0.2,0-0.8-0.1-0.9c-0.1-0.1-0.2-0.2-0.4-0.4c-0.2-0.2-0.4-0.4-0.6-0.6l-0.1-0.1
            c-0.1-0.2-0.5-0.6-0.1-1.2c0,0,0,0,0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.1-0.4,0.1-0.6
            c0.1-0.3,0.2-0.6,0.3-1c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.4,0,0.6,0c0.1,0,0.2-0.2,0.3-0.3c0-0.1,0-0.3,0-0.5c0-0.1,0-0.2,0-0.3
            c0-0.4,0-0.7,0.1-1.1c0.1-0.7,0.1-1.4,0-2c-0.1-0.6-0.5-1.5-0.8-2l-0.1-0.2c-0.1-0.2-0.3-0.6-0.4-0.7c-0.1,0-0.2-0.1-0.2-0.1
            c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.1-0.6,0l-0.1-0.1c-0.5-0.3-0.6-0.5-0.7-1c-0.1-0.4,0-0.7,0-0.9l0-0.1c0-0.1,0-0.2,0-0.4
            c0.1-0.6,0.2-1.4,0.7-2c0.2-0.2,0.7-0.6,1.1-0.9c0.2-0.1,0.4-0.2,0.6-0.2c0,0,0,0,0.1,0c0-0.2,0-0.6-0.1-0.8c0-0.2,0-0.3-0.1-0.4
            c0-0.3,0.1-0.5,0.3-0.7c0.1-0.1,0.3-0.1,0.4-0.1c0.1-0.2,0.3-0.3,0.6-0.2c0.3,0,0.6,0.3,0.7,0.6l0,0.1c0.2,1.1,0.4,2,1.1,2.8
            c0.1,0.2,0.3,0.4,0.4,0.5c0.3,0.4,0.6,0.7,0.9,1c0,0,0.1,0.1,0.2,0.1c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.4-0.2-0.8-0.4-1.2
            c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.3-0.1-0.5-0.1-0.6c0,0,0,0,0,0c-0.2-0.1-0.3-0.1-0.5-0.3
            c-0.3-0.2-0.5-0.5-0.6-0.7l-0.1-0.1c-0.4-0.7-0.3-1.2-0.2-1.7c0-0.1,0.1-0.2,0.1-0.4c0.1-0.3,0-0.6-0.1-1l0-0.1
            C50,5.1,50,5,50,4.8c-0.1-0.3-0.1-0.5-0.2-0.6c0,0-0.2-0.1-0.3-0.1c-0.4-0.1-1.1-0.3-1.3-1.1c-0.1-0.4,0.1-0.8,0.4-0.9
            c0.1,0,0.1,0,0.2-0.1l0,0c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.3-0.4-0.3c0,0-0.1,0-0.2,0.1l-0.2,0.1
            c-0.2,0.1-0.5,0.2-0.7,0.3c-0.4,0.1-0.8,0.3-1.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0.1,0.2,0.3,0.3,0.5c0,0.3-0.1,0.5-0.3,0.7
            c0,0-0.1,0.1-0.2,0.1c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.5,0-0.7,0c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.2,0.2
            c-0.3,0.2-0.6,0.6-1.2,0.5c-0.5,0-0.8-0.2-1-0.3c0,0-0.1-0.1-0.1-0.1L41,4.9c-0.2,0.1-0.4,0.2-0.5,0.3c0,0.1,0.1,0.1,0.1,0.2
            c0.2,0.7-0.1,1-0.4,1.2c-0.2,0.2-0.7,0.6-1.3,0.3c-0.4-0.2-0.5-0.5-0.7-0.7c0-0.1-0.1-0.1-0.1-0.1c0,0-0.2-0.1-0.4-0.1l-0.2-0.1
            c-0.1,0-0.1,0-0.2-0.1c0,0,0,0,0,0c-0.1,0.1-0.2,0.3-0.3,0.4c-0.5,0.4-1.2,0.4-1.7,0.4c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5-0.1-0.7-0.4
            c-0.1-0.2-0.1-0.5,0-0.8c0-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.7-0.3-0.9-0.2-1.8,0.2
            c-0.3,0.1-0.6,0.2-0.9,0.3c-0.4,0.1-0.8,0.3-1,0.4C30.4,5.8,30.3,6,30,6.1c-0.3,0.1-0.6,0.1-0.8,0.1c0,0,0,0,0,0
            c0,0.2,0,0.3-0.1,0.4c-0.2,0.2-0.4,0.4-0.7,0.3c-0.1,0-1-0.2-1.3-0.7c0-0.1-0.1-0.1-0.1-0.2c-0.2,0.1-0.4,0.3-0.6,0.4
            c-0.2,0.1-0.4,0.3-0.5,0.4c-0.4,0.3-0.8,0.5-1.1,0.9c-0.6,0.5-1.1,0.7-1.8,1c-0.8,0.3-1.5,0.8-2.2,1.8c-0.1,0.1-0.2,0.3-0.3,0.4
            c-0.3,0.5-0.7,1.1-1.2,1.3c-0.2,0.1-0.4,0.1-0.6,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0.2-0.1,0.4-0.3,0.6
            c-0.2,0.1-0.4,0.2-0.6,0.2l-0.2,0c0,0-0.1,0-0.2,0c0,0.2,0,0.3,0.5,0.6c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.2,0.5-0.5,0.6
            c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3l0,0.2c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.2-0.8,0.1c-0.1-0.1-0.6-0.3-0.9-0.6
            c-0.2-0.2-0.3-0.4-0.3-0.6c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.2,0-0.3,0-0.5-0.1c-0.1,0-0.3-0.1-0.4-0.2
            c-0.1,0-0.2-0.1-0.2-0.1C14.1,15,14,15.3,14,15.4c-0.1,0.2-0.1,0.5-0.2,0.6c-0.1,0.2-0.3,0.4-0.4,0.5c0,0-0.1,0.1-0.1,0.1
            c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.5-0.2,0.8c-0.3,0.3-0.6,0.3-0.8,0.3c0,0-0.1,0-0.1,0l-0.2,0c-0.1,0-0.1,0-0.2,0
            c0,0,0,0-0.1,0.1c-0.2,0.2-0.2,0.4-0.2,0.4c0.1,0.2,0.3,0.4,0.3,0.8c0,0.3-0.1,0.5-0.2,0.7l0,0c0,0.2,0,0.3-0.1,0.5
            c-0.1,0.1-0.2,0.2-0.3,0.3c0,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.3,0,0.4c-0.1,0.4-0.2,1.5-0.9,1.8c-0.3,0.2-0.7,0.1-1-0.1
            c-0.3-0.2-0.8-1.1-0.9-1.5c0-0.7,0.6-1.3,0.8-1.5c0.1-0.1,0.2-0.1,0.3-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0
            c-0.3-0.2-0.4-0.5-0.3-0.9c0-0.2-0.2-0.7-0.3-1c-0.1-0.3-0.2-0.5-0.2-0.7c0-0.1,0-0.1-0.1-0.2c-0.2,0.8-0.7,0.8-1,0.8c0,0,0,0,0,0
            c-0.8,0-1.2-1.1-1.3-1.3c-0.1-0.3-0.1-0.5-0.1-0.7c0-0.1,0-0.1,0-0.2c0,0-0.1-0.2-0.4-0.2c-0.1,0-0.4,0-0.6-0.2
            c-0.2-0.1-0.3-0.2-0.3-0.4c-0.1,0-0.2,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1,0.1-0.4,0.3-0.8,1c-0.2,0.2-0.2,0.8-0.2,1.2
            c0.1,0,0.3,0.1,0.5,0.3c0.2,0.2,0.2,0.5,0.2,0.7c0,0,0.1,0,0.1,0c0.2,0.1,0.4,0.2,0.6,0.5c0.1,0.2,0.2,0.4,0.1,0.7
            c-0.1,0.2-0.2,0.4-0.4,0.5c0,0,0,0.1,0,0.1c-0.1,0.2-0.1,0.4-0.3,0.6c-0.2,0.3-0.6,0.5-0.9,0.7c-0.2,0.1-0.4,0.2-0.5,0.3
            c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.3,0.4-0.4,0.5c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.4
            c0,0.5-0.1,0.9-0.2,1.3c0,0.2-0.1,0.3-0.1,0.5c0,0.3,0,0.4,0,0.7c0,0.2,0,0.4,0,0.6c0,0.4-0.1,0.8-0.2,1.2c0,0.1-0.1,0.2-0.1,0.3
            c-0.1,0.4-0.1,0.7,0,1.1c0,0.3,0.1,0.6,0.2,0.9c0.2,0.7,0.3,1.4,0.2,2.1c-0.1,0.3-0.2,0.5-0.2,0.6c-0.1,0.2-0.1,0.3-0.1,0.4
            c0,0.1,0,0.2,0,0.4c0,0.2-0.1,0.7,0,0.9c0.3,0.4,1.2,0.4,1.8,0.4c1.1,0.1,2,0,2.9-0.1c0.2,0,0.3,0,0.5-0.1c0.4,0,0.8-0.1,0.9-0.2
            c0.2-0.1,0.4-0.3,0.5-0.6c0.2-0.2,0.3-0.4,0.5-0.5c0.4-0.4,0.4-0.4,0.1-0.9c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2-0.3-0.4-0.6-0.5-1.2
            c0-0.3,0.1-0.6,0.4-0.7c0.3-0.1,0.6-0.1,0.8,0.1c0.2,0.1,0.4,0.3,0.5,0.4c0.7,0.5,1.3,1,2.1,1.2c0.1,0,0.2,0,0.3,0
            c0.4,0,0.8,0.1,1.1,0.3c0.2,0.1,0.4,0.2,0.5,0.5c0,0,0,0,0,0.1c0.1,0.3,0.2,0.6,0.3,0.8c0.1,0.4,0.1,0.5,0.3,0.6
            c0.2,0.1,0.4,0.4,0.4,0.6c0,0.2,0,0.3-0.1,0.4c0,0.1,0.2,0.4,0.4,0.7c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.5,0.1,0.6,0.3
            c0.2,0.2,0.2,0.4,0.2,0.6c-0.3,1.6,0.2,3.3,0.7,4.9c0.2,0.5,0.3,1,0.5,1.6c0.2,0.9,0.4,1.3,0.5,1.5l0.1,0c0.9-0.5,1.1-0.7,1.1-0.8
            c0,0,0-0.1,0-0.2c0-0.2,0-0.4,0.1-0.6c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.2c-0.1-0.4-0.2-0.8,0-1.1
            c0,0,0,0,0,0c0,0,0,0,0,0h0c0.1-0.2,0.3-0.4,1.1-0.7c0.2-0.1,0.3-0.1,0.4-0.2c0.1,0,0.2-0.1,0.3-0.1l0.7-0.5
            c0.6-0.5,1.1-0.6,1.6-0.9l0.3-0.1c0.1,0,0.1-0.1,0.2-0.3c0.2-0.2,0.4-0.6,0.9-0.8c0.4-0.2,0.8-0.1,1.2-0.1c0.3,0,0.5,0,0.5,0
            c0.2-0.2,0.5-0.3,0.8-0.2c0.3,0.1,0.5,0.3,0.5,0.6c0,0.1,0,0.2,0.1,0.4c0,0.3,0.1,0.6,0.2,0.7c0.1,0.1,0.2,0.2,0.3,0.3
            c0.2,0.2,0.5,0.4,0.7,1c0.2,0.5,0.3,1,0.2,1.4l0,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0.2-0.1,0.5-0.3,0.8-0.3
            c0.4,0,0.7,0.1,0.8,0.3c0.3,0.3,0.3,0.7,0.3,1c0,0,0,0.1,0,0.2c0,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.4,0.3,0.7
            c0,0.2,0.1,0.4,0.1,0.6l0,0c0.1,0.1,0.1,0.3,0.1,0.5C32.1,45.7,32,45.9,32,46z M34.1,47.2c0.1,0,0.1,0.1,0.2,0.1
            c0.2,0.1,0.4,0.3,0.6,0.4c-0.1-0.2-0.2-0.4-0.2-0.6c-0.2-0.1-0.4-0.1-0.6-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0
            C34.1,46.9,34.1,47.1,34.1,47.2z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M42.7,48c-0.1,0.1-0.1,0.4-0.2,0.4c-0.1,0.1-0.5,0.1-0.6,0.1c-0.5,0.2-0.4,0.5-0.6,0.9
            c-0.3,0.7-1.3,0.3-1.4,1.3c-0.6-0.1-0.8-0.5-1.3,0.1c-0.2,0.3-0.8,1.4,0,1.4c-0.1,0-0.1,0-0.2,0c0.1,0.3,0.4,0.3,0.5,0.5
            c0.1,0.1,0,0.3,0.1,0.5c0.1,0.5,0.1,0.6,0.6,0.6c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.2,0.2,0.3,0.2c0.3,0.1,0.6-0.2,0.8-0.2
            c0.1,0.3,0.4,0.3,0.6,0.2c0.2,1,2.5-0.8,2-1.5c0.4,0.2,0.6-0.9,0.7-1c0.2-0.3,0.5-0.4,0.7-0.6c0.4-0.5,0-0.5-0.3-0.8
            c-0.2-0.2-0.4-0.6-0.3-0.8c0-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0-0.2,0-0.3c0.2-0.3,0.8-0.4,0.3-0.8c-0.1-0.1-0.3,0-0.4-0.1
            c-0.2-0.1-0.1-0.2-0.3-0.3c-0.1-0.4-0.6-0.3-1-0.3l0,0C43.4,47,43,47.7,42.7,48z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M42.3,55c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1,0-0.2,0-0.3-0.1c0,0-0.1,0-0.1-0.1
            c-0.2,0.1-0.5,0.1-0.8,0.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1,0-0.2,0-0.3,0.1c-1.2,0.1-1.4-1-1.4-1.3c0-0.1,0-0.1,0-0.2
            c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.2-0.2-0.2-0.3c-0.4-0.9,0.4-2,0.4-2c0.5-0.7,1.1-0.7,1.5-0.6c0.3-0.4,0.7-0.5,0.9-0.6
            c0.1,0,0.2-0.1,0.3-0.1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.2-0.8,1-1.1c0.1,0,0.2-0.1,0.3-0.1c0-0.1,0.1-0.2,0.2-0.3
            c0,0,0.1-0.1,0.1-0.2c0.3-0.5,0.8-1.1,1.4-1.1l0,0l0.1,0c0.7,0,1.2,0.2,1.4,0.5c0,0,0.1,0.1,0.1,0.1c0.2,0,0.3,0.1,0.5,0.2
            c0.1,0.1,0.5,0.4,0.4,0.9c0,0.4-0.3,0.6-0.5,0.8c0,0.2-0.1,0.4-0.2,0.5c0,0.1-0.1,0.1-0.2,0.1c0,0,0.1,0,0.1,0.1
            c0.2,0.1,0.5,0.3,0.5,0.8c0.1,0.4-0.2,0.7-0.3,0.9c-0.1,0.2-0.3,0.3-0.4,0.4c-0.1,0-0.1,0.1-0.2,0.1l0,0.1c-0.1,0.4-0.3,0.7-0.5,1
            c0,0.2-0.1,0.4-0.2,0.6C44.1,54.2,43.1,55,42.3,55z M41.9,53.2c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.3,0.2
            c0.3-0.1,0.6-0.5,0.7-0.7c-0.1-0.3-0.1-0.6,0.1-0.8c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.1-0.2,0.1-0.3
            c0.1-0.3,0.3-0.4,0.5-0.6c-0.3-0.3-0.7-1.1-0.4-1.7c0.1-0.2,0.2-0.3,0.4-0.3c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.1-0.1-0.2-0.2
            c-0.1-0.1-0.2-0.2-0.3-0.3c0,0-0.1,0-0.1,0l-0.1,0c-0.1,0.1-0.3,0.3-0.4,0.4c0,0.1-0.1,0.1-0.1,0.2c0,0.2-0.1,0.4-0.4,0.6
            c-0.3,0.2-0.6,0.2-0.8,0.2l-0.1,0c0,0,0,0,0,0.1c0,0.1-0.1,0.3-0.2,0.5c-0.3,0.6-0.8,0.8-1.1,0.9c-0.1,0-0.2,0.1-0.2,0.1
            c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.5-0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.2,0.3
            c0.1,0.1,0.3,0.2,0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0.1,0.5,0.1,0.7c0.2,0,0.4-0.1,0.6,0c0.2,0,0.3,0.1,0.4,0.2
            c0,0,0,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1c0.3-0.1,0.5,0,0.7,0.2C41.8,53.2,41.9,53.2,41.9,53.2z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M47.5,55.5c-1.2-0.2-2.6,0.4-3.8,0.5c-1.1,0.1-2.1-0.2-3.2-0.2c-0.8,0-1.6,0.3-2.3,0.3
            c-0.3,0-0.6-0.2-1-0.1c-0.3,0-0.6,0.2-0.9,0.3c0.3-0.5,0.4-1.3,0.1-1.9c-0.2-0.4-0.4-0.3-0.8-0.5c-0.2-0.2-0.2-0.5-0.6-0.6
            c0.4-0.6-0.6-0.8-1-1c-0.5-0.3-1.1-0.7-1.6-1c-0.3-0.2-0.5-0.3-0.8-0.5c-0.3-0.2-0.3-0.3-0.6-0.6c-0.5-0.5-1.1-0.5-1.7-0.7
            c-0.3-0.1-0.5-0.3-0.7,0.1c-0.2,0.3-0.1,0.7,0.1,1c0.2,0.3,0.5,0.4,0.8,0.7c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.2,0.4,0.3,0.7,0.5
            c0.3,0.2,0.3,0.5,0.5,0.8c0.3,0.5,0.9,0.8,1.2,1.3c0.8,1.1,2.1,2.3,3.5,2.1c-0.4,0.8,2,1,2.5,1c1.2,0,2.4,0.1,3.5,0
            c1.1-0.1,2.2-0.4,3.3-0.6c0.4-0.1,0.8-0.1,1.2-0.2c0.3-0.1,1-0.2,1.2-0.5C47.9,56,48,55.6,47.5,55.5z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M40.8,58.3c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.1,0c0,0-2.5,0-3.1-1c-1.8-0.2-3.1-1.8-3.5-2.4
            c-0.1-0.2-0.3-0.3-0.5-0.5c-0.3-0.2-0.5-0.5-0.8-0.8c-0.1-0.2-0.2-0.3-0.2-0.5c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.1
            c-0.2-0.1-0.4-0.2-0.6-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.5-0.3-0.8-0.7
            c-0.3-0.5-0.4-1.3,0-1.8c0.4-0.5,0.8-0.7,1.4-0.4c0,0,0.1,0,0.1,0c0.1,0,0.3,0.1,0.4,0.1c0.5,0.1,1.2,0.2,1.7,0.8
            c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.1,0.2,0.2l0.2,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.4,0.3,1,0.6,1.5,1c0.1,0.1,0.2,0.1,0.3,0.1
            c0.4,0.2,1,0.4,1.2,1c0,0.1,0,0.1,0,0.2c0.1,0.1,0.2,0.3,0.2,0.4c0,0,0,0,0.1,0.1c0.3,0.1,0.6,0.2,0.8,0.7
            c0.2,0.4,0.3,0.8,0.3,1.2c0.2,0,0.4,0,0.6,0.1c0.1,0,0.2,0,0.3,0c0.3,0,0.7-0.1,1.1-0.1c0.4-0.1,0.8-0.1,1.1-0.1
            c0.5,0,1.1,0,1.6,0.1c0.6,0.1,1.1,0.1,1.6,0.1c0.4,0,0.8-0.1,1.2-0.2c0.9-0.2,1.9-0.4,2.9-0.3l0,0c0.4,0.1,0.8,0.4,0.9,0.7
            c0.1,0.4,0,0.9-0.3,1.3c-0.4,0.4-1,0.5-1.5,0.7l-0.1,0c-0.3,0.1-0.6,0.1-0.9,0.2l-0.4,0.1c-0.3,0.1-0.7,0.1-1,0.2
            c-0.8,0.2-1.5,0.3-2.3,0.4C41.6,58.3,41.2,58.3,40.8,58.3z M39.1,56.8c0.2,0,0.4,0,0.6,0c0.8,0,1.5,0,2.3,0c0.1,0,0.3,0,0.4,0
            c-0.2,0-0.3,0-0.5-0.1c-0.5-0.1-0.9-0.1-1.3-0.1c-0.3,0-0.6,0.1-1,0.1C39.4,56.8,39.2,56.8,39.1,56.8z M29.4,50.3
            c0,0,0.1,0.1,0.2,0.1c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.2,0.4,0.4,0.5,0.6c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2
            c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.3,0.5,0.6,0.5,0.8c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.3,0.3,0.5,0.5c0.2,0.2,0.5,0.4,0.7,0.7
            c0.6,0.9,1.7,1.8,2.6,1.8c0,0,0,0,0,0c0.1-0.4,0.2-0.8,0.1-1c-0.2-0.1-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.3-0.4-0.5
            c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.1-0.1-0.3-0.1-0.4c-0.1,0-0.2-0.1-0.2-0.1c-0.2-0.1-0.3-0.1-0.4-0.2c-0.6-0.4-1.1-0.7-1.6-1.1
            c-0.1-0.1-0.3-0.2-0.4-0.3l-0.3-0.2c-0.3-0.2-0.4-0.3-0.6-0.5c0-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.4-0.3-0.9-0.4
            C29.6,50.3,29.5,50.3,29.4,50.3z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M45.1,39.4c0,0.4,0.1,1-0.1,1.4c-0.1,0.2-0.2,0.1-0.3,0.3c-0.2,0.2-0.1,0.4-0.1,0.7
            c0,0.6,0.2,0.8,0.7,1.1c0.4,0.3,0.9,0.7,1.4,0.4c0.4-0.2,0.8-0.7,0.4-1.1c-0.1-0.1-0.5-0.3-0.6-0.4c0-0.2,0.4-0.6,0.5-0.8
            c0.2-0.5-0.1-1.6-0.6-1.8C46.1,39.1,45.1,38.9,45.1,39.4z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M46.3,44.2c-0.5,0-0.9-0.3-1.2-0.5c-0.1,0-0.1-0.1-0.2-0.1c-0.7-0.4-1-0.8-1-1.7l0-0.1
            c0-0.2-0.1-0.6,0.2-1c0.1-0.2,0.2-0.3,0.3-0.3c0-0.2,0-0.5,0-0.7l0-0.2c0,0,0,0,0,0c0-0.4,0.2-0.7,0.5-0.9c0.6-0.4,1.5-0.1,1.9,0
            c0.9,0.5,1.2,1.9,1,2.7c0,0.1-0.1,0.2-0.2,0.4c0.1,0,0.1,0.1,0.2,0.2c0.2,0.3,0.3,0.7,0.2,1.1c-0.1,0.5-0.5,0.9-0.9,1.2
            C46.7,44.1,46.5,44.2,46.3,44.2z M45.3,41.6C45.3,41.6,45.3,41.6,45.3,41.6l0,0.2c0,0.3,0,0.3,0,0.3c0,0,0,0.1,0.3,0.2
            c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.4,0.2c0,0,0,0,0,0c-0.2-0.1-0.5-0.3-0.6-0.7c-0.1-0.4,0.2-0.8,0.4-1.1
            c0-0.1,0.1-0.1,0.1-0.2c0-0.1-0.1-0.7-0.3-0.8c0,0-0.1,0-0.2,0c0,0.4,0,0.9-0.2,1.3C45.5,41.4,45.4,41.5,45.3,41.6z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M49.9,16.4c0.3,0.4,0.6,1.3,1,1.6c0.8,0.6,0.8-0.4,0.7-1c-0.1-0.6-0.3-1.3-0.4-1.9
            c-0.1-0.6-0.5-0.8-0.9-1.2c-0.4-0.4-1-1.1-1.5-1.4c-0.8-0.4-0.6,0.4-0.4,0.9C48.8,14.4,49.3,15.5,49.9,16.4z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M51.3,18.9c-0.2,0-0.5-0.1-0.8-0.3c-0.4-0.3-0.7-0.9-0.9-1.3c-0.1-0.2-0.2-0.3-0.2-0.4
            c-0.5-0.7-0.9-1.5-1.3-2.3c-0.1-0.3-0.2-0.5-0.4-0.8c-0.1-0.2-0.6-1.2,0-1.8c0.2-0.2,0.6-0.5,1.4-0.1c0.5,0.3,0.9,0.7,1.3,1.1
            c0.1,0.2,0.3,0.3,0.4,0.4l0.2,0.2c0.4,0.3,0.8,0.7,0.9,1.4c0.1,0.4,0.2,0.8,0.3,1.2l0.1,0.7c0,0.1,0.3,1.4-0.5,1.9
            C51.8,18.8,51.6,18.9,51.3,18.9z M49.7,14.5c0.3,0.5,0.5,1,0.8,1.5c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c-0.1-0.3-0.1-0.5-0.2-0.8
            c-0.1-0.2-0.2-0.4-0.5-0.6L49.7,14.5C49.7,14.5,49.7,14.5,49.7,14.5z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M53,24c0.1-0.6,0.1-0.9-0.1-1.4c-0.1-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.4-0.3-0.4-0.5
            c-0.1-0.4,0.1-0.2,0.4-0.4c0.3-0.2,0.3-0.3,0.3-0.6c0.1-0.5,0.3-1.3-0.5-1.4c-0.2,0-0.4,0.1-0.6,0.1c-0.3,0-0.5-0.4-0.8-0.4
            c-0.8-0.1-0.5,0.6-0.4,1.1c0.2,0.9,0.4,1.9,0.6,2.8c0.1,0.4,0.1,0.8,0.1,1.2c0,0.2,0,0.4-0.1,0.6c-0.1,0.5-0.5,0.5-0.6,0.9
            c-0.2,0.6,0.2,0.5-0.4,0.8c-0.6,0.3-0.8,0.4-1.2,0.9c-0.3,0.4-0.5,0.8-0.7,1.3c-0.3,0.6,0.1,0.8,0.4,1.4c0.3,0.4,0.5,1.2,1.1,0.7
            c0.5-0.4,0.3-1,0.4-1.5c0.1-0.4,0.8-0.5,1-0.9c0.2-0.2,0.2-0.6,0.4-0.9c0.2-0.2,0.5-0.3,0.7-0.5c0.3-0.3,0.7-0.6,0.9-1
            C53.5,25.2,53.1,24.5,53,24z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M49.5,31.4c-0.1,0-0.1,0-0.2,0c-0.6-0.1-0.9-0.7-1-1c0-0.1-0.1-0.1-0.1-0.2
            c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.4-0.6-0.9-0.3-1.8c0.2-0.5,0.5-1,0.8-1.4c0.4-0.6,0.8-0.8,1.3-1.1l0.1,0c0-0.1,0-0.3,0.1-0.4
            c0.1-0.4,0.3-0.6,0.5-0.7c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.4c0-0.4,0-0.7-0.1-1c-0.1-0.4-0.2-0.9-0.3-1.3
            c-0.1-0.5-0.2-1-0.3-1.5l-0.1-0.2c-0.1-0.4-0.3-1,0.1-1.5c0.2-0.2,0.5-0.4,1.1-0.4c0.3,0,0.6,0.2,0.7,0.3c0,0,0.1,0,0.1,0.1
            c0.2-0.1,0.4-0.1,0.6-0.1c0.5,0.1,0.8,0.4,1,0.6c0.4,0.6,0.2,1.4,0.1,1.7c-0.1,0.3-0.2,0.6-0.4,0.8c0,0,0,0,0,0
            c0.2,0.3,0.3,0.6,0.4,0.8c0.2,0.6,0.2,1,0.2,1.7c0,0.1,0,0.1,0.1,0.2c0.2,0.6,0.4,1.2,0.1,1.9c-0.2,0.4-0.5,0.7-0.7,0.9
            c-0.1,0.1-0.2,0.2-0.2,0.2c-0.2,0.2-0.4,0.3-0.6,0.4c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.2
            c-0.1,0.2-0.1,0.4-0.3,0.6c-0.2,0.3-0.5,0.5-0.8,0.6c-0.1,0-0.2,0.1-0.2,0.1c0,0,0,0.1,0,0.3c0,0.4-0.1,1-0.6,1.5
            C50,31.3,49.7,31.4,49.5,31.4z M51.9,22.3c0,0,0,0.1,0,0.1c0.1,0.5,0.1,0.9,0.1,1.4c0,0.3,0,0.5-0.1,0.8c-0.1,0.4-0.3,0.6-0.5,0.8
            c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.1c0.1,0.7-0.3,1-0.7,1.2l-0.2,0.1c-0.4,0.2-0.5,0.3-0.8,0.6c-0.3,0.4-0.5,0.7-0.7,1.1
            c0,0.1,0,0.1,0,0.1c0,0,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0.1c0,0,0,0,0,0c0-0.2,0-0.4,0.1-0.6
            c0.1-0.6,0.6-0.8,0.9-1c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0-0.1,0.1-0.2c0.1-0.2,0.2-0.5,0.4-0.8c0.1-0.2,0.3-0.3,0.5-0.4
            c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.4-0.3,0.4-0.4c0.1-0.1-0.1-0.6-0.2-0.8c-0.1-0.2-0.1-0.3-0.1-0.4
            c0-0.1,0-0.2,0-0.3c0.1-0.5,0-0.7-0.1-1.1C52.1,22.5,52,22.4,51.9,22.3C52,22.3,51.9,22.3,51.9,22.3z M51.4,19.8
            c0.1,0.3,0.1,0.6,0.2,0.9c0.2-0.2,0.4-0.2,0.5-0.3c0,0,0,0,0,0c0,0,0,0,0-0.1c0.1-0.2,0.1-0.4,0.1-0.5c-0.1,0-0.3,0.1-0.6,0
            C51.6,19.8,51.5,19.8,51.4,19.8z"
          />
        </g>
      </g>
    </svg>
  )
}

export const MapsCentralAmerica = () => {
  return (
    <svg
      className="w-full h-full category__svg mapscentralamerica"
      version="1.1"
      viewBox="0 0 74.1 57.3"
      x="0px"
      y="0px"
    >
      <g>
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M72.1,50.2l0.6-0.7c-1.2-0.8-2.4-1.8-3.4-2.8l-2.2-0.9l-2.7-0.1l0.1-0.5l-2.7-0.3l-1.7,1.4
          c-1.8,0.4-3.5,1.1-5,2.2l-2.3,0.4l-1.3-1.2l-0.5,0.4l-2-0.2l0.1-1c-3.5-3.7-5.8-5.3-7.8-9.3l-0.2-1.3l1-1.2l-0.3-1.4l0.4-2.1
          l0.7-0.5c-0.5-2.6-0.5-5.2,0.2-7.7l1.1-1.7L44,19.9l0.2-1.1l0.4-0.5L43.4,17c-1.6-0.8-3.1-1.7-4.6-2.9c-1-0.1-2-0.4-3-0.9l-1,0.5
          l-1.2,0.1c-0.9-0.3-1.8-0.5-2.7-0.7l-0.6,0.6c-1.9,0.8-4,0.9-6,0.3l-0.7,0.2l-0.4-0.4l-1.4,0.1l-1.2,0.9c-0.7-0.3-1.3-0.5-2-0.9
          l-0.4,0.7l-1.5-0.9l0.9-1.7l0.9-0.1l0.9-1.3l0.6-2.5l-0.3-0.5l0.5-1.8L19.9,5c0.6-1.1,0.9-2.3,0.9-3.5h-0.9V0.7l-0.9,0.1l-1.7,3
          l-0.9-0.6l-0.6,0.2l0.1,0.7c-3-0.1-6-0.1-9-0.1V7H4.6c1,1.2,2.2,2.1,3.6,2.8l0.5,1.1l0.8,0.3l-0.1,1.7H3.1L0.8,17L1.5,18l-0.6,1.2
          l-0.1,1.4c1.4,1.3,3,2.3,4.8,3l3,0.1l2.5,0.9l1.4,1.1l2.7,0.3c2,1.3,4.2,1.8,6.6,1.6l0.5-1.2l1.4,0.4l0.9,1.5l-0.4,0.4L23.4,28
          l-0.5,0.8c1.4,1.4,2.8,2.6,4.4,3.8c1.2,2.1,2.9,3.9,5,5.2l-1.1,0.9l1.4,0.7l-0.7,1.5v1.5l0.7,1l1.5,0.4l1.1,1.4l0.9-1.2l-0.3-1.4
          l1.3,0.9l0.3,1.4c1.8,0.5,3.5,1.5,4.9,2.7l0.1,1.1l-0.6,0.9l1,1l2.6,1.1l0.6,0.7l0.1-1.1h2.1c1,0.6,2,0.9,3.2,0.9l0.9,2l1.6,0.3
          l0.7-0.8l0.9,2.9l2.2-0.2l0.7-0.7l1.3-0.7l-2.3-2.7l0.5-1l1.1-0.1l2-1.2l1-1.7l2.1-0.3l2.4,1.4l0.9,1.6l1.2,0.4l-1.2,1.3l1,2.6
          l1.6,1.4l0.7-2.4l1.6,0.4l0.9-1.4L72.1,50.2z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M55.6,57.3c-0.3,0-0.6-0.2-0.7-0.5l-0.5-1.6c-0.1,0.1-0.3,0.1-0.4,0.1l-1.6-0.3
          c-0.2,0-0.4-0.2-0.5-0.4l-0.7-1.5c-1-0.1-2-0.3-2.9-0.9h-1.2l-0.1,0.4c0,0.3-0.3,0.5-0.5,0.6c-0.3,0.1-0.6,0-0.8-0.3l-0.5-0.6
          l-2.4-1c-0.1,0-0.2-0.1-0.2-0.2l-1-1c-0.3-0.3-0.3-0.6-0.1-0.9l0.4-0.6L41.7,48c-1.3-1.1-2.8-1.9-4.4-2.4c-0.3-0.1-0.5-0.3-0.5-0.6
          l-0.1-0.4l-0.8,1c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3l-0.9-1.2l-1.3-0.4c-0.2,0-0.3-0.2-0.4-0.3l-0.7-1
          c-0.1-0.1-0.1-0.3-0.1-0.4v-1.5c0-0.1,0-0.2,0.1-0.3l0.4-0.9l-0.8-0.4c-0.2-0.1-0.4-0.3-0.4-0.6c0-0.3,0.1-0.5,0.3-0.7l0.3-0.2
          c-1.8-1.3-3.2-2.9-4.3-4.7c-1.6-1.1-3-2.4-4.4-3.8c-0.2-0.2-0.3-0.6-0.1-0.9l0.5-0.8c0-0.1,0.1-0.1,0.2-0.2l-0.3-0.1l-0.2,0.5
          c-0.1,0.3-0.3,0.4-0.6,0.4c-2.5,0.2-4.8-0.3-6.9-1.6l-2.5-0.3c-0.1,0-0.3-0.1-0.4-0.2l-1.3-1l-2.2-0.8l-2.9-0.1
          c-0.1,0-0.2,0-0.3-0.1c-1.9-0.7-3.6-1.8-5.1-3.2C0.1,21,0,20.7,0,20.5l0.1-1.4c0-0.1,0-0.2,0.1-0.3L0.6,18l-0.4-0.6
          c-0.2-0.2-0.2-0.5,0-0.8l2.3-4.1c0.1-0.2,0.4-0.4,0.7-0.4h5.5l0-0.5l-0.3-0.1c-0.2-0.1-0.3-0.2-0.4-0.4l-0.4-0.9
          C6.3,9.6,5.1,8.6,4,7.5C3.8,7.3,3.8,6.9,3.9,6.7C4,6.4,4.3,6.2,4.6,6.2H6V4.2c0-0.4,0.3-0.7,0.8-0.7c2.7,0,5.4,0,8.2,0.1
          c0-0.3,0.2-0.6,0.5-0.7L16,2.6c0.2-0.1,0.5-0.1,0.7,0.1l0.2,0.1l1.3-2.3c0.1-0.2,0.3-0.4,0.6-0.4L19.7,0c0.2,0,0.4,0.1,0.6,0.2
          c0.1,0.1,0.2,0.3,0.2,0.5h0.2c0.4,0,0.7,0.3,0.7,0.7c0.1,1.3-0.2,2.5-0.8,3.6l0.2,0.4C21,5.6,21.1,5.8,21,6l-0.4,1.5l0.1,0.2
          c0.1,0.2,0.1,0.4,0.1,0.5l-0.6,2.5c0,0.1-0.1,0.2-0.1,0.3l-0.9,1.3c-0.1,0.2-0.3,0.3-0.5,0.3l-0.5,0.1l-0.4,0.8l0.2,0.1l0.1-0.1
          c0.2-0.3,0.7-0.5,1-0.3c0.4,0.2,0.8,0.4,1.2,0.6c0.1,0,0.2,0.1,0.2,0.1l0.8-0.7c0.1-0.1,0.3-0.2,0.4-0.2l1.4-0.1
          c0.2,0,0.4,0.1,0.6,0.2l0.1,0.1l0.3-0.1c0.1,0,0.3,0,0.4,0c1.8,0.6,3.6,0.5,5.3-0.2l0.5-0.5c0.2-0.2,0.4-0.2,0.6-0.2
          c0.9,0.1,1.8,0.3,2.7,0.6l0.9-0.1l0.9-0.4c0.2-0.1,0.4-0.1,0.6,0c0.9,0.4,1.8,0.7,2.8,0.8c0.1,0,0.3,0.1,0.4,0.2
          c1.4,1.1,2.8,2,4.4,2.8c0.1,0,0.2,0.1,0.2,0.2l1.3,1.4c0.3,0.3,0.3,0.7,0,1l-0.3,0.4l-0.1,0.7l0.3,1.6c0,0.2,0,0.4-0.1,0.6l-1,1.6
          c-0.6,2.4-0.7,4.8-0.2,7.3c0.1,0.3-0.1,0.6-0.3,0.8L43,32l-0.3,1.7l0.3,1.3c0,0.2,0,0.4-0.2,0.6l-0.8,1l0.1,0.8
          c1.4,2.7,2.9,4.2,5,6.3c0.8,0.8,1.7,1.7,2.6,2.7c0.1,0.2,0.2,0.4,0.2,0.6l0,0.3l0.9,0.1l0.3-0.2c0.3-0.3,0.7-0.2,1,0l1,1l1.8-0.3
          c1.5-1.1,3.1-1.8,4.9-2.2l1.5-1.3c0.2-0.1,0.4-0.2,0.6-0.2l2.7,0.3c0.2,0,0.4,0.1,0.5,0.3c0,0.1,0.1,0.1,0.1,0.2l1.8,0.1
          c0.1,0,0.2,0,0.2,0.1l2.2,0.9c0.1,0,0.2,0.1,0.2,0.2c1,1,2.1,1.9,3.3,2.7c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2,0,0.4-0.2,0.6L73,50.4
          l1,2.4c0.1,0.2,0.1,0.5-0.1,0.7L73,54.9c-0.2,0.3-0.5,0.4-0.8,0.3L71.3,55l-0.5,1.7c-0.1,0.2-0.3,0.4-0.5,0.5
          c-0.2,0.1-0.5,0-0.7-0.2l-1.6-1.4c-0.1-0.1-0.2-0.2-0.2-0.3l-1-2.6c-0.1-0.3,0-0.6,0.1-0.8l0.4-0.4l-0.1,0
          c-0.2-0.1-0.4-0.2-0.4-0.4L66,49.8l-2-1.1l-1.5,0.2l-0.8,1.4c-0.1,0.1-0.1,0.2-0.2,0.2l-2,1.2c-0.1,0.1-0.2,0.1-0.3,0.1l-0.7,0.1
          l-0.1,0.2l2,2.3c0.2,0.2,0.2,0.4,0.2,0.6c0,0.2-0.2,0.4-0.4,0.5L59,56.2l-0.6,0.6c-0.1,0.1-0.3,0.2-0.5,0.2L55.6,57.3
          C55.6,57.3,55.6,57.3,55.6,57.3z M54.7,52.9c0,0,0.1,0,0.1,0c0.3,0.1,0.5,0.3,0.6,0.5l0.7,2.3l1.4-0.1l0.5-0.5
          c0.1-0.1,0.1-0.1,0.2-0.1l0.4-0.2L57,52.8c-0.2-0.2-0.2-0.6-0.1-0.8l0.5-1c0.1-0.2,0.3-0.4,0.6-0.4l0.9-0.1l1.7-1l0.9-1.5
          c0.1-0.2,0.3-0.3,0.5-0.4l2.1-0.3c0.2,0,0.3,0,0.5,0.1l2.4,1.4c0.1,0.1,0.2,0.2,0.3,0.3l0.7,1.3l0.9,0.3c0.2,0.1,0.4,0.3,0.5,0.5
          c0.1,0.2,0,0.5-0.2,0.7l-0.9,1l0.8,2l0.5,0.5l0.3-1.2c0.1-0.4,0.5-0.6,0.9-0.5l1.1,0.2l0.4-0.7l-1.1-2.5c-0.1-0.3-0.1-0.6,0.1-0.8
          l0.1-0.1c-1-0.7-1.9-1.5-2.7-2.3l-1.9-0.8l-2.6-0.1c-0.2,0-0.4-0.1-0.6-0.3c-0.1-0.1-0.1-0.2-0.1-0.3l-1.6-0.2l-1.4,1.2
          c-0.1,0.1-0.2,0.1-0.3,0.2c-1.7,0.4-3.3,1.1-4.7,2.1c-0.1,0.1-0.2,0.1-0.3,0.1L53,49.7c-0.2,0-0.5,0-0.6-0.2l-0.8-0.8
          c-0.2,0.1-0.4,0.2-0.6,0.2l-2-0.2c-0.4,0-0.7-0.4-0.7-0.8l0-0.7c-0.9-0.9-1.7-1.7-2.4-2.4C44,42.6,42.3,41,40.8,38
          c0-0.1-0.1-0.1-0.1-0.2l-0.2-1.3c0-0.2,0-0.4,0.2-0.6l0.8-1l-0.2-1.1c0-0.1,0-0.2,0-0.3l0.4-2.1c0-0.2,0.1-0.4,0.3-0.5l0.3-0.2
          c-0.5-2.6-0.4-5.1,0.3-7.6c0-0.1,0.1-0.1,0.1-0.2l0.9-1.4l-0.3-1.4c0-0.1,0-0.2,0-0.3l0.2-1.1c0-0.1,0.1-0.2,0.2-0.3l-0.7-0.8
          c-1.6-0.8-3-1.7-4.4-2.8c-0.9-0.1-1.8-0.4-2.6-0.8l-0.7,0.4c-0.1,0-0.2,0.1-0.2,0.1l-1.2,0.1c-0.1,0-0.2,0-0.3,0
          c-0.7-0.3-1.4-0.4-2.2-0.6l-0.3,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-2,0.9-4.2,1-6.3,0.4L23.8,15c-0.3,0.1-0.5,0-0.7-0.2l-0.2-0.2
          l-0.8,0l-1,0.8c-0.2,0.2-0.5,0.2-0.8,0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.5-0.2-0.7-0.3l-0.1,0.1c-0.2,0.4-0.7,0.5-1,0.3
          l-1.5-0.9c-0.4-0.2-0.5-0.7-0.3-1l0.9-1.7c0.1-0.2,0.3-0.3,0.5-0.4l0.6-0.1l0.7-0.9l0.5-2.1L19.1,8C19,7.8,19,7.6,19.1,7.4l0.4-1.5
          l-0.3-0.5c-0.1-0.2-0.1-0.5,0-0.7c0.5-0.8,0.7-1.6,0.8-2.4h-0.2c-0.3,0-0.5-0.2-0.7-0.4l-1.4,2.4c-0.1,0.2-0.3,0.3-0.5,0.4
          c-0.2,0-0.4,0-0.6-0.1l-0.2-0.1c0,0.1-0.1,0.3-0.2,0.4C16.1,4.9,16,5,15.7,5C13,4.9,10.2,4.9,7.5,4.9V7c0,0.4-0.3,0.8-0.8,0.8H6.4
          c0.7,0.5,1.4,1,2.1,1.4c0.1,0.1,0.3,0.2,0.3,0.3l0.4,0.8l0.5,0.2c0.3,0.1,0.5,0.4,0.5,0.8L10.1,13c0,0.4-0.4,0.7-0.7,0.7H3.6
          L1.7,17l0.4,0.6c0.2,0.2,0.2,0.5,0.1,0.8l-0.5,1l-0.1,0.9c1.3,1.1,2.7,2,4.2,2.6l2.9,0.1c0.1,0,0.2,0,0.2,0l2.5,0.9
          c0.1,0,0.2,0.1,0.2,0.1l1.2,1l2.5,0.3c0.1,0,0.2,0.1,0.3,0.1c1.7,1.1,3.7,1.6,5.7,1.5l0.3-0.7c0.2-0.3,0.5-0.5,0.9-0.4l1.4,0.4
          c0.2,0.1,0.3,0.2,0.4,0.3l0.9,1.5c0.2,0.3,0.1,0.7-0.1,0.9l-0.4,0.4c0,0-0.1,0.1-0.2,0.1c1,0.9,2.1,1.8,3.2,2.6
          c0.1,0.1,0.2,0.1,0.2,0.2c1.1,2,2.7,3.7,4.7,4.9c0.2,0.1,0.3,0.3,0.4,0.6c0,0.2-0.1,0.5-0.3,0.6l-0.2,0.2l0.4,0.2
          c0.3,0.2,0.5,0.6,0.3,1L32.7,41v1.1l0.4,0.6l1.2,0.4c0.2,0,0.3,0.1,0.4,0.3l0.5,0.6l0.1-0.2l-0.2-1.1c-0.1-0.3,0.1-0.6,0.3-0.8
          c0.3-0.2,0.6-0.2,0.8,0l1.3,0.9c0.2,0.1,0.3,0.3,0.3,0.5l0.2,1c1.8,0.6,3.4,1.5,4.8,2.8c0.1,0.1,0.2,0.3,0.2,0.5l0.1,1.1
          c0,0.2,0,0.4-0.1,0.5l-0.2,0.4l0.5,0.5l2.3,0.9c0.1-0.2,0.4-0.3,0.6-0.3h2.1c0.1,0,0.3,0,0.4,0.1c0.9,0.5,1.8,0.8,2.8,0.8
          c0.3,0,0.6,0.2,0.7,0.4l0.8,1.6l0.8,0.1l0.4-0.5C54.3,53,54.5,52.9,54.7,52.9z"
        />
      </g>
    </svg>
  )
}

export const QuotesAndTestimonials = () => {
  return (
    <svg
      className="w-full h-full category__svg quotesandtestimonials"
      version="1.1"
      viewBox="0 0 44.5 37.1"
      x="0px"
      y="0px"
    >
      <g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M18.4,0.7H0.7v17.8h10c0,0.1,0,0.2,0,0.4c0,5.4-4.4,9.8-9.8,9.8c-0.1,0-0.2,0-0.3,0v7.6
            c0.1,0,0.2,0,0.3,0c9.6,0,17.4-7.8,17.4-17.4c0-0.1,0-0.2,0-0.4h0V0.7z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M1,37.1c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8-0.3-0.8-0.8v-7.6c0-0.2,0.1-0.4,0.2-0.5
            C0.4,28,0.6,28,0.8,28c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c4.9,0,8.9-3.8,9.1-8.7H0.8C0.3,19.3,0,19,0,18.6V0.8C0,0.3,0.3,0,0.8,0h17.7
            c0.4,0,0.8,0.3,0.8,0.8v17.8c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.2C19.2,29,11,37.1,1,37.1z M1.5,29.5v6.1c9-0.3,16.2-7.7,16.2-16.7
            c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2V1.5H1.5v16.3h9.3c0.4,0,0.7,0.3,0.7,0.7c0,0.2,0,0.3,0,0.4
            C11.6,24.6,7.1,29.2,1.5,29.5z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M43.7,0.7H26v17.8h10c0,0.1,0,0.2,0,0.4c0,5.4-4.4,9.8-9.8,9.8c-0.1,0-0.2,0-0.3,0v7.6
            c0.1,0,0.2,0,0.3,0c9.6,0,17.4-7.8,17.4-17.4c0-0.1,0-0.2,0-0.4h0V0.7z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M26.3,37.1c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8-0.3-0.8-0.8v-7.6c0-0.2,0.1-0.4,0.2-0.5
            c0.1-0.1,0.3-0.2,0.5-0.2c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c4.9,0,8.9-3.8,9.1-8.7H26c-0.4,0-0.8-0.3-0.8-0.8V0.8
            C25.3,0.3,25.6,0,26,0h17.7c0.4,0,0.8,0.3,0.8,0.8v17.8c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.2C44.5,29,36.3,37.1,26.3,37.1z
            M26.8,29.5v6.1C35.8,35.3,43,28,43,18.9c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2V1.5H26.8v16.3h9.3
            c0.4,0,0.7,0.3,0.7,0.7c0,0.2,0,0.3,0,0.4C36.9,24.6,32.4,29.2,26.8,29.5z"
          />
        </g>
      </g>
    </svg>
  )
}

export const Presentation = () => {
  return (
    <svg className="w-full h-full category__svg presentation" viewBox="0 0 34.4 34.4" x="0px" y="0px">
      <g>
        <g>
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M9,34.4c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.4-0.4-0.3-0.6l2.7-7.1c0.1-0.3,0.4-0.4,0.6-0.3
            c0.3,0.1,0.4,0.4,0.3,0.6l-2.7,7.1C9.4,34.3,9.2,34.4,9,34.4z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M25.4,34.4c-0.2,0-0.4-0.1-0.5-0.3L22.3,27c-0.1-0.3,0-0.5,0.3-0.6c0.3-0.1,0.5,0,0.6,0.3l2.7,7.1
            c0.1,0.3,0,0.5-0.3,0.6C25.6,34.4,25.5,34.4,25.4,34.4z"
          />
        </g>
        <g>
          <g>
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M32.3,27.3H2.1c-0.3,0-0.5-0.2-0.5-0.5V7.2c0-0.3,0.2-0.5,0.5-0.5h30.1c0.3,0,0.5,0.2,0.5,0.5v19.6
              C32.8,27.1,32.6,27.3,32.3,27.3z M2.6,26.3h29.1V7.7H2.6V26.3z"
            />
            <g>
              <rect
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                height="3.7"
                width="33.4"
                x="0.5"
                y="3.5"
              />
              <path
                className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
                d="M33.9,7.7H0.5C0.2,7.7,0,7.5,0,7.2V3.5C0,3.3,0.2,3,0.5,3h33.4c0.3,0,0.5,0.2,0.5,0.5v3.7
                C34.4,7.5,34.2,7.7,33.9,7.7z M1,6.7h32.4V4H1V6.7z"
              />
            </g>
            <g>
              <path
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                d="M11.7,24.8c-0.3,0-0.5-0.2-0.5-0.5v-8.6H8.8v8.6c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5v-9.1
                c0-0.3,0.2-0.5,0.5-0.5h3.4c0.3,0,0.5,0.2,0.5,0.5v9.1C12.2,24.5,12,24.8,11.7,24.8z"
              />
              <path
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                d="M26.2,24.8c-0.3,0-0.5-0.2-0.5-0.5V13.4h-2.4v10.9c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5V12.9
                c0-0.3,0.2-0.5,0.5-0.5h3.4c0.3,0,0.5,0.2,0.5,0.5v11.4C26.7,24.5,26.4,24.8,26.2,24.8z"
              />
              <path
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                d="M18.9,24.8c-0.3,0-0.5-0.2-0.5-0.5V10.5H16v13.8c0,0.3-0.2,0.5-0.5,0.5S15,24.5,15,24.3V10
                c0-0.3,0.2-0.5,0.5-0.5h3.4c0.3,0,0.5,0.2,0.5,0.5v14.3C19.4,24.5,19.2,24.8,18.9,24.8z"
              />
            </g>
            <rect
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              height="18.6"
              width="1.9"
              x="29.8"
              y="7.7"
            />
          </g>
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M17.2,3.7c-0.3,0-0.5-0.2-0.5-0.5V0.5c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v2.8C17.7,3.5,17.5,3.7,17.2,3.7
            z"
          />
        </g>
      </g>
    </svg>
  )
}

export const Photo = () => {
  return (
    <svg className="w-full h-full category__svg photo" version="1.1" viewBox="0 0 34.8 27.5" x="0px" y="0px">
      <g>
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M30.3,27H4.5c-2.2,0-4-1.8-4-4V6.2c0-2.2,1.8-4,4-4h25.8c2.2,0,4,1.8,4,4V23
          C34.3,25.2,32.5,27,30.3,27z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M30.3,27.5H4.5C2,27.5,0,25.4,0,23V6.2c0-2.5,2-4.5,4.5-4.5h25.8c2.5,0,4.5,2,4.5,4.5V23
          C34.8,25.4,32.8,27.5,30.3,27.5z M4.5,2.7C2.6,2.7,1,4.3,1,6.2V23c0,1.9,1.6,3.5,3.5,3.5h25.8c1.9,0,3.5-1.6,3.5-3.5V6.2
          c0-1.9-1.6-3.5-3.5-3.5H4.5z"
        />
      </g>
      <g>
        <circle
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          cx="17.4"
          cy="15.6"
          r="7"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M17.4,23.1c-4.1,0-7.5-3.4-7.5-7.5s3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5S21.5,23.1,17.4,23.1z
          M17.4,9.1c-3.6,0-6.5,2.9-6.5,6.5s2.9,6.5,6.5,6.5s6.5-2.9,6.5-6.5S21,9.1,17.4,9.1z"
        />
      </g>
      <g>
        <rect
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          height="2.1"
          width="6"
          x="3"
          y="5.5"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M9,8.1H3c-0.3,0-0.5-0.2-0.5-0.5V5.5C2.5,5.2,2.7,5,3,5h6c0.3,0,0.5,0.2,0.5,0.5v2.1
          C9.5,7.9,9.2,8.1,9,8.1z M3.5,7.1h5V6h-5V7.1z"
        />
      </g>
      <g>
        <circle cx="17.4" cy="15.6" fill={"#F5F6F7"} r="4.4" />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M17.4,20.5c-2.7,0-4.9-2.2-4.9-4.9s2.2-4.9,4.9-4.9s4.9,2.2,4.9,4.9S20.1,20.5,17.4,20.5z
          M17.4,11.7c-2.1,0-3.9,1.7-3.9,3.9c0,2.1,1.7,3.9,3.9,3.9c2.1,0,3.9-1.7,3.9-3.9C21.3,13.4,19.5,11.7,17.4,11.7z"
        />
      </g>
      <g>
        <rect
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          height="1.6"
          width="4.7"
          x="25.5"
          y="0.5"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M30.1,2.6h-4.7c-0.3,0-0.5-0.2-0.5-0.5V0.5C25,0.2,25.2,0,25.5,0h4.7c0.3,0,0.5,0.2,0.5,0.5
          v1.6C30.6,2.4,30.4,2.6,30.1,2.6z M26,1.6h3.7V1H26V1.6z"
        />
      </g>
      <g>
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M34.3,21.9h-2c-1.9,0-3.4-1.5-3.4-3.4V12c0-1.9,1.5-3.4,3.4-3.4h2V21.9z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M34.3,22.4h-2c-2.2,0-3.9-1.8-3.9-3.9V12c0-2.2,1.8-3.9,3.9-3.9h2c0.3,0,0.5,0.2,0.5,0.5v13.3
          C34.8,22.2,34.6,22.4,34.3,22.4z M32.3,9.1c-1.6,0-2.9,1.3-2.9,2.9v6.5c0,1.6,1.3,2.9,2.9,2.9h1.5V9.1H32.3z"
        />
      </g>
    </svg>
  )
}

export const Camera = () => {
  return (
    <svg className="w-full h-full category__svg camera" viewBox="0 0 41.5 36.4" x="0px" y="0px">
      <g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M28.9,36.4H2.5c-1.4,0-2.5-1.1-2.5-2.5V15.5C0,14.2,1.1,13,2.5,13h26.4c1.4,0,2.5,1.1,2.5,2.5
          v18.4C31.4,35.3,30.2,36.4,28.9,36.4z M2.5,14C1.7,14,1,14.7,1,15.5v18.4c0,0.8,0.7,1.5,1.5,1.5h26.4c0.8,0,1.5-0.7,1.5-1.5V15.5
          c0-0.8-0.7-1.5-1.5-1.5H2.5z"
        />
        <g>
          <g>
            <circle
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              cx="21.4"
              cy="7"
              r="6.5"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M21.4,14c-3.9,0-7-3.1-7-7s3.1-7,7-7c3.9,0,7,3.1,7,7S25.3,14,21.4,14z M21.4,1
              c-3.3,0-6,2.7-6,6s2.7,6,6,6c3.3,0,6-2.7,6-6S24.7,1,21.4,1z"
            />
          </g>
          <g>
            <circle
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              cx="8.3"
              cy="8.7"
              r="4.9"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M8.3,14C5.4,14,3,11.6,3,8.7s2.4-5.4,5.4-5.4c3,0,5.4,2.4,5.4,5.4S11.3,14,8.3,14z M8.3,4.3
              C5.9,4.3,4,6.3,4,8.7c0,2.4,2,4.4,4.4,4.4s4.4-2,4.4-4.4C12.7,6.3,10.7,4.3,8.3,4.3z"
            />
          </g>
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="41,35.6 30.9,30.6 30.9,18.5 41,13.5 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M41,36.1c-0.1,0-0.2,0-0.2-0.1l-10.1-5c-0.2-0.1-0.3-0.3-0.3-0.4V18.5c0-0.2,0.1-0.4,0.3-0.4
            l10.1-5c0.2-0.1,0.3-0.1,0.5,0c0.1,0.1,0.2,0.3,0.2,0.4v22.1c0,0.2-0.1,0.3-0.2,0.4C41.2,36.1,41.1,36.1,41,36.1z M31.4,30.3
            l9.1,4.5V14.3l-9.1,4.5V30.3z"
          />
        </g>
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M14.2,32.4H4.4c-0.6,0-1-0.4-1-1v-5.8c0-0.6,0.4-1,1-1h9.8c0.6,0,1,0.4,1,1v5.8C15.2,32,14.7,32.4,14.2,32.4z"
        />
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M22.9,18.2h3.9c0.4,0,0.8-0.3,0.8-0.8l0,0c0-0.4-0.3-0.8-0.8-0.8h-3.9c-0.4,0-0.8,0.3-0.8,0.8l0,0
          C22.1,17.8,22.4,18.2,22.9,18.2z"
        />
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M16.5,18.2h3.9c0.4,0,0.8-0.3,0.8-0.8l0,0c0-0.4-0.3-0.8-0.8-0.8h-3.9c-0.4,0-0.8,0.3-0.8,0.8l0,0
          C15.7,17.8,16,18.2,16.5,18.2z"
        />
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M22.9,20.8h3.9c0.4,0,0.8-0.3,0.8-0.8v0c0-0.4-0.3-0.8-0.8-0.8h-3.9c-0.4,0-0.8,0.3-0.8,0.8v0
          C22.1,20.4,22.4,20.8,22.9,20.8z"
        />
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M16.5,20.8h3.9c0.4,0,0.8-0.3,0.8-0.8v0c0-0.4-0.3-0.8-0.8-0.8h-3.9c-0.4,0-0.8,0.3-0.8,0.8v0
          C15.7,20.4,16,20.8,16.5,20.8z"
        />
      </g>
    </svg>
  )
}

export const IMac = () => {
  return (
    <svg className="w-full h-full category__svg imac" viewBox="0 0 36.8 32.8" x="0px" y="0px">
      <g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M35.3,26H1.5C0.7,26,0,25.3,0,24.5v-23C0,0.7,0.7,0,1.5,0h33.8c0.8,0,1.5,0.7,1.5,1.5v23
          C36.8,25.3,36.2,26,35.3,26z M1.5,1C1.2,1,1,1.2,1,1.5v23C1,24.8,1.2,25,1.5,25h33.8c0.3,0,0.5-0.2,0.5-0.5v-23
          c0-0.3-0.2-0.5-0.5-0.5H1.5z"
        />
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M35.3,25.5H1.5c-0.6,0-1-0.4-1-1v-4.4h35.8v4.4C36.3,25,35.9,25.5,35.3,25.5z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M35.3,26H1.5C0.7,26,0,25.3,0,24.5v-4.4c0-0.3,0.2-0.5,0.5-0.5h35.8c0.3,0,0.5,0.2,0.5,0.5
            v4.4C36.8,25.3,36.2,26,35.3,26z M1,20.6v3.9C1,24.8,1.2,25,1.5,25h33.8c0.3,0,0.5-0.2,0.5-0.5v-3.9H1z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="23.5,32.3 13.3,32.3 14.3,25.5 22.5,25.5 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M23.5,32.8H13.3c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4l1-6.8
            c0-0.2,0.2-0.4,0.5-0.4h8.2c0.2,0,0.5,0.2,0.5,0.4l1,6.8c0,0.1,0,0.3-0.1,0.4C23.8,32.7,23.7,32.8,23.5,32.8z M13.9,31.8H23
            L22.1,26h-7.4L13.9,31.8z"
          />
        </g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M26.5,32.8H10.3c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h16.2c0.3,0,0.5,0.2,0.5,0.5
          C27,32.5,26.8,32.8,26.5,32.8z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M3.9,10.8c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.2-0.5,0-0.7l6.2-6.2c0.2-0.2,0.5-0.2,0.7,0
          s0.2,0.5,0,0.7l-6.2,6.2C4.2,10.8,4,10.8,3.9,10.8z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M4,8.2c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.2-0.5,0-0.7l3.5-3.5c0.2-0.2,0.5-0.2,0.7,0
          c0.2,0.2,0.2,0.5,0,0.7L4.4,8.1C4.3,8.2,4.1,8.2,4,8.2z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M18.4,24.5c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7S19.4,24.5,18.4,24.5z
          M18.4,22.1c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7S18.8,22.1,18.4,22.1z"
        />
      </g>
    </svg>
  )
}

export const MapsNorthAmerica = () => {
  return (
    <svg className="w-full h-full category__svg mapsnorthamerica" viewBox="0 0 44.4 57.5" x="0px" y="0px">
      <g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M30.3,0.5C30.3,0.5,30.3,0.5,30.3,0.5l-2.1,0.4c-0.2,0-0.4,0.2-0.4,0.4l-0.4,2.4c0,0,0,0,0,0
            c0,0,0,0.6-0.1,1.3c0,0.7-0.1,1.3-0.1,1.6c0,0.3,0.2,0.5,0.3,0.6c0.1,0.2,0.3,0.3,0.4,0.4c0.3,0.3,0.6,0.5,0.6,0.5
            c0.1,0.1,0.3,0.1,0.5,0l1.1-0.5L31,8.8C31.1,8.9,31.2,9,31.4,9l2.9,0.3l2.5,3.4c0.1,0.1,0.1,0.1,0.2,0.2l4,1.7
            c0.2,0.1,0.5,0,0.6-0.2l0.6-0.9c0.1-0.1,0.1-0.3,0-0.5l-1.8-3.7l-0.1-3.7c0-0.2-0.1-0.3-0.2-0.4l-3.6-2.1c0,0,0,0-0.1,0l-5.9-2.5
            C30.5,0.5,30.4,0.5,30.3,0.5L30.3,0.5z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M41.1,15.1c-0.1,0-0.2,0-0.4-0.1l-4-1.7c-0.2-0.1-0.3-0.2-0.4-0.3L34,9.8l-2.7-0.3
            c-0.3,0-0.5-0.2-0.7-0.4l-0.5-0.8l-0.7,0.3c-0.3,0.1-0.7,0.1-1-0.1l0,0c0,0-0.3-0.2-0.7-0.5c-0.2-0.1-0.4-0.3-0.5-0.5
            c-0.2-0.2-0.4-0.5-0.4-1c0-0.2,0-0.9,0.1-1.5L27,3.7l0.4-2.5c0.1-0.4,0.4-0.7,0.8-0.8l2-0.4c0,0,0.2,0,0.2,0c0.1,0,0.3,0,0.4,0.1
            l5.9,2.5c0,0,0.1,0,0.1,0l3.6,2.1c0.3,0.2,0.5,0.5,0.5,0.8L41,9.2l1.7,3.6c0.1,0.3,0.1,0.7-0.1,1l-0.6,0.9
            C41.8,15,41.5,15.1,41.1,15.1z M30.2,7.1c0.2,0,0.3,0.1,0.4,0.2l0.8,1.2l2.9,0.3c0.1,0,0.3,0.1,0.3,0.2l2.5,3.4l4,1.6l0.6-0.9
            L40,9.5c0-0.1,0-0.1,0-0.2l-0.1-3.7l-3.6-2.1L30.3,1l-2,0.4L28,3.7L27.9,5c0,0.7-0.1,1.3-0.1,1.5c0,0.1,0,0.2,0.1,0.3
            c0.2,0.2,0.3,0.3,0.4,0.4C28.7,7.5,29,7.7,29,7.7l1-0.5C30.1,7.2,30.2,7.1,30.2,7.1z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M30.3,8.8C30.3,8.8,30.3,8.9,30.3,8.8l-3.2,0.4l-1-0.1c-0.3,0-0.5,0.2-0.5,0.4
            c0,0.1,0,0.2,0.1,0.3l0.8,1.3l0.6,1.1c0.1,0.2,0.3,0.3,0.5,0.3l1.3-0.3l1.5,0.3l0.1,0.9c0,0.1,0,0.2,0.1,0.2l1.1,1.6
            c0.1,0.1,0.2,0.2,0.3,0.2l2.6,0.5c0.2,0,0.4-0.1,0.5-0.3l0.4-0.9c0.1-0.2,0-0.5-0.2-0.6l-1-0.6l0.3-1.2c0-0.2,0-0.4-0.2-0.5
            l-3.7-3C30.6,8.9,30.4,8.8,30.3,8.8L30.3,8.8z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M34.6,16.5c-0.1,0-0.1,0-0.2,0L31.8,16c-0.3,0-0.5-0.2-0.6-0.4L30.1,14
            c-0.1-0.1-0.1-0.3-0.2-0.4L29.9,13l-1-0.2L27.7,13c-0.4,0.1-0.9-0.1-1.1-0.5L26,11.4l-0.8-1.2C25.1,10,25,9.7,25.1,9.5
            c0.1-0.5,0.6-0.9,1.1-0.8l1,0.1l3.1-0.4c0,0,0.1,0,0.1,0v0c0.2,0,0.5,0.1,0.6,0.2l3.7,3c0.3,0.2,0.4,0.6,0.3,1l-0.2,0.8l0.7,0.4
            c0.4,0.3,0.6,0.8,0.4,1.3L35.5,16C35.3,16.3,35,16.5,34.6,16.5z M28.8,11.8C28.9,11.8,28.9,11.8,28.8,11.8l1.6,0.3
            c0.2,0,0.4,0.2,0.4,0.4l0.1,0.9L32,15l2.6,0.5l0.4-0.9l-1-0.6c-0.2-0.1-0.3-0.3-0.2-0.5l0.3-1.2l-3.7-3l-3.2,0.4c0,0-0.1,0-0.1,0
            l-1-0.1l0.9,1.2l0.6,1.2l1.3-0.3C28.8,11.8,28.8,11.8,28.8,11.8z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M24.4,1.3c-0.1,0-0.2,0-0.2,0.1L21,3.3c-0.2,0.1-0.3,0.4-0.2,0.7c0,0.1,0.1,0.1,0.2,0.2
            L22.4,5c0,0,0,0,0,0l0,0.6c0,0.2,0.1,0.4,0.2,0.4l2.5,1.5c0.2,0.1,0.5,0.1,0.7-0.2c0,0,0-0.1,0.1-0.1l0.4-1.7c0,0,0-0.1,0-0.1
            L26.5,3c0-0.2-0.1-0.3-0.2-0.4l-1.6-1.2C24.6,1.4,24.5,1.3,24.4,1.3C24.4,1.3,24.4,1.3,24.4,1.3z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M25.3,8.2c-0.2,0-0.3,0-0.5-0.1l-2.5-1.5C22,6.3,21.8,6,21.8,5.6l0-0.3l-1.1-0.7
            c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.2-0.5-0.1-0.7c0.1-0.3,0.2-0.5,0.4-0.6L23.9,1c0.1-0.1,0.3-0.1,0.4-0.1c0,0,0,0,0.1,0
            c0,0,0,0,0,0c0.2,0,0.4,0.1,0.5,0.2l1.6,1.2C26.8,2.4,27,2.7,27,3l-0.2,2.5c0,0,0,0.1,0,0.1l-0.4,1.7c0,0.1-0.1,0.2-0.1,0.3
            C26,8,25.7,8.2,25.3,8.2z M24.4,1.8l-3.2,1.9l1.4,0.8c0.2,0.1,0.3,0.3,0.3,0.5l0,0.6l2.5,1.5l0.4-1.7L26,3L24.4,1.8z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M18.3,8.3c-0.1,0-0.2,0.1-0.3,0.1l-0.6,0.6c-0.1,0.1-0.1,0.1-0.1,0.2l-0.4,1.2
            c-0.1,0.3,0.1,0.5,0.3,0.6c0.1,0,0.1,0,0.2,0c0,0,0.8-0.1,1-0.1c0.1,0,0.2,0,0.3,0l0,1.4c0,0.2,0.1,0.4,0.3,0.4l1.3,0.4
            c0,0,0.1,0,0.2,0h1.4c0.1,0,0.2,0,0.3-0.1l0.4-0.3c0.1-0.1,0.2-0.2,0.2-0.4l0.1-1.4c0-0.1,0-0.2-0.1-0.3L22,9.6
            c-0.1-0.1-0.2-0.2-0.3-0.2l-1.3-0.3l-1.9-0.7C18.4,8.4,18.4,8.3,18.3,8.3z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M21.9,13.8h-1.4c-0.1,0-0.2,0-0.3-0.1l-1.3-0.4c-0.4-0.1-0.7-0.5-0.7-0.9l0-0.8
            c-0.3,0-0.8,0.1-0.8,0.1c-0.1,0-0.2,0-0.4,0c-0.3-0.1-0.5-0.2-0.6-0.5c-0.1-0.2-0.2-0.5-0.1-0.7l0.4-1.2c0-0.2,0.1-0.3,0.2-0.4
            l0.6-0.6C17.8,8,18,7.9,18.3,7.8c0.1,0,0.3,0,0.4,0.1l1.9,0.7l1.2,0.3c0.2,0.1,0.5,0.2,0.6,0.4l0.9,1.3c0.1,0.2,0.2,0.4,0.2,0.6
            l-0.1,1.4c0,0.3-0.2,0.6-0.4,0.7l-0.4,0.3C22.2,13.8,22,13.8,21.9,13.8z M18.6,10.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.4
            l0,1.4l1.3,0.4l1.4,0l0.4-0.3l0.1-1.4l-0.9-1.3l-1.3-0.3l-2-0.7l-0.6,0.6l-0.4,1.2c0,0,0.9-0.1,1.1-0.1
            C18.5,10.6,18.5,10.6,18.6,10.6C18.6,10.6,18.6,10.6,18.6,10.6z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M20.2,6.1c-0.2,0-0.4,0.1-0.5,0.3c0,0-0.1,0.3-0.2,0.6c-0.1,0.3-0.2,0.7-0.3,0.9
            c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0.1,0.8,0.2,0.8,0.2c0.1,0,0.2,0,0.3,0l0.7-0.4
            c0.2-0.1,0.3-0.3,0.3-0.5l-0.1-1.4c0-0.2-0.2-0.4-0.4-0.4L20.2,6.1C20.3,6.1,20.2,6.1,20.2,6.1z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M21.3,9.7c-0.1,0-0.1,0-0.2,0l0,0c0,0-0.4-0.1-0.9-0.2c-0.2-0.1-0.5-0.2-0.7-0.3
            c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.3-0.6-0.3-1c0.1-0.3,0.2-0.6,0.3-1c0.1-0.3,0.2-0.6,0.2-0.6c0.1-0.4,0.5-0.7,0.9-0.7
            c0,0,0,0,0,0c0,0,0.2,0,0.2,0L22.1,6c0.4,0.1,0.8,0.5,0.8,0.9L23,8.3c0,0.4-0.2,0.8-0.5,0.9l-0.7,0.4C21.6,9.6,21.4,9.7,21.3,9.7z
            M20.2,6.6L20,7.2c-0.1,0.3-0.2,0.6-0.2,0.9c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.2,0.1c0.2,0.1,0.3,0.2,0.5,0.2
            c0.4,0.1,0.8,0.2,0.8,0.2L22,8.3L21.9,7L20.2,6.6z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M30.5,55.3h1.9l0.3-1.1l0.1-0.2c0,0,0.1-0.1,0.1-0.1l0.4-1.4c0,0,0,0,0-0.1l0.3-1.9
            c0-0.3-0.1-0.5-0.4-0.6c-0.1,0-0.2,0-0.3,0l-0.5,0.3l-2,0.5c-0.1,0-0.3,0.1-0.3,0.3l-0.6,1.3L28.5,53h-0.9L26,51.5l-0.6-2.1
            l0.3-2.7l1.5-1.4l3.1-1l2.9-0.1l1.3,0.1l0.7,1.3c0,0,0.1,0.1,0.1,0.1l0.9,0.9c0,0,0.1,0.1,0.1,0.1l0.9,0.4c0.2,0.1,0.5,0,0.6-0.2
            c0,0,0,0,0,0l0.4-1c0-0.1,0-0.3,0-0.4l-1.9-3l2-2.6c0,0,0-0.1,0.1-0.1l0.6-1.5c0-0.1,0-0.2,0-0.4l-0.5-1.3l0.5-0.4
            c0.1-0.1,0.1-0.2,0.2-0.3l0.4-2.3l1.3-1.1c0.1-0.1,0.2-0.3,0.2-0.4l-0.1-0.7l0.5-1.1l0.6-0.6l0.1,0.2c0.1,0.2,0.4,0.3,0.7,0.2
            c0.1,0,0.1-0.1,0.2-0.2l1-2.1c0.1-0.2,0-0.5-0.2-0.6c-0.1,0-0.1,0-0.2,0H43l-1.4-0.7v-0.6c0-0.3-0.2-0.5-0.5-0.5c0,0-0.1,0-0.1,0
            l-0.6,0.1l2.1-1.1c0.1-0.1,0.2-0.2,0.2-0.3l0.3-1.1l0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.4v-0.9c0-0.1,0-0.1-0.1-0.2l-0.4-0.7
            c-0.1-0.1-0.2-0.2-0.3-0.3l-3-0.5c-0.1-0.1-0.4-0.3-0.7-0.7c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.1-0.1-0.2-0.2-0.2c0-0.1,0-0.1,0,0
            c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.4,0-0.6,0c-0.4,0.1-0.7,0.2-0.7,0.2c-0.2,0.1-0.3,0.2-0.3,0.4l0,0.9
            l-0.4,0l0-0.7c0-0.2-0.1-0.3-0.3-0.4l-2-0.9c-0.1,0-0.1,0-0.2,0l-1.6-0.1c-0.3,0-0.5,0.2-0.5,0.5c0,0,0,0.1,0,0.1l0.2,1.4
            c0,0,0,0.1,0,0.1l0.6,1.2c0,0,0,0,0,0.1l0.4,0.6l0.7,1.2v0.2l-0.2,0.5l-0.4,0.8c-0.1,0.2-0.1,0.3,0,0.5l0.1,0.2l0.8,1.2l-0.2,0.3
            l-0.2-0.1L32.1,25l-0.4-1.2c-0.1-0.2-0.3-0.4-0.5-0.3l-1,0.1l-2.5-1.4c-0.1,0-0.1-0.1-0.2-0.1l-0.8-0.1L26,21.4l-0.3-0.6l3.7-6
            c0.1-0.2,0.1-0.4,0-0.5l-0.8-1.2c-0.1-0.1-0.2-0.2-0.3-0.2l-1-0.1c-0.1,0-0.2,0-0.3,0l-0.7,0.3l-0.7-1.5c-0.1-0.2-0.4-0.4-0.6-0.3
            c0,0,0,0-0.1,0l-1.1,0.6c-0.2,0.1-0.3,0.4-0.2,0.6l0.3,0.9c-0.2-0.1-0.5,0.1-0.6,0.3l-0.3,0.6l-0.8-0.2l-1-0.5
            c-0.2-0.1-0.5,0-0.6,0.2c0,0,0,0,0,0.1l-0.2,0.6l-1-0.3l-1.3-1.2c0,0,0,0,0,0l-2.4-1.7c-0.1-0.1-0.2-0.1-0.3-0.1h-2l-0.1-1
            c0-0.2-0.1-0.3-0.3-0.4l-1.6-0.8L11.7,8c0-0.2-0.1-0.4-0.2-0.5l-0.8-0.5C10.6,7,10.5,7,10.4,7v0c0,0,0,0-0.1,0L8.6,7.3h-1
            c-0.2,0-0.4,0.2-0.5,0.4L7,8.4c0,0-0.1-0.1-0.1-0.1L6.1,7.8C5.9,7.7,5.7,7.8,5.5,7.9L5,8.5C4.9,8.6,4.9,8.8,4.9,8.9l0.2,0.8h0
            c0,0,0,0-0.1,0H3.4C3.2,9.7,3.1,9.8,3,10l-0.5,1c0,0,0,0.1,0,0.2l-0.2,1.8c0,0.1,0,0.2,0.1,0.3l0.3,0.5l0,0.5l-1.7,0.3
            c-0.3,0.1-0.4,0.3-0.4,0.6c0,0.2,0.2,0.4,0.4,0.4l1.9,0.3c0.1,0,0.2,0,0.3,0l0.9-0.5l1.9-0.2L7,16.8l1.5,2.8L9.8,25l0.2,1.4
            l0.3,2.5l-1.8,4.6c0,0.1,0,0.1,0,0.2l-0.1,2.8c0,0.1,0,0.1,0,0.2l0.9,2.9c0,0,0,0.1,0.1,0.1l1.4,2.1l1.6,2.6l1.7,4.1
            c0,0.1,0.1,0.1,0.1,0.2l1.4,1.2c0.2,0.2,0.5,0.2,0.7,0c0.1-0.1,0.2-0.3,0.1-0.5l-2-4.4l-0.5-1.6l0.7,0.8l1.1,3.1
            c0,0.1,0.1,0.1,0.1,0.2l1.5,1.5l1.3,2l0.3,1.3c0,0.1,0.1,0.2,0.2,0.3l1.7,1.3c0,0,0.1,0,0.1,0.1l1.4,0.6c0,0,0,0,0,0l3.5,1.2
            c0.1,0,0.2,0,0.2,0l2.1-0.4l1.5,1.3c0.1,0.1,0.2,0.1,0.3,0.1l0.8,0L30.5,55.3z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M30.7,57.5C30.7,57.5,30.7,57.5,30.7,57.5l-0.8,0c-0.2,0-0.5-0.1-0.6-0.3L28,56.1l-1.9,0.3
            c-0.2,0-0.3,0-0.5,0L22,55.2c0,0-0.1,0-0.1,0l-1.4-0.6c0,0-0.1-0.1-0.2-0.1l-1.7-1.3c-0.2-0.1-0.3-0.3-0.4-0.6L18,51.3l-1.2-1.9
            c0,0.3,0,0.6-0.2,0.8c-0.4,0.4-1,0.4-1.4,0.1l-1.4-1.2c-0.1-0.1-0.2-0.2-0.3-0.4l-1.7-4.1L10.4,42L9,40c0-0.1-0.1-0.1-0.1-0.2
            L8,36.8c0-0.1,0-0.2,0-0.3L8,33.7c0-0.1,0-0.2,0.1-0.3l1.7-4.5l-0.3-2.3l-0.2-1.4L8,19.8l-1.4-2.7l-0.9-1.4l-1.5,0.1l-0.8,0.4
            c-0.2,0.1-0.4,0.1-0.6,0.1l-1.9-0.3C0.4,16,0.1,15.7,0,15.3c-0.1-0.5,0.2-1,0.8-1.2l1.3-0.3l-0.2-0.3c-0.1-0.2-0.2-0.4-0.1-0.6
            L2,11.1C2,11,2,10.9,2,10.8l0.5-1c0.2-0.3,0.5-0.5,0.9-0.5h1l0-0.2c-0.1-0.3,0-0.6,0.2-0.9l0.5-0.6C5.5,7.2,6,7.2,6.4,7.4l0.3,0.2
            l0,0c0.1-0.4,0.5-0.8,1-0.8h1l1.7-0.3c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.5,0.2l0.8,0.5c0.3,0.2,0.5,0.6,0.4,0.9
            l-0.1,0.8l1.3,0.6c0.3,0.1,0.5,0.4,0.6,0.8l0.1,0.6h1.6c0.2,0,0.4,0.1,0.6,0.2l2.4,1.7c0,0,0.1,0.1,0.1,0.1l1.2,1.1l0.4,0.1l0-0.1
            c0,0,0-0.1,0-0.1c0.1-0.2,0.3-0.4,0.6-0.5c0.3-0.1,0.5-0.1,0.8,0.1l1,0.5l0.3,0.1l0.1-0.2c0.1-0.2,0.2-0.3,0.3-0.4l-0.1-0.3
            c-0.2-0.5,0-1,0.4-1.2l1.1-0.6c0,0,0.1-0.1,0.1-0.1c0.5-0.2,1.1,0,1.3,0.5l0.5,1.1l0.2-0.1c0.2-0.1,0.4-0.1,0.6-0.1l1,0.1
            c0.3,0,0.5,0.2,0.7,0.4l0.8,1.1c0.2,0.3,0.2,0.7,0,1.1l-3.5,5.8l0.1,0.2l0.5,0.5l0.6,0c0.1,0,0.3,0.1,0.4,0.1l2.4,1.3l0.8-0.1
            c0.3,0,0.7,0.1,0.9,0.4l0.4-0.7l0.1-0.4l-1.1-1.7c0,0-0.1-0.1-0.1-0.1l-0.6-1.2c0-0.1-0.1-0.2-0.1-0.3l-0.2-1.4c0,0,0-0.2,0-0.2
            c0-0.5,0.5-1,1-0.9l1.6,0.1c0.1,0,0.3,0,0.4,0.1l2,0.9c0.1,0,0.1,0.1,0.2,0.1c0.1-0.2,0.3-0.3,0.5-0.4c0,0,0.4-0.1,0.8-0.2
            c0.2,0,0.5-0.1,0.8,0c0.1,0,0.4,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.4,0.6c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.3,0.4,0.5,0.6
            c0.2,0.3,0.5,0.5,0.6,0.6l2.9,0.5c0.3,0.1,0.6,0.3,0.7,0.5l0.4,0.7c0.1,0.1,0.1,0.3,0.1,0.4v0.9c0,0.3-0.1,0.6-0.4,0.8l-0.1,0.1
            l-0.2,1c-0.1,0.3-0.2,0.5-0.5,0.6l-0.9,0.5c0.1,0.2,0.2,0.4,0.2,0.6v0.2l1,0.5h0.3c0.1,0,0.3,0,0.4,0.1c0.5,0.2,0.7,0.8,0.5,1.3
            l-1,2.1c-0.1,0.2-0.2,0.3-0.4,0.4c-0.3,0.2-0.8,0.2-1.1,0l-0.1,0.1l-0.4,0.9l0.1,0.5c0.1,0.3-0.1,0.7-0.3,0.9l-1.2,1L39.4,36
            c0,0.2-0.2,0.4-0.3,0.6l-0.2,0.2l0.4,0.9c0.1,0.2,0.1,0.5,0,0.7l-0.6,1.5c0,0.1-0.1,0.2-0.1,0.2l-1.8,2.3l1.7,2.7
            c0.2,0.3,0.2,0.6,0.1,0.9l-0.4,1c-0.3,0.6-0.8,0.8-1.3,0.6L36,47.2c-0.1,0-0.2-0.1-0.3-0.2l-0.9-0.9c-0.1-0.1-0.1-0.2-0.2-0.3
            l-0.5-1.1l-1,0l-2.8,0.1l-2.9,1L26.2,47l-0.3,2.4l0.5,1.9l1.3,1.3h0.6l0.6-0.4l0.5-1.2c0.1-0.3,0.4-0.5,0.7-0.6l2-0.5l0.5-0.2
            c0.2-0.1,0.4-0.1,0.6-0.1c0.3,0,0.5,0.2,0.6,0.4c0.2,0.2,0.2,0.5,0.2,0.7l-0.3,1.9c0,0,0,0.1,0,0.1l-0.4,1.4
            c0,0.1-0.1,0.2-0.1,0.3l-0.1,0.1l-0.3,1c-0.1,0.2-0.3,0.4-0.5,0.4h-1.3l0.1,1.2c0,0.1,0,0.3-0.1,0.4C31,57.5,30.9,57.5,30.7,57.5z
            M28.1,55c0.1,0,0.2,0,0.3,0.1l1.5,1.3l0.2,0L30,55.3c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.4-0.2H32l0.2-0.7
            c0-0.1,0-0.1,0.1-0.2l0.1-0.2l0.4-1.4l0.3-1.9l-0.5,0.3c0,0-0.1,0-0.1,0l-2,0.5l-0.6,1.3c0,0.1-0.1,0.2-0.2,0.2l-0.8,0.6
            c-0.1,0.1-0.2,0.1-0.3,0.1h-0.9c-0.1,0-0.3-0.1-0.4-0.1l-1.5-1.5c-0.1-0.1-0.1-0.1-0.1-0.2L25,49.6c0-0.1,0-0.1,0-0.2l0.3-2.7
            c0-0.1,0.1-0.2,0.2-0.3l1.5-1.4c0.1-0.1,0.1-0.1,0.2-0.1l3.1-1c0,0,0.1,0,0.1,0l2.9-0.1l1.3,0.1c0.2,0,0.3,0.1,0.4,0.3l0.7,1.3
            l0.9,0.9l0.9,0.4l0.3-1l-1.9-3c-0.1-0.2-0.1-0.4,0-0.6l2-2.6l0.6-1.5l-0.5-1.3c-0.1-0.2,0-0.4,0.1-0.6l0.5-0.4l0.4-2.3
            c0-0.1,0.1-0.2,0.2-0.3l1.3-1.1l-0.1-0.7c0-0.1,0-0.2,0-0.3l0.5-1.1c0,0,0.1-0.1,0.1-0.1l0.6-0.6c0.1-0.1,0.3-0.2,0.4-0.1
            c0.2,0,0.3,0.1,0.4,0.2l0.1,0.2l1-2.1l-0.4,0c-0.1,0-0.1,0-0.2,0l-1.4-0.7c-0.2-0.1-0.3-0.3-0.3-0.5v-0.6l-0.6,0.1
            c-0.2,0-0.5-0.1-0.6-0.3c-0.1-0.2,0-0.5,0.2-0.6l2.1-1.1l0.2-1.1c0-0.1,0.1-0.2,0.2-0.3l0.2-0.2l0-0.9l-0.4-0.7l-3-0.5
            c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.4-0.4-0.7-0.7c-0.2-0.2-0.4-0.5-0.6-0.7c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.2-0.4
            c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1-0.1c-0.1,0-0.3,0-0.4,0c-0.3,0.1-0.6,0.2-0.6,0.2l0,0.9c0,0.1-0.1,0.3-0.2,0.3
            c-0.1,0.1-0.2,0.1-0.4,0.1l-0.4,0c-0.3,0-0.5-0.2-0.5-0.5l0-0.7l-2-0.9l-1.6-0.1l0.2,1.4l0.6,1.2l0.4,0.6l0.7,1.3
            c0,0.1,0.1,0.2,0.1,0.3v0.2c0,0.1,0,0.1,0,0.2L33.3,23l-0.4,0.8l0.1,0.2l0.8,1.2c0.1,0.2,0.1,0.3,0,0.5l-0.2,0.3
            c-0.1,0.2-0.4,0.3-0.6,0.2l-0.2-0.1c-0.1,0-0.1,0-0.1-0.1l-0.8-0.7c-0.1-0.1-0.1-0.1-0.2-0.2l-0.4-1.2l-1,0.1
            c-0.1,0-0.2,0-0.3-0.1l-2.5-1.4l-0.8-0.1c-0.1,0-0.2-0.1-0.3-0.1l-0.7-0.7c0,0-0.1-0.1-0.1-0.1l-0.3-0.6c-0.1-0.2-0.1-0.3,0-0.5
            l3.7-6l-0.8-1.1l-1-0.1l-0.7,0.3c-0.1,0.1-0.3,0.1-0.4,0c-0.1,0-0.2-0.1-0.3-0.3l-0.7-1.5l-1.1,0.7l0.4,0.8c0.1,0.2,0,0.4-0.1,0.5
            C24.2,14,24,14.1,23.9,14l-0.2,0.6c-0.1,0.2-0.4,0.4-0.6,0.3l-0.8-0.2l-1.1-0.5L21,14.7c-0.1,0.3-0.4,0.4-0.6,0.3l-1-0.3
            c-0.1,0-0.1-0.1-0.2-0.1L18,13.4l-2.4-1.7l-2,0c-0.3,0-0.5-0.2-0.5-0.5l-0.1-1l-1.6-0.8c-0.2-0.1-0.3-0.3-0.3-0.5L11.2,8l-0.8-0.5
            h0L8.7,7.8l-1,0L7.5,8.5c0,0.2-0.2,0.3-0.4,0.4c-0.2,0-0.4,0-0.5-0.2L5.9,8.3L5.4,8.8l0.2,0.8c0,0.1,0,0.3-0.1,0.4
            c-0.1,0.1-0.2,0.2-0.4,0.2H3.4l-0.5,1L2.7,13L3,13.5c0,0.1,0.1,0.1,0.1,0.2l0,0.5c0,0.3-0.2,0.5-0.4,0.5L1,15.1l1.9,0.3l0.9-0.5
            c0.1,0,0.1,0,0.2-0.1l1.9-0.2c0.2,0,0.4,0.1,0.5,0.2l1.1,1.7L9,19.4c0,0,0,0.1,0,0.1l1.3,5.4l0.2,1.5l0.3,2.5c0,0.1,0,0.2,0,0.2
            L9,33.7l-0.1,2.8l0.9,2.9l1.4,2.1l1.6,2.6l1.7,4.2l1.4,1.2l-2-4.3l-0.5-1.7c-0.1-0.2,0-0.5,0.2-0.6c0.2-0.1,0.5-0.1,0.6,0.1
            l0.7,0.8c0,0,0.1,0.1,0.1,0.1l1.1,3.1l1.5,1.5l1.4,2.1c0,0.1,0.1,0.1,0.1,0.2l0.3,1.3l1.7,1.3l1.4,0.6l3.5,1.2L28.1,55
            C28.1,55.1,28.1,55,28.1,55z"
          />
        </g>
      </g>
    </svg>
  )
}

export const MapsMiddleEast = () => {
  return (
    <svg className="w-full h-full category__svg mapsmiddleeast" viewBox="0 0 64.9 56.6" x="0px" y="0px">
      <g>
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M64.2,30.6c-0.2,0-0.4-0.1-0.6-0.1c0-0.6,0-1.3,0-1.9c-1.1-0.2-2.9-1.6-3-2.8
          c-0.1-0.9,1.3-1.4,1.3-2.2l0.1-0.5c0,0-0.2-0.5-0.2-0.5c0-0.1-1.3-0.2-1.4-0.2c-0.1-1.3-0.1-2.7-0.2-4c-0.1-0.1-0.2-0.1-0.2-0.2
          c-0.4-0.5,0-1.4,0.7-1.4c0.1-1.6,0.2-3.1,0.3-4.7c-0.4-0.1-0.8-0.2-1.3-0.2c-1.8-1-3.6-2-5.4-3.1c-0.5,0-1.1,0-1.6,0
          c-1.3,0.7-2.5,1.4-3.8,2c0.2,0.2,0.3,0.6,0.1,0.8c-0.2,0.3-0.6,0.3-0.8,0.1c0.3-0.1,0.5-0.1,0.8-0.2c-1.2,0-2.4,0.7-3.6,0.7l-0.1,0
          l-0.1,0l-0.1,0L44.8,12l-0.1,0l-0.2,0l-0.2,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0l0,0l-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-1-1-3.5-1.4-3.3-3.2
          c-0.5-0.3-0.9-0.6-1.4-0.9c0.2-0.1,0.3-0.3,0.5-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c0.1-0.1,0.2-0.2,0.4-0.3c-0.2-0.2-0.5-0.5-0.7-0.7
          c-0.8,0.8-1.7,1.6-2.8,1.6c-1,0.1-1.9-0.5-2.6-1.2c-0.6-0.6-1.1-1.3-1.4-2c-0.3-0.6-0.5-1.2-0.8-1.6C30.5,1.3,29,1.8,28,2.2
          c-0.9,0.3-1.6,0.6-2.6,0.6c-1.1,0-2.2,0-3.3,0c-0.1,0-0.1-0.1-0.2-0.1c-0.7-0.3-1.3-0.6-2-0.8c-2-0.8-3.2-1.8-5.4-1.1
          c-1.5,0.5-2.7,1.5-4.2,1.8c-1.7,0.3-3.5-0.5-4.4-2c-0.2,0-0.4,0.1-0.5,0.1C4.8,0.9,4,0,3.5,0.9L3.3,1c0,0,0,0.2,0,0.2
          c0.4,0.3,0.8,1.1-0.1,1c0.2,0.3-0.2,0.7-0.3,1c0,0.2,0.1,0.5,0.4,0.6c0.2,0.1,0.5,0,0.6-0.2C3.4,3.9,3.1,4.4,3.1,4.9
          c0.3-2.4,4.6-2.3,6-1.5C8.5,3.5,7.9,3.7,7.4,3.8C7.5,3.9,7.6,4,7.8,4.1C7.2,4.2,6.5,4.2,5.9,4.2C5.8,4.1,5.7,4,5.6,3.8
          c-0.2,0.1,0,0.1,0,0.2l0.1,0.1l0.1,0l0,0.1l0,0L5.6,4.3l-0.1,0l-0.3,0l-0.1,0L5,4.3L5,4.3L4.9,4.2l0,0l0-0.2L4.8,4l-0.1,0l-0.1,0
          l-0.1,0l0,0.1l-0.1,0l-0.1,0l0,0L4,4.1L4,4.1c0,0-0.1,0-0.1,0c-0.6,0.4-1,1.2-1,1.9C2.9,6,4,6.1,4,6.1c-0.2,0.5-0.1,1,0.3,1.4
          C4.1,7.5,4,7.8,4,8c0,0.2,0.3,0.4,0.5,0.4c-0.4,0.3-1,0-1.1-0.4C3.1,8.2,3.3,8.7,3.6,8.9s0.7,0.2,0.9,0.5c0.3,0.2,0.3,0.8,0,0.8
          c0.5,0.1,0.7,0.9,0.4,1.2c0.5,0,1,0,1.5,0c-0.4,0.2-0.8,0.4-1.2,0.6c0.3-0.2,0.7-0.1,1,0.1c0.2-0.4,0.7-0.4,1.1-0.2
          c0.4,0.2,0.6,0.6,0.9,0.8c0.3,0.3,0.7,0.5,1.1,0.4c1-0.3,1-2,2.4-1.3c0.5,0.2,0.8,0.6,1.2,0.9s0.8,0.7,1.3,0.7c1,0,1.6-0.9,2.5-1.3
          c0.8-0.3,1.8-0.2,2.7-0.2c-0.4,1-0.4,2-0.5,3.1c-0.3,2.7-1.4,9.5-5.5,7.9c-0.9-0.4-1.7-0.9-2.7-0.7c-0.8,0.1-1.5,0.8-2.3,1.1
          c-0.9,0.3-1.8,0-2.7-0.3c-1-0.3-2.1-0.8-3.2-0.9c-0.5,0-1,0.5-1.3-0.1c-0.1,0.1-0.4,0.3-0.5,0.5l0,0.5L1,23.7l-0.1,0.6l-0.4,0.6
          L0.9,26l-0.1,0.1L1,26.7L1,40h10.4l0.3-0.4l0.1,0.1c0,0-0.2,0.3-0.2,0.3c0.2-0.3,4,0,4.4,0c1.6,0,3.1,0,4.7,0
          c-0.6-1.3-2.5-1.4-2.2-3.5c0-0.7-1.1-2.1-1.4-2.8c-0.6-1.1-1.1-2.2-1.7-3.3c-0.5-1-2.6-3.8-2.1-4.8l0.1-0.1l0-0.1l0-0.1l0.1,0
          l0.1,0l0.1,0.1l0,0.1c0,0,0,0.1,0,0.1c0.9,1.3,1.1,2.9,2.6,3.9c0.4-1.1,0.7-2.3,1.1-3.4c0.1,0.5,0,1-0.1,1.5l0,0.4l-0.1,0.1
          l-0.1,0.2L17,28.6l-0.1,0.1l-0.1,0.1l0.1,0.1l0.1-0.1l0,0l0,0.1l0,0l0.3,0l0.2,0l0.1,0l0.1,0l0,0.1l0.1,0.1l0.1,0.1l0.1,0.1
          l0.1,0.1l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.2,0.2l0.1,0.1l0,0.1l0,0.1l0.1,0.1l0.1,0.1l0.1,0.1c0,0,0.1,0.2,0.1,0.2
          c0.3,0.6,0.8,1.2,1.2,1.8c0.3,0.7,0.9,2.8,1.5,2.8l0.1,0l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0l0.1,0l0.1,0.1l0.1,0l0.1,0.1l0.1,0.1
          l0.1,0.1l0.1,0l0.1,0.1l0.1,0.1l0.1,0.1l0,0.1l0,0.2l0,0.1l0,0l0.1,0l0,0l0,0.2l0,0.1l0,0.1l0,0.1c0,0,0,0.1,0,0.1
          c0.5,0.7,0.5,1.4,0.4,2.3c0,2.2,2.5,3.2,3.4,4.7l0.1,0.1l0,0.1l0,0.2l0.1,0l0.1,0l0.1,0l0,0.1l-0.1,0l-0.1,0l-0.1,0l0,0.1l0.1,0
          l0,0.1l0,0.1v0.1l0.1,0.1l0,0.1l0.1,0l0,0.1l0.1,0.1l0,0.2l0,0.1l0.1,0.2l0.1,0.1l0.1,0.2l0.1,0.1l0.1,0.1l0.2,0.2l0.2,0.1l0.1,0.1
          l0.1,0.1l0.1,0.1l0.1,0.1l0.2,0.2l0,0.2l0,0.1l0,0.1l0.1,0l0,0.1l0,0.1l0.1,0.1l0.1,0.1l0,0l0,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0,0.1
          l0,0.1l0,0.1l0.1,0.1l0,0l0,0.1l0,0.2l0,0.1c0,0,0,0.1,0,0.1c0,0.5-0.1,0.9-0.1,1.4c0,0,0,0.1,0,0.1c0.4,1.2,0.6,2.6,0.9,3.7
          l0.1,0.2l0.1,0.1l0.1,0.1l0,0.1l0,0.2l0.2-0.1l0.1,0c0,0,0,0,0.1,0c1.6,0.4,3.2-0.6,4.6-1.1c1.6-0.6,3.2-1.1,4.8-1.9
          c1.2-0.6,2.4-0.8,3.5-1.4l0.1,0l0.1,0l0-0.1l0.1,0l0.2-0.1h0.1l0-0.1l0,0l0.3-0.1l0.1,0l0.1-0.1l0-0.1l0-0.3l0,0l0-0.1l0-0.2
          l0.1-0.3l0.1-0.2l0.2-0.2l0.2-0.1l0.1-0.1l0.4-0.1l0.4-0.2l0.5-0.2l0.4-0.1l0.1-0.1l0.3-0.1c0,0,0.2-0.1,0.2-0.1
          c0.5-0.1,1.5,0.3,1.8-0.3c0.1-0.3,0.1-0.7,0.4-0.9c0.3-0.3,0.9-0.3,1.3-0.5c0.5-0.2,0.8-0.8,1.2-1.2c0.4-0.4,1-0.8,1.5-0.5
          c0-0.7-0.3-1.9,0.3-2.4l0.1-0.2l0.1-0.2c0,0,0.2-0.1,0.2-0.1c0.2-0.1,0.2,0.2,0.2,0.3c1.1,0,2.3-2.7,2.7-3.6
          c-0.2-0.1-0.3-0.1-0.4-0.1l-0.2-0.1l-0.3-0.3l-0.1-0.1l0-0.2L57.3,38L57,37.6l-0.4-0.5l-0.5,0l-0.2,0l-0.2-0.2l-0.5-0.1l-0.4-0.1
          l-0.4-0.1l-0.3-0.1c0,0-0.3-0.2-0.3-0.2c-1.1-1.3-1-2.7-1.1-4.2c-0.3,0.2-0.4,0.5-0.5,0.8l-0.1,0.2l-0.1,0.1l-0.3,0.2l-0.1,0.1
          l-0.2,0L51.1,34l-0.3,0.3l-0.1,0.2l-0.2,0.2L50.1,35L50,35.1L50,35.4l0,0.2l-0.2,0l-0.2-0.1l-0.1,0.1l0,0.1l0.1,0.1l0.1,0.1
          l-0.1,0.1l-0.2,0l-0.3,0L49.1,36L49,36L49,36.2l-0.4,0.1l-0.4,0l-0.3-0.1l-0.7,0l-0.7,0l-0.3,0.2l-0.2,0l-0.3,0l-0.2,0.1l-0.1-0.1
          l0-0.3l-0.1-0.2l-0.2,0h-0.1l0,0l-0.1-0.1l-0.1,0l-0.1,0l0-0.1l0,0l0-0.1l0.1-0.1l0.2-0.1v0c0,0-0.1-0.1-0.1-0.1
          c0.3-0.6,0.8-2-0.1-2.3c-0.4-0.2-0.9,0.2-1,0.7c-0.1,0.4,0,0.9,0.2,1.3c-0.4-0.8-0.9-1.6-1.3-2.4c0.2-0.7-0.2-1.7-0.9-2
          c-0.3,0.1-0.6-0.4-0.4-0.6c-0.2-0.1-0.4-0.1-0.6-0.2c-0.6-1.2-1.1-2.3-1.7-3.5c0.5,0.1,0.8-0.6,0.4-0.9c0.4,0,0.8,0,1.2,0
          c0-0.2,0-0.5,0.1-0.7c0-0.2,0.3-0.3,0.3-0.1c0.1,0.2-0.2,0.3-0.3,0.2c0.3,0.2,0.6,0.4,0.9,0.6c0.4-0.3,1-0.1,1.4,0.3
          s0.4,0.9,0.5,1.4c0.1,0.1,0.2,0.1,0.3,0.2c0.4,1.8,2.2,2.9,3.9,3.7c0.7,0.4,1.5,0.8,2.3,0.9c1.2,0.1,1.9-0.6,2.8-1l0.1,0l0.1,0
          l0.1,0c0,0,0.2,0,0.2,0c0.8,0.1,0.4,0.6,0.8,1.3c0.6,1.2,1.9,2.1,3.3,1.8c0.9,0.1,1.8,0.2,2.6,0.3c0.2,0.5,1,0.6,1.4,0.2
          c0.4-0.3,0.6-0.9,0.8-1.4c0.1-0.2,0.1-0.4,0.2-0.6c0.5-0.4,1.2-0.7,1.9-0.6c0.1-0.3,0.1-0.6,0.2-1C64.4,30.7,64.3,30.6,64.2,30.6z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M31.6,56.6c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.2-0.3-0.2-0.5l0-0.1l-0.2-0.3
          c-0.2-0.7-0.4-1.4-0.6-2.1c-0.1-0.6-0.3-1.2-0.4-1.7c0,0,0-0.1,0-0.1c0-0.4,0-0.7,0-1c0-0.2,0-0.3,0-0.5l0-0.1l0-0.3c0,0,0,0,0-0.1
          l0-0.1c-0.1-0.1-0.1-0.2-0.1-0.3l0,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1
          l-0.1-0.3c0,0,0-0.1,0-0.1l0,0l-0.8-0.7c0,0-0.1-0.1-0.1-0.1l-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.2l-0.3-0.7c0,0,0,0,0,0
          c-0.1,0-0.1-0.1-0.2-0.2L27.4,46c-0.1-0.1-0.1-0.2-0.1-0.3v-0.1c0,0-0.1-0.1-0.1-0.1l0-0.1c0-0.1,0-0.2,0-0.2l0,0
          c-0.3-0.6-0.8-1-1.4-1.5c-1-0.9-2.2-2-2.2-3.6l0-0.2c0-0.8,0-1.3-0.3-1.8l0,0c0,0,0,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1l-0.1-0.4
          c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.2l-0.1-0.4c-0.1,0-0.2-0.1-0.3-0.1l-0.1-0.1c-0.1,0-0.2-0.1-0.2-0.1
          c-0.1,0-0.2-0.1-0.3-0.1l-0.1-0.2c-0.6-0.2-1-1.1-1.5-2.4c-0.1-0.3-0.2-0.5-0.3-0.7c-0.1-0.1-0.2-0.3-0.3-0.4
          c-0.3-0.4-0.6-0.9-0.9-1.3L18.6,31c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2-0.1-0.3l-0.2-0.3l-0.3-0.5c0,0-0.1-0.1-0.1-0.1l-0.2-0.3
          l-0.3,0c-0.1,0.1-0.2,0.1-0.4,0.1l-0.1,0.3c0,0.2-0.2,0.3-0.3,0.3c-0.2,0.1-0.3,0-0.4-0.1c-0.2-0.2-0.4-0.3-0.6-0.5
          c0.2,0.3,0.3,0.6,0.4,0.8l1.7,3.3c0.1,0.2,0.3,0.4,0.4,0.7c0.5,0.8,1,1.6,1,2.3c-0.1,1.1,0.3,1.4,1,1.9c0.4,0.3,0.9,0.7,1.2,1.3
          c0.1,0.2,0.1,0.3,0,0.5c-0.1,0.1-0.3,0.2-0.4,0.2c-1.1,0-2.2,0-3.3,0l-1.4,0c-0.1,0-0.4,0-0.7,0c-0.7,0-2.9-0.2-3.4-0.1
          c-0.1,0.1-0.2,0.1-0.4,0.1c0,0-0.1,0-0.1,0H1c-0.3,0-0.5-0.2-0.5-0.5l0-13.3l-0.2-0.4c-0.1-0.1-0.1-0.3,0-0.4L0,25.1
          c0-0.1,0-0.3,0-0.4l0.4-0.6l0.1-0.3l-0.2-0.6c0,0,0-0.1,0-0.1l0-0.5c0-0.1,0-0.2,0.1-0.3c0.1-0.2,0.3-0.4,0.5-0.5l0.1-0.1
          c0.1-0.1,0.3-0.2,0.4-0.1c0.2,0,0.3,0.1,0.4,0.3c0,0,0.1,0,0.1,0c0.2-0.1,0.5-0.2,0.8-0.1C3.4,21.7,4.2,22,5,22.3
          c0.3,0.1,0.6,0.2,0.9,0.3c0.7,0.2,1.6,0.5,2.4,0.3c0.3-0.1,0.6-0.3,0.9-0.5c0.4-0.3,0.9-0.5,1.5-0.6c0.9-0.2,1.7,0.2,2.5,0.5
          c0.2,0.1,0.3,0.1,0.5,0.2c0.8,0.3,1.4,0.3,2-0.1c1.6-1,2.5-4.5,2.8-7.4c0-0.2,0-0.4,0.1-0.6c0-0.6,0.1-1.3,0.3-1.9
          c-0.6,0-1.3,0-1.8,0.2c-0.3,0.1-0.6,0.3-0.9,0.5c-0.5,0.4-1.1,0.8-1.9,0.8c-0.7,0-1.2-0.4-1.6-0.8L12.4,13
          c-0.3-0.3-0.6-0.5-0.9-0.6c-0.5-0.2-0.6-0.1-1,0.4c-0.2,0.4-0.6,0.8-1.1,1c-0.5,0.2-1.1,0-1.6-0.5c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.2-0.2-0.3-0.4-0.5-0.5c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.2,0.3-0.4,0.3c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.3-0.1-0.4-0.1l-0.1,0
          c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.3,0-0.4c-0.2,0-0.3-0.2-0.3-0.3c-0.1-0.2,0-0.4,0.1-0.5c0,0,0-0.1,0-0.2s-0.1-0.2-0.1-0.2
          c-0.2,0-0.4-0.2-0.4-0.5C4,10,4.1,9.9,4.2,9.8c0,0,0,0,0,0C4.1,9.7,4,9.6,3.8,9.6C3.7,9.5,3.5,9.4,3.3,9.3c-0.3-0.2-0.5-0.7-0.5-1
          c0-0.3,0.2-0.6,0.5-0.7c0.1,0,0.2-0.1,0.3,0c0,0,0-0.1,0.1-0.1C3.5,7.2,3.4,6.9,3.5,6.6c-0.3,0-0.5,0-0.6,0l0-0.5L2.4,6
          c0-0.3,0.1-0.6,0.2-0.9c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0v0c0-0.3,0.1-0.5,0.2-0.8C2.6,3.9,2.4,3.5,2.4,3.2
          c0-0.2,0.1-0.4,0.2-0.6c0-0.1,0.1-0.2,0.1-0.2c-0.1-0.2-0.1-0.3,0-0.5C2.8,1.8,3,1.7,3.1,1.7c0,0-0.1-0.1-0.1-0.1
          C2.8,1.5,2.7,1.3,2.8,1.1l0-0.2c0-0.2,0.2-0.4,0.4-0.4C3.7-0.2,4.5,0,4.9,0.2c0.1,0,0.2,0.1,0.3,0.1l0.5-0.1c0.2-0.1,0.4,0,0.6,0.2
          c0.8,1.3,2.4,2,3.9,1.8c0.8-0.1,1.5-0.5,2.3-0.9c0.6-0.3,1.2-0.6,1.9-0.9c2-0.7,3.3-0.1,4.8,0.6c0.3,0.1,0.6,0.3,1,0.5l2.1,0.9
          l3.2,0c0.9,0,1.6-0.2,2.4-0.6c1-0.4,2.8-1.1,3.9,0.3c0.3,0.4,0.5,0.9,0.7,1.3c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.7,0.8,1.3,1.3,1.8
          c0.4,0.4,1.2,1.1,2.2,1c0.9,0,1.7-0.7,2.5-1.5C38.7,5.1,38.8,5,38.9,5c0.1,0,0.3,0.1,0.4,0.2L40,5.9C40,6,40.1,6.2,40.1,6.3
          c0,0.1-0.1,0.3-0.2,0.4l0,0l0.1,0.1C40.1,7,40,7.3,39.8,7.4L40.7,8c0.2,0.1,0.2,0.3,0.2,0.5c-0.1,0.9,0.6,1.3,1.7,1.8
          c0.5,0.3,1.1,0.6,1.5,1l0.1,0c0,0,0.1,0,0.1,0.1l0.2,0.1l0,0l0.3,0.1c0,0,0.1,0,0.1,0l0.1,0c0,0,0.1,0,0.1,0.1l0.3,0.1
          c0.5,0,1-0.2,1.5-0.3c0.5-0.1,1-0.3,1.5-0.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.3-0.4l3.8-2c0.1,0,0.2,0,0.2-0.1l1.6,0
          c0.1,0,0.2,0,0.2,0.1l5.3,3l1.2,0.2c0.2,0,0.4,0.3,0.4,0.5l-0.3,4.7c0,0.3-0.3,0.4-0.5,0.5c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.2
          c-0.1,0.1-0.1,0.3,0,0.4l0.2,0.2c0.1,0.1,0.2,0.2,0.2,0.3l0.2,3.6c1,0.1,1.3,0.2,1.4,0.5l0.2,0.6c0,0.1,0,0.2,0,0.2l-0.1,0.5
          c0,0.5-0.4,0.9-0.8,1.3c-0.3,0.3-0.6,0.5-0.5,0.8c0.1,0.9,1.7,2.1,2.6,2.3c0.2,0.1,0.4,0.3,0.4,0.5l0,1.5l0.4,0.1
          c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.4l-0.2,1c-0.1,0.2-0.3,0.4-0.5,0.4c-0.5,0-1,0.1-1.4,0.4L62.6,33
          c-0.2,0.5-0.4,1.2-1,1.6c-0.4,0.3-0.9,0.4-1.4,0.2c-0.3-0.1-0.5-0.2-0.6-0.4l-2.3-0.2c-1.5,0.3-3-0.6-3.8-2.1
          c-0.2-0.3-0.2-0.6-0.2-0.8c0-0.1,0-0.1,0-0.2c0,0-0.1,0-0.1,0l-0.2,0l0,0c-0.2,0.1-0.4,0.2-0.7,0.4c-0.7,0.4-1.4,0.8-2.4,0.7
          c-0.9-0.1-1.7-0.5-2.4-0.9c-1.7-0.9-3.5-2-4.1-3.8l-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.4-0.2-0.9-0.4-1.2
          c-0.2-0.2-0.5-0.3-0.7-0.2c-0.2,0.1-0.4,0.1-0.6,0L41,25.5c-0.1,0.2-0.3,0.3-0.5,0.3l-0.6,0c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.2-0.2,0.3-0.3,0.4l1.3,2.8l0.4,0.1c0.2,0,0.3,0.2,0.3,0.3c0,0.1,0,0.2,0,0.3c0,0,0.1,0,0.1,0c0.9,0.4,1.4,1.4,1.2,2.4
          l0.3,0.6c0.1-0.2,0.3-0.4,0.6-0.5c0.3-0.2,0.6-0.2,0.9-0.1c0.3,0.1,0.6,0.4,0.7,0.7c0.3,0.6,0,1.5-0.2,2c0,0.1,0,0.1,0,0.2
          c0,0,0,0.1,0,0.1c0.2,0,0.3,0.1,0.4,0.3l0.1,0.2c0,0,0,0,0,0.1l0.2,0l0,0l0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.1l0.7,0l0.8,0
          c0,0,0.1,0,0.1,0l0.2,0.1l0.2,0l0.1,0c0-0.1,0.1-0.1,0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2c0-0.1,0.1-0.2,0.2-0.3l0.1-0.1
          c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0-0.1,0.1-0.1l0.1-0.1c0,0,0.1-0.1,0.1-0.1l0.5-0.4l0.7-0.8c0.1-0.1,0.2-0.2,0.3-0.2l0.1,0l0.4-0.3
          l0.1-0.1c0.1-0.3,0.3-0.7,0.7-0.9c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0.1,0.3,0.2,0.3,0.4c0,0.2,0,0.5,0,0.7c0.1,1.2,0.1,2.2,0.9,3.2
          l0.2,0.2l0.5,0.2l0.8,0.1c0.1,0,0.2,0.1,0.3,0.1l0.1,0.1l0.4,0c0.2,0,0.3,0,0.4,0.2l1,1.2C58,38,58,38.1,58,38.2l0,0.1l0.2,0.2
          l0.2,0.1c0.1,0,0.3,0,0.5,0.2c0.2,0.1,0.3,0.4,0.2,0.6c-0.2,0.6-1.6,3.9-3.1,3.9c0,0-0.1,0-0.1,0c0,0,0,0.1-0.1,0.1
          c-0.3,0.2-0.2,0.9-0.2,1.4c0,0.2,0,0.4,0,0.6c0,0.2-0.1,0.4-0.3,0.4c-0.2,0.1-0.4,0.1-0.5,0c-0.2-0.1-0.6,0.1-0.8,0.4
          c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.4-0.6,0.8-1.1,1.1c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.1,0.2-0.1,0.4
          c0,0.2-0.1,0.3-0.2,0.5c-0.3,0.7-1.1,0.6-1.7,0.6c-0.1,0-0.3,0-0.4,0l0,0c-0.1,0.1-0.1,0.1-0.2,0.1l-0.2,0.1
          c-0.1,0.1-0.2,0.1-0.3,0.2l-0.4,0.1l-1.5,0.6l-0.2,0.3l0,0c0,0.1,0,0.1,0,0.2l0,0.2c0,0.2,0,0.5-0.2,0.6l-0.5,0.2
          c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.2l-0.2,0c-0.6,0.3-1.2,0.5-1.8,0.7c-0.6,0.2-1.2,0.4-1.7,0.7
          c-1.1,0.5-2.3,1-3.5,1.4c-0.4,0.2-0.9,0.3-1.3,0.5c-0.3,0.1-0.5,0.2-0.8,0.3c-1.2,0.5-2.6,1.1-4,0.8l0,0
          C31.7,56.6,31.6,56.6,31.6,56.6z M32,55.5c1.1,0.2,2.3-0.3,3.5-0.8c0.3-0.1,0.6-0.2,0.8-0.3c0.4-0.2,0.9-0.3,1.3-0.5
          c1.1-0.4,2.3-0.8,3.4-1.4c0.6-0.3,1.2-0.5,1.9-0.7c0.6-0.2,1.2-0.4,1.7-0.7c0,0,0.1,0,0.1,0l0.1,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1
          l0.2-0.1c0.1,0,0.1,0,0.2-0.1c0,0,0.1,0,0.1-0.1l0.1-0.1c0,0,0,0,0-0.1l0-0.1l0-0.1c0-0.1,0-0.2,0-0.2l0.1-0.3c0,0,0-0.1,0-0.1
          l0.1-0.2c0,0,0-0.1,0.1-0.1l0.5-0.4l0.2-0.1l1.4-0.5l0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.1l0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.1
          c0.2,0,0.5,0,0.7,0c0.2,0,0.6,0.1,0.7,0c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.5,0.3-0.8c0.3-0.3,0.7-0.4,1.1-0.5
          c0.2,0,0.3-0.1,0.4-0.1c0.3-0.1,0.5-0.4,0.7-0.7c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.4,0.9-0.7,1.4-0.8c-0.1-0.7-0.1-1.5,0.4-2l0-0.1
          c0,0,0-0.1,0-0.1l0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.2c0,0,0.2-0.1,0.2-0.1c0.2-0.1,0.4-0.1,0.5,0.1c0,0,0.1,0,0.1,0.1
          c0.5-0.4,1.2-1.5,1.7-2.7l-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.1l-0.4-0.4c-0.1-0.1-0.1-0.2-0.1-0.2l0-0.1l-0.7-0.9l-0.2,0
          c0,0-0.1,0-0.1,0l-0.2,0c-0.1,0-0.2-0.1-0.3-0.1l0,0l-0.7-0.1c0,0-0.1,0-0.1,0L54,37c0,0-0.1,0-0.1-0.1c0,0-0.4-0.3-0.4-0.3
          c-0.8-1-1-2-1.1-3.1c0,0,0,0-0.1,0L52,33.9c-0.1,0.1-0.1,0.1-0.2,0.1l0,0l-0.8,1c0,0-0.1,0.1-0.1,0.1l-0.3,0.2l-0.1,0.4
          c0,0.2-0.2,0.3-0.3,0.3c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.4,0.3-0.5,0.2l-0.1,0c0,0,0,0,0,0l0,0c-0.1,0.2-0.2,0.3-0.4,0.3l-0.4,0.1
          c0,0-0.1,0-0.1,0l-0.4,0c0,0-0.1,0-0.1,0l-0.2-0.1l-0.6,0l-0.5,0l-0.2,0.1c-0.1,0.1-0.2,0.1-0.2,0.1L46,37c0,0-0.1,0-0.1,0l-0.3,0
          l-0.2,0c-0.2,0-0.4,0-0.5-0.2l-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.1-0.1-0.2-0.1h0c-0.2,0-0.3,0-0.4-0.2
          C44,36,43.9,35.7,44,35.6l0-0.1c0-0.1,0.1-0.2,0.2-0.3l0,0c0,0,0,0,0-0.1c-0.1,0.1-0.1,0.2-0.3,0.2c-0.2,0.1-0.5,0-0.6-0.2L42,32.8
          c-0.1-0.1-0.1-0.3,0-0.4c0.2-0.5-0.1-1.1-0.5-1.3c-0.3,0-0.5-0.2-0.7-0.4c-0.1-0.1-0.1-0.2-0.1-0.3l-0.2-0.1
          c-0.1,0-0.3-0.1-0.3-0.3l-1.7-3.5c-0.1-0.2-0.1-0.4,0.1-0.5c0.1-0.1,0.3-0.2,0.5-0.2c0,0,0-0.1,0-0.1c-0.2-0.1-0.2-0.3-0.1-0.5
          s0.3-0.3,0.5-0.3l0.8,0l0-0.2c0-0.3,0.2-0.6,0.5-0.7c0.3-0.1,0.7,0,0.8,0.3c0,0.1,0.1,0.2,0.1,0.3l0.1,0.1c0.6-0.2,1.3,0.1,1.7,0.6
          c0.4,0.4,0.5,0.9,0.6,1.4l0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.4,1.6,2.1,2.6,3.6,3.4c0.7,0.4,1.4,0.7,2.1,0.8
          c0.7,0.1,1.2-0.2,1.8-0.6c0.2-0.1,0.5-0.3,0.8-0.4l0.1,0c0.1-0.1,0.3-0.1,0.4,0l0.2,0c0.9,0.1,1,0.8,1,1.1c0,0.2,0,0.3,0.1,0.4
          c0.5,0.9,1.5,1.8,2.8,1.6c0,0,0.1,0,0.1,0l2.6,0.3c0.2,0,0.3,0.1,0.4,0.3c0,0,0.1,0.1,0.1,0.1c0.2,0,0.4,0,0.5-0.1
          c0.3-0.2,0.5-0.7,0.6-1.2l0.2-0.6c0-0.1,0.1-0.2,0.1-0.2c0.5-0.4,1.1-0.7,1.8-0.8l0-0.1L63.5,31c-0.2,0-0.4-0.3-0.4-0.5l0-1.5
          c-1.2-0.5-2.9-1.8-3-3.1c-0.1-0.7,0.4-1.2,0.8-1.6c0.2-0.2,0.5-0.5,0.5-0.6l0.1-0.4l-0.1-0.2c-0.2,0-0.5-0.1-0.9-0.1
          c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4-0.2-0.4-0.5l-0.2-3.8l-0.1-0.1c-0.4-0.4-0.4-1-0.2-1.5c0.2-0.3,0.4-0.6,0.7-0.7l0.2-3.9l-0.8-0.2
          c-0.1,0-0.1,0-0.1-0.1l-5.3-3l-1.4,0L49.5,11c0.1,0.3,0,0.7-0.2,0.9c-0.3,0.5-1,0.6-1.5,0.3c-0.2,0.1-0.4,0.1-0.7,0.2
          c-0.6,0.2-1.2,0.4-1.9,0.4c-0.1,0-0.1,0-0.2,0l-0.3-0.1c0,0-0.1,0-0.1-0.1l-0.1,0l-0.7-0.1c0,0-0.1,0-0.1,0c0,0-0.2-0.1-0.2-0.1
          l-0.1,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.3-0.3-0.8-0.5-1.2-0.8c-1-0.5-2.2-1.2-2.2-2.5l-1.2-0.8
          c-0.1-0.1-0.2-0.2-0.2-0.4s0.1-0.3,0.2-0.4l0.2-0.1l-0.1-0.1c-0.1-0.1-0.1-0.3,0-0.4c-0.7,0.6-1.6,1.3-2.6,1.3
          c-1.2,0.1-2.3-0.7-3-1.3c-0.6-0.6-1.1-1.3-1.5-2.1c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.4-0.3-0.8-0.6-1.1c-0.5-0.7-1.4-0.5-2.8,0
          c-0.9,0.3-1.7,0.6-2.8,0.6l-3.3,0c-0.1,0-0.2,0-0.2-0.1l-0.2-0.1l-1.9-0.8c-0.4-0.2-0.7-0.3-1-0.5c-1.4-0.7-2.4-1.1-4.1-0.6
          c-0.6,0.2-1.1,0.5-1.7,0.8c-0.8,0.4-1.6,0.9-2.6,1c-0.3,0-0.5,0.1-0.8,0.1c0,0.1,0.1,0.2,0,0.3c0,0.2-0.2,0.4-0.4,0.4L8.3,4.1
          c0,0,0,0.1,0,0.1C8.2,4.4,8,4.6,7.8,4.6C7.4,4.6,7,4.7,6.5,4.7L6,4.7c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-0.1,0l-0.4,0L5,4.8
          c-0.1,0-0.1,0-0.2,0c0,0-0.1-0.1-0.1-0.1L4.6,4.6c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0l-0.1,0c0,0,0,0,0,0
          C3.8,4.9,3.6,5.2,3.5,5.5c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0,0.4C4.4,6.5,4.4,6.9,4.6,7.1
          c0.1,0.1,0.2,0.3,0.2,0.5S4.7,7.8,4.5,7.9C4.8,7.9,4.9,8,5,8.2c0.1,0.2,0,0.4-0.2,0.6c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0.1,0.1,0.1
          c0.3,0.3,0.5,0.7,0.4,1.1c0.1,0.1,0.2,0.3,0.3,0.5c0,0.1,0.1,0.3,0.1,0.4l0.9,0c0.2,0,0.4,0.2,0.5,0.4c0,0,0,0,0,0
          c0.2,0,0.5,0.1,0.6,0.2c0.3,0.2,0.6,0.4,0.8,0.7c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0.2,0.5,0.3,0.6,0.3c0.2-0.1,0.4-0.3,0.6-0.6
          c0.3-0.5,0.9-1.3,2.2-0.7c0.5,0.2,0.8,0.5,1.1,0.8l0.2,0.2c0.4,0.4,0.7,0.5,1,0.6c0.4,0,0.8-0.3,1.2-0.6c0.3-0.2,0.7-0.5,1.1-0.7
          c0.7-0.3,1.5-0.3,2.3-0.3c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0.1,0.5c-0.3,0.7-0.3,1.5-0.4,2.3
          c0,0.2,0,0.4-0.1,0.7c-0.2,2-0.9,6.7-3.3,8.2c-0.9,0.5-1.9,0.6-2.9,0.1c-0.2-0.1-0.3-0.1-0.5-0.2c-0.7-0.3-1.3-0.6-1.9-0.5
          c-0.4,0.1-0.7,0.3-1.1,0.5c-0.4,0.2-0.7,0.4-1.2,0.6c-1,0.3-2.1,0-3-0.3c-0.3-0.1-0.6-0.2-0.9-0.3C4,23,3.2,22.7,2.5,22.7
          c-0.1,0-0.2,0-0.4,0.1c-0.2,0.1-0.6,0.2-0.9,0l0,0.1l0.2,0.6c0,0.1,0,0.2,0,0.3l-0.1,0.6c0,0,0,0.1-0.1,0.1L1,25l0.3,0.8
          c0,0.1,0,0.2,0,0.3l0.1,0.3c0,0.1,0,0.1,0,0.2l0,12.9h9.7l0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3,0,0.4,0.1
          c0,0,0.1,0.1,0.1,0.1c0.6,0,1.6,0,3.2,0.1c0.3,0,0.6,0,0.6,0l1.4,0c0.7,0,1.5,0,2.2,0c-0.1-0.1-0.2-0.2-0.3-0.2
          c-0.7-0.5-1.6-1.2-1.4-2.8c0-0.3-0.5-1.1-0.9-1.7c-0.2-0.3-0.4-0.6-0.5-0.8L15,30.7c-0.1-0.2-0.3-0.5-0.5-0.9
          c-1.2-1.9-2-3.4-1.5-4.3l0,0c0-0.1,0-0.1,0-0.2c0.1-0.1,0.2-0.3,0.3-0.3c0.1-0.1,0.4-0.1,0.5,0c0.1,0,0.2,0.1,0.2,0.2l0.1,0.1
          c0.1,0.1,0.1,0.2,0.1,0.3v0c0.3,0.5,0.6,1,0.8,1.5c0.3,0.6,0.6,1.3,1.1,1.8l0.8-2.7c0.1-0.2,0.3-0.4,0.5-0.3c0.2,0,0.4,0.2,0.4,0.4
          c0.1,0.5,0,1-0.1,1.6l0,0.3c0,0.1,0,0.1-0.1,0.2l0,0l0,0c0.1,0,0.1,0,0.2,0l0.2,0.1c0.1,0,0.2,0.2,0.3,0.3l0.2,0.4
          c0.1,0.1,0.1,0.1,0.2,0.2l0.3,0.6l0.1,0.1c0,0,0.1,0.1,0.1,0.1l0.1,0.1c0,0.1,0.1,0.1,0.1,0.2l0.3,0.3l0.1,0.2
          c0.2,0.4,0.5,0.8,0.8,1.2c0.1,0.2,0.3,0.4,0.4,0.5c0.1,0.3,0.2,0.5,0.3,0.8c0.2,0.5,0.6,1.6,0.8,1.8c0,0,0,0,0,0c0,0,0,0,0.1,0
          c0.1,0,0.2,0.1,0.2,0.1l0.1,0.1c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.1,0.1,0.1l0.1,0.1
          c0.1,0,0.1,0,0.2,0.1l0.2,0.1c0,0,0.1,0,0.1,0.1l0.1,0.1c0,0,0.1,0.1,0.1,0.1l0.1,0.3c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.2
          l0.1,0.5c0.6,0.8,0.6,1.6,0.5,2.4l0,0.2c0,1.2,0.9,2,1.8,2.8c0.6,0.5,1.1,1,1.5,1.6l0,0c0,0.1,0.1,0.1,0.1,0.2
          c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.2-0.1,0.3c0,0,0,0,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.4l0.2,0.5
          c0.1,0,0.1,0.1,0.1,0.1l0.2,0.2l0.5,0.4l0.3,0.3c0.1,0.1,0.1,0.2,0.1,0.3l0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1l0,0
          c0.1,0.1,0.2,0.3,0.2,0.4l0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.2,0.1,0.2l0,0.1c0,0,0,0.1,0,0.1c0.1,0.1,0.1,0.2,0.1,0.3l0,0
          l0.1,0.4c0,0.3,0,0.5,0,0.8c0,0.2-0.1,0.5,0,0.7c0.2,0.6,0.3,1.2,0.4,1.8c0.2,0.7,0.3,1.4,0.5,2L32,55.5C32,55.5,32,55.5,32,55.5z
          M44.4,33.3C44.3,33.3,44.3,33.4,44.4,33.3c-0.2,0.1-0.3,0.2-0.3,0.3c-0.1,0.3,0,0.7,0.2,1.1c0,0.1,0,0.1,0,0.2
          c0.3-0.6,0.4-1.2,0.3-1.5C44.5,33.4,44.5,33.4,44.4,33.3C44.4,33.3,44.4,33.3,44.4,33.3z M5.9,3.4C5.9,3.5,6,3.5,6,3.6
          c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0l0.3,0c0.1,0,0.3,0,0.4,0C6.9,3.6,7,3.5,7,3.4C6.7,3.4,6.3,3.4,5.9,3.4z M3.7,2.7
          c0,0.2-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.1,0.2C3.6,3.1,3.8,3,4,2.9c0.8-0.4,1.8-0.5,2.8-0.5C6.3,2.1,5.9,1.7,5.6,1.2L5.4,1.2
          c-0.3,0.1-0.6,0-0.9-0.1C4.1,1,4.1,1,4,1.2c0,0,0,0,0,0c0.2,0.3,0.3,0.7,0.2,1C4.1,2.3,4,2.5,3.7,2.7z"
        />
      </g>
    </svg>
  )
}

export const Australia = () => {
  return (
    <svg className="w-full h-full category__svg australia" viewBox="0 0 73.5 59.1" x="0px" y="0px">
      <g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="53.5,13.6 53.4,13.4 53.3,13.4 53.2,13.4 53.1,13.3 53,13.3 52.9,13.3 52.9,13.3
            52.8,13.2 52.6,13.1 52.5,13.1 52.4,13.1 52.4,13.2 52.4,13.2 52.5,13.2 52.6,13.2 52.6,13.4 52.7,13.4 52.7,13.5 53,13.6
            53.1,13.7 53.2,13.7 53.3,13.8 53.4,13.7 53.5,13.8 53.6,13.8 53.5,13.7 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M53.5,14.3c0,0-0.1,0-0.1,0l0,0c-0.1,0-0.2,0-0.3,0l-0.1,0c0,0-0.1,0-0.1,0l-0.1-0.1
            l-0.3-0.2c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.1,0-0.2,0-0.2c0.1-0.2,0.3-0.5,0.5-0.5c0.1,0,0.2,0,0.2,0l0.1,0
            c0,0,0.1,0,0.1,0.1l0.2,0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.1,0l0,0c0.1,0,0.3,0,0.4,0.1c0,0,0.1,0.1,0.1,0.1l0.1,0.2c0,0,0,0,0,0
            c0.1,0.1,0.2,0.3,0.1,0.5c0,0.2-0.2,0.4-0.4,0.4C53.6,14.3,53.6,14.3,53.5,14.3z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="52.5,12.2 52.5,12.1 52.5,12.2 52.5,12.3 52.5,12.4 52.5,12.5 52.6,12.5 52.6,12.5
            52.7,12.6 52.7,12.6 52.7,12.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M52.7,13.1c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.2-0.3-0.2-0.4
            c0,0,0-0.1,0-0.1l0-0.1c-0.1-0.2,0-0.4,0.1-0.5c0.1-0.1,0.3-0.2,0.5-0.1c0.1,0,0.3,0.1,0.3,0.2l0.2,0.3c0,0.1,0.1,0.1,0.1,0.2
            l0,0.1c0,0,0,0.1,0,0.1c0,0.2-0.2,0.4-0.3,0.4C52.8,13.1,52.7,13.1,52.7,13.1z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="50.9,23.2 50.9,23.2 51,23.1 50.9,23 50.9,23.1 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M50.9,23.7c0,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.2-0.4-0.4l0-0.2c0-0.1,0-0.2,0.1-0.3
            c0.1-0.2,0.3-0.3,0.6-0.3c0.2,0,0.4,0.2,0.4,0.4l0,0.1c0,0.1,0,0.1,0,0.2c0,0-0.1,0.2-0.1,0.2C51.2,23.6,51.1,23.7,50.9,23.7z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="51.1,14.5 51,14.5 51,14.5 51,14.5 51,14.6 51.1,14.6 51.1,14.6 51.2,14.7
            51.3,14.7 51.4,14.7 51.4,14.8 51.5,14.9 51.5,14.9 51.6,14.8 51.6,14.8 51.5,14.7 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M51.5,15.4C51.5,15.4,51.4,15.4,51.5,15.4c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.1
            c-0.1,0-0.1,0-0.2-0.1c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0.1-0.2c0.1-0.2,0.4-0.3,0.6-0.3
            c0,0,0.2,0,0.2,0.1l0.6,0.3c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.1,0.4c0,0.2-0.1,0.3-0.3,0.4C51.8,15.3,51.6,15.4,51.5,15.4z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="47.2,10.6 47.2,10.7 47.2,10.7 47.2,10.9 47.3,10.8 47.3,10.8 47.2,10.6 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M47.2,11.4C47.2,11.4,47.2,11.4,47.2,11.4c-0.2,0-0.4-0.1-0.5-0.3l-0.1-0.1
            c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.2c0-0.1,0.1-0.3,0.2-0.4l0,0c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.3,0.2,0.3,0.3l0,0.2
            c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2C47.6,11.2,47.4,11.4,47.2,11.4z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="59.9,18.4 59.9,18.4 59.9,18.4 59.9,18.7 60,18.8 60,18.7 60,18.6 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M60,19.3c-0.2,0-0.3-0.1-0.4-0.2c0-0.1-0.1-0.2-0.1-0.3l0-0.3c0-0.1,0-0.1,0-0.2
            c0.1-0.2,0.2-0.3,0.4-0.3c0.2,0,0.5,0.1,0.6,0.4l0.1,0.2c0,0,0,0.1,0,0.1l0,0.1c0,0,0,0.1,0,0.1C60.4,19.1,60.2,19.3,60,19.3
            C60,19.3,60,19.3,60,19.3z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="51,12.2 50.7,12.1 50.6,12 50.6,12.1 50.6,12.1 50.6,12.3 50.6,12.3 50.6,12.5
            50.7,12.6 50.8,12.6 50.8,12.6 51,12.6 51.3,12.7 51.5,12.7 51.7,12.8 51.8,12.8 51.9,12.7 51.9,12.7 51.9,12.6 51.8,12.5
            51.7,12.5 51.7,12.4 51.6,12.3 51.4,12.2 51.1,12.2 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M51.7,13.3c0,0-0.1,0-0.1,0l-0.2,0c0,0-0.1,0-0.1,0l-0.1,0l-0.5,0c-0.1,0-0.1,0-0.2-0.1
            c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1l-0.2-0.3c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1l0-0.1c0-0.1,0-0.2,0-0.2
            c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.3,0,0.3,0.1l0.1,0.1l0.3,0c0.1,0,0.2,0,0.3,0.1l0.2,0.2
            c0.1,0,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.2,0.1,0.3l0,0.1c0,0.2,0,0.4-0.2,0.5c0,0-0.1,0.1-0.2,0.1l-0.1,0
            c0,0-0.1,0-0.1,0C51.8,13.3,51.7,13.3,51.7,13.3z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="51.3,11.9 51.3,11.9 51.3,11.9 51.3,11.9 51.4,12 51.4,11.9 51.3,11.8 51.3,11.8
            51.2,11.8 51.2,11.7 51.1,11.8 51.1,11.8 51.2,11.8 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M51.4,12.4c0,0-0.1,0-0.1,0v0c-0.1,0-0.2,0-0.2-0.1c0,0-0.1,0-0.1-0.1
            c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.2,0-0.5,0.2-0.6c0.1-0.1,0.3-0.1,0.4-0.1l0,0c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0.1,0.1,0.1l0.1,0.1
            c0.2,0.2,0.2,0.5,0,0.7C51.7,12.4,51.5,12.4,51.4,12.4z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="52.1,11.4 52.1,11.3 51.8,11 51.8,11 51.7,11 51.7,11 51.6,11 51.6,11.1 51.8,11.2
            51.7,11.3 51.8,11.7 51.9,11.9 52.1,12.1 52.1,12.1 52.3,12.2 52.4,12.4 52.5,12.4 52.5,12.4 52.5,12.3 52.4,12.1 52.4,12
            52.3,11.9 52.3,11.9 52.3,11.7 52.1,11.6 52.1,11.6 52,11.5 52,11.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M52.5,12.9c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.3-0.2l-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1
            l-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.2l-0.1-0.2l-0.2-0.5c0-0.1,0-0.1,0-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.3,0.2-0.4
            c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.2,0,0.4,0.1,0.5,0.2l0.3,0.3c0.1,0.1,0.1,0.2,0.1,0.3l0,0c0.1,0.1,0.1,0.1,0.1,0.2
            l0.1,0.1c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.2l0,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.3-0.3,0.3
            C52.6,12.9,52.5,12.9,52.5,12.9z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="50.6,11.2 50.6,11.1 50.6,11.1 50.5,11.1 50.5,11.1 50.5,11.2 50.5,11.2 50.6,11.2
                "
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M50.6,11.7c-0.1,0-0.2,0-0.2-0.1c0,0-0.2-0.1-0.2-0.1C50,11.4,50,11.2,50,11
            c0-0.1,0.2-0.3,0.3-0.4c0.2-0.1,0.4-0.1,0.6,0c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2,0,0.3c-0.1,0.2-0.2,0.3-0.3,0.3l-0.1,0
            C50.7,11.7,50.6,11.7,50.6,11.7z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="58.4,14.6 58.5,14.7 58.5,14.7 58.6,14.7 58.5,14.6 58.5,14.6 58.5,14.6 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M58.5,15.2C58.5,15.2,58.4,15.2,58.5,15.2c-0.2,0-0.3-0.1-0.4-0.2L58,14.9
            c-0.1-0.1-0.1-0.3-0.1-0.4s0.1-0.3,0.3-0.3l0,0c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0.1,0.5,0.3c0.1,0.1,0.1,0.2,0.1,0.4
            c0,0.2-0.2,0.4-0.4,0.4C58.7,15.2,58.5,15.2,58.5,15.2z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="50.1,11.8 50,11.7 50,11.8 49.9,11.8 50,11.9 50,11.9 50.1,11.9 50.1,11.9
            50.1,11.9 50.1,11.8 50.1,11.8 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M50.1,12.4c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.3-0.1c-0.2-0.2-0.2-0.5-0.1-0.7
            c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.3-0.1,0.5-0.1c0,0,0.1,0,0.1,0.1c0.2,0.1,0.3,0.2,0.3,0.4c0,0.2-0.1,0.4-0.2,0.5
            C50.4,12.3,50.2,12.4,50.1,12.4C50.1,12.4,50.1,12.4,50.1,12.4z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="58.3,18.1 58.3,18 58.2,18 58.2,18.1 58.2,18.2 58.3,18.6 58.3,18.8 58.4,19
            58.4,19 58.4,19.1 58.5,19.1 58.6,19 58.8,19 58.9,18.9 58.9,18.9 58.9,18.8 58.8,18.5 58.8,18.5 58.8,18.5 58.7,18.4 58.8,18.3
            58.7,18.3 58.7,18.3 58.7,18.4 58.6,18.5 58.6,18.6 58.5,18.6 58.4,18.2 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M58.5,19.6C58.5,19.6,58.5,19.6,58.5,19.6c-0.2,0-0.4-0.1-0.5-0.3c0,0,0,0,0,0l-0.1-0.1
            c-0.1-0.1-0.1-0.2-0.1-0.3l0-0.1l-0.1-0.3c0,0,0-0.1,0-0.2l0-0.1c0-0.1,0-0.1,0-0.2l0-0.1c0.1-0.2,0.2-0.3,0.4-0.3
            c0.2,0,0.4,0.1,0.5,0.2l0,0.1c0,0,0.1,0,0.1,0c0.1,0,0.3,0.2,0.4,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2l0.1,0.3
            c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.3-0.2,0.4l-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.1l0,0l0,0C58.7,19.6,58.6,19.6,58.5,19.6z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="50.4,10.7 50.3,10.7 50.2,10.6 50.1,10.6 50,10.6 49.9,10.5 49.9,10.4 49.7,10.4
            49.6,10.2 49.3,10.2 49.3,10.2 49.4,10.3 49.4,10.4 49.5,10.5 49.6,10.6 49.7,10.6 49.7,10.6 49.8,10.7 50,10.8 50.2,10.9
            50.2,10.9 50.3,10.9 50.6,11.1 50.7,11.2 50.9,11.3 50.9,11.2 50.8,11.2 50.8,11.1 50.8,11 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M50.8,11.8c-0.1,0-0.2,0-0.2-0.1l-0.1-0.1c0,0-0.1,0-0.1-0.1l0,0l-0.2-0.1c0,0-0.1,0-0.1-0.1
            c0,0-0.1,0-0.1,0l-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.1L49.4,11c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1l-0.1-0.1c-0.1-0.1-0.2-0.1-0.2-0.3
            c0,0,0,0-0.1-0.1c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.2,0.3-0.3,0.5-0.2l0.3,0.1c0.1,0,0.2,0.1,0.2,0.1l0.2,0.1c0,0,0.1,0.1,0.1,0.1
            c0.1,0,0.1,0,0.2,0.1l0,0c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1l0.5,0.3c0.1,0.1,0.2,0.3,0.2,0.4c0,0.1,0,0.1,0,0.1
            c0,0.2-0.1,0.4-0.2,0.5C51,11.7,50.9,11.8,50.8,11.8z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="44.4,14.4 44.5,14.4 44.5,14.4 44.6,14.4 44.7,14.4 44.7,14.4 44.7,14.3 44.5,14.3
            44.5,14.4 44.4,14.3 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M44.5,14.9C44.5,14.9,44.5,14.9,44.5,14.9c-0.2,0-0.4-0.1-0.5-0.2c0,0-0.1-0.1-0.1-0.2l0,0
            c-0.1-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.3,0l0.2,0c0,0,0.1,0,0.1,0c0.2,0.1,0.4,0.4,0.3,0.6
            c0,0.1-0.1,0.3-0.3,0.4c-0.1,0-0.1,0.1-0.2,0.1l0,0C44.6,14.9,44.6,14.9,44.5,14.9z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="45.6,8.1 45.5,8 45.3,7.9 45.3,7.9 45.2,7.9 45.2,7.9 45.2,8 45.2,8.2 45.2,8.3
            45.2,8.4 45.3,8.4 45.3,8.5 45.4,8.6 45.5,8.7 45.5,8.7 45.7,8.8 45.7,8.9 45.7,9 45.8,9.2 45.9,9.3 46,9.3 46.1,9.4 46.2,9.4
            46.3,9.4 46.3,9.4 46.4,9.3 46.5,9.3 46.5,9.3 46.5,9.3 46.5,9.2 46.5,9.1 46.5,9 46.4,8.9 46.3,8.8 46.2,8.7 46.1,8.7 46.1,8.7
            46,8.6 45.9,8.4 45.8,8.4 45.7,8.3 45.7,8.3 45.7,8.2 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M46.3,9.9C46.3,9.9,46.3,9.9,46.3,9.9l-0.3-0.1c0,0-0.1,0-0.1,0l-0.3-0.2c0,0-0.1,0-0.1-0.1
            l-0.1-0.1c0,0,0-0.1-0.1-0.1l-0.1-0.1c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.1-0.1l0,0c0,0-0.1,0-0.1-0.1l-0.1-0.1
            c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1l0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2l0-0.2l0,0c0-0.2,0-0.3,0.2-0.4
            c0.1-0.1,0.3-0.2,0.4-0.1c0.1,0,0.2,0.1,0.2,0.1l0,0c0.1,0,0.2,0.1,0.2,0.1l0.1,0.1c0,0,0.1,0.1,0.1,0.1L46.1,8c0,0,0,0,0,0
            c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1l0,0c0,0,0.1,0.1,0.1,0.1l0.2,0.2c0,0,0,0.1,0.1,0.1L47,8.9L47,9.1c0,0.1,0,0.2,0,0.3
            l0,0.1c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.2,0.1-0.3,0.1C46.5,9.9,46.4,9.9,46.3,9.9z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="47.3,10.6 47.3,10.6 47.4,10.5 47.5,10.4 47.5,10.4 47.4,10.3 47.3,10.2 47.2,10.2
            47.1,10.3 47.2,10.3 47.3,10.4 47.3,10.5 47.3,10.5 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M47.3,11.1C47.3,11.1,47.3,11.1,47.3,11.1c-0.2,0-0.4-0.1-0.4-0.2l0,0c0,0-0.1-0.1-0.1-0.1h0
            c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.4,0.1-0.5c0.1-0.2,0.5-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.2
            c0.1,0.1,0.1,0.3,0,0.4c0,0.1-0.1,0.2-0.1,0.2l-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2C47.5,11,47.4,11.1,47.3,11.1z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="46.3,9.6 46.2,9.7 46.3,9.7 46.4,9.7 46.4,9.6 46.3,9.5 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M46.3,10.2c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.4-0.4-0.3-0.7c0-0.1,0.1-0.2,0.1-0.2l0,0
            C46.1,9,46.3,9,46.5,9.1l0.1,0.1c0.2,0.1,0.3,0.4,0.2,0.7c-0.1,0.1-0.2,0.3-0.4,0.3l-0.1,0C46.4,10.2,46.3,10.2,46.3,10.2z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="48.1,11.3 48.1,11.2 48.1,11.1 48.1,11.1 47.9,11.2 47.9,11.3 48.1,11.4 48.1,11.5
            48.1,11.4 48.1,11.4 48.1,11.3 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M48.1,12c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.1-0.1c-0.1-0.2-0.2-0.4-0.1-0.6
            c0-0.1,0.1-0.2,0.2-0.2l0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.5,0,0.6,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0,0,0,0,0c0,0,0,0.1,0,0.1
            c0,0.2-0.1,0.3-0.2,0.4C48.3,11.9,48.2,12,48.1,12z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="48.2,9.9 48.1,9.9 48.1,9.9 47.9,9.7 47.9,9.7 47.8,9.5 47.7,9.4 47.4,9.3 47.3,9.2
            47.1,9.1 47.1,9.2 47.1,9.3 47.1,9.3 47.4,9.5 47.6,9.8 47.8,9.9 48,9.9 48,9.9 48,10 48.1,10 48.2,10 48.3,10 48.2,9.9 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M48.2,10.5c0,0-0.1,0-0.1,0l-0.1,0c0,0-0.1,0-0.1,0l-0.1,0c-0.1,0-0.1,0-0.2-0.1l-0.2-0.2
            c0,0-0.1-0.1-0.1-0.1L47,9.8l-0.2-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.4,0.1-0.5c0.1-0.1,0.3-0.2,0.5-0.2
            l0.1,0c0.1,0,0.1,0,0.2,0.1l0.1,0.1L47.9,9c0,0,0.1,0,0.1,0.1l0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.2l0,0c0,0,0.1,0,0.1,0l0,0
            c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.3,0.1,0.4c0,0.1-0.1,0.3-0.2,0.4l-0.1,0.1C48.4,10.5,48.3,10.5,48.2,10.5z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="47.8,10.5 47.7,10.5 47.6,10.6 47.6,10.7 47.7,10.8 47.9,10.8 47.9,10.8 47.9,10.7
            47.9,10.7 47.9,10.6 47.8,10.5 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M47.8,11.3C47.8,11.3,47.8,11.3,47.8,11.3l-0.1,0c-0.1,0-0.3-0.1-0.4-0.2L47.3,11
            c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.3,0.1-0.4l0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.3,0,0.4,0.1l0,0c0.1,0,0.1,0.1,0.1,0.1
            c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0-0.1,0.1-0.1,0.1C48.1,11.2,48,11.3,47.8,11.3z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="48.5,10.9 48.4,10.9 48.4,10.8 48.3,10.7 48.2,10.6 48.2,10.6 48.1,10.8 48,10.8
            47.9,10.9 47.9,10.9 47.9,11 48,11 48.1,11 48.2,10.9 48.3,11 48.3,11 48.3,11.1 48.4,11.2 48.5,11.2 48.6,11.3 48.6,11.3
            48.7,11.3 48.7,11.2 48.6,11 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M48.6,11.8c-0.1,0-0.3,0-0.4-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0
            c-0.2,0-0.4-0.2-0.4-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.3,0.2-0.4c0,0,0,0,0,0l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2
            c0.1,0,0.3-0.1,0.4,0l0.1,0c0.1,0.1,0.3,0.2,0.3,0.3c0,0,0.1,0.1,0.1,0.1l0.1,0.1c0,0,0.1,0.1,0.1,0.1l0.1,0.2c0,0.1,0,0.2,0,0.3
            c0,0.2-0.2,0.4-0.3,0.4c0,0-0.1,0-0.2,0.1C48.6,11.8,48.6,11.8,48.6,11.8z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="48.3,11.4 48.2,11.4 48.2,11.5 48.3,11.5 48.4,11.5 48.4,11.5 48.4,11.5 48.3,11.4
                "
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M48.4,12C48.4,12,48.3,12,48.4,12c-0.1,0-0.2,0-0.2,0L48,11.9c-0.2-0.1-0.3-0.2-0.3-0.4
            c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0.2,0,0.2,0l0.1,0c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.1,0.1,0.1
            c0.1,0.1,0.2,0.3,0.1,0.4c0,0.1-0.1,0.3-0.2,0.4C48.6,12,48.5,12,48.4,12z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="45,7.5 45,7.5 45,7.6 45,7.7 45.1,7.9 45.1,7.8 45.2,7.8 45.2,7.6 45.1,7.5
            45.1,7.4 45.1,7.4 45.1,7.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M45.1,8.4c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.2-0.2-0.3-0.3l0-0.1c0,0,0-0.1,0-0.1l0-0.1
            c-0.1-0.2,0-0.4,0.1-0.5l0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.4-0.1,0.6,0c0.1,0.1,0.2,0.3,0.2,0.4l0,0c0,0.1,0.1,0.2,0.1,0.3
            l0,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0-0.2,0.1-0.2,0.2C45.2,8.3,45.2,8.4,45.1,8.4z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="49,11.5 49,11.6 49,11.6 49.1,11.5 49,11.5 49,11.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M49,12.1c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.1-0.4,0-0.5l0.1-0.1c0.1-0.2,0.3-0.3,0.5-0.3
            c0.2,0,0.4,0.2,0.4,0.4c0.1,0.1,0.1,0.3,0,0.4s-0.1,0.3-0.3,0.3C49.2,12,49.1,12,49,12.1C49.1,12.1,49,12.1,49,12.1z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="48.8,11.2 48.8,11.3 48.7,11.3 48.7,11.3 48.7,11.3 48.7,11.4 48.7,11.5 48.8,11.5
            48.9,11.4 48.9,11.4 48.9,11.3 48.9,11.3 48.9,11.3 48.9,11.2 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M48.8,12c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.4-0.2-0.5
            c0,0,0-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.1,0.4-0.1l0.1,0c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.2,0.3,0.2,0.4
            c0,0.1,0,0.1,0,0.1c0,0.1-0.1,0.3-0.2,0.4l-0.1,0.1C49,12,48.9,12,48.8,12z"
          />
        </g>
        <polygon
          fill={"#878787"}
          points="59.5,57.3 59.4,57.3 59.4,57.4 59.4,57.4 59.3,57.5 59.3,57.6 59.3,57.6 59.4,57.6 59.5,57.5
          59.6,57.5 59.7,57.5 59.8,57.4 60,57.4 60,57.3 60.1,57.3 60.1,57.2 60,57.2 59.9,57.2 59.8,57.1 59.8,57.1 59.9,57.1 59.9,57
          59.7,56.8 59.5,56.8 59.5,57 59.6,57.1 59.5,57.2 	"
        />
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="60.2,20.3 60.1,20.3 60.1,20.2 60,20.2 60,20.2 60,20.2 59.9,20.2 59.9,20.3
            59.9,20.4 60,20.4 60,20.4 60.1,20.4 60.3,20.4 60.3,20.4 60.3,20.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M60.3,20.9c0,0-0.1,0-0.1,0l0,0c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.4-0.2
            c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.2,0-0.3c0-0.1,0.1-0.3,0.2-0.4c0,0,0.1-0.1,0.2-0.1c0.2-0.1,0.5,0,0.6,0.2c0,0,0,0,0.1,0
            c0,0,0,0,0,0c0.2,0.1,0.4,0.4,0.3,0.6C60.7,20.8,60.5,20.9,60.3,20.9z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="60.3,21.2 60.2,21.2 60.1,21.2 60.1,21.2 60.1,21.2 60,21.3 60,21.4 60,21.4
            60,21.4 60.1,21.4 60.1,21.5 60.1,21.5 60.2,21.5 60.4,21.5 60.4,21.4 60.4,21.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M60.2,22C60.2,22,60.2,22,60.2,22L60,22c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2
            c-0.1-0.1-0.1-0.3,0-0.4c0,0,0.1-0.2,0.1-0.2l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0s0.1,0,0.1,0l0,0
            c0.2,0,0.4,0.1,0.5,0.2l0.1,0.1c0.1,0,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.3,0,0.4l-0.1,0.1c-0.1,0.1-0.2,0.2-0.4,0.2L60.2,22
            C60.2,22,60.2,22,60.2,22z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="69.9,20.7 69.9,20.8 69.9,20.8 70,20.7 70,20.7 70,20.7 69.9,20.7 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M69.9,21.3c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0.1-0.2,0.2-0.3l0,0
            c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.3,0.1,0.4c0,0.1-0.1,0.2-0.1,0.3
            c-0.1,0.1-0.2,0.1-0.3,0.2C70,21.3,70,21.3,69.9,21.3z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="59.1,17 59.2,17.2 59.2,17.2 59.2,17.2 59.2,17.1 59.3,17.1 59.3,17.1 59.3,17.1
            59.3,17 59.2,16.9 59.1,17 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M59.2,17.7c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3-0.1-0.3-0.3l-0.1-0.1c-0.1-0.1-0.1-0.3,0-0.4l0,0
            c0-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.5-0.1,0.7,0l0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2
            c0,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0C59.4,17.6,59.3,17.7,59.2,17.7z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="59.6,18.7 59.4,18.9 59.5,18.9 59.6,18.9 59.7,18.9 59.8,18.7 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M59.6,19.4C59.6,19.4,59.6,19.4,59.6,19.4l-0.1,0c-0.1,0-0.2,0-0.2-0.1
            C59.1,19.3,59,19.1,59,19c0-0.2,0-0.3,0.1-0.4l0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1l0.2,0c0.2,0,0.4,0.1,0.5,0.2
            c0.1,0.2,0.1,0.4,0,0.5l-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2C59.9,19.3,59.7,19.4,59.6,19.4z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="58.7,25 58.8,25.1 58.8,25.2 59,25.2 59.1,25.3 59.1,25.3 59.2,25.2 59.2,25.2
            59.1,25.1 59,25 59,24.8 59,24.8 58.9,24.8 58.7,24.8 58.7,24.9 58.8,24.9 58.9,24.9 58.8,25 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M59.1,25.8c-0.1,0-0.3,0-0.4-0.1l0,0c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.2
            c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.2,0.2-0.3,0.3-0.4l0.2-0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2
            c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0l0.1,0.1c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.2C59.6,25.6,59.4,25.8,59.1,25.8
            C59.2,25.8,59.1,25.8,59.1,25.8z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="59.7,25.7 59.6,25.8 59.7,25.8 59.7,25.9 59.8,25.9 59.9,25.8 59.9,25.6 59.8,25.6
            59.8,25.6 59.8,25.6 59.8,25.5 59.8,25.5 59.7,25.6 59.6,25.6 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M59.8,26.4C59.8,26.4,59.8,26.4,59.8,26.4c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.3
            l0-0.1c-0.1-0.1-0.1-0.3,0-0.4c0.1-0.1,0.2-0.2,0.4-0.3l0.1,0c0.1,0,0.3,0,0.4,0c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0.1,0.1,0.1
            c0.1,0.1,0.2,0.2,0.2,0.4l0,0.2c0,0.2-0.2,0.4-0.4,0.4l-0.1,0C59.9,26.4,59.8,26.4,59.8,26.4z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="59.2,27 59.2,27 59.2,27 59.3,27 59.3,26.9 59.2,26.9 59.1,27 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M59.2,27.5C59.2,27.5,59.2,27.5,59.2,27.5c-0.1,0-0.2,0-0.3-0.1c0,0-0.1-0.1-0.1-0.1l0,0
            c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0.1-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.4,0l0.1,0c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0,0.4-0.1,0.5
            C59.6,27.4,59.4,27.5,59.2,27.5z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="61.1,23.4 61.2,23.5 61.3,23.6 61.4,23.6 61.4,23.5 61.3,23.4 61.3,23.2 61.2,23.2
            61.2,23.3 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M61.4,24.1c0,0-0.1,0-0.1,0l-0.1,0c-0.1,0-0.1-0.1-0.2-0.1l-0.1-0.1c0,0-0.1-0.1-0.1-0.1
            c-0.1-0.1-0.1-0.3-0.1-0.4l0-0.1c0-0.1,0.1-0.3,0.2-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0.2,0,0.4,0.2,0.4,0.3c0.2,0.1,0.3,0.4,0.2,0.6
            l-0.1,0.1C61.7,24,61.6,24.1,61.4,24.1z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="59.9,19.1 60,19.4 60,19.5 60,19.5 60.1,19.4 60.1,19.4 60,18.9 60,18.9 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M60,20c-0.2,0-0.4-0.1-0.5-0.3c0,0,0-0.1,0-0.1l-0.1-0.3c0-0.1,0-0.1,0-0.2l0-0.2
            c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.1,0.5,0.3c0,0,0.1,0.1,0.1,0.2l0.1,0.4c0,0.1,0,0.1,0,0.2c0,0.2-0.2,0.4-0.3,0.4
            C60.2,19.9,60.1,20,60,20C60,20,60,20,60,20z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="61.8,24.3 61.9,24.2 61.8,24.2 61.8,24.2 61.7,24.2 61.7,24.2 61.8,24.3 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M61.8,24.8C61.8,24.8,61.8,24.8,61.8,24.8L61.8,24.8c-0.1,0-0.2,0-0.3-0.1
            c-0.2-0.1-0.4-0.4-0.3-0.6c0.1-0.2,0.2-0.4,0.4-0.4c0,0,0.2,0,0.2,0c0.1,0,0.2,0.1,0.3,0.1c0.2,0.2,0.3,0.5,0.1,0.7
            C62.2,24.6,62,24.8,61.8,24.8z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="59.8,19.7 59.7,19.8 59.7,19.8 59.8,19.8 60,19.9 60.1,19.9 60.1,19.8 60.1,19.8
            60,19.8 60,19.6 60,19.6 59.9,19.7 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M60,20.4C60,20.4,59.9,20.4,60,20.4l-0.3,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.2
            c-0.1-0.1-0.1-0.3-0.1-0.4s0.1-0.3,0.2-0.3c0,0,0.1,0,0.1-0.1l0,0c0.1-0.1,0.2-0.2,0.4-0.1c0.1,0,0.3,0.1,0.4,0.2
            c0,0.1,0.1,0.2,0.1,0.2l0,0c0,0,0,0,0,0c0.1,0.1,0.1,0.2,0.1,0.3c0,0.3-0.2,0.5-0.5,0.5L60,20.4C60,20.4,60,20.4,60,20.4z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="61.2,22.8 61.2,22.8 61.3,22.8 61.2,22.7 61.1,22.6 61.2,22.6 61.1,22.6 61,22.4
            61,22.4 60.9,22.5 60.9,22.5 60.9,22.7 60.9,22.7 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M61.2,23.3C61.2,23.3,61.1,23.3,61.2,23.3c-0.1,0-0.2,0-0.2,0l-0.3-0.1
            c-0.2-0.1-0.3-0.3-0.3-0.5l0-0.1c0-0.1,0-0.2,0-0.2c0.1-0.1,0.2-0.3,0.3-0.3c0.1,0,0.3-0.1,0.4,0c0.2,0,0.3,0.2,0.4,0.3l0,0
            c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.1,0.5,0,0.7C61.5,23.2,61.3,23.3,61.2,23.3z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="71.1,21 71,21 70.9,21 70.7,21.1 70.6,21 70.4,21.1 70.2,21.2 70.1,21.3 70.1,21.4
            70.1,21.4 70.1,21.5 70.1,21.5 70.1,21.5 70.1,21.5 70,21.6 70,21.6 70,21.6 70,21.7 70,21.8 70.1,21.9 70.2,21.9 70.4,21.9
            70.5,22 70.6,22 70.7,22 70.9,22 71,22 71,21.9 71.1,21.9 71.2,21.9 71.3,21.9 71.3,21.9 71.4,21.9 71.5,21.8 71.5,21.7
            71.4,21.6 71.4,21.5 71.4,21.4 71.4,21.3 71.4,21.3 71.2,21.2 71.2,21.1 71.1,21.1 71.1,21.1 71.1,21 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M70.7,22.5C70.7,22.5,70.7,22.5,70.7,22.5l-0.2,0c-0.1,0-0.1,0-0.2,0l-0.3-0.1
            c0,0-0.1,0-0.2-0.1c0,0-0.1,0-0.1-0.1c-0.1,0-0.2-0.2-0.2-0.2l-0.1-0.1c0,0,0-0.1-0.1-0.2c0,0,0-0.2,0-0.2c0-0.1,0.1-0.3,0.2-0.4
            c0-0.1,0.1-0.2,0.1-0.3l0.3-0.2c0,0,0.1,0,0.1-0.1l0.2-0.1c0.1,0,0.1,0,0.2,0l0,0l0.1,0c0.1,0,0.2-0.1,0.3,0
            c0.1,0,0.3,0.1,0.3,0.2l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0,0.1,0,0.1
            l0.1,0.2c0.1,0.2,0,0.5-0.3,0.6c-0.1,0-0.2,0.1-0.3,0.1l0,0c0,0-0.1,0-0.1,0l0,0c-0.1,0.1-0.2,0.1-0.3,0.1L70.7,22.5
            C70.8,22.5,70.8,22.5,70.7,22.5z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="71.1,22.8 71.1,22.8 71,22.8 71,22.9 70.9,22.9 70.8,22.9 70.8,23 70.7,23 70.8,23
            71,23 71,23 71,23 71,22.9 71.1,22.9 71.1,22.9 71.2,22.9 71.3,22.9 71.3,22.8 71.2,22.8 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M70.8,23.5c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.4,0.2-0.5
            c0,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3,0l0.1,0c0,0,0.1,0,0.2,0c0.2,0.1,0.3,0.3,0.3,0.5
            c0,0.2-0.2,0.5-0.4,0.5l-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0l-0.2,0C70.9,23.5,70.8,23.5,70.8,23.5z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="69.8,15.6 69.8,15.6 69.8,15.6 69.8,15.6 69.7,15.6 69.7,15.6 69.7,15.6 69.8,15.6
                "
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M69.8,16.1C69.8,16.1,69.7,16.1,69.8,16.1c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
            s0.2-0.3,0.4-0.3c0,0,0.2,0,0.2,0c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.4,0.1,0.5c-0.1,0.1-0.2,0.3-0.3,0.3l0,0
            C69.9,16.1,69.8,16.1,69.8,16.1z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="67.1,25.9 67.1,25.9 67,25.9 67,25.9 67,25.9 67.1,25.9 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M67.1,26.4C67,26.4,67,26.4,67.1,26.4c-0.3,0-0.5-0.2-0.5-0.4c-0.1-0.2,0-0.5,0.2-0.6
            c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0.1,0.4,0.3,0.4,0.5c0,0.1,0,0.2-0.1,0.3l0,0C67.4,26.3,67.2,26.4,67.1,26.4z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="43.3,11.9 43.3,11.9 43.2,11.8 43.2,11.8 43.1,11.8 43.1,11.7 42.9,11.7 42.9,11.7
            42.8,11.7 42.8,11.8 42.9,11.8 43,11.9 43,11.9 43,11.9 43.1,12 43.2,12 43.3,12 43.2,11.9 43.3,11.9 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M43.1,12.5c-0.1,0-0.1,0-0.2,0l-0.1-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.1-0.3-0.2-0.3-0.4
            s0.1-0.4,0.2-0.5c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0l0,0c0.2,0,0.3,0,0.4,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.2,0.4
            c0,0.1,0,0.2-0.1,0.3l0,0c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.4,0.2C43.2,12.5,43.2,12.5,43.1,12.5z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="59.2,17.5 59.1,17.5 59.1,17.6 59.2,17.6 59.3,17.6 59.3,17.6 59.3,17.5 59.2,17.4
                "
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M59.3,18.1C59.2,18.1,59.2,18.1,59.3,18.1l-0.2,0c0,0-0.1,0-0.1,0c-0.2-0.1-0.3-0.3-0.3-0.5
            c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.1-0.1,0.2-0.2l0.1,0c0.1,0,0.3,0,0.4,0l0.1,0c0.2,0.1,0.3,0.3,0.3,0.5c0,0.1-0.1,0.2-0.1,0.3
            C59.6,18,59.4,18.1,59.3,18.1z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="59,19.4 58.9,19.4 58.9,19.4 58.9,19.6 58.9,19.7 59,19.7 59,19.6 59.1,19.7
            59.1,19.8 59.1,19.9 59.2,20.1 59.2,20.1 59.2,20.1 59.3,20.1 59.4,20.1 59.5,20.1 59.6,20 59.6,19.9 59.5,19.9 59.5,19.8
            59.4,19.8 59.4,19.8 59.2,19.7 59.2,19.6 59.2,19.6 59.1,19.6 59.1,19.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M59.3,20.6c0,0-0.1,0-0.1,0c-0.2,0-0.4-0.2-0.4-0.3c0,0,0-0.1,0-0.1l0,0c0,0,0,0,0-0.1
            c0,0-0.1,0-0.1-0.1l-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4l0-0.2c0,0,0-0.1,0-0.1c0.1-0.2,0.2-0.3,0.4-0.3c0.1,0,0.3,0,0.4,0l0.1,0
            c0.1,0.1,0.2,0.1,0.2,0.3l0,0c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0.2,0.2,0.2,0.2l0,0c0.1,0.2,0.1,0.5-0.1,0.6
            l-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.1C59.4,20.6,59.4,20.6,59.3,20.6z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="58.3,24.5 58.3,24.5 58.2,24.5 58.2,24.5 58.2,24.6 58.2,24.7 58.1,24.8 58.2,24.8
            58.2,24.7 58.3,24.7 58.2,24.7 58.2,24.6 58.3,24.5 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M58.2,25.3c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2-0.1-0.4,0-0.5l0,0l0-0.1c0-0.1,0.1-0.2,0.1-0.3
            c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
            c0,0.1-0.1,0.2-0.1,0.2C58.5,25.2,58.4,25.3,58.2,25.3C58.2,25.3,58.2,25.3,58.2,25.3z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="57.1,25.3 57,25.2 56.9,25.2 56.9,25.1 56.9,25.1 56.8,25 56.8,24.9 56.7,24.9
            56.6,24.8 56.5,24.8 56,24.4 55.9,24.3 55.7,24.3 55.6,24.3 55.5,24.2 55.4,24.2 55.4,24.3 55.5,24.3 55.5,24.3 55.6,24.4
            55.6,24.5 55.6,24.5 55.7,24.7 55.8,24.8 55.9,24.9 56,25 56.1,25.1 56.3,25.4 56.4,25.5 56.5,25.5 56.8,25.7 56.8,25.8 57,25.8
            57.2,26 57.3,26 57.4,26.1 57.5,26.2 57.7,26.2 57.7,26.3 57.7,26.3 57.8,26.4 57.9,26.4 58,26.5 58.1,26.5 58.1,26.6 58.2,26.6
            58.2,26.6 58.4,26.7 58.5,26.7 58.6,26.7 58.7,26.6 58.7,26.6 58.6,26.4 58.5,26.3 58.3,26.2 58.2,26.1 58.1,26 57.9,25.9
            57.6,25.7 57.5,25.6 57.5,25.6 57.4,25.5 57.3,25.5 57.2,25.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M58.4,27.2c-0.1,0-0.2,0-0.2-0.1L58,27c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0,0l0,0
            c0,0-0.1,0-0.1,0l-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0l-0.2-0.1c0,0-0.1-0.1-0.1-0.1l-0.1,0l-0.3-0.1c0,0-0.1,0-0.1-0.1
            l-0.4-0.3c-0.1,0-0.2-0.1-0.2-0.1l-0.4-0.4c0,0-0.1,0-0.1-0.1l-0.1-0.1c0,0,0-0.1-0.1-0.1l-0.2-0.2c-0.1-0.1-0.1-0.1-0.1-0.2
            c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3,0,0.4,0.1l0.1,0.1
            c0.1,0,0.2,0,0.3,0.1l0.7,0.5c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1l0,0
            c0,0,0,0,0.1,0.1l0,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0.1l0.4,0.2l0.7,0.5c0.1,0.1,0.2,0.2,0.2,0.3l0.1,0.2
            c0,0.1,0,0.3,0,0.4c-0.1,0.1-0.2,0.3-0.3,0.3c-0.1,0-0.2,0.1-0.3,0.1C58.5,27.2,58.5,27.2,58.4,27.2z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="58.8,19.1 58.9,19.2 58.9,19.2 58.9,19.1 58.8,19.1 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M58.9,19.7c-0.1,0-0.2,0-0.2-0.1l-0.1-0.1c-0.2-0.1-0.3-0.4-0.2-0.6l0-0.1
            c0.1-0.2,0.3-0.4,0.6-0.3l0.1,0c0.3,0.1,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3C59.2,19.6,59.1,19.7,58.9,19.7z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="57.7,13.6 57.6,13.6 57.5,13.6 57.4,13.6 57.4,13.7 57.4,13.7 57.4,13.7 57.4,13.8
            57.4,13.8 57.5,13.8 57.6,13.7 57.6,13.7 57.6,13.7 57.7,13.7 57.7,13.7 57.8,13.6 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M57.5,14.3C57.5,14.3,57.4,14.3,57.5,14.3c-0.3,0-0.5-0.1-0.5-0.3c0-0.1-0.1-0.2-0.1-0.3
            c0-0.1,0.1-0.3,0.2-0.4c0,0,0,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.2-0.1,0.2-0.1l0.1,0c0,0,0.1,0,0.1,0l0.1,0c0,0,0.1,0,0.1,0
            l0,0c0.1,0.1,0.3,0.2,0.3,0.3c0,0.1,0,0.3-0.1,0.4c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0-0.1,0
            C57.7,14.3,57.6,14.3,57.5,14.3z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="72.9,20.5 72.9,20.6 72.9,20.6 73,20.6 73,20.5 73,20.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M72.9,21.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.4-0.1-0.5l0-0.1c0,0,0-0.1,0.1-0.1
            l0.1-0.1c0.1-0.2,0.4-0.2,0.6-0.2c0.2,0.1,0.3,0.3,0.3,0.5v0.2c0,0.2-0.1,0.4-0.3,0.5C73.1,21.1,73,21.1,72.9,21.1z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="73,20.1 73,20.1 73,20 73,20.1 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M73,20.6c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.3l0,0c0-0.1,0-0.2,0-0.2
            c0.1-0.2,0.3-0.4,0.6-0.3c0.2,0,0.4,0.2,0.4,0.5C73.5,20.3,73.3,20.6,73,20.6C73,20.6,73,20.6,73,20.6z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="72.7,19.7 72.7,19.7 72.6,19.7 72.5,19.8 72.4,19.8 72.3,19.9 72.2,19.9 72.1,19.9
            72,20 71.8,20 71.7,20.1 71.7,20.2 71.6,20.2 71.5,20.2 71.5,20.2 71.4,20.2 71.4,20.2 71.4,20.3 71.3,20.3 71.3,20.4 71.4,20.4
            71.5,20.4 71.5,20.5 71.6,20.6 71.7,20.6 71.8,20.5 71.8,20.5 71.9,20.5 71.9,20.4 72.1,20.3 72.2,20.3 72.2,20.3 72.3,20.4
            72.3,20.4 72.3,20.4 72.4,20.4 72.5,20.4 72.7,20.3 72.8,20.3 72.9,20.3 72.9,20.2 72.9,20.1 72.9,20.1 72.9,20.1 72.9,20.1
            72.8,20.1 72.6,20.2 72.5,20.3 72.5,20.2 72.6,20.1 72.7,20 72.8,19.9 72.8,19.8 73,19.7 73,19.7 72.8,19.7 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M71.6,21.1c-0.2,0-0.3-0.1-0.4-0.2c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.2-0.2
            c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0l0.1-0.1
            c0,0,0.1,0,0.1,0l0.3-0.1c0,0,0,0,0.1,0l0,0c0,0,0.1-0.1,0.1-0.1c0,0,0.2-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0l0.1,0
            c0.2-0.1,0.3,0,0.5,0.1s0.2,0.3,0.2,0.4c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3
            c-0.1,0.1-0.3,0.3-0.4,0.3l-0.1,0l-0.3,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0C72.1,21,72,21,71.9,21c0,0,0,0,0,0c0,0-0.1,0-0.1,0
            l-0.1,0C71.6,21.1,71.6,21.1,71.6,21.1z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="72.3,21.8 72.3,21.7 72.2,21.7 72.2,21.7 72.2,21.7 72.2,21.8 72.3,21.9 72.3,21.9
                "
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M72.3,22.4c-0.1,0-0.2,0-0.3-0.1l-0.1-0.1c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.1,0-0.2,0.1-0.2
            c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.3,0.1,0.4,0.2c0,0,0.1,0.1,0.1,0.2c0,0.1,0.1,0.3,0,0.4l0,0
            c0,0.1-0.1,0.1-0.1,0.2C72.5,22.3,72.4,22.4,72.3,22.4z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="71.7,21.4 71.7,21.3 71.6,21.3 71.6,21.4 71.6,21.5 71.7,21.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M71.7,21.9c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.1,0.3-0.3,0.4-0.3
            c0.2,0,0.5,0.1,0.6,0.3c0,0.1,0.1,0.3,0,0.5l0,0.1c-0.1,0.1-0.2,0.2-0.3,0.2C71.8,21.9,71.7,21.9,71.7,21.9z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="72.4,21 72.4,20.9 72.3,20.9 72.3,20.9 72.3,21.1 72.3,21 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M72.3,21.5c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.3-0.2-0.3-0.4l0-0.2c0-0.1,0-0.3,0.1-0.4
            c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1,0.1,0.3,0,0.4l0,0.1c0,0.1-0.1,0.1-0.1,0.2
            C72.6,21.5,72.4,21.5,72.3,21.5z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="40.9,12.6 40.8,12.5 40.9,12.6 40.9,12.7 41,12.8 41,12.8 41,13 41.3,13 41.3,13.1
            41.4,12.8 41.4,12.8 41.3,12.8 41.2,12.9 41.2,12.9 41.1,12.8 41.1,12.8 40.9,12.6 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M41.3,13.6c-0.1,0-0.2,0-0.3-0.1l-0.2-0.1c-0.2-0.1-0.3-0.2-0.3-0.3c0,0,0-0.1-0.1-0.1
            l-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.4,0.2-0.6c0.2-0.1,0.4-0.1,0.5,0c0.1,0,0.1,0.1,0.2,0.2l0,0c0.1,0,0.3,0,0.4,0.1
            c0.1,0.1,0.2,0.4,0.2,0.5l-0.1,0.3c0,0.2-0.2,0.3-0.3,0.3C41.5,13.6,41.4,13.6,41.3,13.6z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="37.6,4.4 37.6,4.4 37.5,4.4 37.5,4.5 37.6,4.5 37.7,4.4 37.6,4.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M37.6,5C37.6,5,37.6,5,37.6,5c-0.1,0-0.2,0-0.3-0.1C37.1,4.8,37,4.6,37,4.4
            c0-0.2,0.1-0.3,0.2-0.4c0,0,0,0,0.1,0l0,0c0.2-0.1,0.4-0.1,0.6,0c0.2,0.2,0.3,0.5,0.1,0.7C38,4.8,37.8,5,37.6,5z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="40.4,12.3 40.4,12.3 40.4,12.2 40.4,12.1 40.3,12 40.2,12 40.1,12 40.1,12.2
            40.3,12.3 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M40.3,12.8c0,0-0.1,0-0.1,0l-0.1,0c-0.1,0-0.1-0.1-0.2-0.1l-0.2-0.2
            c-0.1-0.1-0.2-0.3-0.2-0.5l0-0.1c0-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.4-0.2,0.6-0.1l0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.2l0,0.1
            c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.2l0,0.1c0,0,0,0.1-0.1,0.1C40.7,12.7,40.5,12.8,40.3,12.8z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="36.9,7.8 36.9,7.8 36.9,7.7 36.8,7.6 36.7,7.7 36.7,7.7 36.7,7.7 36.8,7.9 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M36.8,8.4c-0.1,0-0.3-0.1-0.4-0.2l-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.4
            c0-0.1,0.1-0.3,0.3-0.4l0.1-0.1c0.2-0.1,0.4-0.1,0.6,0l0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.3l0,0.1c0,0.1,0,0.3-0.1,0.4
            c-0.1,0.1-0.2,0.2-0.4,0.2L36.8,8.4C36.9,8.4,36.8,8.4,36.8,8.4z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="41.2,11.1 41.1,11.1 41.1,11.2 41.2,11.3 41.2,11.3 41.2,11.4 41.1,11.5 41.2,11.5
            41.2,11.5 41.2,11.3 41.2,11.2 41.2,11.1 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M41.2,12c-0.1,0-0.2,0-0.3-0.1l-0.1-0.1c-0.1-0.1-0.2-0.3-0.1-0.4c0-0.1-0.1-0.3,0-0.4
            c0-0.1,0.2-0.2,0.2-0.3c0,0,0.1-0.1,0.2-0.1l0,0c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4v0c0,0.1,0,0.2,0,0.2l0,0.1
            c0,0.2-0.1,0.3-0.2,0.4C41.4,12,41.3,12,41.2,12z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="37.6,7.9 37.6,8.1 37.7,8.2 37.8,8.2 37.8,8.3 37.8,8.3 37.9,8.2 37.9,8.1 37.9,8
            37.6,7.9 37.6,7.9 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M37.8,8.8c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.1-0.1-0.2-0.1l-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.3
            l0-0.1c0-0.1,0-0.1,0-0.2l0,0c0.1-0.2,0.2-0.3,0.4-0.3c0.1,0,0.2,0,0.3,0.1l0.2,0.1c0.1,0,0.1,0.1,0.2,0.2
            c0.1,0.1,0.1,0.2,0.1,0.4l0,0.1c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.4,0.3C37.9,8.8,37.8,8.8,37.8,8.8z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="35.5,6.9 35.5,6.9 35.5,7 35.5,7.1 35.5,7.1 35.6,7.1 35.7,7 35.6,6.9 35.6,6.9
            "
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M35.5,7.6c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.3-0.2-0.3-0.3C35,7.1,35,7,35,6.9
            c0-0.1,0.1-0.2,0.2-0.3c0,0,0.2-0.1,0.2-0.1l0,0c0.2-0.1,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.3,0,0.4l0,0.1
            c0,0.1-0.1,0.2-0.3,0.3C35.7,7.6,35.6,7.6,35.5,7.6z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="41,12.3 40.9,12.2 40.8,12.2 40.7,12.2 40.6,12.1 40.5,12.1 40.5,12.1 40.5,12.2
            40.5,12.2 40.6,12.3 40.5,12.4 40.5,12.4 40.6,12.4 40.7,12.5 40.9,12.5 40.9,12.5 40.9,12.5 41,12.5 41,12.5 41,12.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M40.9,13c-0.1,0-0.1,0-0.2,0l0,0c0,0-0.1,0-0.1,0l-0.1,0l-0.1,0c-0.2,0-0.4-0.1-0.4-0.3
            c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.2c0-0.2,0.2-0.4,0.4-0.4c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0.1l0,0
            c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1l0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.3l0,0.1c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.3-0.2,0.3
            C41.2,13,41,13,40.9,13z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="35.9,22 35.8,22 35.8,22 35.7,22 35.7,22.1 35.8,22.1 35.9,22.2 35.9,22.3 36,22.2
            36,22.2 35.9,22.1 35.9,22 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M35.9,22.8c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.2-0.3-0.3l0,0c-0.1,0-0.1-0.1-0.1-0.2
            c-0.1-0.2-0.1-0.4,0-0.5c0.1-0.1,0.2-0.3,0.4-0.2c0.1,0,0.2,0,0.2,0l0,0c0.2,0,0.4,0.2,0.4,0.4l0,0.1c0,0.1,0.1,0.3,0,0.4
            c0,0.1-0.1,0.2-0.1,0.2C36.2,22.7,36.1,22.8,35.9,22.8z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="38.8,24.1 38.8,24.1 38.8,24.2 38.8,24.2 38.8,24.2 38.8,24.1 38.9,24 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M38.8,24.7c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.3,0-0.4
            c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.2-0.2l0,0c0.2-0.1,0.4-0.1,0.5,0c0.2,0.1,0.3,0.3,0.2,0.5l0,0.1c0,0.1-0.1,0.3-0.2,0.3
            c-0.1,0-0.2,0.1-0.2,0.1C38.9,24.7,38.8,24.7,38.8,24.7z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="38.9,24.3 38.9,24.3 38.9,24.3 38.9,24.2 38.9,24.2 38.8,24.3 38.9,24.3 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M38.9,24.8C38.9,24.8,38.9,24.8,38.9,24.8L38.9,24.8c-0.1,0-0.2,0-0.2,0
            c-0.2-0.1-0.4-0.4-0.4-0.6l0-0.1c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0.1,0.5,0.3c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2-0.1,0.3-0.3,0.4
            C39.1,24.8,39,24.8,38.9,24.8z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="41.3,28 41.3,28 41.3,28 41.2,27.9 41.1,28 41.3,28.3 41.4,28.3 41.4,28.3 41.4,28.2
            41.4,28.2 41.3,28.1 41.4,28 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M41.3,28.8c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.2l-0.2-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
            c0.1-0.3,0.4-0.5,0.7-0.4l0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1l0,0c0.1,0.1,0.2,0.2,0.2,0.4c0,0,0,0,0,0c0.1,0.2,0.1,0.4,0,0.6
            c0,0-0.1,0.1-0.2,0.2C41.6,28.8,41.5,28.8,41.3,28.8z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="40.9,13.5 40.9,13.5 40.9,13.5 41,13.6 41,13.5 41,13.5 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M40.9,14.1C40.9,14.1,40.9,14,40.9,14.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.5
            c0.1-0.2,0.3-0.4,0.5-0.4l0.1,0c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.3,0.3,0.3,0.5c0,0.1-0.1,0.3-0.2,0.4C41.1,14,41,14.1,40.9,14.1z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="39.8,26.4 39.9,26.5 39.9,26.5 39.9,26.4 39.9,26.3 39.9,26.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M39.9,27c0,0-0.1,0-0.1,0c-0.2,0-0.3-0.2-0.4-0.3c0-0.1-0.1-0.2,0-0.3l0-0.1
            c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0.1,0.3,0.2,0.3,0.4l0,0.1c0,0,0,0.1,0,0.1c0,0.1-0.1,0.3-0.2,0.4
            C40.2,26.9,40,27,39.9,27z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="40.5,26.6 40.5,26.6 40.5,26.6 40.6,26.6 40.6,26.6 40.6,26.5 40.5,26.5 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M40.6,27.1c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.4-0.2-0.6c0-0.2,0.1-0.3,0.3-0.4
            c0.2-0.1,0.3-0.1,0.5,0c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0,0.6-0.2,0.7C40.8,27.1,40.7,27.1,40.6,27.1
            C40.6,27.1,40.6,27.1,40.6,27.1z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="42.9,5.3 42.9,5.2 42.8,5.3 42.8,5.3 42.8,5.4 42.9,5.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M42.9,5.9c-0.1,0-0.2,0-0.3-0.1c0,0-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.5,0-0.6
            c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.1,0.6,0,0.7,0.3l0,0.1c0,0.1,0,0.2,0,0.3l0,0.1c0,0.2-0.2,0.3-0.3,0.3C43,5.9,42.9,5.9,42.9,5.9z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="40.8,4.9 40.9,5 41.1,5 41.2,5 41.5,5.2 41.6,5.3 41.7,5.4 42,5.6 42.1,5.6 42.2,5.7
            42.2,5.7 42.3,5.7 42.4,5.7 42.5,5.8 42.6,5.9 42.8,6.3 42.9,6.4 43,6.6 43,6.7 42.9,6.8 42.9,6.8 43,7 43.1,7 43.1,7.1 43.2,7.2
            43.3,7.1 43.3,7 43.3,6.9 43.3,6.8 43.4,6.7 43.4,6.7 43.4,6.6 43.3,6.4 42.6,5.8 42.6,5.8 42.4,5.6 42.3,5.5 42.2,5.5 42,5.4
            41.8,5.3 41.7,5.2 41.6,5.1 41.4,5.1 41.3,5.1 41.3,5 41.1,4.9 40.9,4.7 40.9,4.8 40.9,4.9 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M43.2,7.7C43.2,7.7,43.1,7.7,43.2,7.7c-0.2,0-0.3-0.1-0.4-0.2c0,0-0.1-0.1-0.1-0.1
            c0,0-0.1-0.1-0.1-0.2L42.5,7c0-0.1,0-0.1,0-0.2c0,0,0-0.2,0-0.2l0-0.1l-0.3-0.3c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0L41.7,6l-0.3-0.2
            c-0.1,0-0.1-0.1-0.2-0.1l-0.3-0.2c-0.1,0-0.1,0-0.2,0l-0.1,0c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.1,0.1-0.3,0.2-0.4
            c0-0.1,0.1-0.1,0.2-0.2c0.2-0.1,0.4-0.2,0.6,0l0.3,0.2c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0L42.4,5c0.1,0,0.1,0.1,0.2,0.1
            c0,0,0.1,0.1,0.1,0.1l0.2,0.2c0,0,0.1,0.1,0.1,0.1L43.6,6c0,0,0.1,0.1,0.1,0.1l0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.3l0,0.1
            c0,0.1,0,0.2-0.1,0.3c0,0,0,0,0,0.1c0,0,0,0,0,0.1l0,0.1c0,0.1,0,0.1-0.1,0.2l-0.1,0.1c0,0,0,0.1-0.1,0.1l-0.1,0.1
            C43.4,7.6,43.3,7.7,43.2,7.7z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="42.2,4.9 42.1,4.9 42.1,4.9 42.1,5 42.1,5 42.2,5 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M42.1,5.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2-0.3,0.3-0.3
            c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.3,0.2,0.3,0.4l0,0.1c0,0.2-0.1,0.4-0.3,0.5C42.3,5.5,42.2,5.5,42.1,5.5z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="43.6,14.3 43.5,14.3 43.5,14.3 43.5,14.4 43.6,14.5 43.6,14.5 43.7,14.5 43.7,14.6
            43.9,14.6 43.9,14.6 44.1,14.6 44.1,14.6 44.1,14.5 43.9,14.5 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M43.9,15.1c-0.1,0-0.2,0-0.3-0.1l0,0c-0.1,0-0.2-0.1-0.3-0.2c0,0-0.1,0-0.1-0.1
            c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.1-0.2-0.1-0.2c0-0.2,0.1-0.3,0.2-0.5s0.3-0.2,0.5-0.1l0.1,0c0,0,0.1,0,0.1,0l0.4,0.2
            c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.3-0.3,0.4c0,0-0.2,0.1-0.2,0.1L43.9,15.1
            C43.9,15.1,43.9,15.1,43.9,15.1z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="39.2,8 39,7.9 38.9,7.9 38.7,7.9 38.6,7.9 38.5,7.9 38.5,7.9 38.4,7.9 38.3,7.9
            38.2,8 38.2,8.1 38.2,8.2 38.4,8.3 38.5,8.3 38.6,8.3 38.7,8.4 39,8.6 39,8.6 39.2,8.6 39.3,8.6 39.4,8.6 39.6,8.8 39.6,8.8
            39.7,8.8 39.8,8.8 40.2,8.8 40.5,8.8 40.5,8.8 40.6,8.7 40.8,8.6 40.8,8.6 40.9,8.5 41,8.5 41.1,8.5 41.2,8.5 41.3,8.4 41.5,8.3
            41.6,8.2 41.6,8.1 41.6,8.1 41.6,8 41.7,8 41.9,8 42,8 42.2,8 42.3,7.9 42.3,7.8 42.3,7.6 42.2,7.5 42.2,7.5 42.2,7.4 42.4,7.4
            42.4,7.4 42.5,7.3 42.6,7.2 42.6,7.1 42.6,7 42.6,6.9 42.6,6.8 42.6,6.7 42.5,6.6 42.4,6.6 42.3,6.5 42.2,6.6 42.1,6.6 42,6.6
            42,6.5 41.9,6.5 41.7,6.5 41.7,6.6 41.7,6.6 41.7,6.7 41.8,7 41.8,7.1 41.8,7.2 41.7,7.3 41.6,7.3 41.5,7.3 41.5,7.3 41.2,7.5
            41.2,7.6 41.1,7.7 41,7.8 41,7.9 40.9,7.9 40.9,7.9 40.8,7.9 40.7,7.9 40.6,7.9 40.4,7.9 40.3,8 40.2,7.9 40.1,7.8 40.1,7.7
            40.1,7.6 40.1,7.5 40.2,7.5 40.1,7.4 40.1,7.4 40,7.4 40,7.5 40,7.9 39.8,7.9 39.6,7.9 39.6,7.9 39.4,8 39.3,8 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M39.8,9.3l-0.2,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0
            c0,0-0.1,0-0.1-0.1l-0.5-0.3l-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.2l-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.3l0-0.1c0-0.1,0.1-0.3,0.2-0.4
            l0.1-0.1c0.1-0.1,0.3-0.1,0.5-0.1l0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0.1,0c0.1,0,0.1,0,0.2,0l0.3,0.1l0.1,0c0,0,0.1,0,0.1,0
            l0.1-0.2c0-0.1,0.1-0.2,0.2-0.3c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0.1,0
            c0,0,0-0.1,0.1-0.1l0.1-0.1c0,0,0.1-0.1,0.1-0.1l0.2-0.2c0,0,0.1,0,0.1-0.1l0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.2
            c0-0.1,0.1-0.2,0.1-0.2C41.4,6.1,41.6,6,41.7,6l0.1,0l0.2,0c0,0,0.1,0,0.1,0c0.2-0.1,0.4,0,0.5,0.1l0.1,0c0.1,0,0.3,0.1,0.3,0.2
            c0.1,0.1,0.1,0.3,0.1,0.4l0,0c0,0.1,0,0.1,0,0.2l0,0.1c0,0.1,0,0.2-0.1,0.2l-0.2,0.3c0,0,0,0,0,0.1c0,0.1,0,0.3-0.1,0.4l-0.1,0.1
            c0,0.1-0.1,0.1-0.1,0.1l-0.1,0.1c0,0-0.1,0-0.1,0.1l-0.1,0c-0.1,0-0.1,0-0.2,0l0,0c0,0,0,0,0,0.1l-0.3,0.2l-0.2,0.1
            c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-0.1,0c0,0,0,0,0,0c0,0-0.1,0.1-0.2,0.1l-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.2,0L39.8,9.3
            z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="42.3,5.2 42.3,5.2 42.3,5.1 42.3,5.1 42.2,5 42.1,5.1 42.2,5.1 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M42.3,5.7C42.3,5.7,42.3,5.7,42.3,5.7c-0.2,0-0.3-0.1-0.4-0.2l-0.1-0.1
            c-0.1-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.3,0.7-0.1l0.1,0.1c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.3,0.1,0.5C42.7,5.5,42.5,5.7,42.3,5.7z
            "
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="39.5,3.4 39.5,3.5 39.5,3.5 39.6,3.6 39.7,3.6 39.7,3.7 39.7,3.6 39.7,3.5 39.6,3.4
                "
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M39.7,4.2c0,0-0.1,0-0.1,0v0c-0.1,0-0.2,0-0.3-0.1l-0.1-0.1C39.1,3.8,39,3.7,39,3.5
            c0-0.1,0-0.2,0.1-0.3c0.1-0.2,0.4-0.3,0.5-0.3l0.1,0c0.1,0,0.2,0,0.2,0.1L40,3.1c0.1,0.1,0.1,0.1,0.2,0.2l0,0.1c0,0.1,0,0.1,0,0.2
            C40.2,3.8,40.2,4,40,4C39.9,4.1,39.8,4.2,39.7,4.2z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="44,6.3 44,6.3 43.9,6.4 44,6.4 44,6.4 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M44,6.9c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.3-0.2-0.3-0.4s0-0.3,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.2
            c0.1,0,0.3,0,0.4,0.1s0.2,0.2,0.2,0.4l0,0.1c0,0,0,0.1,0,0.1c0,0.1-0.2,0.2-0.3,0.3C44.1,6.9,44.1,6.9,44,6.9z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="40.2,4.5 40.1,4.5 40,4.6 40,4.6 40,4.7 40.1,4.8 40.2,4.8 40.3,4.8 40.5,4.8
            40.5,4.8 40.5,4.7 40.5,4.7 40.5,4.6 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M40.3,5.3c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
            c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.3,0.3-0.4l0.2-0.1c0,0,0.1,0,0.1,0l0.1,0c0.1,0,0.2,0,0.3,0l0.2,0.1c0.1,0,0.2,0.1,0.3,0.2
            c0,0.1,0.1,0.2,0.1,0.2c0,0,0,0.3,0,0.3c-0.1,0.2-0.2,0.4-0.5,0.4L40.3,5.3C40.3,5.3,40.3,5.3,40.3,5.3z"
          />
        </g>
        <g>
          <polygon
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            points="36.2,4.3 36.2,4.3 36.2,4.3 36.3,4.3 36.4,4.3 36.4,4.3 36.6,4.3 36.8,4.3 36.8,4.3
            36.9,4.3 37,4.2 37.1,4.2 37.1,4.2 37.2,4.2 37.2,4.1 37.2,4.1 37.2,4.1 37.2,4.1 37.1,4.1 36.8,4.1 36.5,4 36.4,4.1 36.3,4.1
            36.2,4.1 36.3,4.2 36.2,4.2 		"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M36.2,4.8C36.2,4.8,36.2,4.8,36.2,4.8c-0.2,0-0.4-0.2-0.5-0.4c0-0.1,0-0.2,0-0.2l0,0
            c0-0.1,0.1-0.3,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.3-0.1,0.4-0.1l0,0l0,0c0.1,0,0.1,0,0.2,0l0.5,0.1c0.1,0,0.3,0,0.4,0.1
            c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.2,0.4-0.4,0.5c0,0,0,0,0,0l0,0c-0.1,0.1-0.3,0.2-0.4,0.1c0,0-0.1,0-0.1,0l-0.2,0
            c0,0-0.1,0-0.1,0l-0.1,0c0,0-0.1,0-0.1,0C36.3,4.8,36.3,4.8,36.2,4.8z"
          />
        </g>
        <g transform="translate(0,-952.36216)">
          <g>
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M56,1002.8c-0.1,0-0.1,0-0.2,0l-0.7,0.4c-0.1,0-0.1,0.1-0.1,0.2l-0.1,0.5l-0.8,0.6
              c0,0-0.1,0.1-0.1,0.1l-0.3,0.9l-1.3,0.8l-1.1,0.6c0,0,0,0-0.1,0l-0.7,0.6c0,0-0.3,0.2-0.6,0.5c-0.1,0.1-0.3,0.2-0.4,0.3
              c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1s0,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3
              c-0.1,0.2-0.1,0.4-0.1,0.4c0,0.1,0,0.3,0.2,0.3l1.4,0.5l1.3,0.5c0.1,0,0.2,0,0.3,0l1.3-1.1c0,0,0,0,0.1-0.1l0.5-1c0,0,0,0,0,0
              l0.2-0.7l1.6-0.5c0.1,0,0.2-0.2,0.2-0.3c0,0,0,0,0,0l-0.1-0.4l0.2-0.2l1-0.9c0,0,0-0.1,0.1-0.1l0.3-0.6c0,0,0-0.1,0-0.1l0.1-0.9
              c0-0.1-0.1-0.3-0.2-0.3c0,0-0.1,0-0.1,0l-0.7,0.2l-0.2-0.8C56.2,1002.9,56.1,1002.8,56,1002.8C56,1002.8,56,1002.8,56,1002.8
              L56,1002.8z"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M51.9,1011.4c-0.1,0-0.2,0-0.3-0.1l-2.7-1c-0.4-0.1-0.6-0.6-0.5-1l0.3-0.9c0,0,0,0,0,0
              c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.2,0.5-0.4,0.6-0.4l0.7-0.6c0,0,0.1-0.1,0.1-0.1
              l2.3-1.2l0.2-0.7c0-0.2,0.1-0.3,0.3-0.4l0.6-0.5l0.1-0.3c0-0.2,0.2-0.4,0.3-0.5l0.7-0.4c0.1-0.1,0.3-0.1,0.4-0.1c0,0,0,0,0,0
              c0,0,0.1,0,0.1,0c0.3,0,0.5,0.2,0.6,0.5l0.1,0.3l0.2,0c0.1,0,0.2,0,0.3,0c0.4,0,0.7,0.4,0.7,0.8l-0.1,0.9c0,0,0,0.2-0.1,0.2
              l-0.3,0.6c0,0.1-0.1,0.2-0.2,0.3l-0.9,0.9l0,0.1c0.1,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.3-0.5,0.4l-1.3,0.4l-0.1,0.5
              c0,0,0,0.1,0,0.1l-0.5,1c0,0.1-0.1,0.2-0.2,0.2l-1.3,1.1C52.3,1011.4,52.1,1011.4,51.9,1011.4z M49.5,1009.5l2.3,0.9l1.2-1
              l0.4-0.9l0.2-0.7c0-0.2,0.2-0.3,0.3-0.3l1.4-0.4l-0.1-0.2c-0.1-0.2,0-0.4,0.1-0.5l0.2-0.2l1-0.9l0.2-0.5l0-0.5l-0.3,0.1
              c-0.3,0.1-0.5-0.1-0.6-0.3l-0.2-0.5l-0.4,0.2l-0.1,0.4c0,0.1-0.1,0.2-0.2,0.3l-0.7,0.5l-0.2,0.8c0,0.1-0.1,0.2-0.2,0.3l-2.5,1.3
              l-0.6,0.6c-0.1,0.1-0.3,0.3-0.6,0.5c-0.1,0.1-0.3,0.2-0.4,0.3c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0L49.5,1009.5z"
            />
          </g>
          <g>
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M57.3,994.3c-0.1,0-0.3,0.1-0.3,0.2c0,0.1,0,0.1,0,0.2l0.4,0.7l0,0.4c0,0.1,0,0.1,0,0.1
              l0.4,0.8l0.5,1.1l0.1,1.1l-0.2,0.9l-0.2,0.6l-0.3,0c-0.1,0-0.1,0-0.2,0.1l-0.3,0.3c-0.1,0.1-0.1,0.2,0,0.3l0.2,0.5
              c0,0.1,0.1,0.1,0.1,0.1l0.5,0.3l0.3,0.2l0.1,0.4l-0.4,0.5c0,0,0,0,0,0l-0.3,0.5c-0.1,0.1,0,0.3,0.1,0.4c0,0,0,0,0.1,0l0.3,0.1
              l0,0.2c0,0.1,0.1,0.3,0.2,0.3c0.1,0,0.1,0,0.2-0.1l0.3-0.3l0.6-0.2c0,0,0.1,0,0.1-0.1l0.8-0.9c0,0,0,0,0,0l0.4-0.7
              c0,0,0-0.1,0-0.1l0-0.4l0.3-0.1l0.3,0.3c0.1,0.1,0.3,0.1,0.4,0c0,0,0.1-0.1,0.1-0.1l0.1-0.6l0,0c0.1,0,0.2,0,0.2-0.1l0.2-0.2
              c0,0,0.1-0.1,0.1-0.1l0.1-0.4l0.3-0.3c0.1-0.1,0.1-0.3,0-0.4c0,0,0,0,0,0l-0.4-0.3c0,0-0.1,0-0.1-0.1l-0.2,0c-0.1,0-0.1,0-0.1,0
              l-0.4,0.2c0,0,0,0,0,0l-0.3,0.2l0,0l-0.7-0.5c0,0-0.1,0-0.1,0l-0.1,0l-0.1-1.1c0-0.1-0.1-0.2-0.2-0.2l-0.4,0c-0.1,0-0.1,0-0.2,0
              l-0.1-0.2l-0.1-0.8c0,0,0,0,0,0l-0.1-0.9c0-0.1,0-0.1-0.1-0.2l-0.9-0.6l-0.7-0.6C57.4,994.3,57.3,994.3,57.3,994.3L57.3,994.3z"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M58.5,1005.1C58.5,1005.1,58.4,1005.1,58.5,1005.1c-0.4,0-0.7-0.3-0.7-0.6c0,0-0.1,0-0.1,0
              c-0.4-0.2-0.5-0.7-0.3-1l0.3-0.5c0,0,0.1-0.1,0.1-0.1l0.2-0.3l-0.6-0.4c-0.1-0.1-0.3-0.2-0.3-0.4l-0.2-0.5
              c-0.1-0.3,0-0.6,0.1-0.8l0.3-0.3c0.1-0.1,0.3-0.2,0.5-0.2l0.3-1.1l-0.1-1l-0.4-1.1l-0.4-0.7c-0.1-0.1-0.1-0.3-0.1-0.4l0-0.2
              l-0.3-0.5c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0,0,0,0,0.1,0c0.1,0,0.2,0.1,0.3,0.2l0.7,0.6
              l0.9,0.6c0.2,0.1,0.3,0.3,0.3,0.5l0.2,1.5l0.2,0c0.4,0,0.6,0.3,0.7,0.7l0.1,0.8c0,0,0,0,0,0l0.4,0.3l0,0c0,0,0.1-0.1,0.1-0.1
              l0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.1l0.2,0c0.1,0,0.2,0.1,0.3,0.1l0.4,0.3c0.2,0.1,0.3,0.3,0.3,0.5s0,0.4-0.2,0.6l-0.2,0.2l0,0.3
              c0,0.2-0.1,0.3-0.2,0.4l-0.2,0.2c0,0-0.1,0.1-0.1,0.1l0,0.2c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.3-0.6,0.3-0.9,0.2c0,0,0,0.1-0.1,0.1
              l-0.4,0.7c0,0-0.1,0.1-0.1,0.1l-0.8,0.9c-0.1,0.1-0.2,0.2-0.3,0.2l-0.5,0.2l-0.3,0.2C58.8,1005,58.7,1005.1,58.5,1005.1z
              M58.4,1003.6L58.4,1003.6c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0,0.1-0.1l0.5-0.2l0.7-0.9l0.4-0.6l0-0.3c0-0.2,0.1-0.4,0.3-0.5
              l0.3-0.1c0.2-0.1,0.3,0,0.5,0.1l0-0.1c0-0.2,0.2-0.4,0.4-0.4l0.1-0.1l0.1-0.4c0-0.1,0.1-0.2,0.1-0.2l0.1-0.2l-0.2-0.1l-0.3,0.2
              l-0.3,0.2c0,0-0.1,0-0.1,0c-0.2,0.1-0.4,0.1-0.5-0.1l-0.7-0.5l0,0c-0.2-0.1-0.3-0.2-0.4-0.4l-0.1-0.9l-0.1,0c-0.1,0-0.2,0-0.3,0
              c-0.1,0-0.2-0.1-0.3-0.3l-0.1-0.2c0,0,0-0.1,0-0.2l-0.2-1.6l-0.8-0.5l0,0.3l0.4,0.7l0.5,1.2c0,0,0,0.1,0,0.1l0.1,1.1
              c0,0.1,0,0.1,0,0.2l-0.4,1.6c-0.1,0.2-0.2,0.3-0.4,0.4l-0.2,0l-0.1,0.1l0.1,0.2l0.8,0.5c0.1,0.1,0.2,0.2,0.2,0.3l0.1,0.4
              c0,0.2,0,0.3-0.1,0.5L58.4,1003.6z"
            />
          </g>
          <g>
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M32.8,965.8c-0.1,0-0.1,0-0.2,0l-0.5,0.2c-0.1,0.1-0.2,0.2-0.2,0.3l-0.1,1.1l-0.4,0.5
              c-0.1,0.2-0.1,0.4,0.1,0.6c0,0,0,0,0.1,0l-0.5,1.1c0,0.1,0,0.2,0,0.2l0.2,1.2l-1.1,2.6l-0.6-0.1l-0.6-0.7c0,0-0.1-0.1-0.1-0.1
              l-2.5-1.1l-1.1-1l0.6-1.2c0-0.1,0.1-0.2,0-0.3c0.2,0,0.4-0.2,0.4-0.4l0-0.4l0.4-0.3c0.2-0.1,0.2-0.4,0.1-0.6l-0.4-0.6
              c-0.1-0.2-0.4-0.2-0.6-0.1c0,0,0,0,0,0l-0.2,0.2c-0.1-0.1-0.2-0.1-0.3-0.1l-0.6,0.1l-0.5-0.3c-0.1-0.1-0.3-0.1-0.4,0l-0.3,0.2
              l0-0.1c-0.1-0.2-0.3-0.3-0.5-0.3l-0.4,0.1l-1.6-0.6c-0.2-0.1-0.4,0-0.5,0.2c-0.1,0.1,0,0.3,0.1,0.4l0.5,0.5l-1,0.1h-0.4
              c0,0-0.1,0-0.1,0l-0.5,0.2c-0.1,0-0.2,0.1-0.3,0.3c-0.2,0-0.4,0.2-0.4,0.4c0,0,0,0,0,0l0.1,0.5l-0.4,0.1c-0.1,0-0.2,0.1-0.2,0.2
              l-0.5,0.8c-0.1,0.2,0,0.4,0.1,0.6l0.4,0.2l0,0l-1-0.4c-0.1,0-0.1,0-0.2,0l-0.2,0l-0.6-0.9c-0.1-0.1-0.2-0.2-0.3-0.2l-0.8,0
              c-0.1,0-0.2,0-0.3,0.1l-0.4,0.3l-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.3l0,0c-0.2-0.1-0.4-0.1-0.5,0.1l-0.6,0.7
              c-0.1,0.1-0.1,0.3-0.1,0.4l-0.1,0c-0.1,0-0.2,0.1-0.2,0.2l-0.5,0.7c-0.1,0.1-0.1,0.2,0,0.4l-0.3-0.1c-0.2-0.1-0.4,0-0.5,0.2
              c0,0.1,0,0.2,0,0.2l0,0.2c-0.1,0-0.3,0-0.3,0.1l-0.9,0.9c-0.1,0.1-0.1,0.2-0.1,0.4l0.2,0.8l-1.2,1.3l-2,1c0,0,0,0,0,0l-1.6,1.1
              L4,978.8c-0.1,0-0.2,0-0.3,0.1l-2.1,1.6c-0.1,0.1-0.1,0.1-0.1,0.2l0,0.2c-0.1-0.1-0.3,0-0.4,0.1l-0.3,0.3
              c-0.1,0.1-0.2,0.2-0.2,0.3v2.7c0,0.1,0,0.1,0,0.2l0.8,1.7l-0.2,0.3c-0.1,0.1-0.1,0.3,0,0.5l1.5,1.8l0.8,1.9l0.3,1.2
              c0,0,0,0.1,0.1,0.1l1,1.4l0.2,0.8l-0.5,0.3c-0.1,0.1-0.2,0.2-0.2,0.3l-0.1,0.8c0,0.2,0.1,0.4,0.3,0.4l2.1,0.8c0.1,0,0.1,0,0.2,0
              l1.1-0.2c0.1,0,0.2-0.1,0.3-0.2l1.3-1.8l3.3-0.1c0.2,0,0.3-0.1,0.4-0.3l0.4-1.4l1.7-0.7l4.7-1l2.6,1l0,0c0,0.2,0,0.3,0.2,0.4
              l0.5,0.3l0.7,1.6c0,0.1,0.1,0.2,0.2,0.2l0.5,0.3c0.2,0.1,0.4,0.1,0.6-0.1c0,0,0-0.1,0.1-0.1l0.1-0.4l1.1-1.2l0,0.1l-0.4,0.6
              c0,0.1-0.1,0.1-0.1,0.2l0,0.3l-0.2,0.1c-0.2,0.1-0.2,0.4-0.1,0.6c0.1,0.1,0.2,0.2,0.3,0.2l0.7,0.1c0.1,0,0.1,0,0.2,0
              c0,0.2,0.1,0.4,0.2,0.4l0.9,0.4l0.1,1.2c0,0.1,0,0.2,0.1,0.2l0.9,1.1c0,0.1,0.1,0.1,0.2,0.1l2.8,1c0.1,0,0.3,0,0.4-0.1l0.9-0.8
              l0.7,1c0.1,0.2,0.4,0.2,0.5,0.1l1.3-0.8c0,0,0,0,0,0l0.3-0.3l1.9-0.2c0.2,0,0.3-0.1,0.4-0.3l0.6-2.4l1-1.7l1.9-1.7
              c0,0,0.1-0.1,0.1-0.2l1.4-3.7c0-0.1,0-0.1,0-0.2l-0.2-1.8l0.2-1.7c0-0.1,0-0.2,0-0.2l-1.3-2.6c0-0.1-0.1-0.1-0.1-0.2l-0.5-0.3
              l-0.6-1.5c-0.1-0.2-0.2-0.3-0.4-0.3l-0.2,0l-0.5-2.3c0-0.1-0.1-0.2-0.2-0.2l-2.6-1.7l-0.3-2.2c0-0.1,0-0.2-0.1-0.2l-0.5-0.5
              l0.1-1.6c0-0.1,0-0.2-0.1-0.3l-0.9-1c-0.1-0.1-0.3-0.2-0.4-0.1l-0.5,0.1l-0.1-1.5c0,0,0-0.1,0-0.1l-1.1-2.5
              C33.1,965.9,33,965.8,32.8,965.8L32.8,965.8z"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M34.4,1000.8c-0.3,0-0.6-0.1-0.7-0.4l-0.4-0.5l-0.5,0.4c-0.2,0.2-0.6,0.3-0.9,0.2l-2.8-1
              c-0.2-0.1-0.3-0.2-0.4-0.3l-0.9-1.1c-0.1-0.1-0.2-0.3-0.2-0.5l-0.1-0.9l-0.6-0.3c-0.2-0.1-0.3-0.2-0.4-0.4l-0.5,0
              c-0.2,0-0.5-0.1-0.6-0.3c-0.3,0.1-0.6,0.1-0.9,0l-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.4l-0.6-1.5l-0.4-0.2c-0.2-0.1-0.3-0.3-0.4-0.5
              l-2.2-0.8l-4.6,1l-1.4,0.6l-0.4,1.2c-0.1,0.4-0.5,0.6-0.8,0.6l-3.1,0.1l-1.2,1.6c-0.1,0.2-0.4,0.3-0.6,0.4l-1.1,0.2
              c-0.2,0-0.3,0-0.5-0.1l-2.1-0.8c-0.4-0.1-0.6-0.5-0.6-0.9l0.1-0.8c0-0.3,0.2-0.6,0.5-0.7l0.2-0.1l-0.1-0.3l-0.9-1.3
              c-0.1-0.1-0.1-0.2-0.1-0.3L3,990.8l-0.8-1.7l-1.4-1.8c-0.3-0.3-0.3-0.7-0.1-1.1l0.1-0.1l-0.7-1.5C0,984.6,0,984.4,0,984.3v-2.7
              c0-0.3,0.1-0.6,0.4-0.7l0.3-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3l2.1-1.6c0.2-0.2,0.4-0.2,0.7-0.2l0.2,0l1.5-1
              c0,0,0.1,0,0.1-0.1l2-0.9l0.9-1l-0.1-0.6c-0.1-0.3,0-0.6,0.2-0.9l0.9-0.9c0,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2,0.1-0.3
              c0.1-0.2,0.3-0.4,0.5-0.5c0.1-0.1,0.3-0.1,0.4-0.1l0.4-0.7c0-0.1,0.1-0.1,0.2-0.2c0-0.2,0.1-0.3,0.2-0.4l0.6-0.7
              c0.2-0.2,0.4-0.3,0.7-0.3c0.1-0.1,0.3-0.2,0.4-0.2l0.1,0l0.3-0.2c0.2-0.1,0.4-0.2,0.6-0.2l0.8,0c0.3,0,0.5,0.1,0.7,0.4l0.5,0.6
              c0.1,0,0.1,0,0.2,0c0-0.1,0-0.1,0.1-0.2l0.5-0.8c0.1-0.2,0.3-0.3,0.5-0.4l0-0.1c0,0,0-0.1,0-0.1c0-0.4,0.2-0.7,0.5-0.9
              c0.1-0.1,0.2-0.3,0.4-0.3l0.5-0.2c0.1,0,0.2-0.1,0.3-0.1h0.4c-0.1-0.2-0.1-0.5,0-0.7c0.2-0.5,0.7-0.7,1.2-0.5l1.5,0.6l0.3,0
              c0.3,0,0.6,0,0.8,0.2c0.3-0.1,0.6-0.1,0.8,0.1l0.3,0.2l0.4-0.1c0.1,0,0.2,0,0.3,0l0,0c0,0,0.1-0.1,0.1-0.1
              c0.2-0.1,0.4-0.2,0.7-0.1c0.2,0,0.4,0.2,0.6,0.4l0.4,0.6c0.3,0.4,0.2,1-0.2,1.2l-0.2,0.2l0,0.1c0,0.3-0.2,0.6-0.4,0.7
              c0,0.1,0,0.1-0.1,0.2l-0.5,0.8l0.8,0.7l2.5,1.1c0.1,0.1,0.2,0.1,0.3,0.2l0.5,0.6l0.1,0l0.9-2.2l-0.2-1.1c0-0.2,0-0.4,0.1-0.5
              l0.4-0.8c-0.2-0.3-0.2-0.7,0.1-1l0.3-0.4l0.1-1c0-0.3,0.2-0.6,0.5-0.8l0.5-0.2c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0,0
              c0.4,0,0.7,0.2,0.8,0.5l1.1,2.5c0,0.1,0.1,0.2,0.1,0.3l0.1,0.9c0.3,0,0.6,0.1,0.8,0.3l0.9,1c0.2,0.2,0.3,0.4,0.2,0.7l0,1.4
              l0.3,0.4c0.1,0.1,0.2,0.3,0.2,0.5l0.3,2l2.4,1.6c0.2,0.1,0.3,0.3,0.4,0.5l0.4,1.9c0.3,0.1,0.5,0.3,0.7,0.5l0.6,1.4l0.4,0.3
              c0.1,0.1,0.2,0.2,0.3,0.3l1.3,2.6c0.1,0.2,0.1,0.4,0.1,0.5l-0.2,1.6l0.2,1.7c0,0.1,0,0.3-0.1,0.4l-1.4,3.7c0,0.1-0.1,0.2-0.2,0.3
              l-1.8,1.7l-0.9,1.6l-0.6,2.3c-0.1,0.4-0.4,0.6-0.8,0.7l-1.7,0.2l-0.2,0.2c0,0-0.1,0.1-0.1,0.1l-1.3,0.8
              C34.8,1000.8,34.6,1000.8,34.4,1000.8z M33.4,998.7C33.4,998.7,33.4,998.7,33.4,998.7c0.2,0,0.3,0.1,0.4,0.2l0.6,0.9l1.3-0.8
              l0.3-0.3c0.1-0.1,0.2-0.1,0.3-0.1l1.8-0.2l0.6-2.3c0,0,0-0.1,0-0.1l1-1.7c0,0,0.1-0.1,0.1-0.1l1.9-1.7l1.4-3.7l-0.2-1.8
              c0,0,0-0.1,0-0.1l0.2-1.7l-1.3-2.6l-0.5-0.3c-0.1-0.1-0.1-0.1-0.2-0.2l-0.6-1.4l-0.1,0c-0.3,0-0.5-0.1-0.5-0.4l-0.5-2.3l-2.6-1.7
              c-0.1-0.1-0.2-0.2-0.2-0.3l-0.3-2.2l-0.5-0.5c-0.1-0.1-0.1-0.2-0.1-0.4l0.1-1.6l-0.9-0.9l-0.4,0.1c-0.1,0-0.3,0-0.4-0.1
              c-0.1-0.1-0.2-0.2-0.2-0.4l-0.1-1.5l-1.1-2.4l-0.5,0.2l0,1c0,0.1,0,0.2-0.1,0.3l-0.3,0.4c0.2,0.1,0.2,0.4,0.1,0.6l-0.5,1.1
              l0.2,1.2c0,0.1,0,0.2,0,0.3l-1.1,2.6c-0.1,0.2-0.3,0.3-0.5,0.3l-0.6-0.1c-0.1,0-0.2-0.1-0.3-0.2l-0.6-0.7l-2.5-1.1
              c0,0-0.1,0-0.1-0.1l-1.1-1c-0.2-0.2-0.2-0.4-0.1-0.6l0.6-1.2c0-0.1,0-0.2,0.1-0.4c0.1-0.1,0.2-0.1,0.3-0.2l0-0.3
              c0-0.1,0.1-0.3,0.2-0.4l0.3-0.3l-0.3-0.4l-0.2,0.1c-0.2,0.1-0.4,0.2-0.6,0l-0.5,0.2c-0.1,0-0.3,0-0.4-0.1l-0.5-0.3l-0.2,0.2
              c-0.1,0.1-0.3,0.1-0.4,0c-0.1-0.1-0.2-0.2-0.3-0.3l-0.3,0.1c-0.1,0-0.2,0-0.3,0l-1.3-0.5l0.3,0.2c0.1,0.1,0.2,0.3,0.1,0.5
              s-0.2,0.3-0.4,0.3l-1,0.1l-0.5,0l-0.4,0.2c0,0.2-0.1,0.3-0.3,0.3l0.1,0.4c0.1,0.2-0.1,0.5-0.3,0.6l-0.4,0.1l-0.4,0.7l0.3,0.2
              c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.4,0.3-0.6,0.2l-1-0.4l-0.1,0c-0.2,0-0.4,0-0.5-0.2l-0.6-0.9l-0.7,0
              l-0.3,0.3c-0.1,0-0.1,0.1-0.2,0.1l-0.3,0.1c0,0.1-0.1,0.2-0.3,0.3c-0.1,0-0.2,0-0.3,0l-0.5,0.7c0.1,0.3,0,0.5-0.3,0.6l-0.5,0.7
              c0.1,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.4,0.1-0.5,0l-0.1-0.1l0,0c0,0.2,0,0.3-0.1,0.4s-0.3,0.2-0.4,0.1l-0.8,0.9l0.2,0.8
              c0,0.2,0,0.3-0.1,0.5l-1.2,1.3c0,0-0.1,0.1-0.2,0.1l-2,1l-1.6,1.1c-0.1,0.1-0.2,0.1-0.3,0.1l-0.4-0.1l-2,1.6l0,0.1
              c0,0.1-0.1,0.3-0.3,0.3c-0.1,0.1-0.3,0.1-0.4,0L1,981.7l0,2.6l0.8,1.7c0.1,0.2,0.1,0.3,0,0.5l-0.2,0.3l1.4,1.8
              c0,0,0.1,0.1,0.1,0.1l0.8,1.9l0.4,1.2l1,1.3c0,0.1,0.1,0.1,0.1,0.2l0.2,0.8c0,0.2-0.1,0.4-0.2,0.5l-0.5,0.3l0,0.7l2.1,0.8l1-0.2
              l1.3-1.7c0.1-0.1,0.2-0.2,0.4-0.2l3.3-0.1l0.4-1.4c0-0.1,0.2-0.3,0.3-0.3l1.7-0.7l4.8-1c0.1,0,0.2,0,0.3,0l2.6,1
              c0.2,0.1,0.3,0.3,0.3,0.5l0.5,0.3c0.1,0.1,0.2,0.1,0.2,0.2l0.7,1.6l0.3,0.2l0-0.2c0-0.1,0.1-0.2,0.1-0.3l1.1-1.2
              c0.1-0.2,0.4-0.2,0.6-0.1c0.2,0.1,0.3,0.3,0.3,0.5c0,0.1,0,0.3-0.1,0.3l-0.4,0.6l0,0.3c0,0.1-0.1,0.3-0.2,0.4l0,0l0.5,0
              c0.2-0.1,0.3,0,0.5,0.1c0.1,0.1,0.2,0.2,0.2,0.4l0.8,0.4c0.2,0.1,0.3,0.2,0.3,0.4l0.1,1.2l0.9,1.1l2.8,1l0.9-0.8
              C33.2,998.7,33.3,998.7,33.4,998.7z"
            />
          </g>
          <g>
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M32.8,1001.4c-0.2,0-0.3,0.1-0.4,0.3l-0.2,0.9c0,0.1,0,0.2,0,0.3l0.4,0.6l0.1-0.1
              c-0.2,0.1-0.2,0.3-0.1,0.5l0.4,0.7l0.2,0.5c0,0.1,0.1,0.2,0.2,0.2l0.8,0.4c0.2,0.1,0.4,0,0.5-0.2c0,0,0,0,0-0.1l0.1-0.4l0.1,0.1
              c0.2,0.2,0.4,0.2,0.6,0c0,0,0.1-0.1,0.1-0.2l0.4-1.3c0,0,0-0.1,0-0.1l0.1-1.4c0-0.2-0.2-0.4-0.4-0.4c0,0-0.1,0-0.1,0l-1.3,0.4
              l-1.4-0.7C33,1001.4,32.9,1001.4,32.8,1001.4z"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M34.4,1006.2c-0.1,0-0.3,0-0.4-0.1l-0.8-0.4c-0.2-0.1-0.3-0.3-0.4-0.5l-0.2-0.5l-0.3-0.6
              c-0.1-0.2-0.1-0.4-0.1-0.6l-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.6l0.2-0.9c0.1-0.4,0.4-0.7,0.8-0.7l0,0c0.1,0,0.3,0,0.4,0.1l1.3,0.6
              l1.1-0.3c0.1,0,0.2,0,0.3,0c0.5,0,0.9,0.5,0.9,1l-0.1,1.4c0,0.1,0,0.2,0,0.2l-0.4,1.3c0,0.1-0.1,0.3-0.2,0.4
              c-0.2,0.2-0.4,0.3-0.6,0.3c-0.1,0-0.1,0-0.2,0C35,1006.1,34.7,1006.2,34.4,1006.2z M33.2,1003.8l0.5,1.1l0.6,0.3l0.1-0.3
              c0.1-0.2,0.2-0.3,0.4-0.3c0.2,0,0.3,0,0.5,0.1l0.1,0.1l0.4-1.2l0.1-1.3l-1.1,0.4c-0.1,0-0.3,0-0.4,0l-1.3-0.6l-0.1,0.8l0.2,0.3
              c0.1,0,0.2,0.1,0.2,0.2C33.3,1003.4,33.3,1003.6,33.2,1003.8z"
            />
          </g>
          <g>
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M22.3,955.2c0,0-0.1,0-0.1,0l-2,0.7c-0.1,0-0.2,0.1-0.2,0.2l-0.1,0.3c-0.1,0.2,0,0.4,0.2,0.5
              c0,0,0,0,0,0l1.3,0.4l0,0.1h-0.3c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.1,0.3,0.2,0.4l1,0.5l0.2,0.5c0.1,0.2,0.2,0.3,0.4,0.3l1-0.1
              l0.2,0.3c0.1,0.1,0.1,0.2,0.3,0.2l2.7,0.8l0.4,0.5l0.3,0.7l-0.3,0.1c-0.2,0.1-0.4,0.3-0.3,0.5c0,0,0,0.1,0,0.1l0.2,0.4
              c0.1,0.1,0.2,0.2,0.4,0.2l1.3-0.1l0.8,0.8c0.1,0.1,0.2,0.1,0.3,0.1l0.9-0.1l0.2,0c0.1,0,0.2,0,0.3,0l0.8-0.4
              c0.2-0.1,0.3-0.3,0.2-0.5c0-0.1-0.1-0.1-0.2-0.2l0,0l2.1,0.6l1.3,1.9c0.1,0.1,0.1,0.1,0.2,0.2l1.8,0.5l0.2,0.2
              c0.1,0.1,0.2,0.1,0.4,0l0.7-0.3c0.2-0.1,0.3-0.3,0.2-0.5c0-0.1-0.1-0.1-0.1-0.2l-0.8-0.6l-0.3-0.5c-0.1-0.1-0.2-0.2-0.4-0.2h-0.4
              l-0.4-1c0-0.1-0.1-0.2-0.2-0.2l-0.4-0.2c0.2-0.1,0.3-0.3,0.2-0.5l-0.2-0.6c0-0.1-0.1-0.2-0.2-0.2l-1.2-0.5l-0.2-0.5
              c0,0,0,0,0-0.1l-0.7-0.9c0-0.1-0.1-0.1-0.2-0.1l-6.3-2.5c-0.1-0.1-0.3,0-0.4,0l-1.1,0.7c0,0,0,0-0.1,0.1l-0.7,0.7l-0.5-0.1
              l-0.8-1.7c0-0.1-0.1-0.1-0.1-0.1l-0.8-0.6C22.5,955.2,22.4,955.2,22.3,955.2L22.3,955.2z"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M37.9,966.6c-0.2,0-0.3,0-0.5-0.1l-0.2-0.1l-1.7-0.5c-0.2-0.1-0.4-0.2-0.5-0.4l-1.2-1.7
              l-1.1-0.3c-0.1,0.2-0.2,0.4-0.4,0.5l-0.8,0.4c-0.2,0.1-0.4,0.1-0.6,0.1l-0.1,0l-0.8,0c-0.3,0-0.5-0.1-0.7-0.3l-0.7-0.7l-1.1,0
              c-0.4,0-0.7-0.2-0.8-0.5l-0.2-0.4c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.4,0.1-0.7,0.4-0.9l0-0.1l-0.3-0.3l-2.6-0.8
              c-0.2-0.1-0.4-0.2-0.6-0.5l0,0l-0.6,0.1c-0.4,0-0.8-0.2-1-0.6l-0.1-0.3l-0.8-0.4c-0.3-0.1-0.5-0.5-0.5-0.8c0-0.1,0-0.2,0-0.3
              l-0.4-0.1c-0.6-0.2-0.8-0.7-0.6-1.2l0.1-0.3c0.1-0.2,0.3-0.4,0.5-0.5l2-0.7c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0,0
              c0.2,0,0.4,0.1,0.6,0.2l0.8,0.6c0.1,0.1,0.2,0.2,0.3,0.3l0.7,1.5l0.6-0.6c0,0,0.1-0.1,0.1-0.1l1.1-0.7c0.3-0.2,0.6-0.2,0.8-0.1
              l6.3,2.5c0.2,0.1,0.3,0.2,0.4,0.3l0.7,0.9c0,0,0.1,0.1,0.1,0.1L35,960l1.1,0.5c0.2,0.1,0.4,0.3,0.5,0.5l0.2,0.6
              c0.1,0.2,0.1,0.3,0,0.5c0.2,0.1,0.3,0.3,0.4,0.5l0.3,0.7h0.1c0.3,0,0.7,0.2,0.8,0.5l0.2,0.4l0.7,0.5c0.1,0.1,0.2,0.2,0.3,0.4
              c0.1,0.2,0.1,0.5,0,0.7c-0.1,0.2-0.3,0.4-0.5,0.5l-0.7,0.3C38.2,966.6,38.1,966.6,37.9,966.6z M32.2,962.3c0,0,0.1,0,0.1,0
              l2.1,0.6c0.1,0,0.2,0.1,0.3,0.2l1.3,1.9l1.7,0.5c0,0,0.1,0,0.1,0.1l0.2,0.2l0.5-0.2l-0.7-0.5c-0.1,0-0.1-0.1-0.2-0.2l-0.3-0.5
              l-0.3,0c-0.2,0-0.4-0.1-0.5-0.3l-0.4-1l-0.4-0.2c-0.2-0.1-0.3-0.3-0.3-0.4c0-0.2,0.1-0.3,0.2-0.4l-0.2-0.6l-1.2-0.5
              c-0.1,0-0.2-0.1-0.2-0.2l-0.2-0.5l-0.7-0.9l-6.3-2.5l-1,0.7l-0.7,0.7c-0.1,0.1-0.3,0.2-0.4,0.1l-0.5-0.1c-0.2,0-0.3-0.1-0.4-0.3
              l-0.8-1.7l-0.8-0.6l-2,0.7l0,0.1l1.3,0.4c0.1,0,0.2,0.1,0.3,0.2c0.1,0.2,0.2,0.4,0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.3l0.7,0.3
              c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.5l0.9-0.1c0.2,0,0.4,0.1,0.5,0.3l0.2,0.3l2.7,0.7c0.1,0,0.2,0.1,0.2,0.1l0.4,0.5
              c0,0,0.1,0.1,0.1,0.1l0.3,0.7c0.1,0.1,0.1,0.3,0,0.4s-0.2,0.2-0.3,0.3l-0.1,0l0.2,0.3l1.2-0.1c0.1,0,0.3,0,0.4,0.1l0.8,0.8
              l0.8-0.1c0.1,0,0.1,0,0.2,0l0.2,0l0.6-0.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.2-0.4C31.9,962.4,32,962.3,32.2,962.3z"
            />
          </g>
          <g>
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M15.5,962.8c-0.1,0-0.2,0-0.2,0.1l-1.5,1c-0.1,0.1-0.1,0.1-0.2,0.2l-0.2,0.9
              c-0.1,0.2,0.1,0.4,0.3,0.5c0,0,0,0,0.1,0l0.6,0.1c0.1,0,0.2,0,0.3-0.1l2.9-2c0.2-0.1,0.2-0.4,0.1-0.6c-0.1-0.1-0.2-0.2-0.3-0.2
              H15.5z"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M14.3,966.2C14.3,966.2,14.2,966.2,14.3,966.2l-0.7-0.1c0,0-0.1,0-0.1,0
              c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.2-0.2-0.5-0.1-0.7L13,964c0.1-0.2,0.2-0.4,0.4-0.5l1.5-1c0.1-0.1,0.3-0.1,0.5-0.1h1.7
              c0,0,0,0,0,0c0.3,0,0.6,0.1,0.7,0.4c0.3,0.4,0.2,1-0.2,1.3l-2.9,2C14.6,966.1,14.5,966.2,14.3,966.2z M13.8,965.1l0.5,0l2.6-1.9
              l-1.4,0l-1.5,1L13.8,965.1z"
            />
          </g>
          <g>
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M17.6,952.9c-0.2,0-0.3,0.1-0.4,0.3l-1,2.6c-0.1,0.1,0,0.3,0.1,0.4l1,1.1
              c0.1,0.1,0.2,0.1,0.3,0.1h1.1c0.2,0,0.4-0.2,0.4-0.4c0-0.1,0-0.2-0.1-0.2l-0.4-0.7h0.2c0.2,0,0.4-0.2,0.4-0.4
              c0-0.1,0-0.2-0.1-0.3l-0.5-0.4l0.4-0.4c0.2-0.1,0.2-0.4,0.1-0.6c-0.1-0.1-0.2-0.2-0.3-0.1h-0.5l-0.3-0.8
              C17.9,953,17.8,952.9,17.6,952.9z"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M18.7,957.9C18.7,957.9,18.7,957.9,18.7,957.9h-1.1c-0.3,0-0.5-0.1-0.7-0.3l-1-1.1
              c-0.2-0.3-0.3-0.6-0.2-0.9l1-2.6c0.1-0.3,0.5-0.6,0.8-0.6l0,0c0,0,0,0,0,0c0.4,0,0.7,0.2,0.8,0.6l0.2,0.4h0.2
              c0.3,0,0.5,0.1,0.7,0.3c0.3,0.4,0.3,1-0.1,1.3l0,0c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7l0.1,0.1
              c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2-0.1,0.5-0.3,0.6C19.2,957.8,18.9,957.9,18.7,957.9z M17.6,953.5l-0.9,2.4l1,1l0.9,0l-0.3-0.5
              c-0.1-0.2-0.1-0.3,0-0.5c0.1-0.1,0.2-0.2,0.4-0.3l-0.3-0.3c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.3,0.2-0.4l0.3-0.2l-0.3,0
              c-0.2,0-0.4-0.1-0.5-0.3L17.6,953.5z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const Africa = () => {
  return (
    <svg className="w-full h-full category__svg africa" viewBox="0 0 52.3 56.1" x="0px" y="0px">
      <g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M22.4,33.6C22.4,33.7,22.4,33.7,22.4,33.6c0.1,0.1,0,0.3,0,0.4c0,0.2,0.2,0.3,0.3,0.5
            c0.2,0.3,0.4,0.6,0.4,0.9c0.1,0.5-0.2,0.8,0,1.3c0.2,0.4,0.4,0.6,0.3,1.1c-0.1,0.7-0.8,1.3-1.1,1.9c-0.2,0.4-0.3,0.9-0.4,1.3
            c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0.1,0.5-0.1,0.3c0.1,0,0,0.8,0,0.9c0.1,0.5,0.5,0.9,0.8,1.4c0.4,0.6,0.7,1.2,1.1,1.9
            c0.3,0.6,0.2,1.3,0.4,2c0.2,0.6,0.2,1.3,0.4,1.9c0.2,0.6,0.7,1.1,1.1,1.6c0.4,0.6,0.7,1.3,0.9,1.9c0.1,0.2,0.2,0.9-0.2,0.7
            c-0.1,0.1,0.3,1.2,0.4,1.2c0.1,0-0.1-0.2,0.2-0.1c0.3,0.1,0.4,0.3,0.8,0.3c0.7,0,1.2-0.4,1.9-0.5c0.6,0,1.2,0.2,1.8,0.1
            c0.3-0.1,0.2-0.1,0.4-0.2c0.2-0.1,0.4-0.1,0.6-0.1c1.2-0.3,2.2-1.3,2.9-2.3c0.2-0.3,0.3-0.6,0.6-0.8c0.2-0.2,0.5-0.4,0.8-0.7
            c0.4-0.5,0.5-1.2,0.6-1.8c-0.1,0.2-0.2,0-0.3-0.1c0.2,0,0.5-0.4,0.7-0.5c0.3-0.1,0.6-0.1,0.8-0.3c0.1-0.1,1-0.6,0.8-0.8
            c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.5,0.3-0.9,0.3-1.4c0-0.5-0.3-0.9-0.4-1.4c-0.1-0.8,0.7-0.9,1.1-1.5c0.1,0.3,0.3-0.2,0.4-0.3
            c0.3-0.4,0.7-0.6,1.1-0.7c0.8-0.3,1.7-0.8,2-1.7c0.2-0.5,0-1.1,0.1-1.7c0-0.5,0.2-1.1,0.3-1.6c0-0.4-0.2-0.6-0.4-0.9
            c-0.3-0.5-0.4-1-0.4-1.6c0-0.5,0-0.7-0.2-1.2c-0.2-0.4-0.1-0.7,0.1-1.1c0.4-1,1-1.8,1.7-2.6c0.7-0.7,1.3-1.6,2-2.3
            c0.7-0.7,1.6-1.2,2.2-2c0.4-0.5,0.8-1.1,1.1-1.7c0.3-0.5,0.4-1,0.7-1.5c0.3-0.6,0.6-1.1,0.7-1.7c0-0.3-0.1-0.8,0.4-0.9
            c-0.3-0.2,0.1-1.3-0.5-1.2c-0.2,0-0.6,0.4-0.9,0.5c-0.3,0.1-0.7,0.2-0.9,0.5c0-0.4-0.5-0.3-0.7-0.2c-0.4,0.2-0.8,0.2-1.3,0.3
            c-0.5,0.1-0.8,0.4-1.3,0c-0.3-0.2-0.4-0.6-0.9-0.5c-0.1-0.3,0.4-0.3,0.5-0.6c0.1-0.4-0.4-0.6-0.6-0.8c-0.4-0.3-0.7-0.7-1.1-1
            c-0.4-0.2-0.8-0.3-1.1-0.7c0,0.1,0,0.2-0.1,0.3c-0.2-0.3-0.4-0.7-0.5-1.1c-0.1-0.3-0.2-0.7-0.4-1c-0.5-0.6-1.1-0.7-1.2-1.5
            c-0.2-0.7-0.2-1.4-0.7-2c-0.2-0.2-0.6-0.3-0.8-0.6c-0.1-0.1-0.2-0.3-0.1-0.4c0.1-0.2,0.1,0,0.2-0.2c0-0.1-0.7-0.8-0.8-1
            c-0.5-0.7-0.9-1.5-1.4-2.2c-0.3-0.3-0.5-0.6-0.6-1c-0.1-0.4-0.1-0.7-0.3-1.1c-0.2-0.3-1-0.9-1.2-0.3c0.3-0.2-0.3,0-0.4,0.1
            C34.2,5,33.8,5,33.4,5c-1.1-0.2-2.2-0.4-3.2-0.8c-0.6-0.3-1.7-0.8-2.1,0.1c-0.3,0.5,0,1.8-1,1.4c-0.4-0.1-0.8-0.4-1.2-0.5
            C25.2,5,25,4.9,24.6,4.6C24,3.9,23.3,4,22.4,3.7c-0.6-0.2-1.7-0.6-1-1.3c0.4-0.4,0.2-0.4,0.2-0.8c0-0.3,0.3-0.6,0.1-0.8
            c-0.2-0.2-0.8-0.3-1-0.2c-0.6,0-1.1,0.2-1.7,0.2c-1.1,0.1-2.1-0.1-3.2,0.2c-0.9,0.2-1.8,0.8-2.8,0.9c-0.4,0-0.7,0-1.1-0.1
            c-0.4-0.1-0.7-0.5-1-0.5c-0.4,0-0.7,0.5-0.9,0.8C9.9,2.4,9.6,2.7,9.3,2.9C8.5,3.4,7.8,3.8,7.5,4.7C7.2,5.5,7,6.2,6.1,6.6
            C5.6,6.7,5.2,6.8,4.8,7.1C4.5,7.4,4.2,7.8,3.9,8.1C3.1,9.1,2.4,10.1,1.7,11c-0.3,0.4-0.5,0.8-0.3,1.3c0.2,0.5,0.2,0.9,0.2,1.5
            c0,0.5-0.1,1.1-0.3,1.6C1,15.9,0.7,16.4,0.5,17c-0.1,0.4-0.1,0.9,0.3,1.1c0.1,0.1,0.7,0.3,0.8,0.1c-0.2,0-0.4,0.1-0.6,0.1
            c0.1,0-0.5,0-0.4,0c-0.2,0.1-0.2,0.3-0.2,0.6c0.1,0.3,0.3,0.6,0.6,0.7c0.2,0,0.6-0.2,0.8,0c-0.3-0.2-0.8,0.8-0.2,0.7
            c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0.3-0.1,0.3-0.1c-0.2,0.6,0.7,1,0.9,1.5c0.2,0.3,0.2,0.7,0.4,1c0.2,0.4,0.5,0.6,0.8,0.9
            C4.7,24,5.3,24.7,6,25.1c0.9,0.6,1.6,0.3,2.4,0c0.9-0.3,1.6-0.1,2.5,0.1c0.5,0.1,0.7-0.1,1.2-0.3c0.4-0.1,0.8-0.3,1.2-0.4
            c0.8-0.3,1.5-0.6,2.4-0.5c0.3,0,0.6,0.1,0.9,0.3c0.1,0,0.4,0.5,0.4,0.4c-0.2,0.1,0,0.5,0.1,0.6c0.3,0.3,0.6,0.2,1,0
            c0.3-0.1,0.6,0.1,0.9,0c0.3-0.1,0.3-0.2,0.6,0.1c0.5,0.5,0.9,0.8,0.8,1.6c0,0.1-0.4,1.2-0.1,1c-0.3,0.2-0.1,0.7,0.2,0.7
            c-0.2,0.1-0.2-0.1-0.4-0.1c0,0-0.2,0.6-0.3,0.7c-0.1,0.5,0.2,1,0.6,1.4c0.4,0.5,0.9,0.9,1.3,1.3c0.2,0.2,0.4,0.5,0.5,0.8
            C22.2,33.2,22.2,33.5,22.4,33.6"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M27.8,56.1c0,0-0.1,0-0.1,0c-0.3,0-0.5-0.2-0.7-0.3c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0
            c-0.2,0-0.4-0.2-0.4-0.3c0,0-0.6-1.2-0.3-1.7c0-0.1,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c-0.2-0.5-0.4-1.1-0.9-1.8
            c-0.1-0.2-0.3-0.4-0.4-0.6c-0.3-0.3-0.5-0.7-0.7-1.1c-0.2-0.4-0.2-0.8-0.3-1.2c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.3-0.1-0.6-0.1-0.9
            c0-0.4-0.1-0.7-0.2-1c-0.4-0.7-0.7-1.3-1-1.8c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.3-0.6-0.7-0.6-1.3c0,0,0-0.2,0-0.3
            c0-0.2,0-0.3,0-0.5c-0.1-0.2,0-0.3,0-0.5c0,0,0,0,0,0c0-0.2,0-0.4,0.1-0.6c0.1-0.6,0.2-1.1,0.4-1.5c0.1-0.3,0.3-0.5,0.5-0.8
            c0.2-0.3,0.5-0.7,0.5-1c0-0.2,0-0.3-0.2-0.6c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.4-0.1-0.8-0.1-1.1c0-0.1,0-0.3,0-0.4
            c0-0.2-0.2-0.4-0.3-0.7l-0.1-0.1c-0.1-0.1-0.2-0.3-0.3-0.6c0-0.1,0-0.2,0-0.2c-0.2-0.2-0.2-0.4-0.2-0.5c0,0,0-0.1,0-0.1
            c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.4-0.4-0.6-0.6c-0.3-0.2-0.5-0.5-0.7-0.8c-0.3-0.4-0.9-1-0.7-1.8c0,0,0-0.1,0.1-0.3
            c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.1,0.1-0.2c0-0.2,0-0.3,0.1-0.4c0-0.3,0.1-0.6,0.2-1l0-0.1c0.1-0.5-0.1-0.6-0.6-1.1
            c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c-0.2,0.1-0.4,0.1-0.6,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0.1c-0.3,0.1-0.8,0.3-1.3-0.2
            c0,0-0.4-0.4-0.4-0.8c0,0-0.1-0.1-0.1-0.1l-0.1-0.1c-0.1-0.1-0.3-0.2-0.6-0.2c-0.8-0.1-1.4,0.2-2.2,0.5l-0.3,0.1
            c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.6,0.3-1.1,0.2l-0.3-0.1c-0.7-0.1-1.3-0.3-2-0.1l-0.2,0.1
            c-0.8,0.3-1.7,0.5-2.7-0.1c-0.4-0.3-0.8-0.6-1.2-1c-0.2-0.2-0.5-0.4-0.7-0.6c-0.4-0.3-0.7-0.6-1-1.1c-0.1-0.2-0.1-0.3-0.2-0.5
            c0-0.2-0.1-0.3-0.2-0.4c0-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.5-0.5-0.6-0.8c0,0-0.1,0-0.1,0c-0.1-0.1-0.2-0.2-0.3-0.3
            C1,20.4,1,20.3,0.9,20.2c0-0.1,0-0.2,0-0.3c-0.5-0.2-0.8-0.6-0.9-1c-0.1-0.3,0-0.6,0.1-0.8C0,17.8,0,17.4,0.1,16.9
            c0.1-0.4,0.3-0.8,0.4-1.1c0.1-0.2,0.2-0.4,0.3-0.6C0.9,14.8,1,14.3,1,13.8c0-0.5,0-0.8-0.2-1.3c-0.3-0.8,0.2-1.4,0.4-1.7
            c0.9-1.2,1.6-2.1,2.3-2.9c0.1-0.1,0.2-0.2,0.3-0.3C4,7.2,4.2,6.9,4.5,6.7c0.3-0.3,0.7-0.4,1-0.5c0.1,0,0.2-0.1,0.3-0.1
            c0.6-0.2,0.7-0.6,1-1.3L7,4.5c0.4-0.9,1-1.4,1.7-1.8L9,2.5c0.3-0.2,0.5-0.4,0.7-0.7l0.1-0.1C10,1.3,10.4,0.8,11,0.8
            c0.3,0,0.6,0.2,0.8,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6,0.1,0.9,0c0.5,0,0.9-0.2,1.4-0.4c0.4-0.2,0.8-0.3,1.3-0.4
            c0.7-0.2,1.4-0.2,2-0.2c0.4,0,0.8,0,1.2,0c0.2,0,0.5-0.1,0.7-0.1C20,0.1,20.4,0,20.7,0c0,0,1,0,1.4,0.4c0.3,0.4,0.2,0.8,0.1,1
            c0,0,0,0.1-0.1,0.1c0,0.1,0,0.2,0,0.2c0.1,0.4,0.1,0.6-0.3,1c-0.1,0.1-0.1,0.1-0.1,0.1c0,0,0.3,0.2,0.8,0.4
            c0.2,0.1,0.4,0.1,0.6,0.1c0.6,0.1,1.2,0.2,1.8,0.8c0.2,0.3,0.4,0.4,0.8,0.5c0.3,0.1,0.5,0.2,0.8,0.3c0.2,0.1,0.3,0.1,0.5,0.2
            c0.1,0,0.2,0,0.2,0c0,0,0-0.3,0.1-0.5c0-0.3,0.1-0.5,0.2-0.7c0.6-1.2,2-0.6,2.6-0.4l0.1,0.1c1,0.4,2.1,0.6,3.1,0.8
            c0.4,0.1,0.7,0,1.1-0.1c0.1,0,0.2-0.1,0.3-0.1C34.9,4.1,35.1,4,35.3,4c0.6-0.1,1.2,0.5,1.4,0.8C36.9,5.1,37,5.4,37,5.6
            c0,0.1,0,0.2,0.1,0.3c0.1,0.3,0.3,0.5,0.5,0.8c0.4,0.4,0.6,0.9,0.8,1.3c0.2,0.4,0.4,0.7,0.6,1c0,0,0.1,0.1,0.2,0.2
            c0.7,0.7,0.9,1,0.6,1.4c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.5,0.5,0.7,1.2,0.8,1.8
            c0,0.1,0,0.3,0.1,0.4c0.1,0.3,0.2,0.5,0.5,0.7c0.2,0.2,0.4,0.3,0.6,0.6c0.2,0.3,0.3,0.6,0.4,0.9c0,0.1,0.1,0.2,0.1,0.3
            c0.1,0.1,0.1,0.3,0.2,0.4c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.4,0.2c0.3,0.2,0.5,0.4,0.7,0.7
            c0.1,0.1,0.3,0.3,0.4,0.4c0,0,0.1,0.1,0.1,0.1c0.3,0.2,0.8,0.6,0.6,1.3c0,0.1,0,0.1-0.1,0.2c0.1,0.1,0.2,0.2,0.2,0.2
            c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.5,0c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.5-0.1,0.7-0.2
            c0.3-0.1,0.6-0.2,1-0.1c0.2-0.1,0.4-0.2,0.5-0.2l0.2-0.1c0.1,0,0.2-0.1,0.3-0.2c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.6,0.2
            c0.3,0.2,0.3,0.6,0.3,0.9c0,0.1,0,0.2,0,0.3c0.1,0.1,0.2,0.3,0.1,0.5c0,0.2-0.2,0.3-0.3,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3
            c-0.1,0.5-0.3,1-0.5,1.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.3-0.3,0.7-0.5,1.1c-0.4,0.6-0.8,1.2-1.2,1.7
            c-0.4,0.6-0.9,1-1.4,1.3c-0.3,0.2-0.6,0.4-0.8,0.7c-0.4,0.4-0.7,0.8-1.1,1.2c-0.3,0.3-0.6,0.7-0.9,1c-0.7,0.7-1.2,1.5-1.6,2.4
            c-0.2,0.4-0.2,0.5-0.1,0.7c0.3,0.5,0.3,0.9,0.2,1.4c0,0.5,0.1,0.9,0.3,1.3c0,0.1,0.1,0.1,0.1,0.2c0.2,0.2,0.4,0.6,0.3,1
            c0,0.3-0.1,0.5-0.1,0.8s-0.1,0.5-0.1,0.8c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8-0.1,1.3c-0.4,1.1-1.4,1.5-2.3,1.9
            c-0.4,0.2-0.7,0.3-0.9,0.6c-0.1,0.2-0.3,0.5-0.6,0.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.3-0.4,0.3-0.4,0.5c0,0.2,0.1,0.3,0.1,0.5
            c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.6-0.2,0.9c0,0,0,0,0,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.6-1.1,1.1-1.1,1.1
            c-0.2,0.1-0.4,0.1-0.5,0.2c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.4
            c-0.1,0.5-0.2,1.2-0.7,1.7c-0.2,0.2-0.3,0.3-0.5,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.1-0.1,0.2-0.2,0.3
            c-0.7,1-1.8,2.2-3.2,2.5c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0.1-0.2,0.2-0.5,0.3c-0.4,0.1-0.8,0-1.2,0
            c-0.2,0-0.5-0.1-0.7-0.1c-0.2,0-0.4,0.1-0.7,0.2C28.6,56,28.2,56.1,27.8,56.1z M27,54.8C27.1,54.8,27.1,54.8,27,54.8
            c0.2,0.1,0.4,0.2,0.5,0.2c0.1,0.1,0.2,0.1,0.2,0.1c0.3,0,0.5-0.1,0.8-0.2c0.3-0.1,0.6-0.3,1-0.3c0.3,0,0.6,0,0.8,0.1
            c0.3,0,0.6,0.1,0.9,0c0,0,0.1-0.1,0.3-0.2c0.2-0.1,0.4-0.1,0.5-0.1c0.1,0,0.1,0,0.2,0c1.1-0.2,2-1.3,2.6-2.1
            c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.3,0.3-0.7,0.4-1.1
            c0,0-0.1-0.1-0.1-0.1c-0.1-0.2-0.2-0.4-0.1-0.6c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.3-0.3,0.4-0.3
            c0.2-0.1,0.4-0.1,0.5-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2c0-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.1-0.4,0.1-0.5
            c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.2-0.2-0.5-0.2-0.7c-0.1-0.8,0.4-1.2,0.8-1.4c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.2,0.3-0.2
            c0,0,0-0.1,0-0.1c0.4-0.5,0.9-0.7,1.3-0.9c0.8-0.3,1.5-0.7,1.8-1.4c0.1-0.2,0.1-0.5,0.1-0.9c0-0.2,0-0.4,0-0.6
            c0-0.3,0.1-0.6,0.2-0.9c0.1-0.2,0.1-0.4,0.1-0.7c0-0.1,0-0.2-0.2-0.4c0-0.1-0.1-0.2-0.1-0.2c-0.3-0.6-0.5-1.2-0.4-1.9
            c0-0.5,0-0.6-0.1-0.9c-0.3-0.7-0.1-1.1,0.1-1.6c0.5-1,1-1.9,1.8-2.7c0.3-0.3,0.6-0.6,0.8-1c0.4-0.4,0.7-0.9,1.2-1.3
            c0.3-0.3,0.6-0.5,0.9-0.8c0.5-0.4,0.9-0.7,1.2-1.2c0.4-0.5,0.8-1.1,1.1-1.7c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.2,0.1-0.4,0.2-0.5
            c0.1-0.2,0.1-0.3,0.2-0.5c0.2-0.4,0.4-0.7,0.4-1.1c0,0,0-0.1,0-0.2c0-0.2,0-0.6,0.2-0.8c0-0.1,0-0.2,0-0.4c0,0,0,0,0,0
            c0,0,0,0,0,0c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.1,0-0.2,0.1c-0.2,0.1-0.4,0.1-0.4,0.2c-0.1,0.2-0.3,0.3-0.5,0.3
            c-0.1,0-0.3-0.1-0.3-0.2c0,0-0.1,0-0.1,0c-0.3,0.1-0.6,0.2-0.9,0.2c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.1
            c-0.4,0.1-0.9,0.3-1.5-0.2c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.4,0c-0.1-0.1-0.2-0.2-0.2-0.3
            c-0.1-0.5,0.3-0.7,0.4-0.8c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.3-0.3-0.5-0.5c-0.2-0.2-0.4-0.4-0.5-0.5
            c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.4-0.2c0,0,0,0-0.1,0c-0.2,0.1-0.4,0-0.5-0.2c-0.2-0.3-0.4-0.7-0.6-1.1
            c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.4-0.3-0.6c-0.1-0.2-0.3-0.3-0.5-0.4c-0.3-0.3-0.7-0.6-0.9-1.3c0-0.2-0.1-0.3-0.1-0.5
            c-0.1-0.5-0.2-0.9-0.5-1.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1-0.2-0.3-0.5-0.2-0.8c0,0,0,0,0-0.1
            c-0.1-0.1-0.3-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.2-0.2c-0.3-0.4-0.5-0.7-0.7-1.1c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.4-0.6-0.7-0.7-1.2
            c0-0.1-0.1-0.3-0.1-0.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.3-0.4-0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1
            c-0.1,0-0.1,0-0.1,0.1c-0.6,0.2-1,0.3-1.6,0.2C32.2,5.3,31,5,29.9,4.6l-0.1-0.1c-0.9-0.4-1.2-0.4-1.4-0.1c0,0.1-0.1,0.3-0.1,0.5
            c-0.1,0.4-0.1,0.9-0.6,1.2c-0.3,0.2-0.6,0.2-1,0C26.6,6,26.4,6,26.2,5.9c-0.2-0.1-0.4-0.2-0.7-0.2c-0.5-0.1-0.9-0.3-1.3-0.7
            c-0.4-0.4-0.7-0.4-1.3-0.5c-0.2,0-0.5-0.1-0.7-0.1c-0.5-0.1-1.4-0.4-1.5-1.1c-0.1-0.3-0.1-0.6,0.4-1C21.2,2,21.2,2,21.2,2
            c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.2,0.1-0.4,0.1-0.6C21.1,1,20.9,1,20.7,1c-0.3,0-0.6,0.1-0.9,0.1c-0.3,0-0.5,0.1-0.8,0.1
            c-0.5,0-0.9,0-1.3,0c-0.6,0-1.2,0-1.8,0.1c-0.4,0.1-0.7,0.2-1.1,0.4c-0.5,0.2-1.1,0.5-1.7,0.5c-0.4,0-0.9,0-1.2-0.1
            c-0.3-0.1-0.5-0.2-0.7-0.3C11.1,1.9,11,1.8,11,1.8c-0.1,0-0.2,0.3-0.3,0.4l-0.1,0.2c-0.3,0.4-0.6,0.7-1,1L9.2,3.5
            C8.6,3.9,8.2,4.2,7.9,4.8L7.8,5.1C7.6,5.8,7.3,6.7,6.2,7.1C6.1,7.1,6,7.1,5.8,7.2C5.6,7.3,5.3,7.3,5.2,7.5C5,7.6,4.8,7.9,4.6,8.1
            C4.5,8.2,4.4,8.3,4.3,8.5c-0.7,0.8-1.4,1.7-2.2,2.9c-0.3,0.4-0.3,0.6-0.3,0.8c0.2,0.6,0.3,1,0.2,1.7c0,0.7-0.1,1.2-0.3,1.7
            c-0.1,0.2-0.2,0.5-0.3,0.7c-0.2,0.3-0.3,0.6-0.4,0.9c0,0.2-0.1,0.5,0.1,0.6c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.2,0,0.4-0.1
            c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0.1,0.5c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.3,0-0.5,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0
            c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0.1,0c0.2-0.1,0.6-0.1,0.9,0.1l0,0c0.2,0.2,0.2,0.5,0,0.7c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1
            c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0.3,0.4,0.4,0.5c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.3,0.6c0,0.1,0.1,0.3,0.1,0.4
            c0.1,0.3,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.5,0.8,0.7c0.4,0.3,0.7,0.6,1.1,0.9c0.6,0.4,1,0.3,1.8,0l0.2-0.1c0.9-0.3,1.7-0.1,2.5,0
            l0.3,0.1c0.2,0,0.3,0,0.6-0.1c0.1,0,0.2-0.1,0.4-0.1c0.3-0.1,0.5-0.2,0.8-0.3l0.3-0.1c0.8-0.3,1.6-0.6,2.6-0.5
            c0.5,0,0.9,0.2,1.2,0.5c0,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.3,0,0.5c0,0,0,0,0,0.1
            c0.1,0,0.2,0,0.2,0c0.1,0,0.2-0.1,0.2-0.1c0.2-0.1,0.5,0,0.6,0c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.1-0.1c0.4-0.2,0.7,0,0.9,0.2
            c0.6,0.5,1.1,1,0.9,2.1c0,0,0,0.1-0.1,0.2c0,0.1-0.1,0.3-0.1,0.5c0,0,0,0,0,0.1c0.1,0.2,0.1,0.4,0,0.5c0.2,0,0.3,0.2,0.3,0.4
            c0,0.2-0.1,0.4-0.3,0.5c-0.1,0.1-0.2,0.1-0.4,0.1c0,0,0,0,0,0.1l-0.1,0.2c-0.1,0.2,0.1,0.5,0.5,0.9c0.2,0.2,0.4,0.5,0.7,0.7
            c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.5,0.6,0.9c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.1c0.1,0,0.2,0.1,0.2,0.2
            c0.1,0.2,0.1,0.5,0,0.6c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.2,0.4,0.5,0.7,0.5,1.2c0,0.3,0,0.5,0,0.6c0,0.2,0,0.3,0,0.5
            c0,0.1,0.1,0.1,0.1,0.2c0.1,0.3,0.3,0.6,0.3,1.1c-0.1,0.6-0.4,1.1-0.7,1.5c-0.2,0.2-0.3,0.4-0.4,0.6c-0.2,0.3-0.3,0.7-0.3,1.2
            c0,0.2,0,0.3-0.1,0.5c0,0.1,0,0.3,0,0.5c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.2c0,0.2,0.2,0.5,0.4,0.8c0.1,0.1,0.2,0.2,0.3,0.4
            c0.4,0.6,0.7,1.3,1.1,1.8c0.3,0.5,0.3,1,0.4,1.4c0,0.3,0,0.5,0.1,0.7c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.4,0.1,0.7,0.2,1
            c0.1,0.3,0.3,0.6,0.6,0.9c0.2,0.2,0.3,0.4,0.5,0.7c0.5,0.8,0.8,1.4,1,2c0.2,0.8,0,1.1-0.1,1.2C27,54.6,27,54.7,27,54.8z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M45.9,45c0.2-0.3,0.1-0.5,0-0.8c-0.1-0.2,0-0.5,0-0.7c0-0.1-0.1-0.4-0.1-0.5
            c0-0.2,0.2-0.3,0.2-0.4c0.1-0.1,0.2-0.5,0.2-0.6c0.1-0.1,0.5-0.2,0.6-0.2c0,0,0,0.1,0.1,0.1c0.2,0,0.3-0.3,0.6-0.3
            c0.2,0,0.1,0.2,0.3,0.3c0.3,0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.4,0.3-0.4c0.2,0,0.1,0.3,0.3,0.2c0.1-0.1,0-0.3,0-0.4
            c0,0,0.2-0.3,0.2-0.3c0,0.1,0,0.2,0,0.3c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.2,0,0.2,0c0,0-0.2-0.2-0.2-0.2c0-0.2,0.2-0.1,0.2-0.2
            c0-0.1,0-0.9,0.2-0.3c0.1-0.1,0.2-0.3,0.2-0.4c0.2,0,0.2,0.1,0.3-0.2c0.1-0.1,0-0.5,0-0.5c0.6-0.6,0.8,0.9,0.8,1.4
            c0,0.3,0.2,1.1,0,1.3c-0.3,0.4-0.1-0.2-0.3-0.3c-0.2-0.1-0.1,0.7-0.1,0.8c0,0.3-0.2,0.4-0.3,0.6c-0.1,0.2-0.1,0.4-0.2,0.6
            c-0.3,1-0.8,2-1.3,2.9c-0.4,0.8-0.6,1.7-1.1,2.4c-0.2,0.3-0.4,0.2-0.7,0.3c-0.4,0.1-0.6,0.4-1,0.1C45.2,49,45,48.7,45,48.2
            c0-0.2,0.1-0.4,0.1-0.5c0-0.3-0.1-0.2-0.2-0.4c-0.1-0.4,0-0.8,0.3-1.1C45.4,45.8,45.6,45.4,45.9,45"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M45.9,49.9c-0.2,0-0.4,0-0.6-0.2c-0.7-0.5-0.9-1.1-0.9-1.5c0-0.2,0-0.3,0.1-0.4
            c0,0,0-0.1,0-0.1c0,0-0.1-0.2-0.2-0.3c-0.1-0.5,0-1.1,0.3-1.6c0.1-0.1,0.2-0.2,0.3-0.4c0.2-0.2,0.3-0.5,0.5-0.7c0,0,0-0.1,0-0.3
            l0-0.1c-0.1-0.2-0.1-0.4-0.1-0.6l0-0.2c0,0,0-0.1,0-0.1c0-0.2-0.1-0.3,0-0.4c0-0.2,0.2-0.4,0.3-0.5c0.1-0.1,0.1-0.2,0.1-0.2
            c0.1-0.2,0.1-0.4,0.2-0.5c0.2-0.2,0.7-0.4,0.9-0.4c0,0,0.1,0,0.1,0c0.1-0.1,0.3-0.2,0.5-0.2c0.1-0.2,0.3-0.3,0.5-0.3
            c0,0,0.1-0.1,0.1-0.1l0.1-0.1l0,0c0-0.1,0.1-0.1,0.1-0.1c0-0.2,0.1-0.3,0.2-0.3c0,0,0,0,0,0c0-0.3,0.2-0.6,0.5-0.6
            c0-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0,0c0-0.2,0-0.3,0.1-0.5c0,0,0-0.1,0.1-0.1c0.4-0.3,0.7-0.3,0.9-0.2c0.6,0.2,0.8,1.2,0.8,1.9
            c0,0.1,0,0.2,0,0.3c0.1,0.5,0.2,1-0.1,1.4c0,0.1-0.2,0.2-0.3,0.3c0,0.3-0.2,0.5-0.2,0.6c0,0-0.1,0.1-0.1,0.1
            c0,0.1-0.1,0.2-0.1,0.3l0,0.2c-0.2,0.8-0.6,1.6-1,2.4c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.5-0.3,0.8
            c-0.2,0.6-0.5,1.2-0.9,1.7c-0.3,0.4-0.6,0.4-0.9,0.5c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1C46.3,49.8,46.1,49.9,45.9,49.9z
            M46.4,43.1C46.3,43.1,46.4,43.1,46.4,43.1c0,0.2,0,0.3,0,0.4l0,0.2c0,0.1,0,0.3,0,0.4l0,0.1c0.1,0.3,0.2,0.6-0.1,1
            c0,0,0,0.1-0.1,0.1c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.2,0.5-0.2,0.7c0.1,0.1,0.2,0.3,0.2,0.5
            c0,0.2,0,0.3-0.1,0.5c0,0,0,0.1,0,0.1c0,0.1,0,0.4,0.4,0.7c0,0,0.1,0,0.1,0c0.1-0.1,0.3-0.1,0.5-0.2c0.1,0,0.2,0,0.3,0
            c0,0,0.1,0,0.1,0c0,0,0,0,0.1-0.1c0.3-0.4,0.5-1,0.7-1.5c0.1-0.3,0.2-0.6,0.3-0.8c0.1-0.2,0.2-0.4,0.3-0.6
            c0.4-0.7,0.7-1.5,0.9-2.2l0-0.2c0-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.1-0.2c0-0.5-0.1-0.9,0.2-1.1
            c0.1-0.1,0.2-0.1,0.3-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3,0-0.4c0-0.1,0-0.3,0-0.4c-0.1,0.1-0.1,0.1-0.3,0.2c0,0.1-0.1,0.1-0.1,0.2
            c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.2,0,0.3c-0.1,0.2-0.2,0.3-0.4,0.3L49,41.5c0,0-0.1,0.1-0.1,0.1
            c0,0.1-0.1,0.3-0.2,0.4c-0.1,0-0.2,0.1-0.2,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.3,0.3-0.7,0.1c-0.1,0-0.1-0.1-0.2-0.1
            c-0.1,0.1-0.3,0.1-0.5,0.1c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c0,0,0,0,0,0.1c0,0.2-0.1,0.3-0.2,0.5C46.5,43,46.4,43.1,46.4,43.1
            C46.4,43.1,46.4,43.1,46.4,43.1z"
          />
        </g>
      </g>
    </svg>
  )
}

export const Other = () => {
  return (
    <svg className="w-full h-full category__svg other" viewBox="0 0 54.2 40.9" x="0px" y="0px">
      <g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M20,3.3l-0.9-1.5c-0.5-0.8-1.4-1.3-2.4-1.3H2.8c-1.3,0-2.3,1-2.3,2.3v35.2
            c0,1.3,1,2.3,2.3,2.3h48.5c1.3,0,2.3-1,2.3-2.3V7c0-1.3-1-2.3-2.3-2.3h-29C21.4,4.7,20.5,4.2,20,3.3z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M51.4,40.9H2.8c-1.6,0-2.8-1.3-2.8-2.8V2.8C0,1.3,1.3,0,2.8,0h13.8c1.2,0,2.2,0.6,2.8,1.6
            L20.4,3c0.4,0.7,1.2,1.1,2,1.1h29c1.6,0,2.8,1.3,2.8,2.8v31.1C54.2,39.6,52.9,40.9,51.4,40.9z M2.8,1C1.8,1,1,1.8,1,2.8v35.2
            c0,1,0.8,1.8,1.8,1.8h48.5c1,0,1.8-0.8,1.8-1.8V7c0-1-0.8-1.8-1.8-1.8h-29c-1.2,0-2.2-0.6-2.8-1.6l-0.9-1.5
            c-0.4-0.7-1.2-1.1-2-1.1H2.8z"
          />
        </g>
        <g>
          <path
            d="M51.4,40.4H2.8c-1.3,0-2.3-1-2.3-2.3V11.6c0-1.3,1-2.3,2.3-2.3h48.5c1.3,0,2.3,1,2.3,2.3v26.5
            C53.7,39.3,52.6,40.4,51.4,40.4z"
            fill={"#FFFFFF"}
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M51.4,40.9H2.8c-1.6,0-2.8-1.3-2.8-2.8V11.6c0-1.6,1.3-2.8,2.8-2.8h48.5
            c1.6,0,2.8,1.3,2.8,2.8v26.5C54.2,39.6,52.9,40.9,51.4,40.9z M2.8,9.8c-1,0-1.8,0.8-1.8,1.8v26.5c0,1,0.8,1.8,1.8,1.8h48.5
            c1,0,1.8-0.8,1.8-1.8V11.6c0-1-0.8-1.8-1.8-1.8H2.8z"
          />
        </g>
        <g>
          <circle
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            cx="19"
            cy="24.4"
            r="2.5"
          />
          <circle
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            cx="27"
            cy="24.4"
            r="2.5"
          />
          <circle
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            cx="35"
            cy="24.4"
            r="2.5"
          />
        </g>
      </g>
    </svg>
  )
}

export const Intro = () => {
  return (
    <svg className="w-full h-full category__svg intro" viewBox="0 0 53.5 51" x="0px" y="0px">
      <g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M49.1,43.4H4.4c-1.3,0-2.3-1-2.3-2.3V8.4c0-1.3,1-2.3,2.3-2.3h44.7c1.3,0,2.3,1,2.3,2.3v32.6
          C51.5,42.4,50.4,43.4,49.1,43.4z M4.4,7.1c-0.7,0-1.3,0.6-1.3,1.3v32.6c0,0.7,0.6,1.3,1.3,1.3h44.7c0.7,0,1.3-0.6,1.3-1.3V8.4
          c0-0.7-0.6-1.3-1.3-1.3H4.4z"
        />
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M36.6,31.6l-19.6,0c-0.5,0-0.9-0.4-0.9-0.9v0c0-0.5,0.4-0.9,0.9-0.9l19.6,0c0.5,0,0.9,0.4,0.9,0.9l0,0
            C37.5,31.2,37.1,31.6,36.6,31.6z"
          />
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M34.7,35.3H18.8c-0.5,0-0.9-0.4-0.9-0.9v0c0-0.5,0.4-0.9,0.9-0.9h15.9c0.5,0,0.9,0.4,0.9,0.9v0
            C35.6,34.9,35.2,35.3,34.7,35.3z"
          />
          <g>
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M32.1,21.9c0.1-0.5,0.9-0.9,0.9-1.4c0-0.5-0.8-0.9-0.9-1.4c-0.1-0.5,0.3-1.3,0.1-1.7
              c-0.3-0.4-1.1-0.4-1.5-0.8c-0.4-0.4-0.3-1.2-0.8-1.5C29.8,15,29.7,15,29.6,15c-0.4,0-0.8,0.2-1.1,0.2c-0.1,0-0.1,0-0.2,0
              c-0.5-0.1-0.9-0.9-1.4-0.9c-0.5,0-0.9,0.8-1.4,0.9c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8-0.2-1.1-0.2c-0.1,0-0.2,0-0.3,0.1
              c-0.5,0.3-0.4,1.1-0.8,1.5c-0.4,0.4-1.2,0.3-1.5,0.8c-0.3,0.4,0.2,1.2,0.1,1.7c-0.1,0.5-0.9,0.9-0.9,1.4c0,0.5,0.8,0.9,0.9,1.4
              s-0.3,1.3-0.1,1.7c0.3,0.4,1.1,0.4,1.5,0.8c0.4,0.4,0.4,1.2,0.8,1.5C23.7,26,23.9,26,24,26c0.4,0,0.8-0.2,1.1-0.2
              c0.1,0,0.1,0,0.2,0c0.5,0.1,0.9,0.9,1.4,0.9c0.5,0,0.9-0.8,1.4-0.9c0.1,0,0.1,0,0.2,0c0.4,0,0.8,0.2,1.1,0.2c0.1,0,0.2,0,0.3-0.1
              c0.4-0.3,0.4-1.1,0.8-1.5c0.4-0.4,1.2-0.4,1.5-0.8C32.4,23.2,32,22.4,32.1,21.9z M26.8,24.9c-2.4,0-4.4-2-4.4-4.4s2-4.4,4.4-4.4
              s4.4,2,4.4,4.4S29.2,24.9,26.8,24.9z"
            />
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M26.8,17c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S28.7,17,26.8,17z"
            />
          </g>
        </g>
        <g>
          <rect
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            height="4.5"
            width="52.5"
            x="0.5"
            y="5"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M53,10.1H0.5C0.2,10.1,0,9.9,0,9.6V5c0-0.3,0.2-0.5,0.5-0.5H53c0.3,0,0.5,0.2,0.5,0.5v4.5
            C53.5,9.9,53.3,10.1,53,10.1z M1,9.1h51.5V5.5H1V9.1z"
          />
        </g>
        <g>
          <line
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            x1="26.8"
            x2="26.8"
            y1="0.5"
            y2="5"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M26.8,5.5c-0.3,0-0.5-0.2-0.5-0.5V0.5c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5V5
            C27.3,5.3,27,5.5,26.8,5.5z"
          />
        </g>
        <g>
          <g>
            <line
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              x1="33.2"
              x2="36.9"
              y1="43"
              y2="50.5"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M36.9,51c-0.2,0-0.4-0.1-0.4-0.3l-3.7-7.5c-0.1-0.2,0-0.5,0.2-0.7c0.2-0.1,0.5,0,0.7,0.2
              l3.7,7.5c0.1,0.2,0,0.5-0.2,0.7C37.1,51,37,51,36.9,51z"
            />
          </g>
          <g>
            <line
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              x1="20.4"
              x2="16.6"
              y1="43"
              y2="50.5"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M16.6,51c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.3-0.4-0.2-0.7l3.7-7.5c0.1-0.2,0.4-0.3,0.7-0.2
              c0.2,0.1,0.3,0.4,0.2,0.7l-3.7,7.5C17,50.9,16.8,51,16.6,51z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const People = () => {
  return (
    <svg className="w-full h-full category__svg people" viewBox="0 0 55.2 50.5" x="0px" y="0px">
      <g>
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M42,8.3L42,8.3c-4.8,0-8.6,3.9-8.6,8.6v2.5c0.2,0,0.3,0,0.5,0c4.8,0,8.8-3.5,9.6-8.1c0.8,3.5,3.6,6.3,7.2,7.2
          v-1.6C50.6,12.2,46.7,8.3,42,8.3z"
        />
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M51.5,24.2l-1,0.4l-0.1,0c-0.3,2.7-1.9,5-4.1,6.3h6.2L51.5,24.2z"
        />
        <path
          className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
          d="M33.3,24.7c-0.4-0.1-0.8-0.3-1.2-0.4L31.2,31h6.2C35.1,29.7,33.6,27.4,33.3,24.7z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M41.8,32.7c-5,0-9.1-4.1-9.1-9.1v-6.7c0-5,4.1-9.1,9.1-9.1s9.1,4.1,9.1,9.1v6.7
          C50.9,28.7,46.8,32.7,41.8,32.7z M41.8,8.8c-4.5,0-8.1,3.6-8.1,8.1v6.7c0,4.5,3.6,8.1,8.1,8.1s8.1-3.6,8.1-8.1v-6.7
          C49.9,12.4,46.3,8.8,41.8,8.8z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M41.8,41.7c-3.1,0-5.3-6.5-5.3-9.3V31c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v1.4
          c0,2.6,2.2,8.3,4.3,8.3s4.3-5.7,4.3-8.3V31c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v1.4C47.1,35.1,44.8,41.7,41.8,41.7z"
        />
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M47.9,32.8h-1.3c-0.2,2.4-2.3,8.3-4.8,8.3c-2.5,0-4.5-5.9-4.8-8.3h-1.3c-3.7,0-6.8,3-6.8,6.8
            V50h25.7V39.6C54.7,35.9,51.6,32.8,47.9,32.8z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M54.7,50.5H28.9c-0.3,0-0.5-0.2-0.5-0.5V39.6c0-4,3.3-7.3,7.3-7.3H37c0.3,0,0.5,0.2,0.5,0.5
            c0.3,2.7,2.3,7.9,4.3,7.9s4-5.2,4.3-7.9c0-0.3,0.2-0.5,0.5-0.5h1.3c4,0,7.3,3.3,7.3,7.3V50C55.2,50.3,54.9,50.5,54.7,50.5z
            M29.4,49.5h24.7v-9.9c0-3.5-2.8-6.3-6.3-6.3H47c-0.4,2.5-2.3,8.3-5.2,8.3s-4.8-5.9-5.2-8.3h-0.9c-3.5,0-6.3,2.8-6.3,6.3V49.5z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M50.3,50.5c-0.3,0-0.5-0.2-0.5-0.5v-6.7c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5V50
            C50.8,50.3,50.5,50.5,50.3,50.5z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M33.4,50.5c-0.3,0-0.5-0.2-0.5-0.5v-6.7c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5V50
            C33.9,50.3,33.6,50.5,33.4,50.5z"
          />
        </g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M41.8,27.8c-1.1,0-2.2-0.5-2.9-1.5c-0.2-0.2-0.1-0.5,0.1-0.7c0.2-0.2,0.5-0.1,0.7,0.1
          c0.5,0.7,1.2,1.1,2.1,1.1s1.6-0.4,2.1-1.1c0.2-0.2,0.5-0.3,0.7-0.1c0.2,0.2,0.3,0.5,0.1,0.7C44,27.3,42.9,27.8,41.8,27.8z"
        />
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M15.7,0.5C10.1,0.5,5.5,5,5.5,10.6v2.8c1.2,0.4,2.5,0.6,3.8,0.6c5.1,0,9.5-3.2,11.2-7.7
            c1.4,1.6,3.2,2.9,5.3,3.5v0.8C25.8,5,21.3,0.5,15.7,0.5z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M15.7,29.6C9.8,29.6,5,24.8,5,18.9v-8.3C5,4.8,9.8,0,15.7,0c5.9,0,10.6,4.8,10.6,10.6v8.3
            C26.3,24.8,21.5,29.6,15.7,29.6z M15.7,1C10.3,1,6,5.3,6,10.6v8.3c0,5.3,4.3,9.6,9.6,9.6h0c5.3,0,9.6-4.3,9.6-9.6v-8.3
            C25.3,5.3,21,1,15.7,1z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M15.7,35.4c-3.4,0-6.1-2.8-6.1-6.1v-1.6c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v1.6
            c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1v-1.6c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v1.6C21.8,32.6,19,35.4,15.7,35.4z"
          />
          <g>
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M22.9,29.8h-1.6c-0.3,2.9-2.7,5.1-5.6,5.1c-2.9,0-5.3-2.2-5.6-5.1H8.5c-4.4,0-8,3.6-8,8V50
              h30.3V37.7C30.8,33.3,27.3,29.8,22.9,29.8z"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M30.8,50.5H0.5C0.2,50.5,0,50.3,0,50V37.7c0-4.7,3.8-8.5,8.5-8.5H10c0.3,0,0.5,0.2,0.5,0.5
              c0.3,2.7,2.5,4.7,5.1,4.7s4.9-2,5.1-4.7c0-0.3,0.2-0.5,0.5-0.5h1.6c4.7,0,8.5,3.8,8.5,8.5V50C31.3,50.3,31.1,50.5,30.8,50.5z
              M1,49.5h29.3V37.7c0-4.1-3.3-7.5-7.5-7.5h-1.1c-0.5,2.9-3,5.1-6.1,5.1s-5.6-2.2-6.1-5.1H8.5c-4.1,0-7.5,3.3-7.5,7.5V49.5z"
            />
          </g>
          <g>
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M5.7,50.5c-0.3,0-0.5-0.2-0.5-0.5v-7.9c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5V50
              C6.2,50.3,6,50.5,5.7,50.5z"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M25.6,50.5c-0.3,0-0.5-0.2-0.5-0.5v-7.9c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5V50
              C26.1,50.3,25.9,50.5,25.6,50.5z"
            />
          </g>
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M15.7,23.7c-1.3,0-2.5-0.6-3.3-1.7c-0.2-0.2-0.1-0.5,0.1-0.7c0.2-0.2,0.5-0.1,0.7,0.1
            c0.6,0.8,1.5,1.3,2.5,1.3s1.9-0.5,2.5-1.3c0.2-0.2,0.5-0.3,0.7-0.1c0.2,0.2,0.3,0.5,0.1,0.7C18.2,23.1,17,23.7,15.7,23.7z"
          />
        </g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M5.5,20.1c-1.7,0-3-1.3-3-3v-0.6c0-1.7,1.3-3,3-3c0.3,0,0.5,0.2,0.5,0.5v5.6
          C6,19.9,5.8,20.1,5.5,20.1z M5,14.6c-0.9,0.2-1.5,1-1.5,1.9v0.6c0,0.9,0.6,1.7,1.5,1.9V14.6z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M25.8,20.1c-0.3,0-0.5-0.2-0.5-0.5v-5.6c0-0.3,0.2-0.5,0.5-0.5c1.7,0,3,1.3,3,3v0.6
          C28.8,18.8,27.5,20.1,25.8,20.1z M26.3,14.6v4.4c0.9-0.2,1.5-1,1.5-1.9v-0.6C27.8,15.6,27.2,14.9,26.3,14.6z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M33.2,25.2c-1.5,0-2.7-1.2-2.7-2.7V22c0-1.5,1.2-2.7,2.7-2.7c0.3,0,0.5,0.2,0.5,0.5v4.8
          C33.7,24.9,33.5,25.2,33.2,25.2z M32.7,20.4c-0.7,0.2-1.2,0.8-1.2,1.6v0.5c0,0.7,0.5,1.4,1.2,1.6V20.4z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M50.4,25.2c-0.3,0-0.5-0.2-0.5-0.5v-4.8c0-0.3,0.2-0.5,0.5-0.5c1.5,0,2.7,1.2,2.7,2.7v0.5
          C53.1,24,51.9,25.2,50.4,25.2z M50.9,20.4v3.7c0.7-0.2,1.2-0.8,1.2-1.6V22C52.1,21.3,51.6,20.6,50.9,20.4z"
        />
      </g>
    </svg>
  )
}

export const Food = () => {
  return (
    <svg className="w-full h-full category__svg food" viewBox="0 0 47.1 50.5" x="0px" y="0px">
      <g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M23.8,0.5L23.8,0.5C11.2,0.5,1,10.7,1,23.3h45.6C46.6,10.7,36.3,0.5,23.8,0.5z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M46.6,23.8H1c-0.3,0-0.5-0.2-0.5-0.5C0.5,10.5,10.9,0,23.8,0s23.3,10.5,23.3,23.3
                    C47.1,23.6,46.8,23.8,46.6,23.8z M1.5,22.8H46C45.8,10.7,35.9,1,23.8,1S1.7,10.7,1.5,22.8z"
          />
        </g>
        <g>
          <path
            className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
            d="M40,50H7.5C3.9,50,1,47.1,1,43.5v0h45.6v0C46.6,47.1,43.6,50,40,50z"
          />
          <path
            className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
            d="M40,50.5H7.5c-3.9,0-7-3.2-7-7C0.5,43.2,0.7,43,1,43h45.6c0.3,0,0.5,0.2,0.5,0.5
                    C47.1,47.3,43.9,50.5,40,50.5z M1.5,44c0.3,3.1,2.8,5.5,6,5.5H40c3.2,0,5.7-2.4,6-5.5H1.5z"
          />
        </g>
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M23.8,36.6c-0.1,0-0.3,0-0.4-0.1L16,29.1c-1-1-2.4-1.6-3.8-1.6H1c-0.3,0-0.5-0.2-0.5-0.5
                s0.2-0.5,0.5-0.5h11.2c1.7,0,3.3,0.7,4.5,1.9l7.1,7.1l7.1-7.1c1.2-1.2,2.8-1.9,4.5-1.9h11.2c0.3,0,0.5,0.2,0.5,0.5
                s-0.2,0.5-0.5,0.5H35.4c-1.4,0-2.8,0.6-3.8,1.6l-7.4,7.4C24.1,36.6,24,36.6,23.8,36.6z"
        />
        <path
          className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
          d="M40.8,40.7c-1.6,0-2.5-0.7-3.2-1.4c-0.7-0.6-1.3-1.1-2.6-1.1c-1.3,0-1.9,0.5-2.6,1.1
                c-0.7,0.6-1.6,1.4-3.2,1.4c-1.6,0-2.5-0.7-3.2-1.4c-0.7-0.6-1.3-1.1-2.5-1.1c-1.3,0-1.9,0.5-2.5,1.1c-0.7,0.6-1.6,1.4-3.2,1.4
                c-1.6,0-2.5-0.7-3.2-1.4c-0.7-0.6-1.3-1.1-2.5-1.1c-1.3,0-1.9,0.5-2.5,1.1c-0.7,0.6-1.6,1.4-3.2,1.4S3.8,39.9,3,39.3
                c-0.7-0.6-1.3-1.1-2.5-1.1c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5c1.6,0,2.5,0.7,3.2,1.4c0.7,0.6,1.3,1.1,2.5,1.1s1.9-0.5,2.5-1.1
                c0.7-0.6,1.6-1.4,3.2-1.4c1.6,0,2.5,0.7,3.2,1.4c0.7,0.6,1.3,1.1,2.5,1.1c1.3,0,1.9-0.5,2.5-1.1c0.7-0.6,1.6-1.4,3.2-1.4
                s2.5,0.7,3.2,1.4c0.7,0.6,1.3,1.1,2.5,1.1c1.3,0,1.9-0.5,2.6-1.1c0.7-0.6,1.6-1.4,3.2-1.4c1.6,0,2.5,0.7,3.2,1.4
                c0.7,0.6,1.3,1.1,2.6,1.1c1.3,0,1.9-0.5,2.6-1.1c0.7-0.6,1.6-1.4,3.2-1.4c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5
                c-1.3,0-1.9,0.5-2.6,1.1C43.3,39.9,42.4,40.7,40.8,40.7z"
        />
        <g>
          <g>
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M18.8,31.1H2.6c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h19.5L18.8,31.1z"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M22.1,34.9H2.6c-1.2,0-2.1-1-2.1-2.1c0-1.2,1-2.1,2.1-2.1h16.2c0.1,0,0.3,0.1,0.4,0.1
                        l3.3,3.3c0.1,0.1,0.2,0.4,0.1,0.5C22.4,34.7,22.3,34.9,22.1,34.9z M2.6,31.6c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1h18.2
                        l-2.3-2.3H2.6z"
            />
          </g>
          <g>
            <path
              className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
              d="M44.9,31.1h-16l-3.3,3.3h19.3c0.9,0,1.6-0.7,1.6-1.6C46.6,31.8,45.8,31.1,44.9,31.1z"
            />
            <path
              className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
              d="M44.9,34.9H25.6c-0.2,0-0.4-0.1-0.5-0.3s0-0.4,0.1-0.5l3.3-3.3c0.1-0.1,0.2-0.1,0.4-0.1h16
                        c1.2,0,2.1,1,2.1,2.1C47.1,33.9,46.1,34.9,44.9,34.9z M26.8,33.9h18.1c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1H29.1L26.8,33.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <g>
              <line
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                x1="19.1"
                x2="20.3"
                y1="11.9"
                y2="13.1"
              />
              <path
                className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
                d="M20.3,13.6c-0.1,0-0.3,0-0.4-0.1l-1.2-1.2c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l1.2,1.2
                            c0.2,0.2,0.2,0.5,0,0.7C20.6,13.6,20.4,13.6,20.3,13.6z"
              />
            </g>
            <g>
              <line
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                x1="11"
                x2="12.2"
                y1="11.9"
                y2="13.1"
              />
              <path
                className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
                d="M12.2,13.6c-0.1,0-0.3,0-0.4-0.1l-1.2-1.2c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l1.2,1.2
                            c0.2,0.2,0.2,0.5,0,0.7C12.5,13.6,12.3,13.6,12.2,13.6z"
              />
            </g>
            <g>
              <line
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                x1="27.2"
                x2="28.4"
                y1="11.9"
                y2="13.1"
              />
              <path
                className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
                d="M28.4,13.6c-0.1,0-0.3,0-0.4-0.1l-1.2-1.2c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l1.2,1.2
                            c0.2,0.2,0.2,0.5,0,0.7C28.7,13.6,28.5,13.6,28.4,13.6z"
              />
            </g>
            <g>
              <line
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                x1="35.3"
                x2="36.5"
                y1="11.9"
                y2="13.1"
              />
              <path
                className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
                d="M36.5,13.6c-0.1,0-0.3,0-0.4-0.1L35,12.3c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l1.2,1.2
                            c0.2,0.2,0.2,0.5,0,0.7C36.7,13.6,36.6,13.6,36.5,13.6z"
              />
            </g>
          </g>
          <g>
            <g>
              <line
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                x1="15.1"
                x2="16.3"
                y1="7.2"
                y2="8.4"
              />
              <path
                className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
                d="M16.3,8.9c-0.1,0-0.3,0-0.4-0.1l-1.2-1.2c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0L16.6,8
                            c0.2,0.2,0.2,0.5,0,0.7C16.5,8.8,16.4,8.9,16.3,8.9z"
              />
            </g>
            <g>
              <line
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                x1="23.2"
                x2="24.3"
                y1="7.2"
                y2="8.4"
              />
              <path
                className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
                d="M24.3,8.9c-0.1,0-0.3,0-0.4-0.1l-1.2-1.2c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0L24.7,8
                            c0.2,0.2,0.2,0.5,0,0.7C24.6,8.8,24.5,8.9,24.3,8.9z"
              />
            </g>
            <g>
              <line
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                x1="31.3"
                x2="32.4"
                y1="7.2"
                y2="8.4"
              />
              <path
                className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
                d="M32.4,8.9c-0.1,0-0.3,0-0.4-0.1l-1.2-1.2c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0L32.8,8
                            c0.2,0.2,0.2,0.5,0,0.7C32.7,8.8,32.6,8.9,32.4,8.9z"
              />
            </g>
          </g>
          <g>
            <g>
              <line
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                x1="15.1"
                x2="16.3"
                y1="16.6"
                y2="17.8"
              />
              <path
                className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
                d="M16.3,18.3c-0.1,0-0.3,0-0.4-0.1L14.7,17c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l1.2,1.2
                            c0.2,0.2,0.2,0.5,0,0.7C16.5,18.3,16.4,18.3,16.3,18.3z"
              />
            </g>
            <g>
              <line
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                x1="23.2"
                x2="24.3"
                y1="16.6"
                y2="17.8"
              />
              <path
                className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
                d="M24.3,18.3c-0.1,0-0.3,0-0.4-0.1L22.8,17c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l1.2,1.2
                            c0.2,0.2,0.2,0.5,0,0.7C24.6,18.3,24.5,18.3,24.3,18.3z"
              />
            </g>
            <g>
              <line
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                x1="7"
                x2="8.2"
                y1="16.6"
                y2="17.8"
              />
              <path
                className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
                d="M8.2,18.3c-0.1,0-0.3,0-0.4-0.1L6.6,17c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l1.2,1.2
                            c0.2,0.2,0.2,0.5,0,0.7C8.4,18.3,8.3,18.3,8.2,18.3z"
              />
            </g>
            <g>
              <line
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                x1="31.3"
                x2="32.4"
                y1="16.6"
                y2="17.8"
              />
              <path
                className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
                d="M32.4,18.3c-0.1,0-0.3,0-0.4-0.1L30.9,17c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l1.2,1.2
                            c0.2,0.2,0.2,0.5,0,0.7C32.7,18.3,32.6,18.3,32.4,18.3z"
              />
            </g>
            <g>
              <line
                className="fill-sc-collection-fill group-hover:fill-sc-collection-fill-blue group-aria-checked:fill-sc-collection-fill-blue"
                x1="39.4"
                x2="40.5"
                y1="16.6"
                y2="17.8"
              />
              <path
                className="fill-sc-collection-border group-hover:fill-sc-collection-border-blue group-aria-checked:fill-sc-collection-border-blue"
                d="M40.5,18.3c-0.1,0-0.3,0-0.4-0.1L39,17c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l1.2,1.2
                            c0.2,0.2,0.2,0.5,0,0.7C40.8,18.3,40.7,18.3,40.5,18.3z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
