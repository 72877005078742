import { MutableRefObject, useContext } from "react"
import { Batch } from "../../graphql/types/queries/batch"
import { useClickOutside } from "../../hooks/ClickOutsideHook"
import { UserDataContext } from "@/hooks/UserDataHook"

interface CartDropdownListProps {
  canSave: boolean
  openForm(): void
  closeDropdown(): void
}

const CartDropdownList = ({ closeDropdown, openForm, canSave }: CartDropdownListProps) => {
  const {
    updateSlidesInCartFunction,
    user: { cart }
  } = useContext(UserDataContext)

  const ref: MutableRefObject<HTMLUListElement | undefined | null> = useClickOutside(closeDropdown)

  const handleClearAll = () => {
    updateSlidesInCartFunction([])
    closeDropdown()
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "scClearCart",
      cart: {
        deletedSlides: cart?.sets[0].slides.map((item) => item.slideId),
        collectionId: cart?.batchId,
        collectionName: cart?.name
      }
    })
  }

  const handleClickSave = () => {
    openForm()
    closeDropdown()
  }

  return (
    <ul
      className="*:p-[6px] *:pl-[22px] hover:*:bg-[#c6cbce4d] hover:*:text-[#3390ff] *:cursor-pointer"
      ref={(_ref) => {
        ref.current = _ref
      }}
    >
      {canSave && <li onClick={handleClickSave}>Save presentation</li>}
      <li onClick={handleClearAll}>Clear all</li>
    </ul>
  )
}

export default CartDropdownList
