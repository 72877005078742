import React from "react"
import CollectionSection from "@/components/category/CollectionSection"
import CategoryMenu from "@/components/category/CategoryMenu"

const SubCategory = ({
  category,
  subCategory,
  typeName,
  Icon,
  batchesName,
  isEditModeActive,
  active,
  setOpenedBatch,
  openedBatch,
  relevantBatches,
  index
}) => {
  const requiresExtraTopMargin = relevantBatches.length || isEditModeActive || index !== 0

  return (
    <div>
      <div className={`${requiresExtraTopMargin ? "mt-[25px]" : ""} group`}>
        <div className={`${requiresExtraTopMargin ? "pt-[18px]" : ""} flex w-fit items-center`}>
          <h3 className="text-base font-semibold leading-none text-sc-text-dark">{subCategory.name}</h3>
          {isEditModeActive && (
            <CategoryMenu
              buttonStyles={{ width: "20px", paddingInline: "5px" }}
              categoryName={subCategory.name}
              deleteModalContext={
                <span>
                  Are you sure you want to remove <span className="text-scblue">{subCategory.name}&nbsp;</span>
                  from your&nbsp;SlideCamp?
                </span>
              }
              deleteModalTitle="Delete Section"
              icon={
                <svg fill="currentColor" height="12" viewBox="0 0 3 12" width="3" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.4375 6.02002C0.4375 6.62408 0.927188 7.11377 1.53125 7.11377C2.13531 7.11377 2.625 6.62408 2.625 6.02002C2.625 5.41596 2.13531 4.92627 1.53125 4.92627C0.927189 4.92627 0.4375 5.41596 0.4375 6.02002Z"
                    stroke="#6B6F7A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.21875"
                  />
                  <path
                    d="M0.4375 1.64502C0.4375 2.24908 0.927188 2.73877 1.53125 2.73877C2.13531 2.73877 2.625 2.24908 2.625 1.64502C2.625 1.04096 2.13531 0.55127 1.53125 0.551269C0.927189 0.551269 0.4375 1.04096 0.4375 1.64502Z"
                    stroke="#6B6F7A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.21875"
                  />
                  <path
                    d="M0.4375 10.395C0.4375 10.9991 0.927188 11.4888 1.53125 11.4888C2.13531 11.4888 2.625 10.9991 2.625 10.395C2.625 9.79096 2.13531 9.30127 1.53125 9.30127C0.927189 9.30127 0.4375 9.79096 0.4375 10.395Z"
                    stroke="#6B6F7A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.21875"
                  />
                </svg>
              }
              id={category._id}
              order={category.order}
              renameModalTitle="Rename Section"
              subCategory={subCategory}
            />
          )}
        </div>
      </div>
      <li>
        <div className="flex flex-col gap-[25px]">
          {/*/!*{category?.name !== "Team Presentations" && category?.name !== "Opportunities and Packages" && (*!/*/}
          <CollectionSection
            active={active}
            batchesName={batchesName}
            category={category}
            Icon={Icon}
            id={subCategory._id}
            isEditModeActive={isEditModeActive}
            isSection={true}
            name={typeName}
            openedBatch={openedBatch}
            relevantBatches={batchesName === "Collections" ? subCategory.collections : subCategory.presentations}
            setOpenedBatch={setOpenedBatch}
          />
        </div>
      </li>
    </div>
  )
}
export default SubCategory
