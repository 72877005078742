import { useEffect, useState, useRef, useLayoutEffect, KeyboardEvent, MouseEventHandler, MouseEvent } from "react"
import { useParams } from "react-router-dom"
import getBrowserFingerprint from "get-browser-fingerprint"
import { useMutation } from "@apollo/client"
import { findDOMNode } from "react-dom"
import { imagesQueue } from "react-images-queue"
import Fullscreen from "react-full-screen"
import AllSlidesContainer from "./AllSlidesContainer"
import BatchController from "./BatchController"
import ViewerSlide from "./ViewerSlide"
import { Batch } from "@/graphql/types/queries"
import { ADD_EVENT } from "@/graphql/mutations"

enum EventEnum {
  slideShowOpened = "slideShowOpened",
  slideChanged = "slideChanged",
  slideShowClosed = "slideShowClosed",
  presentationDownloadedFromSharedLink = "presentationDownloadedFromSharedLink"
}

interface GenerateTrackedEventParams {
  type: EventEnum
  extraTopFields?: Record<string, any>
  extraDataFields?: {
    presentation?: string
    slide?: string
  }
}

interface BatchPresenterProps {
  batch: Batch
  trigger?: string

  exitFullScreen?(val: boolean): void
}

const BatchPresenter = ({ batch, trigger, exitFullScreen = () => {} }: BatchPresenterProps) => {
  const { shareToken } = useParams<{ shareToken: string }>()
  const [isPanelVisible, setIsPanelVisible] = useState<boolean>(true)
  const [isFull, setIsFull] = useState<boolean>(false)
  const [showAllSlides, setShowAllSlides] = useState<boolean>(false)
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true)
  const [currentSlide, _setCurrentSlide] = useState<number>(0)
  const [toggledByClick, setToggledByClick] = useState(false)
  const shouldBlockNavigationRef = useRef(true)

  const currentSlideRef = useRef(currentSlide)

  function setCurrentSlide(num: number) {
    currentSlideRef.current = num
    _setCurrentSlide(num)
  }

  // let intervalId: NodeJS.Timer

  const [addEvent] = useMutation(ADD_EVENT, {
    context: { isUsingNewScApi: true }
  })

  const divElement = useRef<HTMLDivElement | null>(null)

  // eslint-disable-next-line no-undef
  const timeout = useRef<NodeJS.Timeout | null>(null)

  const focusDiv = () => {
    // eslint-disable-next-line react/no-find-dom-node
    findDOMNode(divElement.current)
  }

  useEffect(() => {
    focusDiv()
    if (!showAllSlides) {
      focusDiv()
    }
  }, [])

  const fingerprint = useRef<number>(getBrowserFingerprint()).current
  const generateTrackedEvent = ({ type, extraTopFields, extraDataFields }: GenerateTrackedEventParams) => ({
    type,
    browserFingerprint: fingerprint.toString(),
    ...extraTopFields,
    data: {
      ...extraDataFields,
      presentation: batch.batchId,
      shareToken
    }
  })

  useEffect(() => {
    const slide = getBluePrintAtIndex(currentSlide)
    if (isInitialLoad) {
      addEvent({
        variables: generateTrackedEvent({
          type: EventEnum.slideShowOpened,
          extraDataFields: {
            slide
          }
        })
      })
      setIsInitialLoad(false)
    } else {
      addEvent({
        variables: generateTrackedEvent({
          type: EventEnum.slideChanged,
          extraDataFields: {
            slide
          }
        })
      })
    }
  }, [currentSlide])

  const beforeUnload = (e: BeforeUnloadEvent) => {
    const slide = getBluePrintAtIndex(currentSlideRef.current)
    if (shouldBlockNavigationRef.current) {
      addEvent({
        variables: generateTrackedEvent({
          type: EventEnum.slideShowClosed,
          extraDataFields: {
            slide
          }
        })
      })
      const confirmationMessage = "Unload"
      e.returnValue = confirmationMessage
      return confirmationMessage
    }
  }

  const handleAddDownloadEvent = () => {
    const slide = getBluePrintAtIndex(currentSlideRef.current)
    addEvent({
      variables: generateTrackedEvent({
        type: EventEnum.presentationDownloadedFromSharedLink,
        extraDataFields: {
          slide
        }
      })
    })
  }

  useLayoutEffect(() => {
    window.addEventListener("beforeunload", beforeUnload)
    return () => {
      window.removeEventListener("beforeunload", beforeUnload)
    }
  }, [])

  const getBluePrintAtIndex = (index: number) => {
    return batch.sets[0].slides[index].blueprintId
  }

  const handleMove = () => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    !isPanelVisible && setIsPanelVisible(true)
    setToggledByClick(true)
    timeout.current = setTimeout(() => setIsPanelVisible(false), 1000)
  }

  const handleLeave = (event: MouseEvent): MouseEventHandler<HTMLDivElement> | undefined => {
    event.stopPropagation()
    const relatedTarget = event.relatedTarget

    // Check if relatedTarget is a valid DOM node or null
    if (
      relatedTarget instanceof Node &&
      (relatedTarget === divElement.current || divElement.current?.contains(relatedTarget))
    ) {
      return
    }

    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    if (!toggledByClick) {
      setIsPanelVisible(false)
    }
  }

  useEffect(() => {
    const element = divElement.current

    if (element) {
      element.addEventListener("mousemove", handleMove)
      element.addEventListener("mouseleave", handleLeave)
    }

    return () => {
      if (element) {
        element.removeEventListener("mousemove", handleMove)
        element.removeEventListener("mouseleave", handleLeave)
      }
    }
  }, [isPanelVisible])

  const toggleShowAllSlides = (value: boolean) => {
    setShowAllSlides(value)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: "scViewAllSlides" })
  }

  const changeSlideNumber = (newSlideNumber: number) => {
    setCurrentSlide(newSlideNumber)
    window.dataLayer = window.dataLayer || []
    if (currentSlide === newSlideNumber + 1) {
      window.dataLayer.push({ event: "scChangeSlide", direction: "previous" })
    } else if (currentSlide === newSlideNumber - 1) {
      window.dataLayer.push({ event: "scChangeSlide", direction: "next" })
    }
  }

  const handleKeyPressed = (e: KeyboardEvent) => {
    if (e.key === "ArrowRight") {
      if (currentSlide + 1 === batch.size) {
        return
      }
      changeSlideNumber(currentSlide + 1)
    } else if (e.key === "ArrowLeft") {
      if (currentSlide === 0) {
        return
      }
      changeSlideNumber(currentSlide - 1)
    }
  }

  const renderMainContent = () => {
    const imgSrc = batch.sets[0].slides[currentSlide].thumbUrl.replace(
      "{width}",
      window.devicePixelRatio > 1 ? "2880" : "1440"
    )
    return (
      <div>
        {!showAllSlides ? (
          <div className="viewer-slide-wrap">
            <div
              className="viewer-slide-container"
              onKeyDown={handleKeyPressed}
              onMouseLeave={handleLeave}
              onMouseMove={handleMove}
              ref={(node) => (divElement.current = node)}
              tabIndex={0}
            >
              <ViewerSlide
                linksData={batch.sets[0].slides[currentSlide].linksData || []}
                linksHeight={batch.sets[0].slides[currentSlide].linksDataHeight || 0}
                linksWidth={batch.sets[0].slides[currentSlide].linksDataWidth || 0}
                slide={batch.sets[0].slides[currentSlide].thumbUrl}
                slideLoaded={imgSrc}
              />
            </div>
            {isPanelVisible && (
              <BatchController
                changeSlide={changeSlideNumber}
                currentSlide={currentSlide}
                handleAddDownloadEvent={handleAddDownloadEvent}
                isDownloadable={batch.sharedPresentationLinks.isDownloadable}
                isFull={trigger === "fullscreen" || isFull}
                pptx={batch.pptx}
                ref={shouldBlockNavigationRef}
                size={batch.size}
                toggleFull={trigger === "fullscreen" ? exitFullScreen : setIsFull}
                toggleShowAllSlides={toggleShowAllSlides}
              />
            )}
          </div>
        ) : (
          <AllSlidesContainer
            changeSlide={changeSlideNumber}
            slides={batch.sets[0].slides}
            title={batch.name}
            toggleShowAllSlides={toggleShowAllSlides}
          />
        )}
      </div>
    )
  }

  if (trigger === "fullscreen") {
    return renderMainContent()
  }
  return (
    <Fullscreen enabled={isFull} onChange={(isFull) => setIsFull(isFull)}>
      {renderMainContent()}
    </Fullscreen>
  )
}

export default imagesQueue(
  ({ batch }: { batch: Batch }) =>
    batch.sets[0].slides.map((slide) =>
      slide.thumbUrl.replace("{width}", window.devicePixelRatio > 1 ? "2880" : "1440")
    ),
  { size: 3 }
)(BatchPresenter)
