export function getSelectedSlideID() {
  return new OfficeExtension.Promise(function (resolve, reject) {
    Office.context.document.getSelectedDataAsync(Office.CoercionType.SlideRange, function (asyncResult) {
      try {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          reject(console.error(asyncResult.error.message))
        } else {
          resolve(asyncResult.value.slides[0].id)
        }
      } catch (error) {
        reject(console.log(error))
      }
    })
  })
}

export function selectInsertedSlide(id) {
  const index = id ? Office.Index.Next : Office.Index.First
  Office.context.document.goToByIdAsync(index, Office.GoToType.Index, (asyncResult) => {
    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
      setMessage("Error: " + asyncResult.error.message)
    }
  })
}
