import { render } from "react-dom"
import MainComponent from "./App"
import * as Sentry from "@sentry/react"
import { ErrorPlaceholder } from "./components/utils/placeholders"
import "./scss/application.scss"
import "./index.css"
import { IsInsidePowerPointProvider } from "./context/IsInsidePowerPointContext"

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({ networkDetailAllowUrls: ["api.slidecamp.io", "api2.slidecamp.io"] })
  ],
  environment: import.meta.env.MODE,
  tracesSampleRate: 1.0
})

if (import.meta.env.PROD && window.location.href.search("appspot.com") === -1 && import.meta.env.VITE_MIXPANEL_TOKEN) {
  window.mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN)
}

document.body.addEventListener(
  "error",
  (event) => {
    if (!event.target) return

    if (event.target.tagName === "IMG") {
      Sentry.captureMessage(`Failed to load image: ${event.target.src}`, "warning")
    } else if (event.target.tagName === "LINK") {
      Sentry.captureMessage(`Failed to load css: ${event.target.href}`, "warning")
    }
  },
  true // useCapture - necessary for resource loading errors
)

const App = () => {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <ErrorPlaceholder
          tryAgain={() => {
            window.location.replace("/")
          }}
        />
      }
    >
      <IsInsidePowerPointProvider>
        <MainComponent />
      </IsInsidePowerPointProvider>
    </Sentry.ErrorBoundary>
  )
}

render(<App />, document.getElementById("app"))
