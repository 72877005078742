import { useContext, useState } from "react"
import { Route, Redirect, Switch } from "react-router-dom"
import slugify from "slug"
import NewNavigationBar from "./topbar/NewNavigationBar"
import SlideModal from "./modals/SlideModal"
import PricingModal from "./modals/PricingModal"
import MainModal from "./modals/MainModal"
import CartContainer from "./cart/CartContainer"
import { NotFoundPage } from "./NotFoundPage"
import BatchesContainer from "./containers/BatchesContainer"
import DashboardContainer from "./containers/DashboardContainer"
import Users from "./admin/Users"
import AdminPage from "./admin/AdminPage"
import ProfilePage from "./profile/ProfilePage"
import { PricingPage } from "./pricing/PricingPage"
import PayPalRedirect from "./pricing/PayPalRedirect"
import AdminInfoBar from "./admin/AdminInfoBar"
import { LoadingPlaceholder, TrialBar } from "./utils/placeholders"
import LogsPage from "./logs"
import { MainModalContext } from "../hooks/MainModalHook"
import { SlideModalContext } from "../hooks/SlideModalHook"
import { PricingModalContext } from "../hooks/PricingModalHook"
import { AuthContext } from "../hooks/AuthHook"
import { NotificationContext } from "../hooks/NotificationHook"
import { Notification } from "./utils/notifications"
import Category from "./category"
import { UserDataContext, UserDataContextProvider } from "../hooks/UserDataHook"
import { CategoryContext, CategoryContextProvider } from "@/context/CategoryContext"
import SearchContainer from "./containers/SearchContainer"
import FavouritesContainer from "./containers/FavouritesContainer"
import { useQuery } from "@apollo/client"
import { UserData } from "../graphql/queries"
import BatchDetailsPage from "./containers/DashboardContainer/BatchDetailsPage"
import { useIsInsidePowerPoint } from "../context/IsInsidePowerPointContext"
import PowerPointNavbar from "./topbar/PowerPointNavbar"
import RecentSearches from "./containers/RecentSearches"

const MainPage = () => {
  const { user, sessionOwner } = useContext(UserDataContext)
  const { categories } = useContext(CategoryContext)
  const { subscription, group, teamOwner, cart } = user
  const [searchInputValue, setSearchInputValue] = useState("")
  const isInsidePowerPoint = useIsInsidePowerPoint()

  const categoryName = {
    SLIDES: group.groupId === "latimes" ? "Core Decks" : "Slides",
    TEAM_PRESENTATIONS: group.groupId === "latimes" ? "Opportunities and Packages" : "Team Presentations"
  }

  const getCategoryByName = (name) => {
    return categories.find((category) => category.name === name)
  }

  const getPresentationFromCategory = (category, presentationId) => {
    if (!category || !presentationId) {
      return null
    }
    return category.presentations.find((presentation) => presentation.id === presentationId)
  }

  if (isInsidePowerPoint === null) {
    return <LoadingPlaceholder />
  }

  return (
    <div className={`${isInsidePowerPoint ? "bg-[#F5F5F5] flex flex-col min-h-screen" : ""}`}>
      {!!sessionOwner && <AdminInfoBar />}
      {subscription && group.groupId === "management" && subscription.plan.planId === "trial" && teamOwner && (
        <TrialBar />
      )}
      <PayPalRedirect />
      {isInsidePowerPoint ? (
        <PowerPointNavbar searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue} />
      ) : (
        <NewNavigationBar />
      )}
      <div className={`${isInsidePowerPoint ? "flex-1 flex w-full" : ""}`} data-testid="cart-wrapper">
        <div
          className={`${isInsidePowerPoint ? "w-full" : ""} ${
            cart && !isInsidePowerPoint ? "mr-[312px] min-[2050px]:mr-[402px]" : ""
          }`}
        >
          <Switch>
            <Route component={BatchDetailsPage} path="/dashboard/:urlToken/:name/:slideId?" />
            <Route exact path="/dashboard" render={() => <DashboardContainer />} />
            <Route
              path="/assets/:id"
              render={(props) => {
                const assetId = props.match.params.id
                const category = getCategoryByName(categoryName.SLIDES)
                const presentation = getPresentationFromCategory(category, assetId)

                if (presentation) {
                  const path = `/library/${presentation.urlToken}/${slugify(presentation.name)}`
                  return <Redirect from={`/assets/${assetId}`} to={path} />
                }
              }}
            />
            {/* <Route component={BatchesContainer} path="/shared" /> */}
            {/*<Route*/}
            {/*  path="/shared"*/}
            {/*  render={() => {*/}
            {/*    const category = getCategoryByName(categoryName.TEAM_PRESENTATIONS)*/}

            {/*    if (category) {*/}
            {/*      const categoryPath = `/library/${category.urlToken}/${slugify(category.name)}`*/}
            {/*      return <Redirect from="/shared" to={categoryPath} />*/}
            {/*    }*/}
            {/*  }}*/}
            {/*/>*/}
            {/* <Route component={BatchesContainer} path="/presentations" /> */}
            <Route component={BatchesContainer} path="/my-presentations/:urlToken?/:name?/:slideId?" />
            <Redirect from="/presentations" to="/my-presentations" />
            {/* <Route path="/templates" render={(props) => <Batches rest={props} />} /> */}
            <Route
              path="/templates"
              render={() => {
                const category = getCategoryByName(categoryName.SLIDES)

                if (category) {
                  const categoryPath = `/library/${category.urlToken}/${slugify(category.name)}`
                  return <Redirect from="/templates" to={categoryPath} />
                }
              }}
            />
            <Route component={FavouritesContainer} path="/favorites" />
            <Route
              path="/search/recent"
              render={(props) => <RecentSearches {...props} setSearchInputValue={setSearchInputValue} />}
            />
            <Route component={SearchContainer} path="/search" />
            <Route component={PricingPage} path="/pricing" />
            <Route component={Users} path="/admin-panel/users" />
            <Route component={AdminPage} path="/admin-panel" />
            {/* FIX: does not work, is this even used? */}
            <Route component={ProfilePage} path="/myprofile" />
            <Route component={LogsPage} path="/activity" />
            <Route component={Category} path="/library/:urlToken?/:name?" />
            <Redirect from="/collections" to="/presentations" />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
        {cart && !isInsidePowerPoint && <CartContainer />}
      </div>
    </div>
  )
}

const MainPageContainer = () => {
  const { isAuthenticated } = useContext(AuthContext)
  const { modalContent, modalData, modalType, modalCloseable } = useContext(MainModalContext)
  const { showModal } = useContext(SlideModalContext)
  const { modalOpened, modalComponent, selectedPlan, paymentStage, stripeStage, closeModal, setSelectedPlan } =
    useContext(PricingModalContext)
  const { type, content } = useContext(NotificationContext)

  if (!isAuthenticated) {
    return <Redirect to="/users/sign_in" />
  }

  const { loading } = useQuery(UserData, {
    fetchPolicy: "cache-and-network"
  })

  if (loading) {
    return <div />
  }

  return (
    <UserDataContextProvider>
      <CategoryContextProvider>
        <MainModal
          closeable={modalCloseable}
          content={modalContent}
          data={modalData}
          isOpen={!!modalContent}
          type={modalType}
        />
        <SlideModal showModal={showModal} />
        <PricingModal
          closeModal={closeModal}
          modalComponent={modalComponent}
          modalOpened={modalOpened}
          paymentStage={paymentStage}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          stripeStage={stripeStage}
        />
        <Notification type={type}>{content}</Notification>
        <Route component={MainPage} path="/" />
      </CategoryContextProvider>
    </UserDataContextProvider>
  )
}

export default MainPageContainer
