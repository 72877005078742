import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { useContext, useEffect, useState } from "react"
import { CategoryIcon } from "@/svg/CategoryIcons"
import { MainModalContext } from "@/hooks/MainModalHook"
import { Batch } from "@/graphql/types/queries/batch"

const BATCH_DATA = gql`
  query getBatches {
    session {
      user {
        templates {
          id
          batchId
          name
          icon
          sets {
            slides {
              id
            }
          }
        }
      }
    }
  }
`

const MOVE_SLIDE_BETWEEN_CATEGORIES = gql`
  mutation moveSlideBetweenCategories($slideId: String!, $fromCategoryId: String!, $toCategoryId: String!) {
    moveSlideBetweenCategories(slideId: $slideId, fromCategoryId: $fromCategoryId, toCategoryId: $toCategoryId) {
      code
      success
      message
      fromCategoryJob {
        id
        queueName
        lifecycle {
          status
          progress
          error
        }
      }
      toCategoryJob {
        id
        queueName
        lifecycle {
          status
          progress
          error
        }
      }
    }
  }
`

const GET_PRESENTATIONS = gql`
  query presentations($ids: [String!]!) {
    presentations(ids: $ids) {
      _id
      id
      thumbUrl
      name
      batchId
      icon
      isFavourite
      sharedPresentationLinks {
        isActive
        token
        isDownloadable
        _id
      }
      sets {
        id
        slides {
          id
          slideId
          blueprintId
          thumbUrl
          name
          tags
          downloadUrl
          linksDataHeight
          linksDataWidth
          state
          isFavourite
        }
      }
      category {
        _id
      }
    }
  }
`

interface MoveSlideToCategoryProps {
  blueprintId: string
  batchId: string
  refetch: () => void
}

interface Category extends Batch {
  selected: boolean
}

const MoveSlideToCategory = ({ blueprintId, batchId }: MoveSlideToCategoryProps) => {
  const { closeModal } = useContext(MainModalContext)
  const [categories, setCategories] = useState<Category[]>([])
  const selectedCategoryBatchId = categories.find((c) => c.selected)?.batchId || ""
  const [getPresentationsData, { stopPolling: stopPresentationDataPolling }] = useLazyQuery(GET_PRESENTATIONS, {
    context: { isUsingNewScApi: true },
    pollInterval: 1500,
    fetchPolicy: "network-only",
    onCompleted: ({ presentation }) => {
      if (!presentation) {
        stopPresentationDataPolling()
        return
      }
      const sets = presentation.sets
      if (!sets?.length) return
      if (sets[0]?.slides.length && sets[0].slides[0]?.state === "uploaded") {
        stopPresentationDataPolling()
      }
    }
  })
  const [moveSlideBetweenCategories] = useMutation(MOVE_SLIDE_BETWEEN_CATEGORIES, {
    context: { isUsingNewScApi: true }
  })

  const { data } = useQuery(BATCH_DATA)

  useEffect(() => {
    if (data) {
      setCategories(
        data.session.user.templates
          .filter((category: Category) => category.batchId !== batchId)
          .map((c: Category) => ({ ...c, selected: false }))
      )
    }
  }, [data, batchId])

  const onCategoryClick = (batchId: string) => {
    if (batchId) {
      const selectedCount = categories.filter((c) => c.selected).length
      if (selectedCount > 0)
        setCategories((state) =>
          state.map((c) => ({
            ...c,
            selected: false
          }))
        )
      setCategories((state) => state.map((c: Category) => (c.batchId === batchId ? { ...c, selected: true } : c)))
    }
  }

  const handleSubmit = async () => {
    if (!selectedCategoryBatchId) return

    await moveSlideBetweenCategories({
      variables: {
        slideId: blueprintId,
        fromCategoryId: batchId,
        toCategoryId: selectedCategoryBatchId
      }
    })
    await getPresentationsData({ variables: { ids: [batchId, selectedCategoryBatchId] } })
    closeModal()
  }

  return (
    // BU: this is "move to category" modal content (category list)
    <div className="categories-manager">
      <h1 className="change-order">Move to</h1>
      <div className="order-list">
        {categories.map((category) => (
          <div
            className={"order-list-cat"}
            key={category.batchId}
            onClick={() => onCategoryClick(category.batchId)}
            style={{ backgroundColor: category.selected ? "#e4e8ea" : "#ffffff" }}
          >
            <div className="order-icon">
              <CategoryIcon categoryIcon={category.icon} />
            </div>
            <p>{category.name}</p>
          </div>
        ))}
      </div>

      <button
        // TODO: BU: this should be "grayed out" when disabled...
        className="btn btn-lightblue"
        disabled={!selectedCategoryBatchId}
        onClick={handleSubmit}
      >
        Move
      </button>
    </div>
  )
}

export default MoveSlideToCategory
