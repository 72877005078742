import { useEffect } from "react"
import Select from "react-select"
import { IShareWithOption } from "@/components/modals/types"

const UnitsAndTeamMembersInput = ({ handleOptionChange, options, selectedOptions, formatOptionLabel }) => {
  useEffect(() => {
    // import.meta.env.DEV && console.log("♻️ UnitsAndTeamMembersInput")
  })

  return (
    <div className={"team-select w-full"}>
      <Select
        classNames={{
          control: () => "unitsAndTeamMembersInput pt-2 pb-1 px-2",
          multiValue: () => "bg-sc-blue text-white text-xs rounded-full flex items-center mx-0.5 px-2",
          multiValueLabel: () => "p-1",
          multiValueRemove: () => "flex items-center react-select-remove-item",
          valueContainer: () => "p-0"
        }}
        closeMenuOnSelect={false}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        getOptionLabel={formatOptionLabel}
        getOptionValue={(option: IShareWithOption) => option.label}
        inputId="teamMembers"
        isClearable={false}
        isMulti
        noOptionsMessage={() => null}
        onChange={handleOptionChange}
        options={options}
        styles={{
          multiValue: () => null,
          multiValueLabel: () => null,
          multiValueRemove: () => null
        }}
        value={selectedOptions}
      />
    </div>
  )
}

export default UnitsAndTeamMembersInput
