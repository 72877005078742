import { gql } from "@apollo/client"

export const fragments = {
  batch: gql`
    fragment fields on UsersBatch {
      id
      batchId
      name
      icon
      isFavourite
      size
      labels
      thumbUrl
      progress
      deleted
      uploaded
      mode
      sets {
        id
        downloadUrl
        slides {
          id
          slideId
          blueprintId
          thumbUrl
          # texts
          name
          tags
          isFavourite
          downloadUrl
          linksDataHeight
          linksDataWidth
          state
          linksData {
            URL
            IsTextLink
            Positions {
              Left
              Top
              Width
              Height
              Text
            }
          }
        }
      }
    }
  `,
  presentation: gql`
    fragment fields on Presentation {
      id
      batchId
      name
      icon
      thumbUrl
      sharedPresentationLinks {
        isActive
        token
        isDownloadable
        _id
      }
      sets {
        id
        slides {
          id
          slideId
          blueprintId
          thumbUrl
          name
          tags
          isFavourite
          downloadUrl
        }
      }
    }
  `,
  set: gql`
    fragment Set on SetOfUserSlides {
      id
      slides {
        id
        slideId
        blueprintId
        thumbUrl
        name
        tags
        texts
        isFavourite
        downloadUrl
      }
    }
  `,
  group: gql`
    fragment group on Group {
      __typename
      id
      groupId
      name
      custom
      plans {
        id
        planId
      }
      urlPrefix
      searchTags
      themes {
        id
      }
    }
  `,
  plan: gql`
    fragment plan on Plan {
      __typename
      id
      planId
      name
      amount
      interval
      teamMembers
      description
    }
  `,
  userFragment: gql`
    fragment teamOwner on User {
      id
      teamOwner
    }
  `,
  deletedBatch: gql`
    fragment deletedBatch on UsersBatch {
      __typename
      deleted
    }
  `,
  batchSmall: gql`
    fragment batch on UsersBatch {
      __typename
      id
      batchId
      name
      icon
      isFavourite
    }
  `,
  favouriteBatch: gql`
    fragment batch on Presentation {
      __typename
      id
      batchId
      name
      icon
      isFavourite
    }
  `,
  presentationSmall: gql`
    fragment batch on Presentation {
      __typename
      id
      batchId
      name
      icon
      isFavourite
    }
  `,
  presentationSlide: gql`
    fragment slideFields on Slide {
      id
      slideId
      blueprintId
      thumbUrl
      name
      tags
      downloadUrl
      linksDataHeight
      linksDataWidth
      state
      isFavourite
    }
  `,
  favouriteSlide: gql`
    fragment slideFields on Slide {
      id
      slideId
      blueprintId
      thumbUrl
      name
      tags
      downloadUrl
      linksDataHeight
      linksDataWidth
      state
      isFavourite
    }
  `
}
