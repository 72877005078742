import React, { useRef, useEffect, useState } from "react"

function IntersectionObserverWrapper({ children, onVisibilityChange }) {
  const navRef = useRef(null)
  const [visibilityMap, setVisibilityMap] = useState({})

  const handleIntersection = (entries) => {
    const updatedEntries = {}
    entries.forEach((entry) => {
      const targetid = entry.target.dataset.targetid
      updatedEntries[targetid] = entry.isIntersecting
    })

    setVisibilityMap((prev) => ({ ...prev, ...updatedEntries }))
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: navRef.current,
      threshold: 1
    })

    // Function to handle category removal
    const handleRemoval = (mutationList) => {
      mutationList.forEach((mutation) => {
        if (mutation.type === "childList") {
          mutation.removedNodes.forEach((removedNode) => {
            if (removedNode.dataset && removedNode.dataset.targetid) {
              observer.unobserve(removedNode)
            }
          })
        }
      })
    }

    // Create a MutationObserver to handle element removal
    const mutationObserver = new MutationObserver(handleRemoval)

    mutationObserver.observe(navRef.current, { childList: true })

    Array.from(navRef.current.children).forEach((item) => {
      if (item.dataset.targetid) {
        observer.observe(item)
      }
    })

    return () => {
      observer.disconnect()
      mutationObserver.disconnect()
    }
  }, [children.length])

  useEffect(() => {
    if (onVisibilityChange) {
      onVisibilityChange(visibilityMap)
    }
  }, [visibilityMap, onVisibilityChange])

  return (
    <div className="flex h-full w-9/10" ref={navRef}>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          className: `${child.props.className} ${
            visibilityMap[child.props["data-targetid"]]
              ? "visible opacity-100"
              : "invisible opacity-0 pointer-events-none"
          }`
        })
      })}
    </div>
  )
}

export default IntersectionObserverWrapper
