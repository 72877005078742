import slugify from "slug"

export const getLibraryLink = (categories) => {
  if (categories?.length) {
    return `/library/${categories[0].urlToken}/${slugify(categories[0].name)}`
  }
  return "/library"
}

export const pluralize = (count, noun) => {
  return count === 1 ? noun : `${noun}s`
}

export const getFoundQuery = (searchQuery, slide) => {
  // Helper function to extract words around the searched query
  const query = searchQuery?.toLowerCase()
  const wordsAroundQuery = 3

  const allText = slide.name.toLowerCase().includes(query) ? slide.name.trim() : slide.texts.join(" ").trim()
  const queryStartIndex = allText.toLowerCase().indexOf(query)
  const queryEndIndex = queryStartIndex + query.length
  const queryText = queryStartIndex !== -1 && allText.slice(queryStartIndex, queryEndIndex)
  const beginningWords = allText.slice(0, queryStartIndex).trim().split(/\s+/).slice(-wordsAroundQuery)
  const endingWords = allText.slice(queryEndIndex).trim().split(/\s+/).slice(0, wordsAroundQuery)
  const foundText = [...beginningWords, queryText, ...endingWords].join(" ").trim()
  const indexOfFoundText = allText.indexOf(foundText)
  const hasPreDots = indexOfFoundText > 0
  const hasEndDots = allText.length > indexOfFoundText + foundText.length

  if (!queryText) {
    return getFoundQueryInTags(query, slide.tags)
  }

  return {
    queryText,
    beginningWords: beginningWords.join(" "),
    endingWords: endingWords.join(" "),
    hasPreDots,
    hasEndDots
  }
}

function getFoundQueryInTags(query, tags) {
  const foundTagIndex = tags.findIndex((tag) => tag.toLowerCase().includes(query))
  if (foundTagIndex === -1) {
    return {
      foundTag: false
    }
  }
  const availableBeginningTagsQty = foundTagIndex >= 2 ? 2 : foundTagIndex >= 1 ? 1 : 0
  let beginningWords = tags.slice(foundTagIndex - availableBeginningTagsQty, foundTagIndex).join(" ∙ ")
  let endingWords = tags.slice(foundTagIndex + 1, foundTagIndex + 3).join(" ∙ ")
  beginningWords += beginningWords.length ? " ∙ " : ""
  endingWords = endingWords.length ? " ∙ " + endingWords : endingWords

  const hasPreDots = foundTagIndex - 2 > 0
  const hasEndDots = foundTagIndex + 3 < tags.length

  return {
    foundTag: tags[foundTagIndex],
    beginningWords,
    endingWords,
    hasPreDots,
    hasEndDots
  }
}
