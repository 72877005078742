import { Fragment, useContext, useState } from "react"
import { Menu, Transition } from "@headlessui/react"
import ConfirmationModal from "@/components/modals/ConfirmationModal"
import useModal from "@/hooks/useModal"
import { CategoryContext } from "@/context/CategoryContext"
import { pluralize } from "@/utils/helpers"
import { UserDataContext } from "@/hooks/UserDataHook"

const CategoryMenu = ({
  categoryName,
  id,
  order,
  isActive,
  presentationsQty,
  collectionsQty,
  subCategory = null,
  deleteModalContext = null,
  deleteModalTitle,
  renameModalTitle,
  icon,
  buttonStyles
}) => {
  const { isOpen: isOpenRenameModal, openModal: openRenameModal, closeModal: closeRenameModal } = useModal()
  const { isOpen: isOpenDeleteModal, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal()
  const [nameInput, setNameInput] = useState(categoryName)

  const {
    user: { isEditModeActive }
  } = useContext(UserDataContext)
  const { renameCategory, removeCategory, categories, moveSubCategory } = useContext(CategoryContext)

  const isEditButtonDisabled = !nameInput.trim()

  const handleRenameCategory = async () => {
    renameCategory({
      categoryId: id,
      name: nameInput,
      order,
      subCategoryId: subCategory && subCategory?._id
    })

    closeRenameModal()
  }

  const handleCloseRenameModal = () => {
    closeRenameModal()
    setNameInput(categoryName)
  }

  const handleRemoveCategory = async () => {
    await removeCategory(id, subCategory && subCategory?._id, order, categoryName)
    closeDeleteModal()
  }

  return (
    <Menu as="div" className={`relative ${isActive === false ? "opacity-0 invisible pointer-events-none" : ""}`}>
      <Menu.Button
        className="flex justify-end text-[#6B6F7A] hover:text-scblue"
        data-testid="category-menu-button"
        id="contextMenu"
        style={
          buttonStyles || {
            width: "15px",
            paddingRight: "2.19px"
          }
        }
      >
        {icon || (
          <svg fill="currentColor" height="12" viewBox="0 0 3 12" width="3" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.622559 6C0.622559 6.60406 1.11225 7.09375 1.71631 7.09375C2.32037 7.09375 2.81006 6.60406 2.81006 6C2.81006 5.39594 2.32037 4.90625 1.71631 4.90625C1.11225 4.90625 0.622559 5.39594 0.622559 6Z"
              stroke="#6B6F7A"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.21875"
            />
            <path
              d="M0.622559 1.625C0.622559 2.22906 1.11225 2.71875 1.71631 2.71875C2.32037 2.71875 2.81006 2.22906 2.81006 1.625C2.81006 1.02094 2.32037 0.53125 1.71631 0.53125C1.11225 0.53125 0.622559 1.02094 0.622559 1.625Z"
              stroke="#6B6F7A"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.21875"
            />
            <path
              d="M0.622559 10.375C0.622559 10.9791 1.11225 11.4687 1.71631 11.4687C2.32037 11.4687 2.81006 10.9791 2.81006 10.375C2.81006 9.77094 2.32037 9.28125 1.71631 9.28125C1.11225 9.28125 0.622559 9.77094 0.622559 10.375Z"
              stroke="#6B6F7A"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.21875"
            />
          </svg>
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute top-0 z-20 w-56 origin-top-right rounded-md bg-white shadow-lg ring-gray-300 focus:outline-none p-1"
          id="contextMenuItems"
          style={{ left: "23px" }}
        >
          <Menu.Item>
            {({ active }) => (
              <a
                className={`block px-4 py-2 text-sm cursor-pointer ${
                  active ? "bg-gray-100 !text-gray-500" : "text-gray-500"
                }`}
                onClick={openRenameModal}
              >
                Rename
              </a>
            )}
          </Menu.Item>
          {isEditModeActive && subCategory && (
            <Menu.Item>
              {({ active }) => (
                <Menu as="div" className={`block text-sm ${active ? "bg-gray-100 !text-gray-500" : "text-gray-500"}`}>
                  <Menu.Button className="flex px-4 py-2 justify-between w-full">
                    <span>Move to</span>
                    <span> {">"} </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="cursor-pointer absolute -mt-[28px] left-56 z-2000 w-56 origin-top-right  bg-white shadow-lg ring-gray-300 focus:outline-none"
                      id="contextMenuMoveToItems"
                    >
                      {categories
                        .filter(
                          (category) =>
                            category?._id !== id &&
                            !category?.subCategories?.find((sub) => sub._id === subCategory?._id)
                        )
                        .map((category) => (
                          <Menu.Item key={category._id}>
                            {({ active }) => (
                              <a
                                className={`block px-4 py-2 text-sm ${
                                  active ? "bg-gray-100 !text-gray-500" : "text-gray-500"
                                }`}
                                onClick={async () => {
                                  await moveSubCategory(id, category._id, subCategory)
                                }}
                              >
                                {category.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              )}
            </Menu.Item>
          )}
          <Menu.Item>
            {({ active }) => (
              <a
                className={`block px-4 py-2 text-sm cursor-pointer ${
                  active ? "bg-gray-100 !text-gray-500" : "text-gray-500"
                }`}
                onClick={openDeleteModal}
              >
                Delete
              </a>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
      <ConfirmationModal
        close={handleCloseRenameModal}
        confirmButtonText="Save"
        confirmFunction={handleRenameCategory}
        context={
          <input
            data-testid="category-rename-input"
            onChange={(e) => setNameInput(e.target.value)}
            style={{
              border: "none",
              background: "none",
              width: "100%",
              borderBottom: "1px solid #DCDEE3",
              outline: "none",
              boxShadow: "none"
            }}
            type="text"
            value={nameInput}
          />
        }
        isDisabled={isEditButtonDisabled}
        isOpen={isOpenRenameModal}
        title={renameModalTitle}
      />
      <ConfirmationModal
        close={closeDeleteModal}
        confirmButtonText="Delete"
        confirmFunction={handleRemoveCategory}
        context={
          deleteModalContext ||
          (collectionsQty + presentationsQty > 0 ? (
            <span>
              You have {collectionsQty > 0 && `${collectionsQty} ${pluralize(collectionsQty, "collection")}`}{" "}
              {collectionsQty && presentationsQty ? "and " : ""}
              {presentationsQty > 0 && `${presentationsQty} ${pluralize(presentationsQty, "presentation")}`} on this
              page. Are you sure you want to delete{" "}
              {collectionsQty + presentationsQty === 1
                ? "it"
                : collectionsQty + presentationsQty === 2
                ? "both of them"
                : "all of them"}{" "}
              ?
            </span>
          ) : (
            <span>Are you sure you want to delete this page?</span>
          ))
        }
        isOpen={isOpenDeleteModal}
        mode="danger"
        title={deleteModalTitle}
      />
    </Menu>
  )
}

export default CategoryMenu
