import { useState, createContext, useRef, MutableRefObject } from "react"
import { Set, Slide } from "../graphql/types/queries"

interface ShowModalParams {
  position: number
  sets: any
  pId: number | null
  downloadSlide(): void
}

interface UseSlideModalContext {
  position: number
  sets: Set[]
  pId: number | null
  showModal: boolean
  downloadSlide(): void
  openModal(params: ShowModalParams): void
  closeModal(): void
  changePosition(position: number): void
  updateSlide(slide: Slide): void
}

export const useSlideModal = (): UseSlideModalContext => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [position, setPosition] = useState<number>(0)
  const [sets, setSets] = useState<Set[]>([])
  const [pId, setPid] = useState<number | null>(null)
  // const [downloadSlideFunc, setDownloadSlideFunc] = useState(() => {})

  const downloadSlideFunc: MutableRefObject<() => void> = useRef(() => {})

  const openModal = ({ position, sets, pId, downloadSlide }: ShowModalParams) => {
    setPosition(position)
    setSets(sets)
    setShowModal(true)
    setPid(pId)
    downloadSlideFunc.current = downloadSlide
  }

  const closeModal = () => {
    setShowModal(false)
    setSets([])
    setPosition(0)
  }

  const changePosition = (position: number) => {
    setPosition(position)
  }

  const updateSlide = (data) => {
    const slides = [...sets[0].slides]
    slides[position] = { ...slides[position], ...data }
    setSets([{ ...sets[0], slides }])
  }

  return {
    showModal,
    position,
    sets,
    pId,
    downloadSlide: downloadSlideFunc.current,
    openModal,
    closeModal,
    changePosition,
    updateSlide
  }
}

export const SlideModalContext = createContext({} as UseSlideModalContext)
