import { useContext, useMemo, useRef, useState } from "react"
import { NavLink, useParams } from "react-router-dom"
import slugify from "slug"
import { CategoryContext } from "@/context/CategoryContext"
import IntersectionObserverWrapper from "./IntersectionObserverWrapper"
import OverflowMenu from "./OverflowMenu"
import CategoryMenu from "@/components/category/CategoryMenu"
import { UserDataContext } from "@/hooks/UserDataHook"
import ConfirmationModal from "../modals/ConfirmationModal"
import useModal from "../../hooks/useModal"
import NotificationPopUp from "../notifications/NotificationPopUp"
import { NotificationContext } from "../../hooks/NotificationHook"

const CategoryNavLinks = () => {
  const { categories, loading, createCategory, getCategory } = useContext(CategoryContext)
  const { open } = useContext(NotificationContext)
  const {
    user: { isEditModeActive }
  } = useContext(UserDataContext)
  const [visibilityMap, setVisibilityMap] = useState({})
  const { urlToken: currentUrlToken } = useParams()
  const addCategoryInputRef = useRef()
  const {
    isOpen: isOpenAddCategoryModal,
    openModal: openAddCategoryModal,
    closeModal: closeAddCategoryModal
  } = useModal()
  const [addCategoryInput, setAddCategoryInput] = useState("")
  const handleAddCategory = () => {
    createCategory(addCategoryInputRef.current?.value, null)
    setAddCategoryInput("")
    closeAddCategoryModal()
    open({
      type: "success",
      duration: 4,
      content: (
        <NotificationPopUp>
          <p>Category successfully created.</p>
        </NotificationPopUp>
      )
    })
  }

  const handleCloseAddCategoryModal = () => {
    setAddCategoryInput("")
    closeAddCategoryModal()
  }

  const isAddCategoryButtonDisabled = !addCategoryInput.trim()

  const categoryNavLinks = useMemo(() => {
    return categories.map((category) => ({
      title: category.name,
      id: category._id,
      to: {
        pathname: `/library/${category.urlToken}/${slugify(category.name)}`,
        category
      },
      presentationsQty:
        category.presentations.length +
        category.subCategories.reduce((prev, subCategory) => {
          prev += subCategory.presentations.length
          return prev
        }, 0),
      collectionsQty:
        category.collections.length +
        category.subCategories.reduce((prev, subCategory) => {
          prev += subCategory.collections.length
          return prev
        }, 0)
    }))
  }, [categories])

  const isActiveCategory = (categoryId) => categoryId === getCategory(currentUrlToken)?._id

  if (loading) {
    return "Loading..."
  }

  if (!categoryNavLinks.length) {
    return null
  }

  return (
    <>
      <div className="flex items-center justify-between gap-8 mx-auto desktop-big:w-[1812px] whitespace-nowrap h-[58px]">
        <div className="flex items-center h-full">
          <IntersectionObserverWrapper onVisibilityChange={setVisibilityMap}>
            {categoryNavLinks.map(({ title, to, id, presentationsQty, collectionsQty }, index) => (
              <div
                className={`flex mr-[23px] gap-1 items-center border-b ${
                  isActiveCategory(to.category._id) ? "border-scblue" : "border-transparent"
                }`}
                data-targetid={`category-${index}`}
                key={index}
              >
                <div className={"flex items-center"}>
                  <NavLink
                    activeClassName="text-scblue  hover:text-scblue"
                    className="hover:text-scblue text-sm"
                    data-testid="navigation-menu-item"
                    isActive={() => isActiveCategory(to.category._id)}
                    title={title}
                    to={to}
                  >
                    {title}
                  </NavLink>
                  {isEditModeActive && (
                    <CategoryMenu
                      categoryName={title}
                      collectionsQty={collectionsQty}
                      deleteModalTitle="Delete Page"
                      id={id}
                      isActive={isActiveCategory(to.category._id)}
                      order={to.category.order}
                      presentationsQty={presentationsQty}
                      renameModalTitle="Rename"
                    />
                  )}
                </div>
              </div>
            ))}
          </IntersectionObserverWrapper>
          {isEditModeActive ? (
            <>
              <div className="h-[19.5px] border-l border-solid border-l-[#D1D5DB] mr-[10px]" />
              <button
                className="text-sm flex items-center py-[7px] px-[13px] hover:bg-sc-hover-light gap-[12px] rounded-sm"
                data-testid="add-category-button"
                onClick={openAddCategoryModal}
              >
                <svg fill="none" height="10" viewBox="0 0 10 10" width="10" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_599_50128)">
                    <path
                      d="M5.44766 0.368655L5.44772 0.368652L5.44749 0.366984C5.41744 0.145559 5.22764 -0.025 4.99803 -0.025C4.74755 -0.025 4.54456 0.178181 4.54462 0.428678L4.5453 4.54661H0.42852V4.54656L0.426853 4.54667L0.368706 4.55055L0.368702 4.55049L0.367009 4.55072C0.145618 4.58079 -0.025 4.77055 -0.025 5.0002C-0.025 5.2507 0.17804 5.45379 0.42852 5.45379H4.54531L4.54645 9.57148H4.54639L4.54651 9.57316L4.55045 9.63133L4.55039 9.63134L4.55062 9.63301C4.58068 9.85445 4.77047 10.025 5.00009 10.025C5.25057 10.025 5.45355 9.82178 5.45349 9.57129L5.45235 5.45379H9.57148V5.45384L9.57315 5.45373L9.63131 5.44985L9.63131 5.44991L9.63301 5.44968C9.85435 5.41961 10.025 5.22986 10.025 5.0002C10.025 4.7497 9.82195 4.54661 9.57148 4.54661H5.45234L5.45166 0.428499H5.45172L5.45161 0.426812L5.44766 0.368655Z"
                      fill="#6B6F7A"
                      stroke="#6B6F7A"
                      strokeWidth="0.05"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_599_50128">
                      <rect fill="white" height="10" width="10" />
                    </clipPath>
                  </defs>
                </svg>
                <span>Add New</span>
              </button>
            </>
          ) : null}
        </div>
        <OverflowMenu visibilityMap={visibilityMap}>
          {categoryNavLinks.map(({ title, to }, index) => (
            <NavLink
              activeClassName="text-blue-600 block px-4 py-2 text-blue-600 hover:bg-gray-100 hover:text-[#0b387f]"
              className="block px-4 py-2 hover:bg-gray-200 hover:text-blue-600 text-sm"
              data-targetid={`category-${index}`}
              isActive={() => isActiveCategory(to.category._id)}
              key={index}
              title={title}
              to={to}
            >
              {title}
            </NavLink>
          ))}
        </OverflowMenu>
      </div>
      <ConfirmationModal
        close={handleCloseAddCategoryModal}
        confirmButtonText="Save"
        confirmFunction={handleAddCategory}
        context={
          <div className="flex justify-center">
            <input
              className="border-0 border-b border-solid border-[#dcdee3] bg-[#f6f7f8] text-[#0f2642] text-15 font-normal focus:border-[#3390ff] focus:shadow-none focus:text-[#0f2642] placeholder:text-[#bcbec0] w-full"
              data-testid="category-create-input"
              onChange={(event) => {
                setAddCategoryInput(event?.target?.value)
              }}
              placeholder="Category Name"
              ref={addCategoryInputRef}
              type="text"
            />
          </div>
        }
        isDisabled={isAddCategoryButtonDisabled}
        isOpen={isOpenAddCategoryModal}
        title="Add new"
      />
    </>
  )
}
export default CategoryNavLinks
