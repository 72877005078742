export interface IconType {
  name: string
  tags: string[]
}

export const icons: IconType[] = [
  { name: "Presentation", tags: ["presentation", "slide show"] },
  { name: "Agenda", tags: ["agenda", "list", "schedule", "plan"] },
  { name: "Basics", tags: ["basics", "standard", "slide"] },
  { name: "Calendars", tags: ["calendar", "day", "month", "year"] },
  { name: "Charts", tags: ["charts", "data", "pie"] },
  { name: "Images", tags: ["images", "landscape", "mountain"] },
  { name: "Notebook", tags: ["notebook", "devices", "laptop"] },
  { name: "Processes", tags: ["process", "cycle", "scheme"] },
  { name: "Data", tags: ["data", "table"] },
  { name: "Icons", tags: ["icons", "human", "man"] },
  { name: "Maps", tags: ["maps", "pin", "coordinates"] },
  { name: "Team", tags: ["team", "people"] },
  { name: "Smartphone", tags: ["smartphone", "devices", "iphone", "android", "mobile"] },
  { name: "Timelines", tags: ["timeline", "process"] },
  { name: "Infographics", tags: ["infographics", "scheme", "illustration"] },
  { name: "Backgrounds", tags: ["backgrounds", "layers"] },
  { name: "Sections", tags: ["sections", "break", "division"] },
  { name: "Free Slides", tags: ["free", "gift", "present"] },
  { name: "Social Media", tags: ["social media", "social", "thumb"] },
  { name: "Maps Europe", tags: ["maps", "europe", "continent"] },
  { name: "Maps USA", tags: ["maps", "usa", "country"] },
  { name: "Maps Canada", tags: ["maps", "canada", "country"] },
  { name: "Maps South America", tags: ["map", "south america", "continent", "america"] },
  { name: "Maps Asia", tags: ["maps", "asia", "continent"] },
  { name: "Maps Central America", tags: ["maps", "central america", "continent", "america"] },
  { name: "Tablet", tags: ["tablet", "devices", "ipad"] },
  { name: "Photo", tags: ["photo", "camera", "devices"] },
  { name: "Web", tags: ["web", "browser", "window"] },
  { name: "Camera", tags: ["movie", "camera", "devices", "rolls", "video", "film"] },
  { name: "IMac", tags: ["iMac", "desktop", "devices", "computer", "screen"] },
  { name: "Quotes and testimonials", tags: ["quotes", "testimonials"] },
  { name: "Maps North America", tags: ["maps", "north america", "america", "continent"] },
  { name: "Maps Middle East", tags: ["maps", "middle east", "asia", "region"] },
  { name: "Australia", tags: ["maps", "australia", "oceania", "continent"] },
  { name: "Africa", tags: ["maps", "africa", "continent"] },
  { name: "Other", tags: ["other", "folder"] },
  { name: "Intro", tags: ["intro", "start", "beginning", "title slide"] },
  { name: "People", tags: ["people", "humans", "persons", "man"] },
  { name: "Food", tags: ["food", "burger"] }
]
