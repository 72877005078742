const SharedIndicator = ({ value }) => (
  <div className={`w-5 flex ${value ? "opacity-100" : "opacity-0"}`} data-testid="presentation-shared-icon">
    <svg className="w-full" version="1.1" viewBox="0 0 17 10" x="0px" y="0px">
      <path
        d="M11.6,4.6c1.3,0,2.3-1,2.3-2.3S12.9,0,11.6,0S9.3,1,9.3,2.3S10.3,4.6,11.6,4.6z"
        style={{ fill: "#3390FF", opacity: "0.8" }}
      />
      <path
        d="M5.4,4.6c1.3,0,2.3-1,2.3-2.3S6.7,0,5.4,0S3.1,1,3.1,2.3S4.1,4.6,5.4,4.6z"
        style={{ fill: "#3390FF", opacity: "0.8" }}
      />
      <path
        d="M5.4,5.3C3.6,5.3,0,6.2,0,8v2h10.9V8C10.9,6.2,7.2,5.3,5.4,5.3z"
        style={{ fill: "#3390FF", opacity: "0.8" }}
      />
      <path
        d="M11.6,5.7c-0.2,0-0.4,0-0.7,0c0.9,0.6,1.2,1.1,1.2,2.2v2H17V8C17,6.2,13.4,5.7,11.6,5.7z"
        style={{ fill: "#3390FF", opacity: "0.8" }}
      />
    </svg>
  </div>
)

export default SharedIndicator
