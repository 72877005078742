import { useMutation, useQuery } from "@apollo/client"
import { useState } from "react"
import { GroupsData } from "../../graphql/queries"
import { inviteTeamOwner } from "../../graphql/mutations"
import { MainLoader } from "../utils/placeholders"
import { useForceUpdate } from "../../hooks/ForceUpdate"
import { useForm } from "../../hooks/FormHook"
import { emailValidator, isRequired } from "../utils/helpers"
import { InputWrapper } from "../utils/forms"

const InviteTeamOwnerForm = ({ initialValues, wizard }) => {
  const {
    data,
    loading: { queryLoading }
  } = useQuery(GroupsData)
  const [_inviteTeamOwner, { loading }] = useMutation(inviteTeamOwner)

  const [success, setSuccess] = useState(false)
  const forceUpdate = useForceUpdate()
  const [values, onChange, validations, validate] = useForm([
    {
      name: "group",
      initialValue: initialValues ? initialValues.group : undefined,
      validators: [isRequired]
    },
    {
      name: "email",
      validators: [isRequired, emailValidator]
    },
    {
      name: "showBar",
      initialValue: "hide"
    }
  ])

  const submitForm = () => {
    if (!validate()) {
      forceUpdate()
      return
    }
    _inviteTeamOwner({
      variables: {
        groupId: values.group,
        email: values.email,
        showBar: values.showBar === "show"
      }
    }).then(() => {
      setSuccess(true)
      if (wizard) {
        // dispatch({type: 'SET_USER_DATA', newUserData: res.data.admin.inviteTeamOwner.user})
        // dispatch({type: 'CHANGE_STAGE', newStage: 'trial'})
      }
    })
  }

  if (queryLoading) return <MainLoader />

  return (
    <div>
      <form>
        <div className="row">
          <div>
            <InputWrapper error={validations.group} label="Group">
              <select disabled={loading || wizard} name="group" onChange={onChange} value={values.group}>
                <option />
                {data.admin.groups.map((group) => (
                  <option key={group.groupId} value={group.groupId}>
                    {group.groupId}
                  </option>
                ))}
              </select>
            </InputWrapper>
          </div>
          <div>
            <div>
              <InputWrapper error={validations.email} label="Email">
                <input disabled={loading} name="email" onChange={onChange} type="text" value={values.email} />
              </InputWrapper>
            </div>
          </div>
          <div>
            <div>
              <label>Show Bar</label>
              <div>
                <label>
                  <input
                    checked={values.showBar === "show"}
                    name="showBar"
                    onChange={onChange}
                    style={{ WebkitAppearance: "radio" }}
                    type="radio"
                    value="show"
                  />{" "}
                  Info Bar is visible
                </label>
                <label>
                  <input
                    checked={values.showBar === "hide"}
                    name="showBar"
                    onChange={onChange}
                    style={{ WebkitAppearance: "radio" }}
                    type="radio"
                    value="hide"
                  />{" "}
                  Info Bar is hidden
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="admin-button-submit">
            <button className="btn btn-dark-blue" disabled={loading} onClick={submitForm} type="button">
              Submit
            </button>
            {success && <span>User invited</span>}
          </div>
        </div>
      </form>
    </div>
  )
}

export default InviteTeamOwnerForm
