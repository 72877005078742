import { useState, useEffect, useContext, useCallback } from "react"
import ReactTooltip from "react-tooltip"
import { Link } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import AsyncCreatableSelect from "react-select/creatable"

import { ADD_TAG, GET_SLIDE_TAGS, REMOVE_TAG, TEAM_TAGS } from "./mutations"

import { NotificationContext } from "@/hooks/NotificationHook"
import { NotificationError } from "../utils/notifications"

import { ReactComponent as BlueTickSvg } from "../../svg2/blue-tick.svg"
import { ReactComponent as DeleteSvg } from "../../svg2/delete.svg"
import { UserDataContext } from "@/hooks/UserDataHook"

const InputTag = ({ slide }) => {
  const [cacheTags, setCacheTags] = useState([])
  const [tags, setTags] = useState([])
  const { open } = useContext(NotificationContext)
  const [options, setOptions] = useState([])

  const {
    user: { isEditModeActive }
  } = useContext(UserDataContext)

  const [getSlideTags] = useLazyQuery(GET_SLIDE_TAGS, {
    context: { isUsingNewScApi: true }
  })
  const [getTeamTags] = useLazyQuery(TEAM_TAGS, {
    context: { isUsingNewScApi: true }
  })

  const [_addTag] = useMutation(ADD_TAG, {
    context: { isUsingNewScApi: true }
  })
  const [_removeTag] = useMutation(REMOVE_TAG, {
    context: { isUsingNewScApi: true }
  })

  const getTags = useCallback(async () => {
    await getSlideTags({
      variables: {
        slideId: slide.blueprintId
      },
      fetchPolicy: "no-cache"
    })
      .then((res) => {
        setTags(res.data.slide.tags)
      })
      .catch((e) => new Error(e))
  }, [slide, getSlideTags, setTags])

  const handleGetTeamTags = useCallback(async () => {
    await getTeamTags({ fetchPolicy: "no-cache" }).then((res) => {
      const filteredTags = tags.filter((tag) => res.data.teamTags.includes(tag))

      const filteredOptions = res.data.teamTags
        .filter((tag) => !filteredTags.includes(tag))
        .concat(filteredTags.filter((tag) => !res.data.teamTags.includes(tag)))
        .map((tag) => ({ label: tag, value: tag }))
      setOptions(filteredOptions)
    })
  }, [tags, getTeamTags])

  useEffect(() => {
    getTags()
  }, [getTags])

  useEffect(() => {
    handleGetTeamTags()
  }, [tags, handleGetTeamTags])

  const addTag = useCallback(
    async (tag) => {
      const res = await _addTag({
        variables: {
          slideId: slide.blueprintId,
          tag
        },
        fetchPolicy: "no-cache"
      })

      if (res.data.addTag.success) setTags(res.data.addTag.tags)
    },
    [slide, _addTag, setTags]
  )

  useEffect(() => {
    const underRemoved = () => {
      if (cacheTags.length === 0) return
      const lastRemovedTag = cacheTags.pop()
      addTag(lastRemovedTag)
    }

    if (cacheTags.length !== 0)
      open({
        type: "success",
        duration: 3,
        content: (
          <div className="profile-notif-content">
            <div className="profile-notif-content-ico">
              <BlueTickSvg />
            </div>
            <div className="profile-notif-content-text">
              <p>
                Tag <strong>"{`${cacheTags[cacheTags.length - 1]}`}"</strong> removed. Click{" "}
                <button className="btn-link" onClick={underRemoved}>
                  here
                </button>{" "}
                to undo.
              </p>
            </div>
          </div>
        )
      })
  }, [cacheTags])

  const removeTag = async (i) => {
    const newTags = [...tags]
    const deletedTag = newTags.splice(i, 1)
    await _removeTag({
      variables: {
        slideId: slide.blueprintId,
        tag: deletedTag[0]
      },
      fetchPolicy: "no-cache"
    }).then((response) => {
      setTags(response.data.removeTag.tags)
    })
    setCacheTags(cacheTags.concat(deletedTag))
  }

  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState()

  const handleCreate = (inputValue) => {
    const isExist = tags.includes(inputValue)
    if (isExist) {
      open({
        type: "error",
        duration: 1,
        content: <NotificationError message="Selected tag already exists." title="Error" />
      })
      return
    }
    setIsLoading(true)
    setIsLoading(false)
    addTag(inputValue)
    getTags()
    setValue(null)
  }

  const handleChange = (inputValue) => {
    const isExist = tags.includes(inputValue.value)
    if (isExist) {
      setValue(inputValue)
      return
    }
    setIsLoading(true)
    addTag(inputValue.value)
    setIsLoading(false)
    setValue(null)
  }

  return (
    <div>
      <h4>TAGS</h4>
      <div className="slide-tags">
        {tags &&
          tags.map((tag, index) => (
            <span className="inline-block" key={index}>
              <Link target="_blank" to={`/search?query=${tag}`}>
                {tag}
              </Link>
              {isEditModeActive && (
                <span data-tip={"remove tag"} onClick={() => removeTag(index)} type="button">
                  <DeleteSvg />
                </span>
              )}
              <ReactTooltip border={true} effect="solid" place="bottom" type="light" />
            </span>
          ))}
      </div>
      {isEditModeActive && (
        <div className="mt-[15px]">
          <AsyncCreatableSelect
            className="react-select"
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            defaultValue={tags}
            isClearable={false}
            isDisabled={isLoading}
            isLoading={isLoading}
            onChange={handleChange}
            onCreateOption={handleCreate}
            options={options}
            placeholder="Type to select or create tag...."
            value={value}
          />
        </div>
      )}
      <ReactTooltip border={true} effect="solid" place="bottom" type="light" />
    </div>
  )
}

export default InputTag
