import React, { useState, useMemo } from "react"

function OverflowMenu({ children, className, visibilityMap = {} }) {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const shouldShowMenu = useMemo(
    () => visibilityMap && Object.values(visibilityMap).some((v) => v === false),
    [visibilityMap]
  )

  if (!shouldShowMenu) {
    return null
  }

  return (
    <div className={`${className} relative`}>
      <button className="text-gray-500 hover:text-gray-700 focus:outline-none" onClick={handleClick}>
        <svg className="svg-icon w-5 h-5" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path d="M512 512m-116.949333 0a116.949333 116.949333 0 1 0 233.898666 0 116.949333 116.949333 0 1 0-233.898666 0Z" />
          <path d="M512 159.616m-116.949333 0a116.949333 116.949333 0 1 0 233.898666 0 116.949333 116.949333 0 1 0-233.898666 0Z" />
          <path d="M512 864.384m-116.949333 0a116.949333 116.949333 0 1 0 233.898666 0 116.949333 116.949333 0 1 0-233.898666 0Z" />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-56 bg-white border border-gray-200 rounded shadow-lg z-10">
          {React.Children.map(children, (child) => {
            if (!visibilityMap[child.props["data-targetid"]]) {
              return (
                <div
                  className="hover:bg-gray-200 cursor-pointer"
                  key={child.props["data-targetid"]}
                  onClick={() => setIsOpen(false)}
                >
                  {child}
                </div>
              )
            }
            return null
          })}
        </div>
      )}
    </div>
  )
}

export default OverflowMenu
