import React, { useState, useEffect, useContext } from "react"
import { Link, useHistory } from "react-router-dom"
import { useQuery, useMutation, gql, useApolloClient, OperationVariables, ApolloQueryResult } from "@apollo/client"
import { cloneDeep } from "lodash"
import Category from "@/components/modals/changeOrder/ChangeOrderCategory"
import { NotificationContext } from "@/hooks/NotificationHook"
import { getLibraryLink } from "@/utils/helpers"
import slugify from "slug"
import { Batch } from "@/graphql/types/queries"

const GET_CATEGORIES = gql`
  query categories {
    categories {
      _id
      name
      order
      urlToken
      isCollectionsCollapsed
      isPresentationsCollapsed
      collections {
        _id
        id
        name
        mode
        batchId
        icon
        labels
        urlToken
        isFavourite
        sharedPresentationLinks {
          isActive
          token
          isDownloadable
          _id
        }
        creator {
          id
          firstName
          lastName
        }
        updatedAt
        accessibleByUsers {
          _id
          firstName
          lastName
        }
        accessibleByUnits {
          _id
          name
        }
        accessibleByAll
        sets {
          id
          slides {
            id
            slideId
            blueprintId
            thumbUrl
            name
            tags
            downloadUrl
            linksDataHeight
            linksDataWidth
            state
            isFavourite
          }
        }
        category {
          _id
        }
      }
      presentations {
        _id
        id
        name
        mode
        batchId
        icon
        labels
        urlToken
        isFavourite
        sharedPresentationLinks {
          isActive
          token
          isDownloadable
          _id
        }
        updatedAt
        creator {
          id
          firstName
          lastName
        }
        accessibleByUsers {
          _id
          firstName
          lastName
        }
        accessibleByUnits {
          _id
          name
        }
        accessibleByAll
        sets {
          id
          slides {
            id
            slideId
            blueprintId
            thumbUrl
            name
            tags
            downloadUrl
            linksDataHeight
            linksDataWidth
            state
            isFavourite
          }
        }
        category {
          _id
        }
      }
      subCategories {
        _id
        name
        order
        urlToken
        type
        collections {
          _id
          id
          name
          mode
          batchId
          icon
          labels
          urlToken
          isFavourite
          sharedPresentationLinks {
            isActive
            token
            isDownloadable
            _id
          }
          updatedAt
          accessibleByUsers {
            _id
            firstName
            lastName
          }
          accessibleByUnits {
            _id
            name
          }
          accessibleByAll
          sets {
            id
            slides {
              id
              slideId
              blueprintId
              thumbUrl
              name
              tags
              isFavourite
              downloadUrl
              linksDataHeight
              linksDataWidth
              state
              isFavourite
            }
          }
          category {
            _id
          }
        }
        presentations {
          _id
          id
          name
          mode
          batchId
          icon
          labels
          urlToken
          isFavourite
          sharedPresentationLinks {
            isActive
            token
            isDownloadable
            _id
          }
          updatedAt
          accessibleByUsers {
            _id
            firstName
            lastName
          }
          accessibleByUnits {
            _id
            name
          }
          accessibleByAll
          sets {
            id
            slides {
              id
              slideId
              blueprintId
              thumbUrl
              name
              tags
              isFavourite
              downloadUrl
              linksDataHeight
              linksDataWidth
              state
              isFavourite
            }
          }
          category {
            _id
          }
        }
      }
    }
  }
`
const REMOVE_CATEGORY = gql`
  mutation removeCategory($id: String!) {
    removeCategory(id: $id) {
      code
      success
      message
      category {
        _id
        name
        order
      }
    }
  }
`

const RENAME_CATEGORY = gql`
  mutation renameCategory($id: String!, $name: String!) {
    renameCategory(id: $id, name: $name) {
      code
      success
      message
      category {
        _id
        name
        order
        parent {
          _id
        }
        urlToken
      }
    }
  }
`

const CREATE_CATEGORY = gql`
  mutation createCategory($name: String!, $parentId: String, $type: String) {
    createCategory(name: $name, parentId: $parentId, type: $type) {
      code
      success
      message
      category {
        _id
        name
        order
        urlToken
        type
        isCollectionsCollapsed
        isPresentationsCollapsed
      }
    }
  }
`
const REORDER_CATEGORY = gql`
  mutation reorderCategory($id: String!, $toIndex: Float!) {
    reorderCategory(id: $id, toIndex: $toIndex) {
      code
      success
      message
      categories {
        _id
        name
        order
      }
    }
  }
`

const CHANGE_PRESENTATION_POSITION = gql`
  mutation updateCategoryPresentationPosition($presentationId: String!, $targetCategoryId: String!, $position: Float!) {
    updateCategoryPresentationPosition(
      presentationId: $presentationId
      targetCategoryId: $targetCategoryId
      position: $position
    ) {
      code
      success
      message
    }
  }
`

const SET_COLLECTIONS_COLLAPSED = gql`
  mutation setCollectionsCollapsed($categoryId: String!, $state: Boolean!) {
    setCollectionsCollapsed(categoryId: $categoryId, state: $state) {
      category {
        _id
        name
        isCollectionsCollapsed
        isPresentationsCollapsed
      }
    }
  }
`

const SET_PRESENTATIONS_COLLAPSED = gql`
  mutation setPresentationsCollapsed($categoryId: String!, $state: Boolean!) {
    setPresentationsCollapsed(categoryId: $categoryId, state: $state) {
      category {
        _id
        name
        isCollectionsCollapsed
        isPresentationsCollapsed
      }
    }
  }
`

// BU: new stuff start
const CREATE_PRESENTATION = gql`
  mutation createPresentation(
    $fileToken: String
    $name: String!
    $labels: [String!]
    $icon: String
    $slides: [SlideInput!]
    $shared: Boolean = false
    $category: String
    $isMyPresentation: Boolean = false
  ) {
    createPresentation(
      fileToken: $fileToken
      name: $name
      labels: $labels
      icon: $icon
      slides: $slides
      shared: $shared
      category: $category
      isMyPresentation: $isMyPresentation
    ) {
      code
      success
      message
      job {
        id
        queueName
        lifecycle {
          status
          error
        }
      }
      presentation {
        _id
        id
        thumbUrl
        name
        batchId
        mode
        icon
        isFavourite
        sharedPresentationLinks {
          isActive
          token
          isDownloadable
          _id
        }
        urlToken
        labels
        sets {
          id
          slides {
            id
            slideId
            blueprintId
            thumbUrl
            name
            tags
            downloadUrl
            linksDataHeight
            linksDataWidth
            state
            isFavourite
          }
        }
        category {
          _id
          parent {
            _id
          }
        }
      }
    }
  }
`

const UPDATE_PRESENTATION = gql`
  mutation updatePresentation($id: String!, $name: String!, $icon: String!) {
    updatePresentation(id: $id, name: $name, icon: $icon) {
      code
      success
      message
      presentation {
        _id
        id
        thumbUrl
        name
        batchId
        mode
        icon
        isFavourite
        sharedPresentationLinks {
          isActive
          token
          isDownloadable
          _id
        }
        urlToken
        labels
        accessibleByUsers {
          _id
        }
        sets {
          id
          slides {
            id
            slideId
            blueprintId
            name
            tags
            downloadUrl
            linksDataHeight
            linksDataWidth
            state
            isFavourite
          }
        }
        category {
          _id
          parent {
            _id
          }
        }
      }
    }
  }
`
const MOVE_PRESENTATION_TO_CATEGORY = gql`
  mutation updatePresentation($id: String!, $category: String!) {
    updatePresentation(id: $id, category: $category) {
      code
      success
      message
    }
  }
`

const MOVE_TO_CATEGORY = gql`
  mutation moveToCategory($id: String!, $subId: String!) {
    moveToCategory(id: $id, subId: $subId) {
      code
      success
      message
    }
  }
`

const MY_PRESENTATION = gql`
  query myBatches {
    myBatches {
      _id
      id
      name
      mode
      batchId
      icon
      labels
      urlToken
      isFavourite
      sharedPresentationLinks {
        isActive
        token
        isDownloadable
        _id
      }
      sets {
        id
        slides {
          id
          slideId
          blueprintId
          thumbUrl
          name
          tags
          downloadUrl
          linksDataHeight
          linksDataWidth
          state
          isFavourite
        }
      }
      category {
        _id
      }
    }
  }
`

const getEntityByUrlToken = (urlToken: string) => {
  if (!urlToken) {
    return null
  }
  const entity = Object.entries({
    presentation: 0,
    category: 1
  }).find(([, i]) => `${i}` === urlToken[2])

  return entity ? entity[0] : null
}

interface UseCategoryContext {
  categories: (typeof Category)[] | []
  getCategory: typeof Category
  loadCategories: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
  createCategory: (name: string, parentId: string, type: string) => Promise<void> | undefined
  removeCategory: (categoryId: string, subCategoryId: string, order: number, name: string) => Promise<void>
  renameCategory: ({
    categoryId,
    name,
    order,
    subCategoryId
  }: {
    categoryId: string
    name: string
    order: number
    subCategoryId: string
  }) => Promise<void>
  reorderCategory: (id: string, toIndex: number, parentId: string) => Promise<void>
  moveSubCategory: (fromId: string, toId: string, subCategory: typeof Category) => Promise<void>
  addPresentation: (options: any, isSection: boolean) => any
  editPresentation: (variables: any) => void
  onRemoveBatch: (batch: Batch) => void
  changePresentationPosition: (
    presentationId: string,
    targetCategoryId: string,
    position: number,
    category: typeof Category
  ) => Promise<void>
  setCollapsed: (presentationsName: string, categoryId: string, state: boolean) => Promise<void>
  moveBatchToCategory: (category: typeof Category, batch: Batch) => Promise<void>
  loading: boolean
}

export const CategoryContext = React.createContext<UseCategoryContext>({} as UseCategoryContext)

export const CategoryContextProvider = ({ children }: any) => {
  const history = useHistory()
  const client = useApolloClient()
  const [categories, setCategories] = useState<(typeof Category)[]>([])
  const { data, error, loading, refetch } = useQuery(GET_CATEGORIES, {
    context: { isUsingNewScApi: true }
  })
  const [removeCategory] = useMutation(REMOVE_CATEGORY, {
    context: { isUsingNewScApi: true }
  })
  const [renameCategory] = useMutation(RENAME_CATEGORY, {
    context: { isUsingNewScApi: true }
  })
  const [createCategory] = useMutation(CREATE_CATEGORY, {
    context: { isUsingNewScApi: true }
  })
  const [reorderCategory] = useMutation(REORDER_CATEGORY, {
    context: { isUsingNewScApi: true }
  })
  const [moveToCategory] = useMutation(MOVE_TO_CATEGORY, {
    context: { isUsingNewScApi: true }
  })
  const [changePresentationPosition] = useMutation(CHANGE_PRESENTATION_POSITION, {
    context: { isUsingNewScApi: true }
  })

  const [setCollectionsCollapsed] = useMutation(SET_COLLECTIONS_COLLAPSED, {
    context: { isUsingNewScApi: true }
  })

  const [setPresentationsCollapsed] = useMutation(SET_PRESENTATIONS_COLLAPSED, {
    context: { isUsingNewScApi: true }
  })

  const [createPresentation] = useMutation(CREATE_PRESENTATION, {
    context: { isUsingNewScApi: true }
  })

  const [updatePresentation] = useMutation(UPDATE_PRESENTATION, {
    context: { isUsingNewScApi: true }
  })
  const [movePresentationToCategory] = useMutation(MOVE_PRESENTATION_TO_CATEGORY, {
    context: { isUsingNewScApi: true }
  })

  const { open } = useContext(NotificationContext)

  useEffect(() => {
    if (data) {
      setCategories(data?.categories)
    }
  }, [data])

  const getCategory = (urlToken: string) => {
    const entity = getEntityByUrlToken(urlToken)

    switch (entity) {
      case "category":
        return categories.find((c: typeof Category) => c.urlToken === urlToken)
      case "presentation":
        return (
          categories.find((c: typeof Category) =>
            c.presentations.some((p: typeof Category) => p.urlToken === urlToken)
          ) ||
          categories.find((c: typeof Category) =>
            c.collections.some((p: typeof Category) => p.urlToken === urlToken)
          ) ||
          categories.find((c: typeof Category) =>
            c.subCategories.find((s: typeof Category) =>
              s.presentations.some((p: typeof Category) => p.urlToken === urlToken)
            )
          ) ||
          categories.find((c: typeof Category) =>
            c.subCategories.find((s: typeof Category) =>
              s.collections.some((p: typeof Category) => p.urlToken === urlToken)
            )
          )
        )
    }
  }
  const removeCategoryHandler = async (categoryId: string, subCategoryId: string, order: number, name: string) => {
    await removeCategory({
      variables: { id: subCategoryId || categoryId },
      optimisticResponse: {
        removeCategory: {
          code: 200,
          success: true,
          message: "Category removed successfully",
          __typename: "CategoryResponse",
          category: {
            _id: categoryId,
            __typename: "Category",
            name,
            order
          }
        }
      },
      update: (cache, { data: { removeCategory } }) => {
        cache.modify({
          fields: {
            categories(existingCategories, { readField }) {
              if (!subCategoryId) {
                const categories = existingCategories.filter(
                  (category: typeof Category) => categoryId !== readField("_id", category)
                )
                history.push(getLibraryLink(categories))
                return categories.map((category: typeof Category) => {
                  if (category.order > removeCategory.category.order) {
                    return { ...category, order: category.order - 1 }
                  }
                  return category
                })
              }
              return existingCategories.map((category: typeof Category) => {
                if (categoryId === readField("_id", category)) {
                  let subCategories = category.subCategories?.filter(
                    (subCategory: typeof Category) => subCategoryId !== readField("_id", subCategory)
                  )
                  subCategories = subCategories.map((category: typeof Category) => {
                    if (category.order > removeCategory.category.order) {
                      return { ...category, order: category.order - 1 }
                    }
                    return category
                  })
                  return { ...category, subCategories }
                }
                return category
              })
            }
          }
        })
      }
    })
  }

  const renameCategoryHandler = async ({
    categoryId,
    name,
    order,
    subCategoryId
  }: {
    categoryId: string
    name: string
    order: number
    subCategoryId: string
  }) => {
    await renameCategory({
      variables: { id: subCategoryId || categoryId, name },
      optimisticResponse: {
        code: 200,
        message: "Category renamed successfully.",
        success: true,
        __typename: "CategoryResponse",
        category: {
          name,
          order,
          urlToken: "",
          parent: "",
          __typename: "Category",
          _id: categoryId
        }
      },
      update: (cache, { data: { renameCategory } }) => {
        cache.modify({
          fields: {
            categories(existingCategories, { readField }) {
              if (subCategoryId) {
                return existingCategories.map((category: typeof Category) => {
                  if (readField("_id", category) === categoryId) {
                    return {
                      ...category,
                      subCategories: category.subCategories.map((subCategory: typeof Category) => {
                        if (readField("_id", subCategory) === subCategoryId) {
                          return { ...subCategory, name: renameCategory ? renameCategory.category.name : name }
                        }
                        return subCategory
                      })
                    }
                  }
                  return category
                })
              }
              return existingCategories.map((category: typeof Category) => {
                if (readField("_id", category) === categoryId) {
                  return { ...category, name: renameCategory ? renameCategory.category.name : name }
                }
                return category
              })
            }
          }
        })
      },
      onCompleted: ({ renameCategory: { category, success } }) => {
        if (success && !category.parent) {
          history.replace(`/library/${category.urlToken}/${slugify(category.name)}`)
        }
      }
    })
  }

  const createCategoryHandler = async (name: string, parentId: string, type: string) => {
    if (!name || !name.length) {
      return
    }

    await createCategory({
      variables: { name, parentId, type },
      update: (cache, { data: { createCategory } }) => {
        const { name, urlToken } = createCategory.category

        if (parentId) {
          const parentCategory: typeof Category = categories.find(
            (category: typeof Category) => category._id === parentId
          )
          const categoriesData: any = categories.map((category: typeof Category) => {
            if (parentCategory && parentCategory?._id === category._id) {
              return {
                ...category,
                subCategories: [
                  ...category.subCategories,
                  { ...createCategory.category, presentations: [], subCategories: [], collections: [] }
                ]
              }
            }
            return category
          })
          setCategories(categoriesData)
          cache.writeQuery({ query: GET_CATEGORIES, data: { categories: categoriesData } })
        } else {
          const categoriesData: any = [
            ...categories,
            { ...createCategory.category, presentations: [], subCategories: [], collections: [] }
          ]
          setCategories(categoriesData)
          cache.writeQuery({ query: GET_CATEGORIES, data: { categories: categoriesData } })
          if (urlToken) {
            history.push(`/library/${urlToken}/${slugify(name)}`)
          }
        }
      },
      optimisticResponse: {
        createCategory: {
          code: 200,
          success: true,
          message: parentId ? "Sub-Category created successfully." : "Category created successfully.",
          category: {
            _id: "temp-id",
            name: name,
            order: 0,
            urlToken: "",
            type: null,
            isCollectionsCollapsed: true,
            isPresentationsCollapsed: true,
            __typename: "Category"
          },
          __typename: "CategoryResponse"
        }
      }
    })
  }

  const loadCategories = refetch

  const reorderCategoryHandler = async (id: string, toIndex: number, parentId: string) => {
    await reorderCategory({
      variables: { id, toIndex },
      update: (cache, { data: { reorderCategory } }) => {
        cache.modify({
          fields: {
            categories(existingCategories = []) {
              const categories = [...existingCategories]

              if (parentId) {
                const parentCategory = categories.find((category) => category._id === parentId)
                return categories.map((category) => {
                  if (parentCategory._id === category._id) {
                    return { ...category, subCategories: reorderCategory.categories }
                  }
                  return category
                })
              }
              return reorderCategory.categories
            }
          }
        })
      }
    })
  }

  const handleMoveSubCategory = async (fromId: string, toId: string, subCategory: typeof Category) => {
    let moveToCategoryUrl = ""
    await moveToCategory({
      variables: { id: toId, subId: subCategory._id },
      optimisticResponse: {
        moveToCategory: {
          code: 200,
          success: true,
          message: "Sub-Category was successfully moved",
          __typename: "MutationResponse"
        }
      },
      update: (cache) => {
        const categoriesData: { categories: (typeof Category)[] } = cache.readQuery({ query: GET_CATEGORIES }) || {
          categories: []
        }
        const data = cloneDeep(categoriesData)
        data.categories = data.categories.map((category: typeof Category) => {
          if (category._id === toId) {
            moveToCategoryUrl = `/library/${category.urlToken}/${slugify(category.name)}`
            return {
              ...category,
              subCategories: [subCategory, ...category.subCategories]
            }
          } else if (category._id === fromId) {
            return {
              ...category,
              subCategories: category.subCategories.filter((sub: typeof Category) => sub._id !== subCategory._id)
            }
          }
          return category
        })
        setCategories(data.categories)
        cache.writeQuery({ query: GET_CATEGORIES, data })
      },
      onCompleted: async ({ moveToCategory }) => {
        if (moveToCategory.success) {
          open({
            type: "success",
            duration: 3,
            content: (
              <div className="profile-notif-content">
                <div className="profile-notif-content-ico">
                  <svg version="1.1" viewBox="-470 271 19 19" x="0px" y="0px">
                    <path
                      d="M-460.5,271c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5S-455.3,271-460.5,271z
              M-456.3,278l-4.9,5.9c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3l-2.4-3c-0.2-0.2-0.2-0.7,0.2-1c0.3-0.2,0.8-0.2,1,0.1
              l1.9,2.3l4.3-5.2c0.3-0.3,0.7-0.4,1.1-0.1C-456,277.2-456,277.7-456.3,278z"
                      fill={"#3390FF"}
                    />
                  </svg>
                </div>
                <div className="profile-notif-content-text">
                  <p>
                    Sub-Category moved successfully. Click <Link to={moveToCategoryUrl}>here</Link> to preview.
                  </p>
                </div>
              </div>
            )
          })
        } else {
          open({
            type: "error",
            duration: 4,
            content: (
              <div className="profile-notif-content cursor-default">
                <div className="profile-notif-content-ico">
                  <svg id="Layer_1" version="1.1" viewBox="0 0 38.3 38.3" x="0px" y="0px">
                    <circle cx="19.2" cy="19.2" fill={"#ED1E79"} r="19.2" />
                    <path
                      d="M27.4,28.1c-0.2,0-0.4-0.1-0.5-0.2L10.4,11.5c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l16.4,16.4
          c0.3,0.3,0.3,0.8,0,1.1C27.8,28.1,27.6,28.1,27.4,28.1z"
                      fill={"#FFFFFF"}
                    />
                    <path
                      d="M10.9,28.1c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l16.4-16.4c0.3-0.3,0.8-0.3,1.1,0
          s0.3,0.8,0,1.1L11.5,27.9C11.3,28.1,11.1,28.1,10.9,28.1z"
                      fill={"#FFFFFF"}
                    />
                  </svg>
                </div>
                <div className="profile-notif-content-text">{moveToCategory.message}</div>
              </div>
            )
          })
        }
      }
    })
  }

  const changePresentationPositionHandler = async (
    presentationId: string,
    targetCategoryId: string,
    position: number,
    category: typeof Category
  ) => {
    await changePresentationPosition({
      variables: { presentationId, targetCategoryId, position },
      update: (cache, { data: result }) => {
        if (result.updateCategoryPresentationPosition.success) {
          const categoriesData: any = cache.readQuery({ query: GET_CATEGORIES })
          if (categoriesData) {
            const index = categoriesData.categories.findIndex(
              (currCategory: typeof Category) => currCategory?._id === category?._id
            )
            const data = structuredClone(categoriesData)
            data.categories[index] = { ...category }
            cache.writeQuery({ query: GET_CATEGORIES, data })
          }
        } else {
          // only for testing, should be error alert
          open({
            type: "error",
            duration: 4,
            content: (
              <div className="profile-notif-content cursor-default">
                <div className="profile-notif-content-ico">
                  <svg id="Layer_1" version="1.1" viewBox="0 0 38.3 38.3" x="0px" y="0px">
                    <circle cx="19.2" cy="19.2" fill={"#ED1E79"} r="19.2" />
                    <path
                      d="M27.4,28.1c-0.2,0-0.4-0.1-0.5-0.2L10.4,11.5c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l16.4,16.4
          c0.3,0.3,0.3,0.8,0,1.1C27.8,28.1,27.6,28.1,27.4,28.1z"
                      fill={"#FFFFFF"}
                    />
                    <path
                      d="M10.9,28.1c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l16.4-16.4c0.3-0.3,0.8-0.3,1.1,0
          s0.3,0.8,0,1.1L11.5,27.9C11.3,28.1,11.1,28.1,10.9,28.1z"
                      fill={"#FFFFFF"}
                    />
                  </svg>
                </div>
                <div className="profile-notif-content-text">{result.updateCategoryPresentationPosition.message}</div>
              </div>
            )
          })
        }
      }
    })
  }

  const addPresentationHandler: (options: any, isSection: boolean) => void = async (options, isSection) =>
    await createPresentation({
      ...options,
      optimisticResponse: {
        createPresentation: {
          code: 200,
          success: true,
          message: "Presentation created successfully.",
          job: null,
          presentation: {
            _id: "temp-id",
            id: "temp-id",
            name: options.variables.name,
            icon: options.variables.icon || null,
            isFavourite: false,
            progress: 1,
            mode: options.variables.icon ? "blueprints" : "slides",
            mergeProgress: null,
            pptx: null,
            labels: [],
            thumbUrl: "",
            urlToken: null,
            batchId: "temp-id",
            accessibleByUsers: [],
            accessibleByUnits: [],
            accessibleByAll: true,
            sets: [
              {
                id: "temp-id",
                slides: [],
                __typename: "Set"
              }
            ],
            category: {
              _id: options.variables.category || null,
              parent: null,
              __typename: "Category"
            },
            __typename: "Presentation"
          },
          __typename: "PresentationJobResponse"
        }
      },
      update: (cache, { data: { createPresentation } }) => {
        if (createPresentation.success && options.variables?.isMyPresentation && options.variables?.slides?.length) {
          const cachedMyBatches: { myBatches: Batch[] } = cache.readQuery({
            query: MY_PRESENTATION
          }) || { myBatches: [] }

          cache.writeQuery({
            query: MY_PRESENTATION,
            data: { myBatches: [createPresentation.presentation, ...cachedMyBatches.myBatches] }
          })
        } else {
          const cachedCategoriesData: { categories: (typeof Category)[] } = cache.readQuery({
            query: GET_CATEGORIES
          }) || { categories: [] }
          if (isSection) {
            const categoriesData: (typeof Category)[] = cachedCategoriesData.categories.map(
              (category: typeof Category) => {
                const subCategories = category.subCategories.map((subCategory: typeof Category) => {
                  if (subCategory._id === options.variables?.category) {
                    if (createPresentation.presentation.icon) {
                      return {
                        ...subCategory,
                        collections: [...subCategory.collections, createPresentation.presentation]
                      }
                    } else {
                      return {
                        ...subCategory,
                        presentations: [...subCategory.presentations, createPresentation.presentation]
                      }
                    }
                  }
                  return subCategory
                })
                return { ...category, subCategories }
              }
            )
            if (options.variables.icon) {
              setCategories(categoriesData)
            }
            if (createPresentation.presentation.urlToken) {
              cache.writeQuery({
                query: GET_CATEGORIES,
                data: {
                  categories: categoriesData
                }
              })
            }
          } else {
            const categoriesData: (typeof Category)[] = cachedCategoriesData.categories.map(
              (category: typeof Category) => {
                if (category._id === options.variables?.category) {
                  if (createPresentation.presentation.icon) {
                    return {
                      ...category,
                      collections: [...category.collections, createPresentation.presentation]
                    }
                  } else {
                    return {
                      ...category,
                      presentations: [...category.presentations, createPresentation.presentation]
                    }
                  }
                }
                return category
              }
            )
            if (options.variables.icon) {
              setCategories(categoriesData)
            }
            if (createPresentation.presentation.urlToken) {
              cache.writeQuery({
                query: GET_CATEGORIES,
                data: {
                  categories: categoriesData
                }
              })
            }
          }
        }
      }
    })

  const editPresentationHandler: (variables: any) => void = async (variables: any) => {
    await updatePresentation({
      variables,
      optimisticResponse: {
        updatePresentation: {
          code: 200,
          success: true,
          message: "Presentation was successfully updated.",
          presentation: {
            _id: "temp-id",
            id: variables.id,
            name: variables.name,
            icon: variables.icon,
            isFavourite: false,
            progress: 1,
            mode: "blueprints",
            mergeProgress: null,
            pptx: null,
            labels: [],
            urlToken: null,
            thumbUrl: "",
            batchId: variables.id,
            accessibleByUsers: [],
            accessibleByUnits: [],
            sets: [
              {
                id: "temp-id",
                slides: [],
                __typename: "Set"
              }
            ],
            category: {
              _id: variables.category,
              parent: null,
              __typename: "Category"
            },
            __typename: "Presentation"
          },
          __typename: "PresentationJobResponse"
        }
      },
      onCompleted: (data) => {
        const { updatePresentation } = data
        if (updatePresentation.success) {
          if (history.location.pathname.includes(updatePresentation.presentation.urlToken)) {
            history.replace(
              `/library/${updatePresentation.presentation.urlToken}/${slugify(updatePresentation.presentation.name)}`
            )
          }
        }
      }
    })
  }

  const removeBatchFromCategories = (categories: (typeof Category)[], batch: Batch) => {
    for (let i = 0; i < categories.length; i++) {
      if (batch.icon) {
        const index = categories[i].collections.findIndex(
          (collection: typeof Category) => collection.id === batch.batchId
        )
        if (index !== -1) {
          categories[i].collections.splice(index, 1)
          return true
        }
      } else {
        const index = categories[i].presentations.findIndex(
          (presentation: typeof Category) => presentation.id === batch.batchId
        )
        if (index !== -1) {
          categories[i].presentations.splice(index, 1)
          return true
        }
      }

      if (categories[i].subCategories) {
        if (removeBatchFromCategories(categories[i].subCategories, batch)) {
          return true
        }
      }
    }
    return false
  }

  const removeBatchHandler = (batch: Batch) => {
    const currentCategories = cloneDeep(categories)
    const removed = removeBatchFromCategories(currentCategories, batch)

    removed &&
      client.cache.modify({
        fields: {
          categories: (): any => currentCategories
        }
      })
  }

  const setCollapsedHandler = async (presentationsName: string, categoryId: string, state: boolean) => {
    const mutator = presentationsName === "Collections" ? setCollectionsCollapsed : setPresentationsCollapsed
    await mutator({
      variables: {
        categoryId,
        state
      },
      update: (cache, { data }) => {
        const response = data.setCollectionsCollapsed || data.setPresentationsCollapsed
        cache.modify({
          fields: {
            categories(existingCategories = []) {
              return existingCategories.map((category: typeof Category) => {
                if (category._id === response.category._id) {
                  return {
                    ...category,
                    isCollectionsCollapsed: response.category.isCollectionsCollapsed,
                    isPresentationsCollapsed: response.category.isPresentationsCollapsed
                  }
                }
                return category
              })
            }
          }
        })
      }
    })
  }

  const handleMoveBatchToCategory = async (category: typeof Category, batch: Batch) => {
    const moveToCategoryUrl = `/library/${category.urlToken}/${slugify(category.name)}`
    await movePresentationToCategory({
      variables: { id: batch.id, category: category._id },
      update: (cache) => {
        const categoriesData: { categories: (typeof Category)[] } = cache.readQuery({ query: GET_CATEGORIES }) || {
          categories: []
        }
        const structuredData = structuredClone(categoriesData)
        if (!batch.category) {
          const myBatchesData: { myBatches: Batch[] } = cache.readQuery({ query: MY_PRESENTATION }) || { myBatches: [] }
          const filteredBatches = myBatchesData.myBatches.filter((item) => batch.id !== item.id)

          cache.writeQuery({ query: MY_PRESENTATION, data: { myBatches: filteredBatches } })

          if (categoriesData) {
            const index = categoriesData.categories.findIndex(
              (currCategory: typeof Category) => currCategory?._id === category?._id
            )

            if (batch.icon) {
              structuredData.categories[index] = {
                ...structuredData.categories[index],
                collections: [
                  { ...batch, category: { __typename: "Category", _id: category._id } },
                  ...structuredData.categories[index].collections
                ]
              }
            } else {
              structuredData.categories[index] = {
                ...structuredData.categories[index],
                presentations: [
                  { ...batch, category: { __typename: "Category", _id: category._id } },
                  ...structuredData.categories[index].presentations
                ]
              }
            }

            setCategories(structuredData.categories)
            cache.writeQuery({ query: GET_CATEGORIES, data: structuredData })
          }
        } else {
          if (categoriesData) {
            structuredData.categories = structuredData.categories.map((currCategory: typeof Category) => {
              if (currCategory._id === batch?.category?._id) {
                if (batch.icon) {
                  return {
                    ...currCategory,
                    collections: currCategory.collections.filter((collection: Batch) => collection.id !== batch.id)
                  }
                } else {
                  return {
                    ...currCategory,
                    presentations: currCategory.presentations.filter(
                      (presentation: Batch) => presentation.id !== batch.id
                    )
                  }
                }
              } else if (currCategory?._id === category?._id) {
                if (batch.icon) {
                  return {
                    ...currCategory,
                    collections: [
                      { ...batch, category: { ...batch.category, _id: category._id } },
                      ...currCategory.collections
                    ]
                  }
                } else {
                  return {
                    ...currCategory,
                    presentations: [
                      { ...batch, category: { ...batch.category, _id: category._id } },
                      ...currCategory.presentations
                    ]
                  }
                }
              } else {
                return {
                  ...currCategory,
                  subCategories: currCategory.subCategories.map((currSubCategory: typeof Category) => {
                    if (currSubCategory?._id === batch?.category?._id) {
                      if (batch.icon) {
                        return {
                          ...currSubCategory,
                          collections: currSubCategory.collections.filter(
                            (collection: Batch) => collection.id !== batch.id
                          )
                        }
                      } else {
                        return {
                          ...currSubCategory,
                          presentations: currSubCategory.presentations.filter(
                            (presentation: Batch) => presentation.id !== batch.id
                          )
                        }
                      }
                    }
                    return currSubCategory
                  })
                }
              }
            })
            setCategories(structuredData.categories)
            cache.writeQuery({ query: GET_CATEGORIES, data: structuredData })
          }
        }
      },
      onCompleted: async ({ updatePresentation }) => {
        if (updatePresentation.success) {
          open({
            type: "success",
            duration: 3,
            content: (
              <div className="profile-notif-content">
                <div className="profile-notif-content-ico">
                  <svg version="1.1" viewBox="-470 271 19 19" x="0px" y="0px">
                    <path
                      d="M-460.5,271c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5S-455.3,271-460.5,271z
              M-456.3,278l-4.9,5.9c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3l-2.4-3c-0.2-0.2-0.2-0.7,0.2-1c0.3-0.2,0.8-0.2,1,0.1
              l1.9,2.3l4.3-5.2c0.3-0.3,0.7-0.4,1.1-0.1C-456,277.2-456,277.7-456.3,278z"
                      fill={"#3390FF"}
                    />
                  </svg>
                </div>
                <div className="profile-notif-content-text">
                  <p>
                    Presentation moved successfully. Click <Link to={moveToCategoryUrl}>here</Link> to preview.
                  </p>
                </div>
              </div>
            )
          })
        } else {
          open({
            type: "error",
            duration: 4,
            content: (
              <div className="profile-notif-content cursor-default">
                <div className="profile-notif-content-ico">
                  <svg id="Layer_1" version="1.1" viewBox="0 0 38.3 38.3" x="0px" y="0px">
                    <circle cx="19.2" cy="19.2" fill={"#ED1E79"} r="19.2" />
                    <path
                      d="M27.4,28.1c-0.2,0-0.4-0.1-0.5-0.2L10.4,11.5c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l16.4,16.4
          c0.3,0.3,0.3,0.8,0,1.1C27.8,28.1,27.6,28.1,27.4,28.1z"
                      fill={"#FFFFFF"}
                    />
                    <path
                      d="M10.9,28.1c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l16.4-16.4c0.3-0.3,0.8-0.3,1.1,0
          s0.3,0.8,0,1.1L11.5,27.9C11.3,28.1,11.1,28.1,10.9,28.1z"
                      fill={"#FFFFFF"}
                    />
                  </svg>
                </div>
                <div className="profile-notif-content-text">{updatePresentation.message}</div>
              </div>
            )
          })
        }
      },
      optimisticResponse: {
        updatePresentation: {
          code: 200,
          success: true,
          message: "Presentation was successfully updated.",
          __typename: "PresentationResponse"
        }
      }
    })
  }

  if (error) {
    return <div>Error loading categories</div>
  }

  return (
    <CategoryContext.Provider
      value={{
        categories,
        getCategory,
        loadCategories,
        createCategory: createCategoryHandler,
        removeCategory: removeCategoryHandler,
        renameCategory: renameCategoryHandler,
        reorderCategory: reorderCategoryHandler,
        moveSubCategory: handleMoveSubCategory,
        addPresentation: addPresentationHandler,
        editPresentation: editPresentationHandler,
        onRemoveBatch: removeBatchHandler,
        changePresentationPosition: changePresentationPositionHandler,
        setCollapsed: setCollapsedHandler,
        moveBatchToCategory: handleMoveBatchToCategory,
        loading
      }}
    >
      {children}
    </CategoryContext.Provider>
  )
}
