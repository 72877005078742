import React from "react"
import Presentation from "../presentation/Presentation"
import slugify from "slug"
import Slide from "../slide/Slide"
import { useHistory } from "react-router-dom"

const PowerPointFavourites = ({ presentations, slides, refetch }) => {
  const history = useHistory()
  const handleViewBatch = (batchUrlToken, pathName, slides) => {
    history.push({
      pathname: `/dashboard/${batchUrlToken}/${slugify(pathName)}`,
      state: { slides, currentPathname: pathName }
    })
  }

  return (
    <div>
      {presentations.length > 0 && (
        <div className="mt-[20px]">
          <h2 className="text-[9px] text-[#717171] uppercase mb-[15px] ">Presentations</h2>
          <div
            className={
              "grid gap-[15px] min-[339px]:grid-cols-2 w-full tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
            }
          >
            {presentations.map((presentation) => (
              <Presentation
                active={false}
                batch={presentation}
                handleViewBatch={handleViewBatch}
                hideTools
                id={presentation.id}
                isFavourite={presentation.isFavourite}
                isSearchPage={true}
                key={presentation.id}
                name={presentation.name || "No given name"}
                noActions
                refetch={refetch}
              />
            ))}
          </div>
        </div>
      )}
      {slides.length > 0 && (
        <div className="mt-[20px]">
          <h2 className="text-[9px] text-[#717171] uppercase mb-[15px] ">Slides</h2>
          <div className={"flex flex-col gap-[15px]"} style={{ clear: "both" }}>
            {slides.map((slide, index) => {
              return (
                <div key={slide.id}>
                  <div
                    className={
                      "grid gap-[20px] mobile-sm:grid-cols-1 w-full tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
                    }
                  >
                    <Slide
                      index={index}
                      isSearchPage
                      noActions
                      sets={[
                        {
                          slides: slides
                        }
                      ]}
                      slide={slide}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default PowerPointFavourites
