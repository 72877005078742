import { ApolloClient, ApolloLink, HttpLink } from "@apollo/client"
import { InMemoryCache } from "@apollo/client/cache"
import { onError } from "@apollo/client/link/error"

// TODO: BU: remove this cache and refactor it at some point for the new sc-api
const cache = new InMemoryCache({
  dataIdFromObject: (object) => object.id,
  typePolicies: {
    Session: {
      merge: true
    },
    User: {
      fields: {
        myBatches: {
          merge(_, incoming) {
            return incoming
          }
        }
      }
    },
    SetOfUsersSlides: {
      fields: {
        slides: {
          merge(_, incoming) {
            return incoming
          }
        }
      }
    },
    UsersBatch: {
      fields: {
        sharedForUnits: {
          merge(_, incoming) {
            return incoming
          }
        }
      }
    },
    Mutation: {
      fields: {
        session: {
          merge: true
        }
      }
    }
  }
})

const createClient = (errorHandler) => {
  const httpLink = new HttpLink({ uri: `${import.meta.env.VITE_SC_API_GATEWAY_PATH}/graphql` })
  const httpLinkNew = new HttpLink({ uri: `${import.meta.env.VITE_SC_API_PATH}/graphql` })

  const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken") || "NONE"}`,
        "screen-dimensions": `${window.screen.width}x${window.screen.height}`,
        timezone: new Date().getTimezoneOffset() / 60
      }
    })
    return forward(operation)
  })
  const afterwareLink = onError((error) => {
    errorHandler(error)
  })

  const httpLinkWithMiddleware = middlewareLink.concat(afterwareLink).concat(httpLink)
  const httpLinkWithMiddlewareNew = middlewareLink.concat(afterwareLink).concat(httpLinkNew)

  const link = ApolloLink.split(
    (operation) => operation.getContext().isUsingNewScApi, // if useQuery(SOME_QUERY, { context: { isUsingNewScApi: true } }) then use new sc-api graphql endpoint
    httpLinkWithMiddlewareNew,
    httpLinkWithMiddleware
  )

  const runningInDevelopment = import.meta.env.DEV // enable devtools only in development

  return new ApolloClient({ link, cache, addTypeName: true, connectToDevTools: runningInDevelopment })
}

export default createClient
